/*eslint-disable */
/* eslint-disable */
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import { Row, Col, Label, Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter,Card, CardTitle, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SingleAttributeDisplay from '../../SingleAttributeDisplay';
import AlarmAttributeDisplay from '../../AlarmAttributeDisplay';
import classnames from 'classnames';
import { getLastMsgBySingleAssetAsync, extractLastMsgs, extractSingleAssetLastMsg, extractSingleAssetMetaData } from '../../../../../redux/actions/surveilenceActions';
import { WEB_SUB_CHANNEL, WEB_SUB_EVENT } from '../../../../../constants/appConstants';
import { WEB_SUB_SOCKET } from '../../../../../constants/appConstants';
import {connect} from 'react-redux';
import { getJwttoken } from '../../../../../factories/utils';
import { Client } from '@stomp/stompjs';
import { bindActionCreators } from 'redux';
import { loadAssets, loadClasses, postAssetAttribute } from '../../../../../redux/actions/assetActions';

var stompClient;

// const WS_ROOT = 'wss://wssdemo.itsp-inc.com';

class DragAssetModalIcon extends Component {
    constructor(props){
        super(props);
        // this.eventSource = new EventSource(WEB_SUB_CHANNEL);
        this.state={
            pubSubData: null,
            assetId: props.assetid,
            modal: false,
            deviceData:{},
            iconData: {},
            locked: this.props.locked
            
        }
    }

    componentDidMount(){
        console.log('======================SENSOR MOUNTED=======================');
        if(this.props.assetname.includes("Tortoise Sensor") || this.props.assetname.includes("OPG") ){
            this.connectSocket()
        }
        // this.eventSource.addEventListener(WEB_SUB_EVENT, e=>{
        //     let eventData = e.data;
        //     let newData = extractLastMsgs(eventData);
        //     if(newData === undefined){
        //         console.log('UNDEFINED DATA');
        //     }else{
        //         console.log('NEW DATA');
        //     }
        //     // this.setState({pubSubData: e.data});
        // })
        var deltaX, deltaY;
        var self = this;
        this.loadAsset();
        let locked = this.state.locked;
        const handleDrag = d3.drag()
        .subject(function() {
            const me = d3.select(this);
            return { x: me.attr('x'), y: me.attr('y') }
          })
        // .on("start", function () {6
        //     var current = d3.select(this);
        //     deltaX = current.attr("x") - d3.event.x;
        //     deltaY = current.attr("y") - d3.event.y;
        // })
        .on('drag', function(){
            const me = d3.select(this);
            if(!locked){
                me.attr('x', d3.event.x);
                me.attr('y', d3.event.y);
            }
            console.log('x', d3.event.x)
            console.log('y', d3.event.y)
        })
        .on('end', function() {
            const me = d3.select(this);
            if(!locked){
                self.props.updatePosition(d3.event.x, d3.event.y);
            }
        });
        
        const node = ReactDOM.findDOMNode(this.refs.useref);
        const tooltipid = ReactDOM.findDOMNode(this.refs.tooltipref);
        // const tooltipid = ReactDOM.findDOMNode(this.props.tooltipref);
        var icon = d3.select(node);
        var tooltip = d3.select(tooltipid);
         
        // var tooltip = d3.select("#tooltipid");
        // var icon = d3.select("#use1");

        // tooltip.text(this.props.assetname);

        icon.on('click', function() {
            // alert('click');
            self.toggle();
        })
        .on("mouseover", function(){
            //  
            return tooltip.style("visibility", "visible").style("font-size", "25px");
        })

        .on("mousemove", function(){ 
            //   
            //   
            //  
            //   
            //   
            // return tooltip.style("top", (event.pageY-80)+"px")
            //             .style("left",(event.pageX-230)+"px")
            //             .text(self.props.assetname);
            //  
            //  
            // return tooltip.style("top", (d3.event.offsetY+30)+"px")
            // .style("left",(d3.event.offsetX)+"px")
            // .text(self.props.assetname);
            return tooltip.attr("y", (d3.event.offsetY+30))
            .attr("x",(d3.event.offsetX))
            .text(self.props.assetname);
        })
        .on("mouseout", function(){
            //  
            return tooltip.style("visibility", "hidden");
        });
        handleDrag(icon);
        // handleDrag(d3.select("#use1"));
    }
    
    connectSocket = () => {
        if (stompClient !== null && stompClient !== undefined) {
            stompClient.deactivate();
            //stompClient = null;

        }

        let query = '?q=' + getJwttoken();
        ///////////////////////////////////////////////////
        stompClient = new Client({
            brokerURL: WEB_SUB_SOCKET + query,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: this.connect_callback,
            onDisconnect: this.disconnect_callback
        });

        stompClient.activate();
        //////////////////////////////////////////////////
        //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
        //stompClient = Stomp.over(socket_URL); 
        //stompClient.reconnectDelay=5000; 
        //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;

    }

    connect_callback = () => {
        // called back after the stompClient is connected and authenticated to the STOMP server
        // James: Comment these 2 stompClient.subscirbe to avoid auto refresh data on staion deatil page. (Refresh time too close to read the chart for client.)
        // stompClient.subscribe(WEB_SUB_WEBSOCKET_WEATHER_DEVICEMESSAGE, this.callbackDeviceMessage);
        // stompClient.subscribe(WEB_SUB_WEBSOCKET_WEATHER_ALERTMESSAGE, this.callbackAlertMessage);
        stompClient.subscribe("/topic/deviceMessageOPG", this.callbackOPGMessage);

    }

    disconnect_callback = () => {
        stompClient.deactivate();
        // called back after the stompClient is connected and authenticated to the STOMP server
        console.log('client disconnected')

    }


    callbackOPGMessage = (message) => {
        // called when the client receives a STOMP DeviceMessage from the server
        console.log("BEACON VISIBLE", JSON.parse(message.body), this.props.beaconList)
        let msg = JSON.parse(message.body).msgs[0].msg //extract msg from socket data
        let beaconId = msg.MessageId.value == 'Beacon Visible' ? msg.BeaconId.value : null
        let visibleBeacon;
        let vBeaconPos;
        if (beaconId && this.props.beaconList && this.props.beaconList.length > 0) {
            visibleBeacon = this.props.beaconList.find(b=>{
                let targetId = b.assetattributes.find(a=>a.attmetaname == 'beacon id').value
                return targetId  == beaconId
            })
            vBeaconPos = visibleBeacon ? visibleBeacon.assetattributes.find(a=> a.attmetaname == 'position'): null;
            console.log('FOUND', visibleBeacon, vBeaconPos.value)
        
        }else{
            console.warn('Not a Beacon Visible message')
        }

        if(vBeaconPos){
            let parsedPos = JSON.parse(vBeaconPos.value)
            console.log(this.props.x, this.props.y, parsedPos)
            //update position
            this.props.updatePosition(parsedPos.x + 10, parsedPos.y + 10)
            this.props.loadAssets();
        }

    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.assetid !== prevProps.assetid){
            this.loadAsset();
        }

        // console.log("LOCKED IS", this.props.locked)

        if(prevProps.locked !== this.props.locked){
            var self = this;
            let locked = this.state.locked; //check if screen is locked
            const handleDrag = d3.drag()
            .subject(function() {
                const me = d3.select(this);
                return { x: me.attr('x'), y: me.attr('y') }
              })
            // .on("start", function () {
            //     var current = d3.select(this);
            //     deltaX = current.attr("x") - d3.event.x;
            //     deltaY = current.attr("y") - d3.event.y;
            // })
            .on('drag', function(){
                const me = d3.select(this);
                if(!locked){
                    me.attr('x', d3.event.x);
                    me.attr('y', d3.event.y);
                }
                console.log('x', d3.event.x)
                console.log('y', d3.event.y)
            })
            .on('end', function() {
                const me = d3.select(this);
                console.log('end x', d3.event.x);
                if(!locked){
                    self.props.updatePosition(d3.event.x, d3.event.y);
                }
            });
            
            const node = ReactDOM.findDOMNode(this.refs.useref);
            const tooltipid = ReactDOM.findDOMNode(this.refs.tooltipref);
            // const tooltipid = ReactDOM.findDOMNode(this.props.tooltipref);
            var icon = d3.select(node);
            var tooltip = d3.select(tooltipid);
            console.log('props tooltipref', this.props.assetname);
            // var tooltip = d3.select("#tooltipid");
            // var icon = d3.select("#use1");
    
            // tooltip.text(this.props.assetname);
    
            icon.on('click', function() {
                // alert('click');
                self.toggle();
            })
            .on("mouseover", function(){
                // console.log('mouseover');
                return tooltip.style("visibility", "visible").style("font-size", "25px");
            })
            .on("mousemove", function(){ 
                // console.log('event page x', event.pageX); 
                // console.log('event x', event.x  ); 
                // console.log('event offsetX', event.offsetX);
                // console.log('event',event); 
                // console.log('eventd3',d3.event.pageY); 
                // return tooltip.style("top", (event.pageY-80)+"px")
                //             .style("left",(event.pageX-230)+"px")
                //             .text(self.props.assetname);
                // console.log('mousemove');
                // console.log('tooltip', tooltip.attr('x')); //me.attr('x', d3.event.x);
                // return tooltip.style("top", (d3.event.offsetY+30)+"px")
                // .style("left",(d3.event.offsetX)+"px")
                // .text(self.props.assetname);
                return tooltip.attr("y", (d3.event.offsetY+30))
                .attr("x",(d3.event.offsetX))
                .text(self.props.assetname);
            })
            .on("mouseout", function(){
                // console.log('mouseout');
                return tooltip.style("visibility", "hidden");
            });
            handleDrag(icon);
            // handleDrag(d3.select("#use1"));
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.port && nextProps.port != prevState.port){
            return{
                port: nextProps.port
            }
        }
        if (nextProps.assetid && nextProps.assetid != prevState.assetId){
            return{
                assetId: nextProps.assetid
            }
        }
        if (nextProps.updateData && nextProps.updateData.assetid == prevState.assetId){
            let newData = extractLastMsgs(nextProps.updateData);
            if (newData){
                // console.log("UPDATE DATA, ", newData)
                return {
                    deviceData: {...prevState.deviceData, ...newData},
                    motion_count: prevState.motion_count + 1,
                    alarm: true,
                }
            }else{
                return {
                    motion_count: prevState.motion_count + 1,
                    alarm: true,
                }
            }
        }
    }

    loadAsset = () => {
        // let assetid = this.state.assetId
        let assetid = this.props.assetid
        getLastMsgBySingleAssetAsync(assetid)
        .then(response => {
            // console.log("==SENSOR REDIS RESPONSE====", response)
            //  
            //  
            let newData = extractSingleAssetLastMsg(response, assetid);
            let metaData = extractSingleAssetMetaData(response, assetid);
            let iconData = response[0].assetIcon
            //  
            newData = newData ? newData : {}
            // console.log('SENSOR DATA', newData);           
            this.setState(preState=>(
                {
                    deviceData: {...preState.deviceData, ...newData},
                    assetMeta: metaData,
                    iconData: iconData
                }
            ));
        })
    }

    toggle = () => {
        this.setState(prevState=>({
            modal: ! prevState.modal,
        }));
    }

    tabToggle = (tab) => {
        this.setState(preState => (
            {
                activeTab: tab
            }
        ));
    }

    switchOff = () => {
        this.setState({
            alarm: false,
        });
    }

    getMetaDataByName = (name) => {
        let reVal = {};
        if (this.state.assetMeta){
            for (let value of this.state.assetMeta) {
                if (value.attmetaname === name) {
                    reVal = value;
                    break;
                }
            }
        }
        return reVal;
    }

    renderMetaInfo = () => {
        let name = this.getMetaDataByName("name");
        let position = this.getMetaDataByName("camera location");
        return (
            <div>
            <Row>
                <Col md={2}>
                    <h3>Camera:</h3>
                </Col>
                <Col md={4} style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontSize: "20px"}}>{name.value}</span>
                </Col>
                <Col md={2}>
                    <h3>Location:</h3>
                </Col>
                <Col md={4} style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontSize: "20px"}}>{position.value}</span>
                </Col>
            </Row>
            <hr/>
            </div>
        );
    }

    renderArrtibutes = () => {
        return (
            <Row>
                {this.state.deviceData && Object.values(this.state.deviceData).map(attributeData => {
                    if(attributeData.displayname && attributeData.displayname == 'PM25'){ //hide PM25
                        return null
                    }else{
                        console.log("========== DISPLAY SENSOR DATA", attributeData)
                        return (
                            <Col md={6}>
                                <SingleAttributeDisplay
                                    assetId = {this.state.assetId}
                                    attribureData = {attributeData}
                                />
                            </Col>
                        )
                    }
                }
                )}
            </Row>
        );
    }

    render(){
        const {assetname, url, httpurl, icon, x, y, ...props} = this.props;
        let name = this.getMetaDataByName("name");
        let tital = name.value ? name.value : this.props.assetname
        let portObj = this.getMetaDataByName("port");
        let positionObj = this.getMetaDataByName("position");

        // let port = portObj.value? portObj.value : null;
        // console.log('chid - drag camera',props.chid)
        let pos = null
        try {
            pos = positionObj ? JSON.parse(positionObj.value) : {}
        }
        catch(ex){
            pos = {}
        }
        return(
            <Fragment>
                <defs>
                    <g id={this.props.assetIconId} transform="scale(1.75)" style={{cursor: 'pointer'}}>
                        {/* <rect width="150" height="100" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        
                        <path d={this.props.assetIcon}/>
                    </g>
                </defs>
                {/* <use id="use1" ref='useref' xlinkHref={"#circle1"} x={'100'} y={'100'}/> */}
                {/* <Tooltip title="Add" placement="top-start"> */}
                <use id="use1" ref='useref' xlinkHref={`#${this.props.assetIconId}`} x={ x? x : '50'} y={ y? y : '50'} fill={props.status ? props.status == "online" ? "orange" : "red" : "orange"} />
                <text id="tooltipid" ref='tooltipref' x={ pos.x? pos.x : '50'} y={ pos.y? pos.y : '50'} fill="red"></text>
                {/* </Tooltip> */}
                {/* <UncontrolledTooltip placement="right" target="use1">
                    {assetname}
                </UncontrolledTooltip> */}
                {/* <div ref='tooltipref' style={{position: 'absolute', visibility: 'visible'}}>
                    {assetname}
                    testststsetestst
                </div> */}
                <Modal
                    animationType="slide"
                    transparent={false}
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    size = {'lg'}
                >
                    <ModalHeader
                        toggle={this.toggle}
                        //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                    >
                        <span style={{fontSize: "20px"}}>{tital}</span>
                        {/* <CameraIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/> */}
                    </ModalHeader>
                    <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                        {/* <VideoPlayer url={'ws://192.168.2.89:8081/webSocketService'}/> */}
                        {/* <VideoPlayer url={url} httpurl={httpurl}/> */}
                        {/* <VideoMpegPlayer/> */}
                        {/* {this.renderMetaInfo()} */}
                        <div>
                            {this.renderArrtibutes()}
                            {/* <AlarmAttributeDisplay 
                                status={this.state.alarm}
                                count={this.state.motion_count}
                                switchOff={this.switchOff}
                            /> */}
                            {this.props.device && this.props.device.lastmessagetime &&
                            <div className='sensorStatus'>Last message: {new Date(this.props.device.lastmessagetime).toLocaleString("en-CA", { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: "false"})}</div>
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => (
    {
        beaconList: state.asset.assetList.filter(asset=> asset.assetclassname.includes("beacon")),
    }
)

const mapDispatchToProps = (dispatch) =>{
    return bindActionCreators({loadAssets}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DragAssetModalIcon);