/* eslint-disable */
import React, {useEffect,useState} from 'react'
import { LabelTextInput} from '../cameraList/CameraCreateModal';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse, CardTitle } from 'reactstrap';
import Select from 'react-select'
import { Fragment } from 'react';
import ImageUploadForm from './ImageUploadForm';
import TagsInput from 'react-tagsinput'
import MultipleValueTextInput from 'react-multivalue-text-input';


export default function CreateVisitEventModal({toggleVisitSetup, modalOpen, handleVisitSubmit}) {
    const [VisitInfo, setVisitInfo] = useState({
        // name: '',
        // phone: '',
        // email: '',
        // scheduleDate: '',
        // scheduleTime: '',
        // event:'',
        // picture: '',
        alertReceiverPhones:[],
        alertReceiverEmails:[],
        location: 'Office',
        subject: 'Surveillance Alert',
        // status: '',
        // comment: ''
    })
    

    useEffect(() => {
        //console.log(VisitInfo)
    }, [VisitInfo])
    const eventPurposes = [
        {value: 'Meeting', label: "Meeting" },
        {value: 'Visit', label: "Visit" },
        {value: 'Maintenance', label: "Maintenance" },
    ]

    const eventStatus = [
        {value: 'ongoing', label: "Ongoing"},
        {value: 'finished', label: "Finished"},
    ]
    const {name, phone, email, scheduleDate, scheduleTime, event, alertReceiverPhones, alertReceiverEmails, picture, status, location, subject} = VisitInfo;


    const handleChangeField = name => e =>{
        if(name == 'picture'){
            e.preventDefault();
            let files = e.target.files
            if(files && files.length > 0){
                //console.log("Picture: ", files[0])
                setVisitInfo({...VisitInfo, picture: files[0]})
            }
        }else{

            //console.log(name, e.target.value)
            setVisitInfo({...VisitInfo, [name]: e.target.value})
        }
    }
    
    const handleEventSelectChange = name =>e =>{
        //console.log("Select", e.value, " as", name);
        setVisitInfo({...VisitInfo, [name]: e.value})
    }

    const handleAddAlertReceiverPhones = (phone) => {
        //console.log("=======ITEM ADDED", phone);
        let temp = VisitInfo.alertReceiverPhones;
        temp.push(phone)
        setVisitInfo({...VisitInfo, alertReceiverPhones: temp})
    }

    const handleDeleteReceiverPhones = (phone) => {
        //console.log("ITEM DELETED",phone);
        let temp = VisitInfo.alertReceiverPhones;
        const index = temp.indexOf(phone);
        if(index > -1){
            temp.splice(index, 1)
        }
        setVisitInfo({...VisitInfo, alertReceiverPhones: temp})
    }

    const handleAddAlertReceiverEmails = (email) => {
        //console.log("======EMAIL ADDED", email);
        let temp = VisitInfo.alertReceiverEmails;
        temp.push(email)
        setVisitInfo({...VisitInfo, alertReceiverEmails: temp})
    }

    const handleDeleteReceiverEmails = (email) => {
        //console.log("EMAIL DELETED",email);
        let temp = VisitInfo.alertReceiverEmails;
        const index = temp.indexOf(email);
        if(index > -1){
            temp.splice(index, 1)
        }
        setVisitInfo({...VisitInfo, alertReceiverEmails: temp})
    }

    const handleSubmit = ()=>{
        //console.log(VisitInfo);
        handleVisitSubmit(VisitInfo);
        toggleVisitSetup();
    }

    return (
        <div>
            <Modal toggle={toggleVisitSetup} isOpen={modalOpen}>
            <ModalHeader toggle={toggleVisitSetup} tag="h3">Alert Setup</ModalHeader>
            <ModalBody>
            {/* <LabelTextInput tag="h5" forIdName="name" label="Name" value={name} type="text" onChange={handleChangeField('name')}/>
            <LabelTextInput tag="h5" forIdName="email" label="Email" value={email} type="text" onChange={handleChangeField('email')}/>
            <LabelTextInput tag="h5" forIdName="phone" label="Phone" value={phone} type="text" onChange={handleChangeField('phone')}/>
            <LabelTextInput tag="h5" forIdName="scheduleDate" label="Date" value={scheduleDate} type="date" onChange={handleChangeField('scheduleDate')}/> */}
            {/* <FormGroup row>
                <Col md={3}>
                    <Label tag="h5" htmlFor={"picture"}>Guest Picture</Label>
                </Col>
                <Col md={9}>
                    <Input id="picInput" name="picture" type="file"/>
                </Col>
            </FormGroup> */}
            <FormGroup row>
                <Col md={3}>
                    <Label tag="h5" htmlFor={"receivers-phone"}>Alert Receiver Phones</Label>
                </Col>
                <Col md={9}>
                    <MultipleValueTextInput
                        name="receivers-phone"
                        values = {VisitInfo.alertReceiverPhones}
                        onItemAdded={(item,allItems)=>handleAddAlertReceiverPhones(item)}
                        onItemDeleted={(item,allItems)=>handleDeleteReceiverPhones(item)}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Col md={3}>
                    <Label tag="h5" htmlFor={"receivers-email"}>Alert Receiver Emails</Label>
                </Col>
                <Col md={9}>
                    <MultipleValueTextInput
                        name="receivers-email"
                        values = {VisitInfo.alertReceiverEmails}
                        onItemAdded={(item,allItems)=>handleAddAlertReceiverEmails(item)}
                        onItemDeleted={(item,allItems)=>handleDeleteReceiverEmails(item)}
                    />
                </Col>
            </FormGroup>
            {/* <WaivSelectInput label="Event Purpose" value={event} name="event" options= {eventPurposes} onChange={handleEventSelectChange('event')}/> */}
            {/* <WaivSelectInput label="Status" value={status} name="status" options={eventStatus} onChange={handleEventSelectChange('status')}/> */}
            <LabelTextInput tag="h5" forIdName="location" label="Location" value={location} type="textarea" onChange={handleChangeField('location')}/>
            <LabelTextInput tag="h5" forIdName="subject" label="Subject" value={subject} type="textarea" onChange={handleChangeField('subject')}/>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleSubmit}>Submit</Button>
                <Button color="custom" onClick={toggleVisitSetup}>Cancel</Button>
            </ModalFooter>
            </Modal>
        </div>
    )
}

export const WaivSelectInput = ({label, name, onChange, value, options})=>{
    return(
        <FormGroup row>
            <Col md={3} style={{ textAlign: 'left' }}>
                <Label tag="h5" htmlFor='react-select-2-input'>{label}</Label>
            </Col>
            <Col md={8} style={{ textAlign: 'left' }}>
                <Fragment>
                    <Select options={options} onChange={onChange} defaultValue={value} name={name}></Select>
                </Fragment>
            </Col>
        </FormGroup>
    )
}
