/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableLargeIcon from 'mdi-react/TableLargeIcon';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';
import {loadDevice, loadAssetClass} from '../../../redux/actions/mapActions';
import {getCurrentUser} from "../../../factories/auth";
import Select from 'react-select';

class AssetClassEditModal extends Component{
    constructor(props){
        super(props);
        this.state={
            tital: 'Dashboard Element',
            modalList: this.props.modalList,
            selectedAssetClassId: null,
            // selectedDeviceTypeId: null,
            modal: true,
        }
        this.userObj = getCurrentUser();
        if( this.userObj.detail==null){
            return;
        }
        // console.log('this.userObj.detail', this.userObj.detail);
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color:  "black",
                '&:active': {backgroundColor: this.userObj.detail.appButtonColorHover},
                backgroundColor:  state.isFocused ? this.userObj.detail.appButtonColorHover: "white",
            }),
            control: (base,selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': {borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor}
            }),
            indicatorsContainer:  (base,selectState)  => ({
                ...base,
                padding: "0",
            }),
        }
    }

    componentDidMount(){
        // this.props.loadDevice();
        this.props.loadAssetClass();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.layout && nextProps.layout !== this.state.layout){
            if (nextProps.layout.parameter){
                let parameter = JSON.parse(nextProps.layout.parameter);
                if (parameter.assetid){
                    this.setState({
                        currentAssetClassId: parameter.assetid,
                        currentParameter: nextProps.layout.parameter
                    });
                }
                // else if (parameter.devicetype){
                //     this.setState({
                //         currentDeviceTypeId: parameter.devicetype
                //     });
                // }
            }
            
            this.setState({
                currentName: nextProps.layout.name,
                layout: nextProps.layout
            });
        }
    }


    onAssetClassChange = (e) => {
        let value = e.assetclassid;
        let assetClass = this.props.assetClasses.filter(asset=> asset.assetclassid == value);
        let assetClassName = assetClass.length > 0 ? assetClass[0].assetclassname: null;
        console.log('ASSET SELECTED, ', value, assetClassName);
        //Special case for OnRoute
        let layoutAssetClassId = []; //array of layout assetclassids
        let parameter;
        if(assetClassName.includes('Layout')){
            let layoutAssetClasses = this.props.assetClasses.filter(asset=>asset.assetclassname.includes('Layout'));
            if(layoutAssetClasses.length > 0){
                layoutAssetClasses.map(assetClass => {
                    layoutAssetClassId.push(assetClass.assetclassid)
                })
            }
            console.log(layoutAssetClassId)
            parameter = JSON.stringify({assetid: layoutAssetClassId});
        }else{  
             parameter = JSON.stringify({assetid: value});
        }
        console.log(parameter);
        this.setState((prevState) => {
            prevState.layout.parameter = parameter;
            return ({
                currentAssetClassId: value,
                currentParameter : parameter,
                layout: prevState.layout,
            })           
        });
    }
    // onDeviceTypeChange = (e) => {
    //     let value = e.target.value;
    //     this.setState({
    //         currentDeviceTypeId: value,
    //         currentParameter : JSON.stringify({devicetype: value})
    //     });
    // }

    handleInputChange =(newVal) => {
        this.setState((prevState)=>{
            if (prevState.layout){
                prevState.layout.name = newVal;
                return ({
                    currentName: newVal,
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        });
    }

    renderTitle =()=> {
        return(
            
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                         type='text'
                         defaultValue={this.props.layout ? this.props.layout.name : ''} 
                         exitOnEnterKeyPress={false}
                         onSave={newVal => {this.handleInputChange(newVal) }}
                    />
                </Col>
            </Row>           
        )
    }

    renderAssetClass = () => {
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select 
                        options = {this.props.assetClasses}
                        getOptionLabel={({ assetclassname }) => assetclassname}
                        getOptionValue={({ assetclassid }) => assetclassid}
                        onChange={this.onAssetClassChange}
                        styles={this.state.customStyles}
                        // value={}
                        // defaultValue={}
                        //onChange = {}

                    />
                </Col>
            </Row>
        )
    }

    onCancleClick = (e) => {
        this.props.toggle();
    }
    onOkClick = (e) => {
        console.log('OK CLICKED');
        console.log(this.state.layout)
        this.props.onEditSubmit(this.state.layout);
        this.props.toggle();
    }
    render(){
        return(
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                size = {'lg'}
                
            >
                <ModalHeader 
                    toggle={this.props.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    {this.renderTitle()}
                    <Row>
                        <Col md={12}>
                            {/* {this.props.layout && this.renderParameterInput(this.props.layout.type)} */}
                            {this.renderAssetClass()}
                        </Col>
                    </Row>
                    <br/>
                
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
function mapStateToProps(state) {
    const types = state.mapboard.types;
    const assetClasses = state.mapboard.assetClass;
    const props = {types,  assetClasses};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            loadDevice, 
            loadAssetClass,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetClassEditModal);