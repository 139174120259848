/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
// import MapBoard from './components/MapBoard';
import TelecomBoard from './components/TelecomBoard';
import { getPageContent } from '../../../src/redux/actions/userAction';

const TelecomPage = (props) => {
  let [isLoaded, setIsLoaded] = useState(false)
  
  useEffect(() => {
    const fetchPageContent = async () => {
      await props.getPageContent({ "content": "Telecom App" })
      setIsLoaded(true)
    }
    fetchPageContent()
  }, []);

  const { pageContentList } = props;

  return (
    <Container className="dashboard">
      <Row>
        <TelecomBoard />
      </Row>
    </Container>
  );

}

function mapStateToProps(state) {
  const { pageContentList } = state.user;
  const props = { pageContentList };
  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
     getPageContent
  }, dispatch);
};

export default (connect(mapStateToProps, mapDispatchToProps))(TelecomPage);
