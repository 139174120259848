/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, InputGroupText, InputGroupAddon, InputGroup, Form, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlusIcon from 'mdi-react/PlusIcon';
import CloseIcon from 'mdi-react/CloseIcon';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import ClientPlanAddOn from './ClientPlanAddOn';
import { TheaterIcon, ThemeLightDarkIcon } from 'mdi-react';

import { getCurrentUser } from '../../../../src/factories/auth';

// const TeisaSamplePlanData = [
//     { name: "OPTI00777" },
//     { name: "OPTI00771" },
//     { name: "OPTI00771" },
//     { name: "SERV00019" },
//     { name: "SERV00020" },
//     { name: "SERV00027" },
// ]

const TeisaSamplePlanData = [
    // { name: "Sigfox Ultra Connect" }
    { name: "Sigfox Basico Connect" },
    { name: "Sigfox Plus Connect" },
    { name: "Sigfox Ultra Connect" },
]

class ClientPlanModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showServiceModal: false,
            resellerCode: null,
            companyCode: null,
            itemExtensionList: [
                { id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 }
            ],
            userObj: getCurrentUser()
        }
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            resellerCode: nextProps.selectedRow.resellerCode,
            companyCode: nextProps.selectedRow.companyCode
        })
        //Add new plan
        if (nextProps.show == true && nextProps.selectedPlan == null) {

            //modal is already open, just recieved new prop like new carrier plan list
            if (this.state.showServiceModal == true) {
                /*
                this.setState({
                    showServiceModal: true,
                    id: 0,
                    carrierId: 0,
                    resellerId: nextProps.selectedRow.id,
                    companyId: nextProps.selectedRow.companyId,
                    itemCode: "",
                    itemName: "",
                    planDataVolume: "",
                    itemDescription: "",
                    price: "",
                    itemPayFrequency: "Monthly",
                    itemPayType: "",
                    pooledFlag: 0,
                    autoRenewFlag: 0,
                    itemExtensionList: [{ id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 }]
                })
                */
            }
            else {
                this.setState({
                    showServiceModal: true,
                    id: 0,
                    carrierId: 0,
                    resellerId: nextProps.selectedRow.id,
                    companyId: nextProps.selectedRow.companyId,
                    itemCode: "",
                    itemName: "",
                    planDataVolume: "",
                    itemDescription: "",
                    price: "",
                    itemPayFrequency: "Monthly",
                    itemPayType: "",
                    pooledFlag: 0,
                    autoRenewFlag: 0,
                    itemExtensionList: [
                        { id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 }]
                })
            }
        }
        //Edit plan
        if (nextProps.show == true && nextProps.selectedPlan != null) {
            let itemPayFrequency = ""
            if (nextProps.selectedPlan.itemPayFrequencyId == 1) {
                itemPayFrequency = "Monthly"
            }
            else if (nextProps.selectedPlan.itemPayFrequencyId == 2) {
                itemPayFrequency = "Yearly"
            }
            else {
                itemPayFrequency = "Monthly"
            }

            //modal is already open, just recieved new prop like new carrier plan list
            if (this.state.showServiceModal == true) {
                //do noting, keep inital state
            }
            else {
                if (nextProps.selectedPlan.resellerId == null) {

                    let itemExtensionList = (nextProps.selectedPlan.extensions.length > 0)
                        ? nextProps.selectedPlan.extensions :
                        [{ id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 }]

                    this.setState({
                        showServiceModal: true,
                        id: null,
                        resellerId: nextProps.selectedRow.id,
                        companyId: nextProps.selectedRow.companyId,
                        carrierId: nextProps.selectedPlan.carrier != null ? nextProps.selectedPlan.carrier.carrierId : null,
                        itemCode: nextProps.selectedPlan.itemCode,
                        itemName: nextProps.selectedPlan.itemName,
                        planDataVolume: nextProps.selectedPlan.planDataVolume,
                        itemDescription: nextProps.selectedPlan.itemDescription,
                        price: nextProps.selectedPlan.unitPrice,
                        itemPayFrequency: itemPayFrequency,
                        itemPayType: nextProps.selectedPlan.itemPayType,
                        autoRenewFlag: nextProps.selectedPlan.autoRenewFlag,
                        pooledFlag: nextProps.selectedPlan.pooledFlag,
                        itemExtensionList: itemExtensionList
                    }, () => this.props.getCarrierPlans({ "CompanyCode": this.props.selectedRow.resellerCode, "CarrierId": this.state.carrierId }))
                }
                else {
                    let itemExtensionList = (nextProps.selectedPlan.extensions.length > 0)
                        ? nextProps.selectedPlan.extensions :
                        [{ id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 }]

                    this.setState({
                        showServiceModal: true,
                        id: nextProps.selectedPlan.itemId,
                        resellerId: nextProps.selectedRow.id,
                        companyId: nextProps.selectedRow.companyId,
                        carrierId: nextProps.selectedPlan.carrier != null ? nextProps.selectedPlan.carrier.carrierId : null,
                        itemCode: nextProps.selectedPlan.itemCode,
                        itemName: nextProps.selectedPlan.itemName,
                        planDataVolume: nextProps.selectedPlan.planDataVolume,
                        itemDescription: nextProps.selectedPlan.itemDescription,
                        price: nextProps.selectedPlan.unitPrice,
                        itemPayFrequency: itemPayFrequency,
                        itemPayType: nextProps.selectedPlan.itemPayType,
                        autoRenewFlag: nextProps.selectedPlan.autoRenewFlag,
                        pooledFlag: nextProps.selectedPlan.pooledFlag,
                        itemExtensionList: itemExtensionList
                    }, () => this.props.getCarrierPlans({ "CompanyCode": this.props.selectedRow.resellerCode, "CarrierId": this.state.carrierId }))
                }
            }


        }
        if (nextProps.show == false) {
            this.setState({
                showServiceModal: false
            })
        }
    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onFeatureChange = (feature, position) => {
        var itemExtensionList = [...this.state.itemExtensionList]
        itemExtensionList[position] = feature

        this.setState({
            itemExtensionList: itemExtensionList
        })
    }
    onInputCarrierChange = (event) => {
        let value = event.target.value;
        //Get carrier plan list
        this.props.getCarrierPlans({ "CompanyCode": this.props.selectedRow.resellerCode, "CarrierId": event.target.value });
        if (event.target.value != -1) {
            /*
            this.setState({
                 carrierId: event.target.value,
             });
             */
            this.setState(prevState => {
                prevState.carrierId = value;
                prevState.itemCode = '';
                return ({
                    carrierId: prevState.carrierId,
                })
            });
        }
        else {
            this.setState({
                carrierId: "",
                itemCode: ""
            });
        }
    }
    onInputCarrierPlanChange = (event) => {

        if (event.target.value != -1) {
            this.setState({
                itemCode: event.target.value,
            });
        }
        else {
            this.setState({
                itemCode: "",
            });
        }
    }
    checkActivationFee = () => {
        var overageList = this.state.itemExtensionList.filter((item) => (item.type == "activationFee" && item.status == 1));
        if (overageList.length <= 0) {
            return false;
        }
        return true;
    }
    onAddActivationFee = () => {
        var itemExtensionList = [...this.state.itemExtensionList]
        var overageList = itemExtensionList.filter((item) => (item.type == "activationFee" && item.status == 1));
        if (overageList.length <= 0) {
            itemExtensionList.push({ id: 0, itemId: 0, type: "activationFee", description: "", price: "", status: 1 });
            this.setState({
                itemExtensionList: itemExtensionList
            })
        }
    }
    checkOverage = (overageType) => {
        var overageList = this.state.itemExtensionList.filter((item) => (item.type === overageType && item.status == 1));
        if (overageList.length <= 0) {
            return false;
        }
        return true;
    }
    onAddOverage = (overageType) => {
        var itemExtensionList = [...this.state.itemExtensionList]
        var overageList = itemExtensionList.filter((item) => (item.type == overageType && item.status == 1));
        if (overageList.length <= 0) {
            itemExtensionList.push({ id: 0, itemId: 0, type: overageType, description: "", price: "", status: 1 });
            this.setState({
                itemExtensionList: itemExtensionList
            })
        }
    }
    onAddFeature = () => {
        var itemExtensionList = [...this.state.itemExtensionList]
        itemExtensionList.push({ id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 });
        this.setState({
            itemExtensionList: itemExtensionList
        })
    }
    onDeleteFeature = (position) => {
        var itemExtensionList = [...this.state.itemExtensionList]
        if (itemExtensionList[position].id > 0) {

            /* Validate here before changing status */
            if (itemExtensionList[position].type === "sms") {

                // check description
                if (!(itemExtensionList[position].description
                    && itemExtensionList[position].description.trim())) {
                    return
                }

                // check value
                if (!(itemExtensionList[position].value
                    && itemExtensionList[position].value.toString().trim())) {
                    return
                }

                // check price
                if (!(itemExtensionList[position].price
                    && itemExtensionList[position].price.toString().trim())) {
                    return
                }

                // SMS Custom Overage value
                if (isNaN(parseInt(itemExtensionList[position].value))) {
                    return
                }

                if (((parseFloat(itemExtensionList[position].value) % 1) > 0)) {
                    return
                }

                if ((parseInt(itemExtensionList[position].value) < 0)) {
                    return
                }

                if (((parseFloat(itemExtensionList[position].value) % 1) < 0)) {
                    return
                }

                // SMS Custom Overage Price
                if (isNaN(parseFloat(itemExtensionList[position].price))) {
                    return
                }

                if ((parseFloat(itemExtensionList[position].price) < 0)) {
                    return
                }
            }

            itemExtensionList[position].status = 0;
        }
        else {
            itemExtensionList.splice(position, 1);
        }

        // check for feature
        var featureList = itemExtensionList.filter((item, index) => (item.type == "feature"));
        if (featureList.length == 0) {
            itemExtensionList.push({ id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 });
        }

        this.setState({
            itemExtensionList: itemExtensionList
        })
    }
    validateForm = () => {
        if (this.state.carrierId != ""
            && this.state.carrierId != null
            && this.state.itemName != ""
            && this.state.itemCode != ""
            && this.state.itemDescription != ""
            && !isNaN(this.state.planDataVolume)
            && !isNaN(this.state.price)) {
            if (parseFloat(this.state.price) >= 0
                && parseInt(this.state.planDataVolume) > 0
                && this.state.itemDescription.length < 50
                && this.validateSmsBilling()
                && this.validateOverageBilling()) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
    validateOverageBilling = () => {
        const { itemExtensionList } = this.state
        let overageFeatureList = itemExtensionList.filter((item, index) => (item.type == "overage"));
        if (overageFeatureList.length > 0) {
            var overageValidate = true;
            for (let overageCost of overageFeatureList) {
                if (overageCost.value != null && overageCost.value != "") {
                    if (parseFloat(overageCost.value) < 0 || isNaN(overageCost.value)) {
                        overageValidate = false
                    }
                }
                if (parseFloat(overageCost.price) < 0 || isNaN(overageCost.price)) {
                    overageValidate = false
                }
            }
            return overageValidate;
        }
        else {
            return true;
        }
    }
    validateSmsBilling = () => {
        const { itemExtensionList } = this.state
        let smsFeatureList = itemExtensionList.filter((item, index) => (item.type == "sms"));
        let lastCheckIdx = smsFeatureList.length - 1
        if (smsFeatureList.length > 0) {

            if (!smsFeatureList[lastCheckIdx].description || !smsFeatureList[lastCheckIdx].description.trim()) {
                return false
            }

            if (isNaN(smsFeatureList[lastCheckIdx].value) || !smsFeatureList[lastCheckIdx].value) {
                return false
            }

            if (isNaN(smsFeatureList[lastCheckIdx].price) || !smsFeatureList[lastCheckIdx].price) {
                return false
            }

            if (smsFeatureList[lastCheckIdx].description
                && smsFeatureList[lastCheckIdx].description.trim()
                && smsFeatureList[lastCheckIdx].value
                && smsFeatureList[lastCheckIdx].value.toString().trim()
                && smsFeatureList[lastCheckIdx].price
                && smsFeatureList[lastCheckIdx].price.toString().trim()) {

                // SMS Custom Overage value
                if (isNaN(parseInt(smsFeatureList[lastCheckIdx].value))) {
                    return false
                }

                if (((parseFloat(smsFeatureList[lastCheckIdx].value) % 1) > 0)) {
                    return false
                }

                if ((parseInt(smsFeatureList[lastCheckIdx].value) < 0)) {
                    return false
                }

                if (((parseFloat(smsFeatureList[lastCheckIdx].value) % 1) < 0)) {
                    return false
                }

                // SMS Custom Overage Price
                if (isNaN(parseFloat(smsFeatureList[lastCheckIdx].price))) {
                    return false
                }

                if ((parseFloat(smsFeatureList[lastCheckIdx].price) < 0)) {
                    return false
                }

                return true

            } else {
                return false
            }
        } else {
            return true
        }
    }
    onSubmit = () => {
        let data = {
            "id": this.state.id,
            "companyId": this.state.companyId,
            "resellerId": this.state.resellerId,
            "carrierId": this.state.carrierId,
            "itemCode": this.state.itemCode,
            "itemName": this.state.itemName,
            "planDataVolume": this.state.planDataVolume,
            "itemDescription": this.state.itemDescription,
            "unitPrice": this.state.price,
            "itemPayFrequency": this.state.itemPayFrequency,
            "itemPayType": this.state.itemPayType,
            "autoRenewFlag": this.state.autoRenewFlag,
            "pooledFlag": this.state.pooledFlag,
            "companyCode": this.state.companyCode,
            "resellerCode": this.state.resellerCode,
            "itemExtensionList": this.state.itemExtensionList
        }
        this.props.SetServicePlan(data).then(res => { this.onModalClose() });
    }
    onRemovePlan = () => {
        let data = {
            "id": this.state.id,
            "resellerId": this.state.resellerId,
            "companyCode": this.state.companyCode,
            "resellerCode": this.state.resellerCode
        }
        this.props.DeleteServicePlan(data).then(res => { this.onModalClose() });
    }
    onModalClose = () => {
        this.props.getServicePlanItem({ "companyCode": this.state.companyCode, "resellerCode": this.state.resellerCode })
            .then(res => { this.props.onClose() });
    }
    render() {
        let carrierPlanControl = <Input
            type='select'
            name='itemCode'
            id='itemCode'
            placeholder='Carrier Plan'
            className="form-control"
            onChange={this.onInputCarrierPlanChange}
        >
            <option key={-1} value={-1}>None Selected</option>

            {
                this.state.userObj.companyCode === "TEISA" && TeisaSamplePlanData.map((plan, index) =>
                    (<option key={('item' + plan.name + '' + index)} value={(plan.name + Date.now() + index)}>{plan.name}</option>)
                )
            }

            {this.props.carrierPlanList.map((plan, index) =>
                (plan.name == this.state.itemCode) ? <option key={index} value={plan.name} selected>{plan.name}</option> : <option key={index} value={plan.name}>{plan.name}</option>
            )}

        </Input>;

        
        let textInputList = ["floLIVE", "Simfony"];

        //if carrier is floLIVE, then display textbox for carrier plan (item code)
        if (this.state.carrierId != null && this.state.carrierId > -1 && this.props.carrierList && this.props.carrierList.length > 0) {
            let foundCarrier = this.props.carrierList.find((element) => {
                return (textInputList.includes(element.carrierName)) && element.carrierId == this.state.carrierId;
            });

            //it is floLive, display textbox instead of carrier plan dropdown list
            if (foundCarrier != null) {
                carrierPlanControl = <Input
                    name='itemCode'
                    id='itemCode'
                    placeholder='Carrier Plan'
                    onChange={this.onInputChange}
                    className="form-control"
                    value={this.state.itemCode}
                />
            }
        }

        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showServiceModal}
                size='lg'
            >
                <ModalHeader toggle={this.onModalClose}>{(this.props.selectedPlan != null) ? "Customize " + this.props.selectedPlan.itemName : "Customize New Plan"}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Carrier<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrierId'
                                    id='carrierId'
                                    placeholder='Carrier'
                                    className="form-control"
                                    onChange={this.onInputCarrierChange}
                                >
                                    <option key={-1} value={-1}>None Selected</option>
                                    {this.props.carrierList && this.props.carrierList.map((carrier, index) =>
                                        (carrier.carrierId == this.state.carrierId) ? <option key={index} value={carrier.carrierId} selected>{carrier.carrierName}</option> : <option key={index} value={carrier.carrierId}>{carrier.carrierName}</option>

                                    )}

                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Carrier Plans<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                {carrierPlanControl}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Plan Name<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='itemName'
                                    id='itemName'
                                    placeholder='Plan Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.itemName}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Description<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='itemDescription'
                                    id='itemDescription'
                                    onChange={this.onInputChange}
                                    placeholder='Item Description'
                                    className="form-control"
                                    value={this.state.itemDescription}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 50 Character Limit</span>

                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Price<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='price'
                                    id='price'
                                    placeholder='Price'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.price}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Data Usage<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='planDataVolume'
                                    id='planDataVolume'
                                    placeholder='Data Amount'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.planDataVolume}
                                />
                                {(this.props.pageContentList.includes("Client Plan Message Note")) ? <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> Messages Per Day</span> :
                                    <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> Input data usage in MB</span>}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Billing Cycle<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='itemPayFrequency'
                                    id='itemPayFrequency'
                                    placeholder='billingCycle'
                                    className="form-control"
                                    //value={this.state.company}
                                    onChange={this.onInputChange}
                                    disabled={false}
                                    defaultValue={this.state.itemPayFrequency}
                                >
                                    {["Monthly", "Yearly"].map((cycle, index) => (
                                        <option key={index} value={cycle}>{cycle}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Pay Type<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            {(this.userObj.companyCode && this.userObj.companyCode !== "TEISA") ?
                                <Col md={8}>
                                    <Input
                                        type='select'
                                        name='itemPayType'
                                        id='itemPayType'
                                        placeholder='Pay Type'
                                        className="form-control"
                                        //value={this.state.company}
                                        onChange={this.onInputChange}
                                        //defaultValue='Pre-paid'
                                        defaultValue={this.state.itemPayType}

                                    >
                                        {/*<option key={1} value={""}>Plan</option>*/}
                                        <option key={1} value={""}>Pre-paid</option>
                                        <option key={2} value={"PAYG"}>Pay As You Go</option>

                                    </Input>
                                </Col>
                                :
                                <Col md={8}>
                                    <Input
                                        //type='select'
                                        name='itemPayType'
                                        id='itemPayType'
                                        placeholder='Pay Type'
                                        className="form-control"
                                        //value={this.state.company}
                                        onChange={this.onInputChange}
                                        disabled={true}
                                        defaultValue='Pre-paid'
                                    >
                                        {/*<option key={1} value={""}>Plan</option>
                                <option key={2} value={"PAYG"}>Pay As You Go</option>*/}
                                        {/*<option key={1} value={""}>Pre-paid</option>*/}

                                    </Input>
                                </Col>
                            }
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Auto Renew<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='autoRenewFlag'
                                    id='autoRenewFlag'
                                    placeholder='Auto Renew'
                                    className="form-control"
                                    //value={this.state.company}
                                    onChange={this.onInputChange}
                                    disabled={false}
                                    defaultValue={this.state.autoRenewFlag}
                                >
                                    <option key={1} value={0}>No</option>
                                    <option key={2} value={1}>Yes</option>

                                </Input>
                            </Col>
                        </Row>
                        <br />
                        {(this.props.pageContentList.includes("Client Plan Modal Pooled")) ?
                            <Row>
                                <Col md={2} >
                                    <span>Pooled Usage<span style={{ color: "red" }}>*</span></span>
                                </Col>
                                <Col md={8}>
                                    <Input
                                        type='select'
                                        name='pooledFlag'
                                        id='pooledFlag'
                                        placeholder='Pooled Usage'
                                        className="form-control"
                                        //value={this.state.company}
                                        onChange={this.onInputChange}
                                        disabled={false}
                                        defaultValue={this.state.pooledFlag}
                                    >
                                        <option key={1} value={0}>No</option>
                                        <option key={2} value={1}>Yes</option>

                                    </Input>
                                </Col>
                            </Row> : null}
                        <br />
                        <hr hidden={this.userObj.companyCode && this.userObj.companyCode == "TEISA"} style={{ borderTop: "3px dotted  rgba(0, 0, 0, 0.2)" }} />

                        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                            <Row>
                                <Col md={2} >
                                    <span>Activation Fee</span>
                                </Col>
                                <Col md={8}>
                                    {
                                        (this.state.itemExtensionList.length > 0) ?
                                            this.state.itemExtensionList.map((feature, index) => {
                                                if (feature.type == "activationFee" && feature.status == 1) {
                                                    return (
                                                        <ClientPlanAddOn
                                                            index={index}
                                                            feature={feature}
                                                            onDeleteFeature={this.onDeleteFeature}
                                                            onFeatureChange={this.onFeatureChange}
                                                        />
                                                    )
                                                }
                                            }
                                            )
                                            :
                                            <ClientPlanAddOn
                                                index={0}
                                                feature={{ id: 0, itemId: 0, type: "activationFee", description: "", price: "", status: 1 }}
                                                onDeleteFeature={this.onDeleteFeature}
                                                onFeatureChange={this.onFeatureChange}
                                            />
                                    }
                                    <div style={{ textAlign: 'right' }}>
                                        {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                                            <Button className="btn-withhover" outline color='custom' type='button' onClick={this.onAddActivationFee} disabled={this.checkActivationFee()}>
                                                <PlusIcon /> Add
                                            </Button>}
                                        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                                            <Button outline color='custom' type='button' onClick={this.onAddActivationFee} disabled={this.checkActivationFee()}>
                                                <PlusIcon /> Add
                                            </Button>}

                                    </div>
                                </Col>
                            </Row>}
                        <hr style={{ borderTop: "3px dotted  rgba(0, 0, 0, 0.2)" }} />
                        <Row>
                            <Col md={2} >
                                <span>Feature</span>
                            </Col>
                            <Col md={8}>
                                {
                                    (this.state.itemExtensionList.length > 0) ?
                                        this.state.itemExtensionList.map((feature, index) => {
                                            if (feature.type == "feature" && feature.status == 1) {
                                                return (
                                                    <ClientPlanAddOn
                                                        index={index}
                                                        feature={feature}
                                                        onDeleteFeature={this.onDeleteFeature}
                                                        onFeatureChange={this.onFeatureChange}
                                                    />
                                                )
                                            }
                                        }
                                        )
                                        :
                                        <ClientPlanAddOn
                                            index={0}
                                            feature={{ id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 }}
                                            onDeleteFeature={this.onDeleteFeature}
                                            onFeatureChange={this.onFeatureChange}
                                        />
                                }
                                <div style={{ textAlign: 'right' }}>

                                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                                        <Button className="btn-withhover" outline color='custom' type='button' onClick={this.onAddFeature}>
                                            <PlusIcon /> Add
                                        </Button>}
                                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                                        <Button outline color='custom' type='button' onClick={this.onAddFeature}>
                                            <PlusIcon /> Add
                                        </Button>}
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <hr hidden={this.userObj.companyCode && this.userObj.companyCode == "TEISA"} style={{ borderTop: "3px dotted  rgba(0, 0, 0, 0.2)" }} />

                        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                            <Row>
                                <Col md={2} >
                                    <span>Custom Overage</span>
                                </Col>
                                <Col md={8}>
                                    {
                                        (this.state.itemExtensionList.length > 0) ?
                                            this.state.itemExtensionList.map((feature, index) => {
                                                if (feature.type == "overage" && feature.status == 1) {
                                                    return (
                                                        <ClientPlanAddOn
                                                            index={index}
                                                            feature={feature}
                                                            onDeleteFeature={this.onDeleteFeature}
                                                            onFeatureChange={this.onFeatureChange}
                                                        />
                                                    )
                                                }
                                            }
                                            )
                                            :
                                            <ClientPlanAddOn
                                                index={0}
                                                feature={{ id: 0, itemId: 0, type: "overage", description: "", price: "", status: 1 }}
                                                onDeleteFeature={this.onDeleteFeature}
                                                onFeatureChange={this.onFeatureChange}
                                            />
                                    }
                                    <div style={{ textAlign: 'right' }}>
                                        {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                                            <Button className="btn-withhover" outline color='custom' type='button' onClick={this.onAddOverage.bind(this, "overage")} disabled={this.checkOverage("overage")}>
                                                <PlusIcon /> Add
                                            </Button>}
                                        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                                            <Button outline color='custom' type='button' onClick={this.onAddOverage.bind(this, "overage")} disabled={this.checkOverage("overage")}>
                                                <PlusIcon /> Add
                                            </Button>}
                                    </div>
                                </Col>
                            </Row>}
                        <br />

                        <hr hidden={this.userObj.companyCode && this.userObj.companyCode == "TEISA"} style={{ borderTop: "3px dotted  rgba(0, 0, 0, 0.2)" }} />

                        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                            <Row>
                                <Col md={2} >
                                    <span>SMS Fees</span>
                                </Col>
                                <Col md={8}>
                                    {
                                        (this.state.itemExtensionList.length > 0) ?
                                            this.state.itemExtensionList.map((feature, index) => {
                                                if (feature.type == "sms" && feature.status == 1) {
                                                    return (
                                                        <ClientPlanAddOn
                                                            index={index}
                                                            feature={feature}
                                                            onDeleteFeature={this.onDeleteFeature}
                                                            onFeatureChange={this.onFeatureChange}
                                                        />
                                                    )
                                                }
                                            }
                                            )
                                            :
                                            <ClientPlanAddOn
                                                index={0}
                                                feature={{ id: 0, itemId: 0, type: "sms", description: "", value: "", price: "", status: 1 }}
                                                onDeleteFeature={this.onDeleteFeature}
                                                onFeatureChange={this.onFeatureChange}
                                            />
                                    }
                                    <div style={{ textAlign: 'right' }}>
                                        {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                                            <Button className="btn-withhover" outline color='custom' type='button' onClick={this.onAddOverage.bind(this, "sms")} disabled={this.checkOverage("sms")}>
                                                <PlusIcon /> Add
                                            </Button>}
                                        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                                            <Button outline color='custom' type='button' onClick={this.onAddOverage.bind(this, "sms")} disabled={this.checkOverage("sms")}>
                                                <PlusIcon /> Add
                                            </Button>}
                                    </div>
                                </Col>
                            </Row>}
                    </Col>

                    {/* <hr/> */}
                </ModalBody>
                <ModalFooter>
                    {(this.props.selectedPlan != null && this.props.selectedPlan.resellerId != null) ?
                        <Button outline color='danger' type="button">
                            <ConfirmDeleteDialog
                                label={"Remove"}
                                onConfirm={(e) => { this.onRemovePlan() }}
                                msg={`Do you want to delete the ${this.props.selectedPlan.itemName}? `}
                            />
                        </Button>
                        :
                        null
                    }

                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}  >
                            Submit
                        </Button>}

                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}  >
                            Submit
                        </Button>}

                    <Button outline color='secondary' type="reset" onClick={this.onModalClose}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ClientPlanModal;
