/* eslint-disable */
import React, { Component, Fragment } from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import PlusIcon from 'mdi-react/PlusIcon';
// import {EditTextButton, DeleteTextButton} from '../../../shared/components/Button/IconButton';
// import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { getActivityReport, getFullSIMList, getInventoryList } from '../../../redux/actions/inventoryActions';
import {
    clearAdjustment, clearCustomerInvoiceHistory, deleteAccount, deleteCompany, deleteService, getAccounts, getAdjustmentByCompanycode, getAllCompanies, getAllPlanItems, getBrandingDetails, getCompanyList, getCountries, getCustomerByCompanycode, getCustomerInvoiceDetails, getCustomerInvoiceHistory, getCustomerPaymentHistory, getOneCompanyByCode, getorderbycustomer, getPageContent, getProvinces, getUserCompanyAssetClass, getUserCompanyDeviceTypes, loadTransactionHistory,
    refundTransaction, releaseOrder, setBrandingDetails, setCurrentCompanycode, updateCompany, updateCompanyAssetClass, updateCompanyDeviceList, updateOrder,
    updateService, GetUserActivityTracking
} from '../../../redux/actions/userAction';
import CompanyAccountBoard from './CompanyAccountBoard';
import CompanyActiveSIMList from './CompanyActiveSIMList';
import CompanyActivityBoard from './CompanyActivityBoard';
import CompanyActivityReport from './CompanyActivityReport';
import CompanyAddGeneralLedgerCode from './CompanyAddGeneralLedgerCode';
import CompanyAddGeneralLedgerCodeReason from './CompanyAddGeneralLedgerCodeReason';
import CompanyAssetClassForm from './CompanyAssetClassForm';
import CompanyBrandingBoard from './CompanyBrandingBoard';
import CompanyDeviceBoard from './CompanyDeviceBoard';
import CompanyDeviceTypeForm from './CompanyDeviceTypeForm';
import CompanyEditBoard from './CompanyEditBoard';
import CompanyEditForm from './CompanyEditForm';
import CompanyList from './CompanyList';
import CompanyPaymentInvoiceBoard from './CompanyPaymentInvoiceBoard';
import CompanyPaymentMethod from './CompanyPaymentMethod';
import CompanyPurchasePlans from './CompanyPurchasePlans';
import CompanyTransactionBoard from './CompanyTransactionBoard';
import CompanyActivityTrackingBoard from './CompanyActivityTrackingBoard';
// import {addUser, removeUser, getUserAssetclassList, upsertUserAssetclassList, getUserDevicetypeList, upsertUserDevicetypeList, getUserCompanyDeviceTypes, getUserCompanyAssetClass} from '../../../redux/actions/userAction';
import log from 'loglevel';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from "react-toastify";
import { LIGHT_GREY_COLOR } from '../../../constants/dataConstants';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { clearSelectedCompantToSesstion, setSelectedCompantToSesstion } from '../../../factories/utils';

class CompanyBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listView: true,
            companyCode: null,
            loading: true,
            userObj: getCurrentUser(),
        }
        this.renderCompanyAddGeneralLedgerCode = this.renderCompanyAddGeneralLedgerCode.bind(this);
        this.renderCompanyGeneralLedgerCodeReasonUsage = this.renderCompanyGeneralLedgerCodeReasonUsage.bind(this);
    }

    async componentDidMount() {
        await this.props.getPageContent({ "content": "Company" });
        // this.props.getCompanyList();
        await this.props.getAllCompanies();
        await this.props.getCountries();
        await this.props.getUserCompanyAssetClass();
        await this.props.getUserCompanyDeviceTypes();
        // if (this.props.companyCode) {
        //     this.setState({
        //         showPayment: true
        //     }, () => {this.toggle();});
        // }
        if (this.props.companyAll.length === 1 && this.props.userObj && hasRole(this.props.userObj, ['ADMIN', 'USER'])) {
            await this.handleRowEditClick(this.props.companyAll[0]);
        }
        else {
            this.setState({ loading: false })
        }



    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.companyCode && nextProps.companyCode != this.props.companyCode) {

            this.loadCompany(nextProps.companyCode);
            this.setState({
                showPayment: true,
                companyCode: nextProps.companyCode,
            }, () => {
                this.toggle();
            });

        }
    }

    componentWillUnmount() {
        clearSelectedCompantToSesstion();
    }

    toggle = () => {
        this.setState(prevState => {
            return ({
                listView: !prevState.listView,
            })
        }, () => {
            if (this.state.listView === true) {
                clearSelectedCompantToSesstion();
            }
        });
    }

    handleRowEditClick = (row, showAccount = false) => {
        // this.props.getOneCompanyByCode(row.companyCode);
        // this.props.setCurrentCompanycode(row.companyCode);
        // this.props.getCustomerByCompanycode(
        //     row.companyCode
        //     // (customerObj)=>{
        //     //     log.debug('getCustomerByCompanycode callback', customerObj);
        //     //     // if (customerObj){
        //     //     //     getorderbycustomer(customerObj.id)
        //     //     // }                
        //     // }
        // );
        // this.props.getorderbycustomer(row.companyCode);
        // setSelectedCompantToSesstion(row.companyCode);
        // log.debug('get company code',getSelectedCompantToSesstion());
        // this.props.getAccounts(row.companyCode);
        // this.props.getAdjustmentByCompanycode(row.companyCode);
        this.setState({
            loading: true
        }, async () => {
            await this.props.loadTransactionHistory({ "objId": row.id })
            await this.loadCompany(row.companyCode);
            await this.props.getActivityReport({ companyId: row.id })
            this.setState({
                showAccount: showAccount,
                loading: false
            }, () => {
                this.toggle();
            });
        })
    }

    loadCompany = (companyCode) => {
        this.props.getOneCompanyByCode(companyCode);
        this.props.setCurrentCompanycode(companyCode);
        this.props.getCustomerByCompanycode(
            companyCode
            // (customerObj)=>{
            //     log.debug('getCustomerByCompanycode callback', customerObj);
            //     // if (customerObj){
            //     //     getorderbycustomer(customerObj.id)
            //     // }                
            // }
        );
        //this.props.getFullSIMList
        this.props.getorderbycustomer(companyCode);
        setSelectedCompantToSesstion(companyCode);
        // log.debug('get company code',getSelectedCompantToSesstion());
        this.props.getAccounts(companyCode);
        this.props.getAdjustmentByCompanycode(companyCode);
        this.props.getBrandingDetails(companyCode);
        this.setState({ companyCode: companyCode })
    }

    handleRowDeleteClick = (row) => {
        let code = row.companyCode;
        // let selectedCompany = this.props.companyList.find((element,index) => (element.companyCode === code));
        let selectedCompany = this.props.companyAll.find((element, index) => (element.companyCode === code));
        if (selectedCompany) {
            this.props.deleteCompany(selectedCompany);
        }
    }

    handleAddNewClick = (e) => {
        this.toggle();
        this.props.getOneCompanyByCode()
        // this.toggle();
    }

    changePlan = (orderId, orderObj) => {
        log.debug("debug changeplan");
        //   this.renderCompanyAccount;
        // this.props.updateOrder(orderObj,
        //     (data)=>{
        //         log.debug("update order success callback");
        //         if (this.props.currentCompanyCode) {
        //             this.props.getorderbycustomer(this.props.currentCompanyCode);
        //         }else{
        //             toast.error("Error occurred during update order");
        //         }                
        //     },
        //     (error)=>{
        //         toast.error("Error occurred during update order");
        //     }
        // );
        this.props.updateService(orderId, orderObj,
            (data) => {
                log.debug("update order success callback");
                if (this.props.currentCompanyCode) {
                    this.props.getorderbycustomer(this.props.currentCompanyCode);
                } else {
                    toast.error("Error occurred during updating order");
                }
            },
            (error) => {
                toast.error("Error occurred during updating order");
            }
        );
    }

    cancelPlan = (orderId) => {
        this.props.deleteService(orderId,
            (data) => {
                log.debug("delete order success callback");
                if (this.props.currentCompanyCode) {
                    this.props.getorderbycustomer(this.props.currentCompanyCode);
                } else {
                    toast.error("Error occurred during deleting order");
                }
            },
            (error) => {
                toast.error("Error occurred during deleting order");
            }
        );
    }

    deleteCompanyAccount = (data) => {
        // console.log(data)
        this.props.deleteAccount(data);
        //    .then(res => {
        //        
        //     //   if (res && res.status === 200 && res.data && res.data.length > 0){
        //     //         let storeData = res.data[0];
        //     //         this.props.addToAccounts(storeData);

        //     //   }else if (!res || res.status !== 200){
        //     //          

        //     //   }           
        //    })
    }

    renderCompanyList() {
        return (
            <CompanyList
                userObj={this.props.userObj}
                // companyList = {this.props.companyList}
                companyList={this.props.companyAll}
                handleRowEditClick={this.handleRowEditClick}
                handleRowDeleteClick={this.handleRowDeleteClick}
                handleAddNewClick={this.handleAddNewClick}
            />
        )
    }

    handleSubmitClick = (companyObj) => {
        this.props.updateCompany(companyObj);
    }

    onExitCompany = () => {
        this.toggle()
    }

    renderCompanyEditForm = () => {
        return (
            <CompanyEditForm
                provinceMap={this.props.provinceMap}
                userObj={this.props.userObj}
                toggle={this.toggle}
                companyObj={this.props.companyObj}
                countryList={this.props.countryList}
                provinceList={this.props.provinceList}
                getProvinces={this.props.getProvinces}
                handleSubmitClick={this.handleSubmitClick}
            />
        )
    }

    renderCompanyActivityReport = (props) => {
        return (
            <CompanyActivityReport
                activityReportList={this.props.activityReportList} />
        )
    }
    renderCompanyAssetClass = (props) => {
        return (
            <div>
                <CompanyAssetClassForm
                    userObj={this.props.userObj}
                    toggle={this.toggle}
                    companyObj={this.props.companyObj}
                    companyAssetClassList={this.props.companyAssetClassList}
                    updateCompanyAssetClass={this.props.updateCompanyAssetClass}
                    {...props}
                />
            </div>
        )
    }

    renderCompanyDeviceType = (props) => {


        return (
            <div>
                <CompanyDeviceTypeForm
                    userObj={this.props.userObj}
                    toggle={this.toggle}
                    companyObj={this.props.companyObj}
                    companyDevicetypeList={this.props.companyDevicetypeList}
                    updateCompanyDeviceList={this.props.updateCompanyDeviceList}
                    {...props}
                />
            </div>
        )
    }

    renderCompanyActivity = (props) => {

        return (
            <div>
                <CompanyActivityBoard
                    userObj={this.props.userObj}
                    toggle={this.toggle}
                    changePlan={this.changePlan}
                    cancelPlan={this.cancelPlan}
                    companyObj={this.props.companyObj}
                    companyDevicetypeList={this.props.companyDevicetypeList}
                    updateCompanyDeviceList={this.props.updateCompanyDeviceList}
                    orderList={this.props.orderList}
                    accountList={this.props.accountList}
                    planItemsList={this.props.planItemsAll}
                    {...props}
                />
            </div>
        )
    }

    renderCompanyAccount = (props) => {

        return (
            <Fragment>
                {this.props.orderList && this.props.orderList.length > 0 ?
                    this.renderCompanyActivity(props)
                    :
                    <CompanyAccountBoard
                        userObj={this.props.userObj}
                        toggle={this.toggle}
                        companyObj={this.props.companyObj}
                        companyDevicetypeList={this.props.companyDevicetypeList}
                        updateCompanyDeviceList={this.props.updateCompanyDeviceList}
                        customerObj={this.props.customerObj}
                        getorderbycustomer={this.props.getorderbycustomer}
                        {...props}
                    />
                }
            </Fragment>
            // <div>
            //     <CompanyAccountBoard
            //         userObj = {this.props.userObj}
            //         toggle = {this.toggle}
            //         companyObj = {this.props.companyObj}
            //         companyDevicetypeList = {this.props.companyDevicetypeList}
            //         updateCompanyDeviceList = {this.props.updateCompanyDeviceList}
            //         customerObj = {this.props.customerObj}
            //         {...props}
            //     />
            // </div>
        )
    }

    renderPaymentHistory = (props) => {
        return (
            <div>
                <CompanyTransactionBoard
                    transactionHistory={this.props.transactionHistory}
                    refundTransaction={this.props.refundTransaction}
                    loadTransactionHistory={this.props.loadTransactionHistory}
                    userObj={this.props.userObj}

                />
                {/*
            <div>
                <CompanyAccountHistoryComp
                    userObj={this.props.userObj}
                    toggle={this.toggle}
                    // accountTitle = {this.state.accountTitle}
                    companyObj={this.props.companyObj}
                    accountList={this.props.accountList}
                    paymentHistory={this.props.paymentHistory}
                    getCustomerPaymentHistory={this.props.getCustomerPaymentHistory}
                    {...props}
                />
            </div>
            */}
            </div>
        )
    }

    renderInvoiceHistory = (props) => {
        return (
            <div>
                {/* <CompanyPaymentInvoice
                    userObj = {this.props.userObj}
                    toggle = {this.toggle}
                    // accountTitle = {this.state.accountTitle}
                    companyObj = {this.props.companyObj}
                    accountList={this.props.accountList}
                    paymentInvoice = {this.props.paymentInvoice}
                    paymentInvoiceDetails = {this.props.paymentInvoiceDetails}
                    getCustomerInvoiceHistory = {this.props.getCustomerInvoiceHistory}
                    getCustomerInvoiceDetails = {this.props.getCustomerInvoiceDetails}
                    {...props}
                /> */}
                <CompanyPaymentInvoiceBoard
                    userObj={this.props.userObj}
                    toggle={this.toggle}
                    // accountTitle = {this.state.accountTitle}
                    companyObj={this.props.companyObj}
                    accountList={this.props.accountList}
                    paymentInvoice={this.props.paymentInvoice}
                    paymentInvoiceDetails={this.props.paymentInvoiceDetails}
                    getCustomerInvoiceHistory={this.props.getCustomerInvoiceHistory}
                    clearCustomerInvoiceHistory={this.props.clearCustomerInvoiceHistory}
                    getCustomerInvoiceDetails={this.props.getCustomerInvoiceDetails}
                    adjustmentList={this.props.adjustmentList}
                    {...props}
                />
            </div>
        );
    }

    renderCompanyEditBoard = (tabObjs) => {
        return (
            // <CompanyEditBoard
            //     EditRender={this.renderCompanyEditForm}
            // />
            <CompanyEditBoard
                tabObjs={tabObjs}

                onExitCompany={(this.props.companyAll.length > 1 && this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN'])) ?
                    this.onExitCompany
                    :
                    null
                }
                companyObj={this.props.companyObj}
                showAccount={this.state.showAccount}
                showPayment={this.state.showPayment}
                companycode={this.state.companyCode}
            />
        )
    }

    renderCompanyPayment = (props) => {
        return (
            <CompanyPaymentMethod
                accountList={this.props.accountList}
                companyCode={this.props.companyCode}
                customerObj={this.props.customerObj}
                getAccounts={this.props.getAccounts}
                removePayment={this.deleteCompanyAccount}
                {...props}
            />
        )
    }

    renderCompanyPurchaseDevices = (props) => {
        return (
            // <CompanyPurchaseDevices
            //     userObj = {this.props.userObj}
            //     getInventoryList = {this.props.getInventoryList}
            //     inventoryList = {this.props.inventoryList}
            //     {...props}
            // />
            // <Fragment>
            //     {this.props.orderList && this.props.orderList.length > 0 ?
            //         this.renderCompanyActivity(props)
            //     :    
            //         <CompanyDeviceBoard
            //             userObj = {this.props.userObj}
            //             getInventoryList = {this.props.getInventoryList}
            //             inventoryList = {this.props.inventoryList}
            //             customerObj = {this.props.customerObj}
            //             {...props}
            //         />
            //     }
            // </Fragment>
            <CompanyDeviceBoard
                userObj={this.props.userObj}
                getInventoryList={this.props.getInventoryList}
                inventoryList={this.props.inventoryList}
                customerObj={this.props.customerObj}
                {...props}
            />
        )
    }

    renederCompanyPurchasePlans = (props) => {
        return (
            <CompanyPurchasePlans
                userObj={this.props.userObj}
                getAllPlanItems={this.props.getAllPlanItems}
                planItemsList={this.props.planItemsAll}
            />
        )
    }
    renderCompanyActiveSIMList = (props) => {
        return (
            <CompanyActiveSIMList
                activeSIMList={this.props.activeSIMList}
            />
        )
    }
    renderCompanyBrandingBoard = (props) => {
        return (
            <CompanyBrandingBoard
                userObj={this.props.userObj}
                branding={this.props.branding}
                setBrandingDetails={this.props.setBrandingDetails}
                companyCode={this.state.companyCode}
            />
        )
    }
    renderCompanyAddGeneralLedgerCode = (props) => {
        return (<>
            <CompanyAddGeneralLedgerCode
                userObj={this.props.userObj}

            />
        </>)
    }
    renderCompanyUserActivityTracking = (props) => {
        return (<>
            <CompanyActivityTrackingBoard
                userObj={this.props.userObj}
                GetUserActivityTracking={this.props.GetUserActivityTracking}
                userActivityTrackingList={this.props.userActivityTrackingList}
            />
        </>)
    }
    renderCompanyGeneralLedgerCodeReasonUsage = (props) => {
        return (<>
            <CompanyAddGeneralLedgerCodeReason
                userObj={this.props.userObj}
            />
        </>)
    }
    renderSpinner = () => {
        return (
            <Fragment >
                {this.state.loading &&
                    <div style={{
                        position: 'Absolute ',
                        height: '100%',
                        width: '100%',
                        top: '0px',
                        left: '0px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        backgroundColor: LIGHT_GREY_COLOR,
                        opacity: '1.0',
                    }}
                    >
                        <Spinner
                            className="spinner"
                            as="span"
                            animation="border"
                            variant="custom"
                            // size="sm"
                            // role="status"
                            // aria-hidden="true"
                            style={{ display: 'inline-block', margin: 'auto' }}
                        />
                    </div>
                }
            </Fragment>
        );
    }
    render() {
        let tabObjs = []
        const { pageContentList } = this.props

        if (pageContentList.includes("Company Info")) {
            tabObjs.push(
                {
                    tabId: '1',
                    label: 'Company Info',
                    // renderTab : this.renderCompanyEditForm,
                    renderTab: (props) => { return (this.renderCompanyEditForm(props)) },
                }
            );
        }

        if (pageContentList.includes("Activity Report *NO*")) {
        } else {
            tabObjs.push(
                {
                    tabId: '13',
                    label: 'Activity Report',
                    // renderTab : this.renderCompanyEditForm,
                    renderTab: (props) => { return (this.renderCompanyActivityReport(props)) },
                }
            );
        }
        if (pageContentList.includes("Company User Activity Tracking")) {
            tabObjs.push(
                {
                    tabId: '17',
                    label: 'User Tracking',
                    // renderTab : this.renderCompanyEditForm,
                    renderTab: (props) => { return (this.renderCompanyUserActivityTracking(props)) },
                }
            );
        }



        if (pageContentList.includes("Company Discounts") && this.props.companyObj && this.props.companyObj.companyCode && this.props.companyObj.companyCode.length > 0) {
            tabObjs.push(
                {
                    tabId: '12',
                    label: 'Discounts',
                    // renderTab : this.renderCompanyDeviceType,
                    renderTab: (props) => { return (this.renderCompanyDeviceType(props)) },
                }
            );
        }

        if (pageContentList.includes("Company Inventory") && this.props.companyObj && this.props.companyObj.companyCode && this.props.companyObj.companyCode.length > 0) {
            tabObjs.push(
                {
                    tabId: '10',
                    label: 'Inventory',
                    // renderTab : this.renderCompanyDeviceType,
                    renderTab: (props) => { return (this.renderCompanyDeviceType(props)) },
                }
            );
        }

        if (pageContentList.includes("Company Active SIMs") && this.props.companyObj && this.props.companyObj.companyCode && this.props.companyObj.companyCode.length > 0) {
            tabObjs.push(
                {
                    tabId: '11',
                    label: 'Activated SIMs',
                    // renderTab : this.renderCompanyDeviceType,
                    renderTab: (props) => { return (this.renderCompanyActiveSIMList(props)) },
                }
            );
        }

        // tabObjs.push(
        //     {
        //         tabId: '2',
        //         label: 'Activity',
        //         // renderTab : this.renderCompanyActivity,
        //         renderTab : (props) => {return (this.renderCompanyActivity(props))},
        //     }
        // );

        if (pageContentList.includes("Company Device Type") && this.props.companyObj && this.props.companyObj.companyCode && this.props.companyObj.companyCode.length > 0) {
            tabObjs.push(
                {
                    tabId: '3',
                    label: 'Device Type',
                    // renderTab : this.renderCompanyDeviceType,
                    renderTab: (props) => { return (this.renderCompanyDeviceType(props)) },
                }
            );
        }

        if (pageContentList.includes("Company Device")) {
            tabObjs.push(
                {
                    // tabId: '5',
                    tabId: '4',
                    label: 'Device',
                    // renderTab : this.renderCompanyAccount,
                    // renderTab : (props) => {return(this.renderCompanyAccount({ collapse:true, isPaymentClicked:true, isPurchaseClicked:false, ...props}))},
                    renderTab: (props) => { return (this.renderCompanyPurchaseDevices(props)) }
                }
            );
        }
        // -------------------------------------------------------------------------------------------
        if (pageContentList.includes("Company Service")) {
            tabObjs.push(
                {
                    tabId: '5',
                    label: 'Service',
                    // renderTab : this.renderCompanyAccount,
                    renderTab: (props) => { return (this.renderCompanyAccount({ collapse: true, isPaymentClicked: false, isPurchaseClicked: true, itemType: '1', ...props })) },
                    // renderTab : (props) => {return(this.renederCompanyPurchasePlans(props))}
                }
            );
        }

        if (pageContentList.includes("Company Payment")) {
            tabObjs.push(
                {
                    tabId: '7',
                    label: 'Payment',
                    // renderTab : this.renderCompanyAccount,
                    // renderTab : (props) => {return(this.renderCompanyAccount({collapse:true, isPaymentClicked:false, isPurchaseClicked:true, ...props}))},
                    // renderTab : (props) => {return(this.renderCompanyAccount(props))},
                    renderTab: (props) => { return (this.renderCompanyPayment(props)) }
                }
            );
        }

        if (pageContentList.includes("Company Invoice")) {
            tabObjs.push(
                {
                    tabId: '8',
                    label: 'Invoice',
                    // renderTab : this.renderCompanyAccount,
                    // renderTab : (props) => {return(this.renderCompanyAccount(props))},
                    renderTab: (props) => { return (this.renderInvoiceHistory(props)) },
                }
            );
        }

        if (pageContentList.includes("Payment History *NO*")) {
        } else if (this.state.companyCode !== "METRO") {
            tabObjs.push(
                {
                    tabId: '9',
                    label: 'Payment History',
                    // renderTab : this.renderCompanyAccount,
                    renderTab: (props) => { return (this.renderPaymentHistory(props)) },
                }
            );
        }

        //if(pageContentList.includes("Branding")){
        tabObjs.push(
            {
                tabId: '14',
                label: 'Branding',
                // renderTab : this.renderCompanyAccount,
                renderTab: (props) => { return (this.renderCompanyBrandingBoard(props)) },
            }
        );
        //}

        //if(pageContentList.includes("GL Codes")){
        (this.state.userObj && hasRole(this.state.userObj, ['ADMIN', 'USER']) && this.state.companyCode !== "METRO") &&
            tabObjs.push(
                {
                    tabId: '15',
                    label: 'GL Codes',
                    renderTab: (props) => { return (this.renderCompanyAddGeneralLedgerCode(props)) },
                }
            );
        //}

        //if(pageContentList.includes("Adjustment Reasons")){
        (this.state.userObj && hasRole(this.state.userObj, ['ADMIN', 'USER']) && this.state.companyCode !== "METRO") &&
            tabObjs.push(
                {
                    tabId: '16',
                    label: 'Adjustment Reasons',
                    renderTab: (props) => { return (this.renderCompanyGeneralLedgerCodeReasonUsage(props)) },
                }
            );
        //}

        // ---------------------------------------------------------------------------------------
        // let tabObjs = [
        //     {
        //         tabId: '1',
        //         label: 'Edit Info',
        //         renderTab : this.renderCompanyEditForm,
        //     },
        //     // {
        //     //     tabId: '2',
        //     //     label: 'Assign Asset Class',
        //     //     renderTab : this.renderCompanyAssetClass,
        //     // },
        //     {
        //         tabId: '3',
        //         label: 'Assign Device Type',
        //         renderTab : this.renderCompanyDeviceType,
        //     },
        // ];
        return (
            <div>
                {
                    (this.state.loading) ?
                        this.renderSpinner()
                        :
                        (this.state.listView) ?
                            this.renderCompanyList()
                            :
                            // this.renderCompanyEditForm()
                            this.renderCompanyEditBoard(tabObjs)
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        companyObj,
        companyList,
        companyAll,
        countryList,
        provinceList,
        companyDevicetypeList,
        companyAssetClassList,
        accountList,
        planItemsAll,
        paymentHistory,
        paymentInvoice,
        paymentInvoiceDetails,
        currentCompanyCode,
        customerObj,
        orderList,
        adjustmentList,
        pageContentList,
        branding,
        transactionHistory,
        provinceMap,
        userActivityTrackingList
    } = state.user;
    let { inventoryList, activeSIMList, activityReportList } = state.inventory;
    let props = {
        companyObj,
        companyList,
        companyAll,
        countryList,
        provinceList,
        companyDevicetypeList,
        companyAssetClassList,
        accountList,
        inventoryList,
        planItemsAll,
        paymentHistory,
        paymentInvoice,
        paymentInvoiceDetails,
        currentCompanyCode,
        customerObj,
        orderList,
        adjustmentList,
        activeSIMList,
        pageContentList,
        activityReportList,
        branding,
        transactionHistory,
        provinceMap,
        userActivityTrackingList
    };
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getCompanyList,
        getAllCompanies,
        getOneCompanyByCode,
        updateCompany,
        deleteCompany,
        getCountries,
        getProvinces,
        getUserCompanyDeviceTypes,
        getUserCompanyAssetClass,
        updateCompanyDeviceList,
        updateCompanyAssetClass,
        getAccounts,
        getInventoryList,
        getAllPlanItems,
        getCustomerPaymentHistory,
        getCustomerInvoiceHistory,
        clearCustomerInvoiceHistory,
        getCustomerInvoiceDetails,
        setCurrentCompanycode,
        getCustomerByCompanycode,
        getorderbycustomer,
        updateOrder,
        updateService,
        deleteService,
        getAdjustmentByCompanycode,
        clearAdjustment,
        releaseOrder,
        deleteAccount,
        getFullSIMList,
        getPageContent,
        getActivityReport,
        getBrandingDetails,
        setBrandingDetails,
        loadTransactionHistory,
        refundTransaction,
        GetUserActivityTracking
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyBoard);