/* eslint-disable */
import React, {useEffect, useState} from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';

const FaceRecordsList = ({eventPurpose,list}) => {
    const [EventPurpose, setEventPurpose] = useState(null);
    useEffect(() => {
        setEventPurpose(eventPurpose)
    }, [eventPurpose])

    useEffect(() => {
        //console.log('Face List', list);
    }, [list]);
    const eventFormatter = (cell,row) => {
        return (
            <span>{EventPurpose? EventPurpose : 'N/A'}</span>
        )
    }
    const cameraNameFormatter = (cell, row) => {
        return (
            <span>IPC</span>
        )
    }
    const cameraChannelFormatter = (cell, row) => {
        return (
            <span>1</span>
        )
    }
    const timeFormatter = (cell, row) => {
        return (
            <span>{moment(cell).utc().subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss')}</span>
        )
    }
    const tablePagination = paginationFactory({
        sizePerPage: 3
    })
    const renderTable = (data, event)=>{
        //console.log('FACE DATA', data)
        const columns=[
            {dataField: 'nvrIp', text: 'NVR IP', headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'nvrName', text: 'NVR Name', headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'cameraName', text: 'Camera', formatter :cameraNameFormatter, headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'cameraChannel', text: 'Channel',formatter: cameraChannelFormatter, headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'name', text: 'Name', style:{fontWeight:'bold'}, headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'time', formatter: timeFormatter, text: 'Time Recorded', headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'similarity', text: 'Similarity', headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'library', text: 'Face Library', headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'Event Purpose', isDummyField: true, text: 'Event Purpose', formatter: eventFormatter, headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'stringFrameImg', text: 'Caught Image', formatter: (value, row)=>(<img src={`data:image/png;base64,${value}`}/>), headerClasses: "ai-records-header", classes: "ai-records"},
            {dataField: 'stringLibraryImg', text: 'Library Image', formatter: (value, row)=>(<img src={`data:image/png;base64,${value}`}/>), headerClasses: "ai-records-header", classes: "ai-records"}
        ]
        if(data){
            //console.log('data existed')
            return (
                <BootstrapTable classes="face-reg-table" data={data} keyField='id' columns={columns} filter={filterFactory()} pagination={tablePagination}/>
            )
        }
    }
    if(EventPurpose){
        return (
            renderTable(list,EventPurpose)
        )

    }else{
        return null
    }
}

export default FaceRecordsList