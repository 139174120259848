/* eslint-disable */
import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
// import {connect } from 'react-redux';
// import {Modal} from "react-overlays";
import { toast } from "react-toastify";
import { getCurrentUser, hasRole } from '../../../factories/auth';

const backdropStyle = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    overflow: 'auto',
};

const modalStyle = function () {
    const top = 5;
    const left = 5;

    return {
        position: 'fixed',
        width: 800,
        height: 650,
        zIndex: 1040,
        top: top + '%',
        left: left + '%',
        border: '0px solid #ffffff',
        backgroundColor: 'white',
        // boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflow: 'auto',
        resize: 'both'
    };
};

class CompanyEditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            data: [],
            email: '',
            fullName: '',
            userName: '',
            newUser: true,
            activeTab: '1',
            statesList: [],
            // groupdata: [],
            passConfirmed: true,
            // passconfirmValid: false,
            companyObj: {
                addressline1: "",
                addressline2: "",
                businessArea: "",
                city: "",
                companyName: "",
                country: "",
                email: "",
                phoneNum: "",
                postcode: "",
                province: "",
                status: "",
                isnew: true,
                industryId: 1,
            },
        };
        this.userObj = getCurrentUser();
    }

    componentDidMount() {
        if (this.props.companyObj) {
            if (this.props.companyObj.companyCode) {
                this.setState(prevState => {
                    prevState.companyObj["isnew"] = false;
                    return ({
                        companyObj: prevState.companyObj,
                    })
                });
            }
        }
        // this.props.getCompanyList()
        // this.props.getRoles();

        let cval = this.state.companyObj.country ? this.state.companyObj.country : this.props.countryList[0].value;

        if (cval) {
            this.setState({
                statesList: this.props.provinceMap.get(cval)
            })
        }
    }

    componentWillUnmount() {

        this.clearCompanyObj();
    }

    clearCompanyObj = () => {
        this.setState({
            companyObj: {
                addressline1: "",
                addressline2: "",
                businessArea: "",
                city: "",
                companyName: "",
                country: "",
                email: "",
                phoneNum: "",
                postcode: "",
                province: "",
                status: "",
                isnew: true,
                industryId: 1,
            },
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {


        if (nextProps.companyObj && nextProps.companyObj !== prevState.rawCompanyObj) {
            // if (nextProps.companyObj.country){
            //     nextProps.getProvinces(nextProps.companyObj.country);
            // }
            nextProps.getProvinces(nextProps.companyObj.country);
            nextProps.companyObj["isnew"] = false;
            return ({
                companyObj: JSON.parse(JSON.stringify(nextProps.companyObj)),
                rawCompanyObj: nextProps.companyObj,
            });
        }
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //
    //
    //     if (nextProps.companyObj && nextProps.companyObj !== this.props.companyObj){
    //         if (nextProps.companyObj.country){
    //             this.props.getProvinces(nextProps.companyObj.country);
    //         }
    //         this.setState({
    //             companyObj: nextProps.companyObj
    //         });
    //     }
    // }

    renderBackdrop = (props) => {
        return <div {...props} style={backdropStyle} />;
    };

    onInputChange = (event) => {

        // this.setState({
        //    [event.target.name]: event.target.value
        // });

        let key = event.target.name;
        let value = event.target.value;
        this.setState(prevState => {
            prevState.companyObj[key] = value;
            return ({
                companyObj: prevState.companyObj,
            })
        });
    };

    onCountryChange = async (event) => {
        let address = this.state.companyObj;
        let stateList = this.props.provinceMap.get(event.target.value);
        address.country = event.target.value;
        address.province = stateList[0].abbrev;
        this.setState({
            companyObj: address,
            statesList: stateList
        })
    };

    onSubmit = () => {


        // let phoneNo = this.state.phoneNumber;
        // if (phoneNo){
        //     phoneNo = phoneNo.replace('(','').replace(')','').replace('-','').replace(' ','');
        // }
        if (this.validateForm()) {
            // // alert ('v yes');
            // let userObj = {
            //     // userId: this.state.loginName,
            //     userType: "pp",
            //     userName: this.state.loginName,
            //     email: this.state.email,
            //     status: "A",
            //     companyCode: this.state.company ? this.state.company : sessionStorage.getItem('companyCode'),
            //     department: '', //sessionStorage.getItem('department') ? sessionStorage.getItem('department'): null ,
            //     businessUnit: '', //sessionStorage.getItem('businessUnit') ? sessionStorage.getItem('businessUnit'): null,
            //     // phoneNum: this.state.phoneNumber,
            //     phoneNum: phoneNo,
            //     password: this.state.passwordConfirm,
            //     creator: sessionStorage.getItem('currentUser'),
            //     createTime: new Date().toISOString().split('.')[0].replace('T', ' '),
            //     role: [this.state.role], //['USER'],
            // }
            //
            // this.props.addUser(userObj,true);
            // this.onClose();
            this.props.handleSubmitClick(this.state.companyObj);
        } else {
            toast.error('Input was not valid.');
        }
    };

    onClose = () => {
        // this.setState({
        //     showModal: false
        // });
        this.clearCompanyObj();
        this.props.toggle();
    };


    validateCompanyName = (companyName) => {
        return (companyName && companyName !== '');
    }

    validatePhoneNumber = (phoneNumber) => {

        // var regex =  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var regex = /^[0-9]{10,14}$/;
        return (phoneNumber && regex.test(phoneNumber));
    }

    validateEmail = (email) => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (email && regex.test(email));
    }

    validateForm = () => {
        let validated = false;
        if (this.state.companyObj) {
            validated = this.validateCompanyName(this.state.companyObj.companyName) &&
                this.validatePhoneNumber(this.state.companyObj.phoneNum) &&
                this.validateEmail(this.state.companyObj.email);
        }
        return validated;
    }

    render() {
        let labalStyle = { textAlign: 'right' };
        let title = 'New Company';
        if (this.props.companyObj && this.props.companyObj.companyCode) {
            title = 'Edit Company';
        }
        return (
            <Col md={12}>
                <Card>
                    <CardBody>
                        {/* <h3>Information</h3> */}
                        {/* <CardTitle><h4><b>{title}</b></h4></CardTitle> */}
                        {/* <br /> */}
                        <Col md={12}>
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Company Code</span>
                                </Col>
                                <Col md={8}>
                                    {/* <span>User Name</span> */}
                                    {/* <Input
                                        name='companyName'
                                        placeholder='Company Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        // value={this.state.companyName}
                                        value={this.state.companyObj.companyName ? this.state.companyObj.companyName : ''}
                                        // disabled={this.props.userName !== ''}
                                        valid = {this.validateCompanyName(this.state.companyObj.companyName)}
                                    /> */}
                                    {this.props.companyObj && this.props.companyObj.companyCode
                                        ? <span style={{ fontSize: "1rem" }}>{this.state.companyObj.companyCode}</span>
                                        : <Input
                                            maxLength={5}
                                            name='companyCode'
                                            placeholder='Company Code'
                                            onChange={this.onInputChange}
                                            className="form-control"
                                            // value={this.state.companyName}
                                            value={this.state.companyObj.companyCode ? this.state.companyObj.companyCode : ''}
                                        // disabled={this.props.userName !== ''}
                                        // valid = {this.validateCompanyName(this.state.companyObj.companyName)}
                                        />
                                    }
                                    {/* <span><b>{this.state.companyObj.companyCode ? this.state.companyObj.companyCode : ''}</b></span> */}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Company Name</span>
                                </Col>
                                <Col md={8}>
                                    {/* <span>User Name</span> */}
                                    <Input
                                        name='companyName'
                                        placeholder='Company Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        // value={this.state.companyName}
                                        value={this.state.companyObj.companyName ? this.state.companyObj.companyName : ''}
                                        // disabled={this.props.userName !== ''}
                                        invalid={!this.validateCompanyName(this.state.companyObj.companyName)}
                                    />
                                </Col>
                            </Row>
                            <br />
                            {/* <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>User Name</span>
                                </Col>
                                <Col md={8}>
                                    <span><Trans i18nKey="fullName">Full Name</Trans></span>
                                    <Input
                                        name='fullName'
                                        placeholder='Full Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        value={this.state.fullName}
                                    />
                                </Col>
                            </Row>
                            <br/> */}
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Phone Number</span>
                                </Col>
                                <Col md={8}>
                                    {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                                    <Input
                                        name='phoneNum'
                                        // placeholder='Phone Number'
                                        placeholder={'Coutry Code + Area Code + Number -- number only no space'}
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        // value={this.state.phoneNumber}
                                        value={this.state.companyObj.phoneNum ? this.state.companyObj.phoneNum : ''}
                                        invalid={!this.validatePhoneNumber(this.state.companyObj.phoneNum)}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Email</span>
                                </Col>
                                <Col md={8}>
                                    {/* <span>Email</span> */}
                                    <Input
                                        name='email'
                                        placeholder='Email'
                                        onChange={this.onInputChange}
                                        pattern={'^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'}
                                        className="form-control"
                                        // value={this.state.email}
                                        value={this.state.companyObj.email ? this.state.companyObj.email : ''}
                                        invalid={!this.validateEmail(this.state.companyObj.email)}
                                    />
                                </Col>
                            </Row>
                            <br />
                        </Col>
                        <br />
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Address Line 1</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='addressline1'
                                    placeholder='Street Line 1'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    // value={this.state.addressline1}
                                    value={this.state.companyObj.addressline1 ? this.state.companyObj.addressline1 : ''}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Address Line 2</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='addressline2'
                                    placeholder='Street Line 2'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    // value={this.state.addressline2}
                                    value={this.state.companyObj.addressline2 ? this.state.companyObj.addressline2 : ''}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>City</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='city'
                                    placeholder='City'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    // value={this.state.city}
                                    value={this.state.companyObj.city ? this.state.companyObj.city : ''}
                                />
                            </Col>
                            <Col md={2} style={labalStyle} >
                                <span>Zip/Postal Code</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='postcode'
                                    placeholder='Code'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    // value={this.state.postalCode}
                                    value={this.state.companyObj.postcode ? this.state.companyObj.postcode : ''}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Country</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    type='select'
                                    name='country'
                                    placeholder='Contry'
                                    onChange={this.onCountryChange}
                                    className="form-control"
                                    // value={this.state.contry}
                                    value={this.state.companyObj.country ? this.state.companyObj.country : ''}
                                >
                                    {/* <option value={'CA'}>Canada</option>*/}
                                    {/* <option value={'USA'}>United States</option> */}
                                    {/* <option value={''}>{'No selection'}</option> */}
                                    {this.props.countryList && this.props.countryList.map((option, index) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col md={2} style={labalStyle} >
                                <span>{this.userObj.companyCode && this.userObj.companyCode == "TEISA" ? 'State/City' : 'State/Province'}</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    type='select'
                                    name='province'
                                    placeholder='Postal Code'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    // value={this.state.postalCode}
                                    value={this.state.companyObj.province ? this.state.companyObj.province : ''}
                                >
                                    {/* <option value={''}>{'No selection'}</option> */}
                                    {this.userObj.companyCode &&
                                        this.state.statesList &&
                                        this.state.statesList.map((option, index) => (
                                            <option key={index} value={option.abbrev}>
                                                {option.name}
                                            </option>
                                        ))}
                                </Input>
                            </Col>
                        </Row>
                        {/* <hr/> */}
                        {/* <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Password</span>
                            </Col>
                            <Col md={8}>
                                <span>User Name</span>
                                <Input
                                    valid = {this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                                    name='password'
                                    type='password'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.password}
                                    disabled={this.props.userName !== ''}
                                />
                            </Col>
                        </Row>
                        <br/> */}
                        {/* <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Password confirmation</span>
                            </Col>
                            <Col md={8}>
                                <span>User Name</span>
                                <Input
                                    valid = {this.state.passConfirmed}
                                    valid = {this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                                    invalid = {this.state.passConfirmed}
                                    invalid = {this.state.passwordConfirm && this.state.password !== this.state.passwordConfirm}
                                    name='passwordConfirm'
                                    type='password'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.passwordConfirm}
                                    disabled={!this.state.password || this.state.password == ''}
                                    disabled = {true}
                                />
                            </Col>
                        </Row> */}
                        <br />
                        {/* <hr/> */}
                        {/* </ModalBody> */}
                        {/* <ModalFooter> */}
                        <br />
                        <div style={{ textAlign: 'right' }}>
                            {
                                (hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"])) ?
                                    <Button color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()} >
                                        Submit
                                    </Button>
                                    : null
                            }
                            {
                                (hasRole(this.props.userObj, ["SUPERADMIN"])) ?
                                    <Button outline color='secondary' type="reset" onClick={this.onClose}>
                                        Close
                                    </Button> : null
                            }

                        </div>
                        {/* </ModalFooter> */}
                        {/* <hr /> */}
                    </CardBody>
                </Card>
            </Col>

        )
    }
}

export default CompanyEditForm;
