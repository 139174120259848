/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {
    LightningBoltIcon,
    WeatherPouringIcon,
    ThermometerIcon,
    WaterPercentIcon,
    WeatherSunnyIcon,
    WeatherWindyIcon,
    Battery70Icon,
    MessageOutlineIcon,
    DirectionsForkIcon,
    GaugeLowIcon,
    LightningBoltOutlineIcon,
    CloseOctagonIcon,
    SmokeFreeIcon,
    MoleculeCo2Icon,
} from 'mdi-react';
import { mdiMoleculeCo2, mdiMoleculeCo, mdiVolumeHigh} from '@mdi/js';
import Icon from '@mdi/react'
import Tooltip from '@material-ui/core/Tooltip';

const ICON_SIZE = 20;
const AttributeIcon = ({ attributeName }) => {
    switch (attributeName) {
        case 'timestamp':
            return <MessageOutlineIcon size={ICON_SIZE} />;
        case 'temp':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <ThermometerIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'Pressure':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <GaugeLowIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'hum':
            return (
                <Tooltip title={attributeName}>
                    <div>
                    <WaterPercentIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'lum':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <WeatherSunnyIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'Battery':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <Battery70Icon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'Rain':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <WeatherPouringIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'Wind_ave10':
        case 'Wind_max10':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <WeatherWindyIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'Dir_ave10':
        case 'Dir_max10':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <DirectionsForkIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'Dir_ave10_txt':
        case 'Dir_max10_txt':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <DirectionsForkIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'hexAlarm':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <LightningBoltOutlineIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'smoke':
            return (
                <Tooltip title={attributeName}>
                <div>
                    <SmokeFreeIcon size={ICON_SIZE}/>
                </div>
            </Tooltip>);
        case 'voc':
            return (
                <Tooltip title={attributeName}>
                <div>
                <CloseOctagonIcon size={ICON_SIZE}/>
                </div>
            </Tooltip>);
        // case 'pm25':
        case 'CO':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <Icon size={1} path={mdiMoleculeCo}/>
                    </div>
                </Tooltip>
            );
        case 'CO2':
            return (
                <Tooltip title={attributeName}>
                    <div>
                        <Icon size={1} path={mdiMoleculeCo2}/>
                    </div>
                </Tooltip>
            );
            
        case 'pm10':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                        <GaugeLowIcon size={ICON_SIZE} />
                    </div>
                </Tooltip>);
        case 'bd':
            return (
                <Tooltip title={attributeName}>
                    <div>
                    <Icon size={1} path={mdiVolumeHigh}/>
                    </div>
                </Tooltip>
            );
        default:
            return <span></span>;
    }
}

export default AttributeIcon;