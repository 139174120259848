/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {Col, Row, Card, CardBody, Container, Nav, NavItem, NavLink, TabPane, TabContent, CardTitle} from 'reactstrap';
import SearchList from './components/search';
import {getCurrentUser, hasRole} from '../../factories/auth';

class SearchUser extends Component {
    render() {
        let userObj = getCurrentUser();
        if (userObj === null || userObj.userName.length === 0){
            this.props.history.push('/log_in');
        }

        return (
            <Container className='dashboard'>
                <Row>
                    <Col md={12}>
                        <SearchList userObj = {userObj}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(SearchUser);
