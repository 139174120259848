/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { EditIcon, WindowCloseIcon } from 'mdi-react';
import moment from 'moment';
import {
    getAssetsData, 
    getAttributeChart,
    // loadAssetList
    getAttributeThreshold,
    updatePubSubDevice,
    deleteDashBoardWidget,
} from '../../../redux/actions/dashboardActions';
import AssetAttrEditModal from './AssetAttrEditModal';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { filtXDataset } from '../../../factories/utils';

class AttrChartWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            chartdata: {},
        }
    }

    componentDidMount(){
        // Do Nothing
    }

    componentWillReceiveProps(nextProps){
        // if(nextProps.dashName && nextProps.parameter && nextProps.parameter.assetid && nextProps.parameter.assetid != this.state.assetid) { 
        //      
        //      
        //     this.setState({
        //         assetid: nextProps.parameter.assetid
        //     });

        //     // nextProps.getAssetRecursiveData(nextProps.parameter.assetid, nextProps.dashName);
        // }
        if(nextProps.dashName && nextProps.parameter && nextProps.parameter.assetid && nextProps.parameter.attid) {
            if (nextProps.dashName !== this.state.dashName || nextProps.parameter.assetid !== this.state.assetid) {
                this.setState({
                    dashName: nextProps.dashName,
                    assetid: nextProps.parameter.assetid,
                });
                let now = new Date();
                // let before24 = new Date(now.getTime() - (24*3600*1000));
                const current = moment(now).format('YYYY-MM-DDTHH:mm'); 
                const before24 = moment(now).add(-24,'hours').format('YYYY-MM-DDTHH:mm'); 
                // this.props.getAssetsData({assetid: this.props.parameter.assetid, starttime: before24, endtime: current}, this.props.dashName);
                this.props.getAttributeChart({entitygroup: [], entity: this.props.parameter.assetid, starttime: before24, endtime: current, latest: 0, themeid: ""}, this.props.dashName);
            }
            // if (nextProps.parameter.attrid !== this.state.attrid){
            //     this.setState({
            //         attrid: nextProps.parameter.attrid,
            //     });
            //     // this.props.getAttributeThreshold( nextProps.parameter.assetid, nextProps.parameter.attrid , nextProps.dashName);
            // }
        }
        if (nextProps.parameter) {
             
        }
        if (nextProps.dashName && nextProps.parameter) {
            let dashname = nextProps.dashName;
            if (nextProps.attributeChart && nextProps.attributeChart[dashname] && nextProps.attributeChart[dashname] != this.props.attributeChart[dashname]) {
                let assetData = nextProps.attributeChart[dashname];
                const {assetclassid, assetid, attid, attname, attunit, attcolor} = nextProps.parameter;
                 
                // this.setAttributes(assetData, nextProps.parameter);
                const chartdata = processAssetData(assetData, assetclassid, assetid, attid, attname, attunit, attcolor);
                this.setState({
                    chartdata : chartdata,
                });
            }

        }
        if (nextProps.pubSubData && this.state.chartdata && this.state.chartdata.data){
            this.updateAssetValue(nextProps.pubSubData, nextProps.parameter);
        }
    }
// --------------------------------------------------------
pubSubUpdate = (data) => {
    
}
updateAssetValue(pubData, parameter) {
    // const {assetclassid, assetid, attid, attname, attunit, attcolor, deviceid} = parameter;
     
    // let dataObj = JSON.parse(JSON.stringify(pubData));
    let dataObj = JSON.parse(pubData);
    //  
    if (dataObj && parameter && (dataObj.deviceid == parameter.deviceid || dataObj.assetid == parameter.assetid)) {
        // let timestamp = dataObj.lastmessagetime;
        if (dataObj.msgs && dataObj.msgs.length > 0) {
            let msgObj = dataObj.msgs[dataObj.msgs.length-1];
            Object.entries(msgObj.msg).forEach(([attr, attrObj],mindex)=>{
                if (attrObj.id == parameter.attrid || attrObj.attributename == parameter.attrname){
                    // this.setValue(attrObj.value, this.state.threshold);
                    let dataItem = {
                        x: msgObj.timestamp ? msgObj.timestamp : dataObj.lastmessagetime, 
                        y: attrObj.value
                    };
                    this.setState(preState => {
                        preState.chartdata.data.push(dataItem);
                        let now = new Date();
                        const before24 = moment(now).add(-24,'hours'); 
                        let newData = preState.chartdata.data
                                        .filter(item => (moment(item.x).isAfter(before24)))
                                        .sort((a, b) => (moment(a.x).isAfter(moment(b.x)) ? 1 : (moment(b.x).isAfter(moment(a.x)) ? -1 : 0)));
                        preState.chartdata.data = newData;
                        if (newData && newData.length > 0) {
                            preState.chartdata.value = newData[newData.length-1].y;
                        }
                        
                        return {
                            chartdata : preState.chartdata,
                        };
                    });
                }
            });
        }
    }

}
// --------------------------------------------------------
renderLineChart = (color) => {
    // const chartlabel = ['A', 'B', 'C', 'D', 'E'];
    // const chartdata1 = [
    //     {x:'2016-12-25', y:20},
    //     {x:'2016-12-26', y:30},
    //     {x:'2016-12-27', y:50},
    //     {x:'2016-12-28', y:40},
    //     {x:'2016-12-29', y:10}
    // ];
    // const chartdata2 = [
    //     {x:'2016-12-25', y:10},
    //     {x:'2016-12-26', y:40},
    //     {x:'2016-12-27', y:30},
    //     {x:'2016-12-28', y:20},
    //     {x:'2016-12-29', y:50}
    // ];

    let h = JSON.parse(this.props.layout.position).h;
    let height = h * 30 - 20;
    const chartColor = color;
    const data = {
        // labels: chartlabel,
        datasets: [
            {
                label: 'Alarm Red',
                // backgroundColor: 'rgba(255,99,132,0.2)',
                // backgroundColor: 'red',
                // borderColor: 'rgba(255,99,132,1)',
                borderColor: chartColor,
                pointRadius: 0,
                // borderWidth: 0.2,
                // barPercentage: 0.9,
                // categoryPercentage: 0.6,
                pointHoverBackgroundColor: 'rgba(255,99,132,0.4)',
                pointHoverBorderColor: 'rgba(255,99,132,1)',
                // data: chartdata1,
                // data: this.state.chartdata.data,
                data: filtXDataset(this.state.chartdata.data, 50),
            },
        ]
    };

    const options = {
        aspectRatio: 2,
        responsive: true,
        maintainAspectRatio: true,
        showScale: false,
        scales: {
            // bounds: 'data',
            // xAxes: [{
            //     stacked: false,
            //     type: 'category',
            //     ticks:{
                    
            //     },
            //     gridLines: {
            //         // display: false,
            //         // color: "rgba(0, 0, 0, 0)",
            //         drawBorder: true,
            //     }
            // }],
            xAxes: [{
                display: true,
                // position: 'bottom',
                type: 'time',
                distribution: 'series',
                // offset: true,
                time: {
                    unit: 'day'
                },
                ticks:{
                    // min: '2016-12-24',
                    // max: '2016-12-30',
                    // sampleSize: 1,
                    source: 'data',
                    // display: false,
                    fontColor: chartColor,
                    maxRotation: 0,
                    padding: 10,
                    callback: function(value, index, values) {
                        //  
                        if (index === 0) {
                            return 'Last 24 hrs';
                        } 
                        if (index === values.length - 1) {
                            //  
                            return 'Now';
                        }
                        return null;
                    }
                },
                gridLines: {
                    // display: false,
                    display: true,
                    color: chartColor,
                    // color: "rgba(0, 0, 0, 0)",
                    drawBorder: true,
                    drawOnChartArea: false,
                    drawTicks: false,
                }
            }],
            yAxes: [{
                // stacked: false,
                display: true,
                // type: 'linear',
                position: 'left',
                // color:'black',
                // // offset: true,
                // scaleLabel: {
                //     display: true,
                // },
                ticks: {
                    min: 10,
                    // max: 70, // this.state.chartdata.value,
                    // sampleSize: 3,
                    // beginAtZero: true,
                    display: false,
                    fontColor: chartColor,
                    // callback: function(value, index, values) {
                    //     //  
                    //     if (index === 0) {
                    //         //  
                    //         return values[0] + '/' + values[values.length-1];
                    //     }else{
                    //         return null;
                    //     }
                    //     // return null;
                    // }
                },
                scaleLabel: {
                    display: true,
                    // labelString: '1k = 1000'
                },
                gridLines: {
                    display: true,
                    color: chartColor,
                    // color: "rgba(0, 0, 0, 0)",
                    drawBorder: true,
                    drawOnChartArea: false,
                    drawTicks: false,

                }
            }]
        },
        legend: {
            display: false,
            position: 'right',
            labels: {
                boxWidth: 10,
                fontSize: 12,
                fontColor: 'rgb(255, 99, 132)'
            }
        }
    }
    return(
        <Line
            width={'200px'}
            height={null}
            // key={h}
            // height={height.toString()} // height={450} 
            data={data} 
            options={options}
        >    
        </Line>
    )
}

// --------------------------------------------------------
    onDeleteWidget = () => {
        if (this.props.layout){
            const {layoutid, templateid} = this.props.layout
            this.props.deleteDashBoardWidget(layoutid, templateid);
        }else{
            toast.error('Could not get the layout ID! Please try again.');
        }
    }

    render(){
        let subtitle = "test";
        return (
            <Fragment>
                <Card >
                    <CardHeader className={'dragBar'}> 
                        {/* <div className="card__title"> */}
                            <h5 className="bold-text">
                                {this.props.dashName} 
                                {this.props.layout && 0 == this.props.layout.locked &&
                                <>
                                 <ConfirmDeleteDialog 
                                    // label={""} 
                                    icon = {(props)=>(<WindowCloseIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} {...props}/>)}
                                    style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                    onConfirm={(e)=>{this.onDeleteWidget()}}
                                    msg={'Do you want to delete the selected widget ? '}
                                />
                                {/* <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>     */}
                                <AssetAttrEditModal 
                                    layout={this.props.layout}
                                    onEditSubmit = {this.props.onEditSubmit}
                                />
                                </>
                                }
                            </h5>                      
                        {/* </div> */}
                    </CardHeader>
                    <CardBody style={{backgroundColor:'white' , padding:15}}>
                        <h4 style={{color: this.state.chartdata.backgroundcolor}}>{this.state.chartdata.value}
                            {' '}
                            {this.state.chartdata.units}
                            {' '}
                            {this.state.chartdata.attributename}
                        </h4>
                        {/* {this.renderLineChart(this.props.dashName, subtitle, this.state.dataValue, null)} */}
                        {this.renderLineChart(this.state.chartdata.backgroundcolor)}
                    </CardBody>

                    {/* <CardBody style={{padding: '20px 30px', backgroundColor: this.state.chartdata.backgroundcolor,}}>
                        <Row style= {{margin: '0px 0px 10px 0px'}}>
                            <h4 style={{color: 'white'}}>{this.state.chartdata.value}
                            {' '}
                            {this.state.chartdata.units}
                            {' '}
                            {this.state.chartdata.attributename}</h4>
                        </Row>
                        <Row>
                            {this.renderLineChart()}
                            {this.renderLineChart(this.props.dashName, subtitle, this.state.dataValue, null)}
                        </Row>
                    </CardBody> */}


                </Card>
            </Fragment>
        );
    }
}
function mapStateToProps(state) { 
    const props = {...state.dashboard};
    return props;
};
    
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAssetsData,
        getAttributeChart,
        // getAssetRecursiveData,
        deleteDashBoardWidget,
    }, dispatch);
};

function processAssetData(assetData, assetclassid, assetid, attid, attname, attunit, attcolor){
     
     
    let hasData = false;
    let reData = {attributename: attname, backgroundcolor: attcolor, units: attunit, data: []};
    for (let assetclass of assetData) {
        if (hasData){
            break;
        }
        if (assetclass.asssetClassId != assetclassid || !assetclass.asset){
            continue;
        }
        for(let asset of assetclass.asset){
            if (hasData){
                break;
            }
            if(asset.assetid != assetid || !asset.device){
                continue;
            }
            for(let dev of asset.device) {
                if (hasData){
                    break;
                }
                if(!dev.msgs || !Array.isArray(dev.msgs) || dev.msgs.length < 1){
                    continue;
                }
                let msg1st = dev.msgs[0];
                let attKey = null;
                for (let key in msg1st.msg){
                    if (msg1st.msg[key].id == attid && msg1st.msg[key].attributename == attname){
                        attKey = key;
                        hasData = true;
                        break;
                    }
                }
                if (!attKey){
                    break;
                }
                for(let msg of dev.msgs){
                    if (msg.msg && msg.msg[attKey]){
                        reData.data.push({x:msg.timestamp, y:msg.msg[attKey].value});
                    }else{
                        reData.data.push({x:msg.timestamp, y:0});
                    }
                }
            }
        }
    }   
    reData.value = 0 ;
    if (reData.data && reData.data.length > 0) {
        let prev = reData.data[0];
        let max = prev.y;
        reData.data.forEach((item, index) => {
            if (index > 0) {
                const pretime = new Date(prev.x);
                const current = new Date(item.x);
                if (current > pretime) {
                    prev = item;
                    max = item.y;
                }
            }
        });
        reData.value = max;
    }
     
     
    return reData;
}
  
export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(AttrChartWidget));