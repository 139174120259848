/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import AttributeIcon from './AttributeIcon';
import { getAttributeThreshold, getAttributeUnits } from '../../../redux/actions/surveilenceActions';
import '../../../scss/animation.scss';


const GREEN_COLOR = '#00cc00';
const RED_COLOR = '#ff0000';

class SingleAttributeDisplay extends Component {
    constructor(props){
        super(props);
        this.state={
            attribureData: null,
            ruleList: null
        }
    }

    componentDidMount(){

    }

    // static async getDerivedStateFromProps(nextProps, prevState){
    //     if (nextProps.assetId && nextProps.attribureData){
    //         console.log("SingleAttributeDisplay props", nextProps)
    //         if (nextProps.assetId != prevState.assetId || nextProps.attribureData.id != prevState.attributid) {
    //              
    //              
    //              
    //              
    //              
    //             let ruleList = await getAttributeThreshold(nextProps.assetId, nextProps.attribureData.id );
    //             return {
    //                 ruleList: ruleList,
    //                 assetId: nextProps.assetId,
    //                 attributeid: nextProps.attribureData.id
    //             }
    //         }
    //     }
    // }

    componentWillReceiveProps(nextProps, nextContext) {
        let self = this;
        if (nextProps.assetId && nextProps.attribureData){
            if (nextProps.assetId != this.assetId || nextProps.attribureData.id != this.attributeid) {
                self.assetId = nextProps.assetId;
                self.attributeid = nextProps.attribureData.id;
                getAttributeThreshold(nextProps.assetId, nextProps.attribureData.id ).then(ruleList => {
                    self.setState({
                        ruleList: ruleList
                    });
                });
                
            }
        }
    }

    loadThreshold = async() => {
        let ruleList = await getAttributeThreshold(assetid, attributeid);
        this.setState({ruleList});
    }

    checkRule = () => {
        try {
            let expressStr = '';
            let dilimiter = ' ';
            this.props.attribureData && this.state.ruleList && this.state.ruleList.forEach((rule, index) => {
                if (index == 0){
                    expressStr = this.props.attribureData.value.toString() + getOperator(rule.operator) + rule.value.toString();
                }else{
                    expressStr += dilimiter + getRelation(rule.relation) + dilimiter + this.props.attribureData.value.toString() + getOperator(rule.operator) + rule.value.toString();
                }
            });
            console.log("my expression", expressStr)
            return eval(expressStr);
        }
        catch(e){
            return false;
        }
        
    }

    render() {
        let data = {};
        if (this.props.attribureData) {
            data = {...this.props.attribureData}
            data.units = getAttributeUnits(data.attributename);
            if (this.checkRule()) {
                data.isBlink = true;
            }else{
                data.isBlink = false;
            }
            // data.isBlink = true;
        }
        return (
            <SingleAttributeElement
                {...data}
            />
        );
    }
}

const SingleAttributeElement = ({attributename, displayname, value, units, isBlink}) => {
    console.log("========================== Test attribute", attributename, value )
    const [fontStyle, setFontStyle] = useState({color: 'black'});
    const smallTextStyle = {
        fontSize: '1.3vh',
        lineHeight: '1.3vh',
    }
    const normalTextStyle = {
        fontSize: '1.7vh',
        lineHeight: '1.7vh',
    }
    const titleRowStyle = {
        // margin: '5px 10px',
        // padding:'5px', 
        margin: '0px 0px',
        padding:'0px',
        // justifyContent: 'flex-end',
        justifyContent: 'space-between',
        alignItems: "center",
        lineHeight: '30px',
        height: '30px'
    }

    // if (isBlink){
    //     setFontStyle({...fontStyle, animation: 'valueBlink 0.7s infinite'});
    // }
    return (
        <Row>
            <Col md={5}>
                <Row style={titleRowStyle}>
                    <AttributeIcon
                        attributeName={attributename}
                    />
                    <span style={{fontWeight:'bold', ...smallTextStyle}}>{displayname == 'Temprature' ? 'Temperature' : displayname}</span>
                    {/* <h5>{displayname}</h5> */}
                </Row>
            </Col>
            <Col md={7}>
                <div style={rowClassGnerator(isBlink, null, attributename)}>
                    {/* <span class="subsup" style={fontStyle}> */}
                    <span style={normalTextStyle}>
                        {/* <sup>{displayname}</sup> */}
                        {value}{' '}{attributename == 'voc' ? "PPM" : units}
                    </span>
                </div>
            </Col>
           
        </Row>
        
    );
}

function rowClassGnerator(status, item, attributeName,  color=GREEN_COLOR, hasrule=false, isSmall=false) {
    let backgroupdColor = color
    let horizontalAlign = 'center'; //'space-evenly'; //'center';
    let alarmColor = RED_COLOR;

    // if (attributeName !== 'hexAlarm') {
    //     alarmColor = RED_COLOR;
    // }

    // var blink = keyframes`
    //     from {background-color: white; color: black; font-weight: bold;}
    //     to {background-color: ${alarmColor}; color: white; font-weight: bold;}
    // `;
    // `
    // 0% { transform: rotate(0deg); }
    // 100% { transform: rotate(360deg); }
    // `
    let reClass = {
        // color: 'black', 
        // fontWeight: 'normal',
        display: 'flex',
        margin: '5px 10px',
        padding:'5px 30px', 
        // paddingLeft: '0px',
        justifyContent: 'center',
        alignContent: 'center',
        // lineHeight: '30px',
        // height: '30px',
        lineHeight: isSmall ? null : '30px',
        height: isSmall ? null : '30px',
        backgroundColor: backgroupdColor, 
        color: 'white',
    };
    
    if (status == true){        
        return ({
           ...reClass,
            // animation: `${blink} 1s infinite`,
            backgroundColor: alarmColor,
            animation: 'alertBlink 1s infinite',
        });
    }else {
        return ({
            ...reClass,
        })
    }
}

function getOperator(code){
    switch(code){
        case 1:
            return "=";
        case 2:
            return ">";
        case 3:
            return ">=";
        case 4: 
            return "<";
        case 5:
            return "<=";
        case 6:
            return "!="
        default:
            return ""
    }
}

function getRelation(code){
    switch(code){
        case 1:
            return "&&";
        case 2:
            return "||";
        default:
            return "";
    }
}

export default SingleAttributeDisplay;