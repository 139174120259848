/* eslint-disable */
import React, {useEffect,useState} from 'react'
import { connect } from 'react-redux'
import {loadFaceDetectionRecords} from '../../../../redux/actions/surveilenceActions'
import {getJwttoken } from '../../../../factories/utils';
import {WEB_SUB_SOCKET} from '../../../../constants/appConstants';

import { Client } from '@stomp/stompjs';
import { LabelTextInput} from '../cameraList/CameraCreateModal';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse, CardTitle } from 'reactstrap';
import FaceDetectionList from './FaceDetectionList';
import { sendSMS } from '../../../../factories/utils';


const FaceDetectionDisplay = ({faceDetectionRecords, loadFaceDetection, location}) => {
    const [PlazaLocation, setPlazaLocation] = useState('ITSP Office')

    useEffect(() => {
        if(location && location !== PlazaLocation){
            setPlazaLocation(location)
        }
    }, [location])
    
    const [listening, setListening] = useState(false)
    const [stompClient, setstompClient] = useState(null);
    const [faceVisible, setFaceVisible] = useState(false);
    const [visitVisible, setVisitVisible] = useState(false);
    // const [alertReceivers, setalertReceivers] = useState(['6475511693'])

    useEffect(() => {
        // console.log('loaded faces...---------------------')
        loadFaceDetection();
        connectSocket();
        
    }, []);

    useEffect(() => {
        if(stompClient){
            // console.log('FD SOCKET CONNECTED', stompClient);
            stompClient.onConnect = connect_callback;
            stompClient.onDisconnect = disconnect_callback;
            // stompClient.onError = error_callback;
            stompClient.activate();
        }
    }, [stompClient])   

    useEffect(() => {
        // console.log('FACE RECOGNITION RECORDS----------------------',faceDetectionRecords)
    }, [faceDetectionRecords]);
    
    const connectSocket =()=> { 
        if (stompClient !== null && stompClient!==undefined) {
            stompClient.deactivate();
            //stompClient = null;
            
        }
        
        let query='?q='+getJwttoken();
        ///////////////////////////////////////////////////
        setstompClient(new Client({
            // brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query,
            brokerURL: `${WEB_SUB_SOCKET}`+query,
            reconnectDelay: 20000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        }));
        
        //////////////////////////////////////////////////
        //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
        //stompClient = Stomp.over(socket_URL); 
        //stompClient.reconnectDelay=5000; 
        //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;
        
    }
    const connect_callback =()=> {
        // console.log('connected to websoket alarm line passing');
        // called back after the stompClient is connected and authenticated to the STOMP server
        stompClient.subscribe('/topic/alarmFaceDetection', callbackDeviceMessage);
    }
    const disconnect_callback =()=> {
        stompClient.deactivate();
        // called back after the stompClient is connected and authenticated to the STOMP server
        // console.log('client disconnected')
        
    }
    const callbackDeviceMessage = (message) => {
        // console.log('FACE DETECTED--------------------------', message)
        // console.log(message.body)
        loadFaceDetection();
        // console.log('ALERT RECEIVERS, ', VisitInfo.alertReceivers)
        // // sendSMS(VisitInfo.alertReceivers, "We have detected Nick Athas – he has been banned from all TRU stores – please call 911 – he is trespassing")
        // // debugger;
    }


    return (
        <>
            <Collapse isOpen={faceDetectionRecords}>
                <Card>
                    <CardBody>
                        <CardTitle tag="h3">{PlazaLocation}</CardTitle>
                        <FaceDetectionList list={faceDetectionRecords}/>
                    </CardBody>
                </Card>
            </Collapse>
        </>
    )
}

const mapStateToProps = (state) => ({
    faceDetectionRecords: state.surveillance.faceDetectionRecords
})

const mapDispatchToProps = (dispatch) => {
    return{
        loadFaceDetection: ()=> dispatch(loadFaceDetectionRecords()),
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaceDetectionDisplay)
