/* eslint-disable */
import React, { Component, useEffect } from 'react';
import { components } from 'react-select';
import { Col, Row, Card, CardBody, Label, Button, Input, FormGroup, UncontrolledTooltip, Container  } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader, CustomInput } from 'reactstrap';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { EditIcon, DeleteIcon } from 'mdi-react';
import IconElement from './IconElement';
import {EditTextButton, DeleteTextButton} from '../../../shared/components/Button/IconButton';

import MultiSelectionComponent, { SingleSelection, DeviceMultiSelection, AssetMultiSelection, ClassMultiSelection } from './SelectionComponents';

import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import AssetIconEditModal from './AssetIconEditModal';

import { hasRole } from '../../../factories/auth';

const svgFormatter = data => {
  return (
    <svg width="50px" height="50px" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path fill="none" stroke="#444"
        d={data} />
    </svg>
  )
}

export default function ClassDisplayDetail({ deviceTypes, assetList, closeDetailsPanel, details, customAttributeActions, calculateAttributeActions, updateClassOnStore, assetClassList, companyList, userObj, classAttributes }) {
  // console.log(details)
  const { addNewCustomAttribute, updateCustomAttribute, deleteCustomAttribute } = customAttributeActions;
  const { upsertCalculateAttribute, deleteCalculateAttribute } = calculateAttributeActions;
  // Set default value to selected device ids
   
   
  //  
  const deviceTypeDefaultValues = details.allowedDeviceTypes
   
  const assetDefaultValues = assetList.filter(({ assetid }) => details.assets.some(a => a.assetid == assetid));
  const parentDefaultValues = assetClassList.find(({id}) => (id == details.parentclass));
  const companyDefaultValue = companyList.find((item, index) => (item.companyCode == details.companycode));
  const companyEnabled =  (userObj &&  hasRole(userObj,  ['SUPERADMIN']));

  const [selectedParentClass, setParentClass] = React.useState(parentDefaultValues);
  const [selectedCompany, setCompany] = React.useState(companyDefaultValue);

  const [selectedDeviceTypes, setDeviceTypes] = React.useState(deviceTypeDefaultValues)
  const [selectedDeviceTypesBuf, setDeviceTypesBuf] = React.useState(deviceTypeDefaultValues)
  const [selectedAssets, setAssets] = React.useState(assetDefaultValues)
  const [requireAll, setRequireAll] = React.useState(false)
  const [iconModal, setIconModal] = React.useState(false);
  const [iconObj, setIconObj] = React.useState({iconId:details.iconid, iconPath:details.iconsvg, iconData:details.iconData});

  useEffect(() => {
    console.log('ASSET CLASS DETAILS ' , details)
  }, [details]);


  const selectableAssetOptions = assetList.filter(({ devices }) =>
    requireAll ?
      devices.every(d => selectedDeviceTypes.some(dt => dt.id == d.devicetype)) :
      devices.some(d => selectedDeviceTypes.some(dt => dt.id == d.devicetype))
  )

  const requiredDeviceTypes = selectedDeviceTypes.filter(({ id }) =>
    selectedAssets.some(a => a.devices.some(d => d.devicetype == id))
  )

  const CustomMultiValue = props => {
    let newProps = {
      ...props,
      isDisabled: requiredDeviceTypes.includes(props.data),
      removeProps: requiredDeviceTypes.includes(props.data) ? {
        ...props.removeProps,
        onClick: () => { console.log('nope') },
      } : props.removeProps
    }
    return <components.MultiValue {...newProps} />
  }

  const CustomMultiValueRemove = props =>
    requiredDeviceTypes.includes(props.data) ?
      <div>&nbsp;</div> : <components.MultiValueRemove {...props} />

  const updateDeviceOnBlur = () => {
    if (selectedDeviceTypes != details.allowedDeviceTypes)
      updateClassOnStore({
        id: details.id,
        data: { allowedDeviceTypes: selectedDeviceTypes }
      },
      ()=>{setDeviceTypesBuf(selectedDeviceTypes)}, // success callback
      ()=>{setDeviceTypes(selectedDeviceTypesBuf)} // error callback
      )
  }
  const updateAssetOnBlur = () => {
    if (selectedAssets != details.assets)
      updateClassOnStore({
        id: details.id, data: { assets: selectedAssets }
      })
  }
  const updateParentOnBlur = () => {
    if (selectedParentClass && selectedParentClass.id != details.parentclass) {
      updateClassOnStore({
        id: details.id, data: { parentclass: selectedParentClass.id }
      })
    }else if (!selectedParentClass && parentDefaultValues) {
      updateClassOnStore({
        id: details.id, data: { parentclass: null }
      })
    }
  }

  const updateCompanyOnBlur = () => {
    if (selectedCompany && selectedCompany.companyCode != details.companycode) {
      updateClassOnStore({
        id: details.id, data: { companycode: selectedCompany.companyCode }
      })
    }
  }

  const modalToggle = () => {
    if (iconModal){
      setIconModal(false);
    }else{
      setIconModal(true);
    }
  }

  const editIcon=(iconId, iconPath) => {
    // setIconObj({iconId:iconId, iconPath:iconPath});
    updateClassOnStore({ id: details.id, data: { iconid: iconId } })
  }

  const disableEdit = details && details.assets && details.assets.length > 0 ;
  const classAttributeList = (classAttributes && Array.isArray(classAttributes) && classAttributes.length > 0 && classAttributes[0]) ? classAttributes[0].atrributes : [];

  return (details ?
    <Card>
      <CardBody>
        <Row>
          <Col>
            {/* <h3>{details.name}</h3> */}
            <EditableText defaultValue={details.name}
              onSave={name => {
                updateClassOnStore({ id: details.id, data: { name: name } })
              }}
              textStyles={{ fontSize: '24px', marginLeft: '-5px' }}
              inputStyles={{ fontSize: '28px', marginLeft: '-5px' }}
            />
          </Col>
          <Col className='text-right'>
            <div onClick = {modalToggle}>
            {/* {svgFormatter(details.iconsvg)} */}
            <IconElement width="45" height="45" path={details.iconsvg} data={details.iconData}/>
            {/* {svgFormatter(iconObj.iconPath)} */}
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <EditableAttribute name="Description" inputStyles={{ height: 100, paddingTop: 8 }}
            disallowNullValue
            defaultValue={details.description} big exitOnEnterKeyPress={false}
            onSave={newVal => updateClassOnStore({ id: details.id, data: { description: newVal } })}
            type='textarea'
          />
          <Col md={8}>
            <AttributeInfo name="Allowed Device Types">
              {/* <DeviceMultiSelection */}
              <div id='AssetClassEdit' >
              <ClassMultiSelection
                styles={multiSelectStyles}
                components={{
                  MultiValue: CustomMultiValue,
                  MultiValueRemove: CustomMultiValueRemove
                }}
                onBlur={updateDeviceOnBlur}
                options={deviceTypes}
                onChange={options => {
                  // Disallows changing of values if change would remove a required Device Type
                  if (requiredDeviceTypes.every(dt => options.includes(dt)))
                    setDeviceTypes(options || [])
                }}
                value={selectedDeviceTypes}
                defaultValue={deviceTypeDefaultValues}
                // isDisabled= {details.assets && details.assets.length > 0}
                
              />
              </div>
              { details.assets && details.assets.length > 0 &&
                <UncontrolledTooltip placement="top" target="AssetClassEdit">
                  Since there are assets linked to the assetclass, you can not edit device types
                </UncontrolledTooltip>
              }
            </AttributeInfo>
            <AttributeInfo name="Assets">
              <AssetMultiSelection
                styles={multiSelectStyles}
                onBlur={updateAssetOnBlur}
                options={selectableAssetOptions}
                onChange={options => setAssets(options || [])}
                defaultValue={assetDefaultValues}  // Set default value to selected device ids
                // isDisabled= {true}
                placeholder ={''}
              />
              {/* <label style={{
                color: 'hsl(0, 0%, 40%)',
                display: 'inline-block',
                fontSize: 12,
                fontStyle: 'italic',
                marginTop: '1em',
              }}>
                <input type="checkbox"
                  checked={requireAll}
                  onChange={() => setRequireAll(!requireAll)}
                  disabled={true}
                />
                Require All
              </label> */}
            </AttributeInfo>
            <AttributeInfo name="Parent Asset Class">
            <MultiSelectionComponent
              options={assetClassList}
              isMulti={false}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
              defaultValue={parentDefaultValues}
              onChange = {(val) => {setParentClass(val)}}
              onBlur = {updateParentOnBlur}
              isDisabled= {details.assets && details.assets.length > 0}
              isClearable
            />
            </AttributeInfo>

            <AttributeInfo name="Company">
              <MultiSelectionComponent
                isClearable
                isSearchable
                isMulti={false}
                isDisabled={!companyEnabled}
                options={companyList}
                getOptionLabel={({ companyName }) => companyName}
                getOptionValue={({ companyCode }) => companyCode}
                defaultValue={companyDefaultValue}
                onBlur={updateCompanyOnBlur}
                onChange={options => setCompany(options)}
                // onChange={this.handleCompanyChange}
              />
            </AttributeInfo>
          </Col>
        </Row>
        <hr />
        <h4>Custom Attributes</h4>
        <br />
        <CustomAttributes id={details.id} attributeMetas={details.attributeMetas}
          disableEdit = {disableEdit}
          addNewCustomAttribute={addNewCustomAttribute}
          updateCustomAttribute={updateCustomAttribute}
          deleteCustomAttribute={deleteCustomAttribute}
          renderClosePanelButton={() =>
            <>
              {/* <Button color="custom" style={{ marginBottom: 0 }} onClick={closeDetailsPanel}>Close</Button> */}
            </>
          }
        />
        <br/>
        <h4>Calculate Attributes</h4>
        <br/>
        <CalculateAttributes 
          id={details.id} 
          calculateAttributes={details.calculateAttributes}
          classAttributeList={classAttributeList}
          // disableEdit = {disableEdit}
          disableEdit = {false}
          addNewCalculateAttribute={upsertCalculateAttribute}
          updateCulculateAttribute={upsertCalculateAttribute}
          deleteCulculateAttribute={deleteCalculateAttribute}
          renderClosePanelButton={() =>
            <>
              <Button color="custom" style={{ marginBottom: 0 }} onClick={closeDetailsPanel}>Close</Button>
            </>
          }

        />
        <AssetIconEditModal 
          modalSetVisible = {iconModal}
          assetId = {''}
          // assetName = {details.assetclassname}
          assetName = {details.name}
          iconId = {iconObj.iconId}
          iconPath = {iconObj.iconPath}
          iconData = {iconObj.iconData}
          toggle = {modalToggle}
          editIcon = {editIcon}
       />
      </CardBody>
    </Card>
    : ''
  )
}

export function AttributeInfo({ name, children }) {
  return (
    <Row style={{ marginBottom: 10 }}>
      <Col className='selectHov'>
        <h5><b>{name}</b></h5>
        {children}
      </Col>
    </Row>
  )
}
function AttributeMeta({ name, type, toggleModal, deleteAttribute, disableEdit }) {
  return (
    <Col md={4} style={{ marginBottom: 10 }} className="iconHover">
      <Row>
        <Col><h5><b>{name + (type ? (': ' + type) : '')}</b></h5></Col>
        <Col align='right'>
          <div id='divEdit' >
          <button id='btnDelet' onClick={deleteAttribute} disabled={disableEdit} style={{border: 'none', background: 'none'}}><DeleteIcon size={20} /></button>
          <button id='btnEdit' onClick={toggleModal} disabled={disableEdit} style={{border: 'none', background: 'none'}}><EditIcon size={20} /></button>
          </div>
        </Col>
        {disableEdit &&
          <UncontrolledTooltip placement="top-end" hideArrow={true} target="divEdit">
            Since there are assets linked to the assetclass, you can not modify attributes
          </UncontrolledTooltip>
        }
      </Row>
    </Col>
  )
}

export function EditableText({ defaultValue, onSave, exitOnBlur = true, exitOnEnterKeyPress = true, disallowNullValue, inputStyles, textStyles, type, typeProps, big }) {
  const [editable, setEditable] = React.useState(false)
  let editRef;
  React.useEffect(() => { editRef && editRef.focus() })

  const exitAndSaveVal = () => {
    setEditable(false)
    if (editRef.value !== defaultValue && (disallowNullValue ? editRef.value !== '' : true)) {
      onSave(editRef.value)
    }
  }

  return (
    editable ?
      // <Input className='attributeEdit' type={`text${big ? 'area' : ''}`} style={inputStyles}
      <Input className='attributeEdit' type={type} style={inputStyles}
        defaultValue={defaultValue}
        innerRef={(input) => { editRef = input }}
        onKeyDown={(e) => (e.key === 'Enter' && exitOnEnterKeyPress) && exitAndSaveVal()}
        onBlur={exitOnBlur && exitAndSaveVal}
      /> :
      <p className='inputHover' onClick={() => { setEditable(true) }}
        style={!defaultValue ? { color: '#ccc', ...textStyles } : textStyles}>{defaultValue || "Enter here..."}</p>
  )
}

export function EditableAttribute({ name, custom, toggleModal, deleteAttribute, md = 4, ...props }) {
  return (
    <Col md={md} style={{ marginBottom: 10 }} className="iconHover">
      <Row>
        <Col><h5><b>{name}</b></h5></Col>
        {custom && <Col align='right'>
          <a onClick={deleteAttribute}><DeleteIcon size={20} /></a>
        </Col>}
      </Row>
      <EditableText
        {...props}
      />
    </Col>
  )
}

export function UneditableAttribute({ name, custom, toggleModal, deleteAttribute, md = 4, ...props }) {
  return (
    <Col md={md} style={{ marginBottom: 10 }} className="iconHover">
      <Row>
        <Col><h5><b>{name}</b></h5></Col>
        {custom && <Col align='right'>
          <a onClick={deleteAttribute}><DeleteIcon size={20} /></a>
        </Col>}
      </Row>
    </Col>
  )
}

export function UploadImageAttribute({name, custom, deleteAttribute, md=4, defaultValue, textStyles, onSave, ...props}) {
  const [modal, setModal] = React.useState(false);
  // const [file, setFile] = React.useState(null);
  const toggle = () => {
    setModal(!modal);
  }
  const handleClick = () => {
    toggle();
  };
  // const onFileChange = (e) => {
  //   e.preventDefault();
  //   let files = e.target.files;

  //   if(files && files.length > 0)
  //   {
  //     setFile(files[0]);
  //   }
  //   else {
  //        
  //   }
  // };
  return (
    <Col md={md} style={{ marginBottom: 10 }} className="iconHover">
      <Row>
        <Col><h5><b>{name}</b></h5></Col>
        {custom && <Col align='right'>
          <a onClick={deleteAttribute}><DeleteIcon size={20} /></a>
        </Col>}
      </Row>
      {/* <p 
        className='inputHover' 
        onClick={() => { handleClick() }}
        style={!defaultValue ? { color: '#ccc', ...textStyles } : textStyles}
      >
        {defaultValue || "Empty..."}
      </p> */}
      <Container>
      <img class="img-responsive" src={defaultValue} alt="No Image" 
        // height="100px" 
        // width="auto" 
        style={{ display: 'block', maxWidth:'100px', maxHeight:'100px', width: 'auto', height: 'auto',}}
        onClick={() => { handleClick() }}
      ></img>
      </Container>
      <FileUploadForm
        modal={modal}
        toggle={toggle}
        onSubmit={onSave}
      />
    </Col>
  )
}

function FileUploadForm ({modal, toggle, onSubmit, ...rest}) {
  const [file, setFile] = React.useState(null);
  const [label, setLabel] = React.useState('Choose a image file');
  const onChange = (e) => {
    e.preventDefault();
    let files = e.target.files;
     

    if(files && files.length > 0)
    {
      setFile(files[0]);
      setLabel(files[0]['name']);
    }
    else {
         
    }
  };
  const OnCancleClick = (e) => {
    toggle();
  }
  const onSubmitClick = (e) => {
    onSubmit(file);
    toggle();
  }
  return (
    <Modal 
      // animationType="slide" 
      // transparent={false} 
      isOpen={modal}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Upload Image</ModalHeader>
      <ModalBody>
          <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>                            
            <Col md={10}>                         
                {/* <FormGroup> */}
                    {/* <CustomInput
                      type="file"
                      id="fileinput"
                      name="fileinput"
                      // label={'choose a image file'}
                      onChange={onChange}
                     /> */}
                {/* </FormGroup> */}
                {/* <input type="file" name="file" class="custom-file-input" id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01" onChange={onChange}/>
              <label class="custom-file-label" for="inputGroupFile01">Choose a CSV file</label> */}
              <div class="custom-file" >
               <input 
                  type="file" 
                  name="file" 
                  class="custom-file-input" 
                  id="inputGroupFile01"
                  // aria-describedby="inputGroupFileAddon01" 
                  onChange={onChange}
                  style={{position:'absolute'}}
                />
                <label class="custom-file-label" for="inputGroupFile01" >{label}</label>
              </div>
            </Col>
          </Row>                       
      </ModalBody>                
      <ModalFooter>
          <Button 
            color="custom" 
            onClick={onSubmitClick} 
            // disabled={!this.state.btnDisable}
          >
            Submit
          </Button>{' '}
          <Button color="custom" onClick={OnCancleClick}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

class CustomAttributes extends Component {
  constructor(props) {
    super(props)
    // console.log(this.props)
    this.state = {
      modalVisible: false,
      attributeToEdit: null,
      fields: this.initialFields,
      attributeToDelete: null,
      iconModal: false,
    }
  }
  initialFields = {
    name: '',
    type: ''
  }

  toggleToEdit = (id) => {
    this.setState({
      attributeToEdit: id,
      fields: this.props.attributeMetas.find(attribute => attribute.attributemetaid == id)
    })
    this.toggle()
  }
  toggle = () => {
    if (this.state.modalVisible)
      this.setState({ fields: this.initialFields })
    this.setState({ modalVisible: !this.state.modalVisible })
  }
  onSaveClick = () => {
    let { attributemetaid, ...fields } = this.state.fields
    let payload = {
      id: this.props.id,
      data: {
        attributemetaid,
        data: {
          assetclassid: this.props.id,
          ...fields
        }
      }
    }

    let data = {
      assetclassid: this.props.id,
      ...this.state.fields,
    }
    let f = this.props.attributeToEdit ? this.props.updateCustomAttribute : this.props.addNewCustomAttribute;
    // let fapi = this.state.attributeToEdit ? this.props.updateCustomAttribute : this.props.addNewCustomAttribute;
     
     
     
     
     
    
    // f(payload);
    f(data);
    // this.props.updateCustomAttribute(data);
    this.toggle()
  }

  handleChange = (event) => {
    const { id, value } = event.currentTarget
    this.setState({ fields: { ...this.state.fields, [id]: value } }, () => console.log(this.state.fields))
  }

  toggleDeleteModal = (id) => {
    this.setState({ attributeToDelete: id })
  }
  deleteAttribute = () => {
    this.props.deleteCustomAttribute({
      id: this.props.id,
      attributemetaid: this.state.attributeToDelete
    })
    this.toggleDeleteModalOff()
  }
  toggleDeleteModalOff = () => { this.setState({ attributeToDelete: null }) }

  render() {
    return (
      <>
        <Row>
          {this.props.attributeMetas.filter(item => ('n' !== item.active)).map(attrMeta => {
            // console.log(attrMeta)
            return (
              <AttributeMeta key={attrMeta.attributemetaid} name={attrMeta.name} type={attrMeta.type}
                toggleModal={() => this.toggleToEdit(attrMeta.attributemetaid)}
                deleteAttribute={() => this.toggleDeleteModal(attrMeta.attributemetaid)}
                disableEdit = {this.props.disableEdit}
              />
            )
          })}
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <div id='btnAddNewAttr'>
            <Button color="custom" style={{ marginBottom: 0 }} onClick={this.toggle} disabled={this.props.disableEdit}>Add New Attribute</Button>
            </div>
            {this.props.disableEdit &&
              <UncontrolledTooltip placement="top-start" hideArrow={true} target="btnAddNewAttr">
                Since there are assets linked to the assetclass, you can not add new attribute
              </UncontrolledTooltip>
            }
          </Col>
          <Col className='text-right'>
            {/* <Button onClick={toggle}>Cancel</Button>
            <Button color="custom" onClick={() => console.log("Save")}>Save</Button> */}
            {this.props.renderClosePanelButton()}
          </Col>
        </Row>
        <AttributeModal
          modalVisible={this.state.modalVisible}
          toggle={this.toggle}
          handleChange={this.handleChange}
          onSaveClick={this.onSaveClick}
          fields={this.state.fields}
        />
        <ConfirmDeleteDialog modalVisible={!!this.state.attributeToDelete}
          onConfirm={this.deleteAttribute}
          toggleOff={this.toggleDeleteModalOff}
          msg="Are you sure you want to delete this attribute?"
        />
         {/* <AssetIconEditModal 
          modalSetVisible = {this.state.iconModal}
          assetId = {''}
          assetName = {this.state.fields.name}
          iconId = {this.state.fields.iconId}
          iconPath = {this.state.fields.iconPath}
          toggle = {this.modalToggle}
          editIcon = {this.editIcon}
       /> */}
      </>
    )
  }
}

function AttributeModal({ modalVisible, toggle, handleChange, onSaveClick, fields }) {
  return (
    <Modal isOpen={modalVisible}
      toggle={toggle}>
      <ModalHeader>New Attribute</ModalHeader>
      <ModalBody>
        <LabelTextInput
          forIdName="name" label="Name"
          onChange={handleChange}
          value={fields.name}
        />
        <LabelSelectionInput
          forIdName="type" label="Format"
          onChange={handleChange}
          value={fields.type}
          options={typeSelectionValues}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onSaveClick}>Save</Button>{' '}
        <Button color="custom" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}

class CalculateAttributes extends Component {
  constructor(props) {
    super(props)
    // console.log(this.props)
    this.state = {
      modalVisible: false,
      attributeToEdit: null,
      fields: this.initialFields,
      attributeToDelete: null,
      iconModal: false,
    }
  }
  initialFields = {
    id: 0,
    name: '',
    displayname: '',
    expression: '',
    vars: '',
  }

  toggleToEdit = (id) => {
    this.setState({
      attributeToEdit: id,
      fields: this.props.calculateAttributes.find(attribute => attribute.id == id)
    }, () => console.log(this.state.fields))
    this.toggle()
  }
  toggle = () => {
    if (this.state.modalVisible)
      this.setState({ attributeToEdit: null, fields: this.initialFields })
    this.setState({ modalVisible: !this.state.modalVisible })
  }
  onSaveClick = () => {
    // let { id, ...fields } = this.state.fields
    // let payload = {
    //   id: this.props.id,
    //   data: {
    //     id,
    //     data: {
    //       id: this.props.id,
    //       ...fields
    //     }
    //   }
    // }

    let data = {
      assetclassid: this.props.id,
      ...this.state.fields,
    }
    let f = this.props.attributeToEdit ? this.props.updateCulculateAttribute : this.props.addNewCalculateAttribute;
    // let fapi = this.state.attributeToEdit ? this.props.updateCustomAttribute : this.props.addNewCustomAttribute;
    //  
    //  
    //  
    //  
     
    
    // f(payload);
    f(data);
    // this.props.updateCustomAttribute(data);
    this.toggle();
  }
  handleChange = (event) => {
    const { id, value } = event.currentTarget
    this.setState({ fields: { ...this.state.fields, [id]: value } }, () => console.log(this.state.fields))
  }

  handleAttrChose = (e, attr) => {
    this.setState(prevState => {
      const variable = `var_${attr.attributename}_${attr.attributeid}`;
      const expression = prevState.fields.expression + ' ' + variable;
      return {
        fields: {...prevState.fields, expression}
      }
    });
  }

  toggleDeleteModal = (attrObj) => {
    this.setState({ attributeToDelete: attrObj })
  }
  deleteAttribute = () => {
    this.props.deleteCulculateAttribute(this.state.attributeToDelete);
    // this.props.deleteCustomAttribute({
    //   id: this.props.id,
    //   attributemetaid: this.state.attributeToDelete
    // })
    this.toggleDeleteModalOff();
  }
  toggleDeleteModalOff = () => { this.setState({ attributeToDelete: null }) }

  render() {
    return (
      <>
        <Row>
          {this.props.calculateAttributes && this.props.calculateAttributes.map(attrCalculate => {
            // console.log(attrMeta)
            return (
              <AttributeMeta 
                key={attrCalculate.id} 
                name={attrCalculate.name} 
                // type={attrMeta.type}
                toggleModal={() => this.toggleToEdit(attrCalculate.id)}
                deleteAttribute={() => this.toggleDeleteModal(attrCalculate)}
                disableEdit = {this.props.disableEdit}
              />
            )
          })}
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col>
            <div id='btnAddNewAttr'>
            <Button color="custom" style={{ marginBottom: 0 }} onClick={this.toggle} disabled={this.props.disableEdit}>Add New Calculate Attribute</Button>
            </div>
            {this.props.disableEdit &&
              <UncontrolledTooltip placement="top-start" hideArrow={true} target="btnAddNewAttr">
                Since there are assets linked to the assetclass, you can not add new attribute
              </UncontrolledTooltip>
            }
          </Col>
          <Col className='text-right'>
            {this.props.renderClosePanelButton()}
          </Col>
        </Row>
        <CalculateAttributeModal
          modalVisible={this.state.modalVisible}
          toggle={this.toggle}
          handleChange={this.handleChange}
          onSaveClick={this.onSaveClick}
          fields={this.state.fields}
          classAttributeList = {this.props.classAttributeList}
          handleAttrChose = {this.handleAttrChose}
        />
        <ConfirmDeleteDialog modalVisible={!!this.state.attributeToDelete}
          onConfirm={this.deleteAttribute}
          toggleOff={this.toggleDeleteModalOff}
          msg="Are you sure you want to delete this attribute?"
        />
      </>
    )
  }

}

function CalculateAttributeModal(props){
  const { modalVisible, toggle, handleChange, onSaveClick, fields, classAttributeList, handleAttrChose } = props ;
  return (
    <Modal 
      isOpen={modalVisible}
      toggle={toggle}
    >
      <ModalHeader>New Attribute</ModalHeader>
      <ModalBody>
        <LabelTextInput
            forIdName="name" 
            label="Name"
            onChange={handleChange}
            value={fields.name}
        />
        <LabelTextInput
            forIdName="displayname" 
            label="Display Name"
            onChange={handleChange}
            value={fields.displayname}
        />
        {/* <LabelSelectionInput
          forIdName="type" label="Format"
          onChange={handleChange}
          value={fields.type}
          // options={typeSelectionValues}
        /> */}
        <Row>
          <Col md = {5}>
            <UncontrolledDropdown>
              <DropdownToggle caret>
                Attributes
              </DropdownToggle>
              <DropdownMenu>
                {classAttributeList && classAttributeList.map(attr=>(
                  <DropdownItem onClick={(e)=>{handleAttrChose(e, attr)}}>{attr.attributename}</DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          {/* <Col md = {5}>
            <UncontrolledDropdown>
              <DropdownToggle caret>
                Operation
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>{attr.attributename}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col> */}
        </Row>
        <LabelTextInput
            forIdName="expression" 
            label="Formula"
            onChange={handleChange}
            value={fields.expression}
            big={true}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onSaveClick}>Save</Button>{' '}
        <Button color="custom" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

function LabelSelectionInput(props) {
  return (
    <FormGroup row>
      <Col md={3} style={{ textAlign: 'left' }}>
        <Label htmlFor={props.forIdName}>{props.label}</Label>
      </Col>
      <Col md={8}>
        <SingleSelection
          {...props} />
      </Col>
    </FormGroup>
  )
}

function LabelTextInput({ forIdName, onChange, label, value, big }) {
  return (
    <FormGroup row>
      <Col md={3} style={{ textAlign: 'left' }}>
        <Label htmlFor={forIdName}>{label}</Label>
      </Col>
      <Col md={8}>
        <Input type={`text${big ? 'area' : ''}`} width="90%"
          // style={{ borderColor: err ? 'red' : '' }}
          value={value}
          id={forIdName}
          name={forIdName}
          onChange={onChange} />
      </Col>
    </FormGroup>
  )
}

const typeSelectionValues = [
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'List', value: 'list' },
  { label: 'Text', value: 'string' },
  { label: 'Number', value: 'num' }]

export const multiSelectStyles = {
  indicatorsContainer: (provided, state) => ({
    ...provided,
    '&:hover svg': {
      fill: '#999999',
    },
  }),
  control: (provided, state) => ({ // Mimic reactstrap input styles
    ...provided,
    transition: '150ms ease',
    borderColor: state.isFocused ? '#80bdff' : 'white',
    //boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
    '&:hover': { borderColor: state.isFocused ? '#80bdff!important' : 'lightgray' },
    '&:hover .css-tj5bde-Svg': { transition: '150ms ease', color: !state.isFocused && 'lightgray' },
    '&:hover .css-1okebmr-indicatorSeparator': { backgroundColor: 'lightgray' },
    '.css-tj5bde-Svg': { color: !state.isFocused && 'white' },
    '.css-1okebmr-indicatorSeparator': { backgroundColor: !state.isFocused && 'white' },
  }),
  multiValue: (provided, state) => {
    // console.log(state)
    return (state.isDisabled ?
      {
        ...provided,
        backgroundColor: '#bbb',
        'svg': { fill: '#bbb' }
      } :
      {
        ...provided,
        'svg': { fill: '#212529' }
      })
  },
  multiValueRemove: (provided, state) => (
    state.isDisabled ? {
      backgroundColor: '#bbb'
    } : provided)
}