/* eslint-disable */
import React, {useEffect,useState, useRef} from 'react'
import { connect } from 'react-redux'
import {loadFaceRecognitionRecords} from '../../../../redux/actions/surveilenceActions'
import {getJwttoken, sendEmail } from '../../../../factories/utils';
import {WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT,WEB_SUB_WEBSOCKET_WEATHER_DEVICEMESSAGE,WEB_SUB_WEBSOCKET_WEATHER_ALERTMESSAGE,WEB_SUB_WEBSOCKET_WEATHER_ALARMMESSAGE, WEB_SUB_SOCKET, WEB_SERVICE_ROOT} from '../../../../constants/appConstants';

import { Client } from '@stomp/stompjs';
import { LabelTextInput} from '../cameraList/CameraCreateModal';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse, CardTitle } from 'reactstrap';
import FaceRecordsList from './FaceRecordsList';
import CreateVisitEventModal from './CreateVisitEventModal';
import { sendSMS } from '../../../../factories/utils';
import axios from "axios";


const FaceRecordsDisplay = ({faceRecords, loadFaces, location}) => {
    const [PlazaLocation, setPlazaLocation] = useState('ITSP Office')
    const [VisitSetupModalOpen, setVisitSetupModalOpen] = useState(false)
    const [EventPurpose, setEventPurpose] = useState("Visit")
    const [VisitInfo, _setVisitInfo] = useState({
        // name: '',
        // phone: '',
        // email: '',
        // scheduleDate: '',
        // scheduleTime: '',
        // event:'',
        // picture: '',
        alertReceiverPhones:[],
        alertReceiverEmails:[],
        location: 'Office',
        subject: 'Surveillance Alert',
        // status: '',
        // comment: ''
    })
    const VisitInfoRef = useRef(VisitInfo)
    const setVisitInfo = data =>{
        //console.log("set visit info", data)
        VisitInfoRef.current = data;
        _setVisitInfo(data)
    }


    
    // useEffect(() => {
    //     console.log("VISIT INFO IN FACE RECOGINITION", VisitInfo)
    // }, [VisitInfo]);
    
    useEffect(() => {
        if(location && location !== PlazaLocation){
            setPlazaLocation(location)
        }
    }, [location])
    
    const [stompClient, setstompClient] = useState(null);
    // const [alertReceivers, setalertReceivers] = useState(['6475511693'])

    useEffect(() => {
        // console.log('loaded faces...---------------------')
        loadFaces();
        connectSocket();
        
    }, []);

    useEffect(() => {
        if(stompClient){
            //console.log('FR SOCKET CONNECTED', stompClient);
            stompClient.onConnect = connect_callback;
            stompClient.onDisconnect = disconnect_callback;
            // stompClient.onError = error_callback;
            stompClient.activate();
        }
    }, [stompClient])   

    useEffect(() => {
        // console.log('FACE RECOGNITION RECORDS----------------------',faceRecords)
    }, [faceRecords]);
    
    const connectSocket =()=> { 
        if (stompClient !== null && stompClient!==undefined) {
            stompClient.deactivate();
            //stompClient = null;
            
        }
        
        let query='?q='+getJwttoken();
        ///////////////////////////////////////////////////
        setstompClient(new Client({
            // brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query,
            brokerURL: `${WEB_SUB_SOCKET}`+query,
            reconnectDelay: 20000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        }));
        
        //////////////////////////////////////////////////
        //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
        //stompClient = Stomp.over(socket_URL); 
        //stompClient.reconnectDelay=5000; 
        //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;
        
    }
    const connect_callback =()=> {
        //console.log('connected to websoket alarm line passing');
        // called back after the stompClient is connected and authenticated to the STOMP server
        stompClient.subscribe('/topic/FRmessage', callbackDeviceMessage);
    //     stompClient.subscribe('/topic/alarmLinePassing', callbackDeviceMessage);
    }
    const disconnect_callback =()=> {
        stompClient.deactivate();
        // called back after the stompClient is connected and authenticated to the STOMP server
        //console.log('client disconnected')
        
    }
    const callbackDeviceMessage = (message) => {
        //console.log('FACE RECOGNIZED--------------------------')
        // console.log(message.body)
        loadFaces();
        // console.log('ALERT RECEIVERS, ', VisitInfoRef.current)
        // sendSMS(VisitInfoRef.current.alertReceiverPhones, `${VisitInfoRef.current.subject} - ${VisitInfoRef.current.message}`)
        // sendEmail(VisitInfoRef.current.alertReceiverEmails, VisitInfoRef.current.message, VisitInfoRef.current.subject)
        // // debugger;
    }
    const handleVisitSubmit = async (visit) =>{
        //console.log("submit visit", visit)
        if(visit){ 
            setVisitInfo(visit)
            // setEventPurpose(visit.event);
            //Create notification instance
            let notiParams = [];
            if(visit.alertReceiverPhones.length > 0){
                visit.alertReceiverPhones.forEach((phone)=>{
                    notiParams.push({nvrIp: '192.168.2.90', location: visit.location, subject: visit.subject, email: null, phone: phone})
                })
            }
            if(visit.alertReceiverEmails.length > 0){
                visit.alertReceiverEmails.forEach((email)=>{
                    notiParams.push({nvrIp: '192.168.2.90', location: visit.location, subject: visit.subject, email: email, phone: null})
                })
            }

            let res = await axios.post(`${WEB_SERVICE_ROOT}surveillanceNotifications`, notiParams)
            // console.log(res.data)

        }
        loadFaces();
    }
    const toggleVisitSetup = ()=>{
        setVisitSetupModalOpen(!VisitSetupModalOpen);
    }

    return (
        <>
            <Collapse isOpen={faceRecords}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={2}>
                                <Button
                                    onClick={toggleVisitSetup}>
                                    Alert Setup
                                </Button>
                            </Col>
                        </Row>
                        {/* <CardTitle tag="h3">{PlazaLocation}</CardTitle> */}
                        <FaceRecordsList eventPurpose={EventPurpose} list={faceRecords}/>
                    </CardBody>
                </Card>
            </Collapse>
            <CreateVisitEventModal toggleVisitSetup={toggleVisitSetup} modalOpen={VisitSetupModalOpen} handleVisitSubmit={handleVisitSubmit}></CreateVisitEventModal>
        </>
    )
}

const mapStateToProps = (state) => ({
    faceRecords: state.surveillance.faceRecords
})

const mapDispatchToProps = (dispatch) => {
    return{
        loadFaces: ()=> dispatch(loadFaceRecognitionRecords()),
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaceRecordsDisplay)
