/* eslint-disable */
import { ConsoleLineIcon, HttpsIcon, ThermostatBoxIcon } from "mdi-react";
import React, { Component, Fragment } from "react";
import { Card, CardBody, Col, Row, Button, Container, Form } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import BatteryWidget from "./BatteryWidget";
import DonutWidget from "./DonutWidget";
import Select from "react-select";
import Tooltip from "@material-ui/core/Tooltip";
import {
  FaCircle,
  FaClock,
  FaFacebookMessenger,
  FaTemperatureHigh,
  FaDownload,
  FaTemperatureLow,
  FaBatteryFull,
  FaBatteryHalf,
  FaBatteryEmpty,
} from "react-icons/fa";
import {
  MdVibration,
  MdOutlineMoreTime,
  MdOutlineAvTimer,
} from "react-icons/md";
import { HiOutlineLightningBolt } from "react-icons/hi";
import ReactApexChart from "react-apexcharts";
import downlaodIcon from "../../../shared/img/download.png";
import {
  GREEN_COLOR,
  RED_COLOR,
  ORENGE_COLOR,
  GREY_COLOR,
  TIME_ZONE,
  YELLOW_COLOR,
  TIME_OPTION,
  TIME_RANGE_OPTION,
  LIGHT_GREY_COLOR,
  HOUR_OF_DAY,
} from "../../../constants/dataConstants";
import { DateRangePicker } from "react-dates";
import { getCurrentUser } from "../../../factories/auth";
import { CSVLink } from "react-csv";
import moment from 'moment';

const CURRENT_HOUR = parseInt(moment().format('HH')) + 1;

class SnowBoard extends Component {
  constructor(props) {
    super(props);

    this.id = [];
    this.state = {
      msg: "",
      collapse: false,
      assetSelector: false,
      assetClick: [],
      assetName: [],
      assetList: [],
      deviceDisplay: false,
      deviceObject: null,
      displayAsset: true,
      displayDevice: false,
      alarm24HCounts: null,
      options: {},
      series: [],
      optionsDevice: {},
      seriesDevice: [],
      startTimeOptions: TIME_OPTION.filter((item, index) => index !== 24),
      endTimeOptions: TIME_OPTION.filter((item, index) => index !== 0),
      timeRangeOptions: TIME_RANGE_OPTION,
      statusData: this.props.statusDataProp,
    };
    /*this.userObj = getCurrentUser();
        console.log(this.userObj)
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }*/
  }
  componentdDidUpdate() {
    if (prevProps.statusDataProp !== this.props.statusDataProp) {
      this.setState({ statusData: this.props.statusDataProp });
    }
  }

  batteryRender = (cell) => {
    let batteryLevel;

    if (cell.OccupancyBattery) {
      for (let n = 0; n < cell.OccupancyBattery.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.OccupancyBattery[n].deviceid) {
            batteryLevel = cell.OccupancyBattery[n].value;
            break;
          }
        }
      }
      if (batteryLevel == null) batteryLevel = 1;
    }

    return (
      <div>
        <Tooltip title={"hello"}>
          <BatteryWidget
            level={parseInt(batteryLevel)}
            style={{ width: "23px", height: "45px" }}
          />
        </Tooltip>
      </div>
    );
  };

  lastMessage = (cell, row) => {
    let lastTimeReceived;

    if (cell.LastMessageTime) {
      for (let n = 0; n < cell.LastMessageTime.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.LastMessageTime[n].deviceid) {
            lastTimeReceived = cell.LastMessageTime[n].value;
            break;
          }
        }
      }
      lastTimeReceived !== null
        ? (lastTimeReceived = new Date(lastTimeReceived).toLocaleString(
            "en-US",
            { timeZone: TIME_ZONE }
          ))
        : (lastTimeReceived = "null");
    }

    return (
      // <span>{lastTimeReceived}</span>
      `${lastTimeReceived}`
    );
  };

  lastOccupied = (cell, row) => {
    let lastTimeOccupied;
    if (cell.LastOccupancy) {
      for (let n = 0; n < cell.LastOccupancy.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.LastOccupancy[n].deviceid) {
            lastTimeOccupied = cell.LastOccupancy[n].value;
            break;
          }
        }
      }
      lastTimeOccupied !== null
        ? (lastTimeOccupied = new Date(lastTimeOccupied).toLocaleString(
            "en-US",
            { timeZone: TIME_ZONE }
          ))
        : (lastTimeOccupied = "null");
    }

    return (
      // <span>{lastTimeOccupied}</span>
      `${lastTimeOccupied}`
    );
  };

  occupancyStatus = (cell, row) => {
    let donutColor;
    let occupancyMessage;

    if (cell.Alarm) {
      let occupancyStatus;
      for (let n = 0; n < cell.Alarm.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.Alarm[n].deviceid) {
            occupancyStatus = cell.Alarm[n].value;
            break;
          }
        }
      }
      switch (occupancyStatus) {
        case "GREEN":
          occupancyMessage = "Unoccupied";
          donutColor = GREEN_COLOR;
          break;
        case "ORANGE":
          occupancyMessage = "Recently Occupied";
          donutColor = ORENGE_COLOR;
          break;
        case "RED":
          occupancyMessage = "Occupied";
          donutColor = RED_COLOR;
          break;
        default:
          occupancyMessage = "Unknown";
          donutColor = GREY_COLOR;
      }
    }

    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          verticalAlign: "top",
        }}
      >
        <DonutWidget
          style={{
            width: "35px",
            alignItems: "stretch",
            justifyContent: "flex-start",
            alignContent: "flex-start",
          }}
          color={donutColor}
        />
        <span>{occupancyMessage}</span>
      </div>
      // `${occupancyMessage}`
    );
  };

  assetnameFormat = (cell, row) => {
    const handleDeviceOnClick = (e, assetname) => {
      e.preventDefault();
      this.props.toggle(true, row);
    };
    return (
      <a
        href={"#"}
        onClick={(e) => {
          handleDeviceOnClick(e, cell);
        }}
      >
        {cell}
      </a>
    );
  };

  batteryRenderCSV = (cell, row) => {
    let batteryLevel;

    if (cell.OccupancyBattery) {
      for (let n = 0; n < cell.OccupancyBattery.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.OccupancyBattery[n].deviceid) {
            batteryLevel = cell.OccupancyBattery[n].value;
            break;
          }
        }
      }
      if (batteryLevel == null) batteryLevel = 1;
    }

    switch (batteryLevel) {
      case 4:
        batteryLevel = "50-100%";
        break;
      case 2:
        batteryLevel = "10-50%";
        break;
      case 0:
        batteryLevel = "0-10%";
        break;
      default:
        batteryLevel = "Unknown";
    }

    return batteryLevel;
  };

  occupancyStatusCSV = (cell, row) => {
    if (cell.Alarm) {
      let occupancyStatus;
      for (let n = 0; n < cell.Alarm.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.Alarm[n].deviceid) {
            occupancyStatus = cell.Alarm[n].value;
            break;
          }
        }
      }
      let donutColor;
      let occupancyMessage;

      switch (occupancyStatus) {
        case "GREEN":
          occupancyMessage = "Unoccupied";
          donutColor = GREEN_COLOR;
          break;
        case "ORANGE":
          occupancyMessage = "Recently Occupied";
          donutColor = ORENGE_COLOR;
          break;
        case "RED":
          occupancyMessage = "Occupied";
          donutColor = RED_COLOR;
          break;
        default:
          occupancyMessage = "Unknown";
          donutColor = GREY_COLOR;
      }

      return occupancyMessage;
    }
  };

  lastOccupiedCSV = (cell, row) => {
    let lastTimeOccupied;

    if (cell.LastOccupancy) {
      for (let n = 0; n < cell.LastOccupancy.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.LastOccupancy[n].deviceid) {
            lastTimeOccupied = cell.LastOccupancy[n].value;
            break;
          }
        }
      }
      lastTimeOccupied !== null
        ? (lastTimeOccupied = new Date(lastTimeOccupied).toLocaleString(
            "en-US",
            { timeZone: TIME_ZONE }
          ))
        : (lastTimeOccupied = "null");
    }

    return lastTimeOccupied;
  };

  lastMessageCSV = (cell, row) => {
    let lastTimeReceived;

    if (cell.LastMessageTime) {
      for (let n = 0; n < cell.LastMessageTime.length; n++) {
        for (const property in this.id) {
          if (this.id[property] == cell.LastMessageTime[n].deviceid) {
            lastTimeReceived = cell.LastMessageTime[n].value;
            break;
          }
        }
      }
      lastTimeReceived !== null
        ? (lastTimeReceived = new Date(lastTimeReceived).toLocaleString(
            "en-US",
            { timeZone: TIME_ZONE }
          ))
        : (lastTimeReceived = "null");
    }

    return lastTimeReceived;
  };
  //---------------------------------------------------------------------
  backOnClick = (e) => {
    this.state.displayAsset = true;
    this.state.displayDevice = false;
    this.forceUpdate();
  };
  handleAssetChange = (val) => {
    if (val.value == "-1") {
      this.state.assetClick = [];
      this.state.assetSelector = true;
      this.forceUpdate();
    } else {
      this.state.assetClick = [];
      this.state.assetSelector = false;
      this.state.assetClick.push(val.value);
      this.state.displayAsset = true;
      this.state.displayDevice = false;
      this.props.toggle(true, val.value);
      //this.state.alarm24HCounts = {this.props.alarm24HCounts}
      this.forceUpdate();
    }
    //console.log('this.state.assetClick')
    //console.log(this.state.assetClick)
    // this.setState({
    //     shape: selectedShape,
    // });

    // this.showShapOnMap(selectedShape)
  };
  //////////////////////////////////////////////////////////////////////
  minutesToDhm(minutes) {
    minutes = Number(minutes);
    var d = Math.floor(minutes / (60 * 24));
    var h = Math.floor((minutes % (60 * 24)) / 60);
    var m = Math.floor(minutes % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";

    return dDisplay + hDisplay + mDisplay;
  }
  updatedevicedata = (object) => {
    // Reset fields to default if closing
    this.state.displayDevice = true;
    this.state.displayAsset = false;
    this.state.deviceObject = object;
    this.forceUpdate();
  };

  /* When the user clicks on the button, 
    toggle between hiding and showing the dropdown content */
  myFunction() {
    document.getElementById("download").classList.toggle("");
  }

  //   // Close the dropdown if the user clicks outside of it
  //   window.onclick = function(event) {
  //     if (!event.target.matches('.dropbtn')) {
  //       var dropdowns = document.getElementsByClassName("dropdown-content");
  //       var i;
  //       for (i = 0; i < dropdowns.length; i++) {
  //         var openDropdown = dropdowns[i];
  //         if (openDropdown.classList.contains('show')) {
  //           openDropdown.classList.remove('show');
  //         }
  //       }
  //     }
  //   }
  rolling24HCount = (arr1, arr2) => {
    // merge today's and yesterday's alarm counts.
    let hour = 0 // From today;
    if(arr2.length !== 0) {
      while(arr2.length != HOURS_OF_DAY)  arr2.push(arr1[hour++])

      return arr2;
    }
    return arr1;

  }

  labelFormatter = (hour) => {
    const rangeChecker = (hour, currentHour) => {
      const pm = hr => (hr += " PM");
      const am = hr => (hr += " AM");
      hour += currentHour;
      if(hour == 12) return pm(hour);
      if(hour < 12) return am(hour);
      else {
        hour -= 12;
        if(hour == 12) return am(hour);
        if(hour == 24) return pm(hour - 12);
        if(hour < 12) return pm(hour);
        if(hour > 12 && hour < 24) return am(hour - 12);
        if(hour > 24) return pm(hour - 24);
      } 
      return hour;
    }

    const checker = rangeChecker(hour, CURRENT_HOUR);

    return checker;
  }

  formatAssetChartData = (object) => {
    const payload = this.props;
    // object = this.props.rolling24HCountData;
    const labelFormatter = (hour) => {
      return this.labelFormatter(hour);
    };
    var xvalues = [];
    var yvalues = [];

    for (var i = 0; i < object.length; i++) {
      xvalues.push(object[i].date.split("T")[1].split(":")[0]);
      yvalues.push(object[i].green + object[i].yellow);
    }
    this.state.series = [
      {
        name: "No of SCDs",
        data: yvalues,
      },
    ];
    this.state.options = {
      chart: {
        fontFamily: "Poppins",
        type: "area",
        stacked: false,
        height: 350,
        zoom: {
          type: "x",
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: "zoom",
          style: {
            width: 110,
          },
          export: {
            csv: {
              filename: "Number_of_SCDs_over_24H",
              headerCategory: "Time of Day (Hour)",
              headerValue: "No of SCDs",
            },
            svg: {
              filename: "Number_of_SCDs_over_24H",
            },
            png: {
              filename: "Number_of_SCDs_over_24H",
            },
          },
          tools: {
            download:
              '<button id="download" onclick="myFunction()" type="button" class="btn btn-success react-bs-table-csv-btn  hidden-print" style="margin-top:-25px; width: 150px; height: 42px; float:right"><span><i style="position:relative" class="fa glyphicon glyphicon-export fa-download"></i>&ensp;Download</span></button>',
            //could not find a way to not hard code this button
            //download:'<img src="'+downlaodIcon+'">',
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      title: {
        text: "Number of SCDs Operating over 24 Hours",
        align: "center",
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        min:0,
        labels: {
          formatter: function(val) {
            return val;
          },
        },
        title: {
          text: "Active Devices",
        },
      },
      xaxis: {
        labels: {
          formatter: function(val) {
            return labelFormatter(val);
          }
        },
        time: xvalues,
        title: {
          text: "Time of Day (Hour)",
        },
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function(val) {
            return val;
          },
        },
      },
    };
  };

  myFunction() {
    debugger;
    event.target.nextElementSibling.classList.toggle("apexcharts-menu-open");
  }

  clearClasses(target) {
    document.querySelectorAll(".apexcharts-menu-item").forEach((item) => {
      if (event.target.nextElementSibling !== item)
        item.classList.remove(".apexcharts-menu-item");
    });
  }

  // // Close the dropdown if the user clicks outside of it
  // window.onclick = function(e) {
  //   clearClasses(event.target.nextElementSibling);
  // }

  formatDeviceChartData = (object) => {
    //console.log(this.props.alarm24HCounts)
    var device = object;
    //console.log('rel device')
    //console.log(device)
    object = this.props.alarmCounts;
    // console.log("===object", object);
    //console.log('alarmCounts')
    //console.log(this.props.alarmCounts)
    var xvalues = [];
    var yvalues = [];
    //console.log('print object')
    //console.log(object)
    for (var i = 0; i < object.length; i++) {
      //console.log(object[i].deviceid)
      //console.log(device)
      if (object[i].deviceId == device) {
        //console.log('inside')
        xvalues.push(object[i].date);
        // yvalues.push(((object[i].green)*1.04).toFixed(2))      //divide by 96 and multipy with 100
        yvalues.push(((object[i].green + object[i].yellow) * 1.04).toFixed(2)); //divide by 96 and multipy with 100
        // console.log("===object[i]", object[i]);
        // console.log("===yvalues", yvalues);
      }
    }
    //console.log('xvalues')
    // console.log(xvalues)
    //console.log('yvalues')
    //console.log(yvalues)
    this.state.seriesDevice = [
      {
        name: "Percentage Uptime",
        data: yvalues,
      },
    ];
    this.state.optionsDevice = {
      chart: {
        fontFamily: "Poppins",
        height: 350,
        type: "bar",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          export: {
            csv: {
              filename: "Device_Uptime",
              headerCategory: "Day of Month",
              headerValue: "Percentage of Uptime",
            },
            svg: {
              filename: "Device_Uptime",
            },
            png: {
              filename: "Device_Uptime",
            },
          },
          tools: {
            download:
              '<button onClick="myFunction()" type="button" class="btn btn-success react-bs-table-csv-btn  hidden-print" style="margin-top:-30px; width: 150px; height: 42px; float:right"><span><i style="position:relative" class="fa glyphicon glyphicon-export fa-download"></i>&ensp;Download</span></button>',
            //download: '<img src="'+downlaodIcon+'">',
          },
        },
      },

      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      title: {
        text: device + " Uptime - Last 30 Days",
        floating: true,
        offsetY: -5,
        align: "center",
        style: {
          color: "#444",
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: xvalues,
        position: "bottom",
        axisBorder: {
          show: false,
        },
        title: {
          text: "Day of Month",
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        labels:{
          rotateAlways: true,
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val + "%";
          },
        },
        title: {
          text: "% of Uptime",
        },
      },
    };
  };

  deviceFormat = (cell, row) => {
    if (this.state.assetClick.length > 0) {
      var assetid = this.state.assetClick[0].assetid;
      //console.log(assetid)
      var updatedAsset = [];
      for (var z = 0; z < this.state.statusData.length; z++) {
        if (this.state.statusData[z].assetid == assetid) {
          updatedAsset.push(this.state.statusData[z]);
          break;
        }
      }
      //console.log(updatedAsset)
      this.state.assetClick = updatedAsset;
      //     var obj={value:asset,
      //         label:asset.assetname}
      var data1 = this.state.assetClick;
      var data = this.state.assetClick[0];
      var devices = data.devices;
      // console.log("===devices", devices);

      for (var value of Object.keys(data)) {
        //console.log(value+":"+data[value])
        if (value == "attributes") {
          var map1 = new Map();
          var set1 = new Set();
          for (var key of Object.keys(data[value])) {
            // console.log("===11111key+data[value][key]", (key+":"+data[value][key]))
            for (var obj of Object.keys(data[value][key])) {
              // console.log("===111obj + data.deviceid", (obj+":"+data[value][key][obj].deviceid))
              map1.set(
                data[value][key][obj].deviceid + ":" + key,
                data[value][key][obj].value
              );
              set1.add(data[value][key][obj].deviceid);
            }
          }
        }
      }
      var listdevices = [];
      for (const device of set1) {
        // console.log("===device", device)
        this.device = {};
        for (const [key, value] of map1) {
          if (key.startsWith(device)) {
            var loca = key.split(":")[1];
            this.device[loca] = value;
          }
        }

        var pushObject = {};
        pushObject[device] = this.device;
        listdevices.push(pushObject);
      }

      if (
        this.state.deviceObject != null &&
        this.state.deviceObject != undefined
      ) {
        var divid = Object.keys(this.state.deviceObject)[0];
        for (var k = 0; k < listdevices.length; k++) {
          if (Object.keys(listdevices[k])[0] == divid) {
            this.state.deviceObject = listdevices[k];
          }
        }
      }

      var deviceName;
      var data1 = this.state.statusData;
      // console.log("===data1", data1);

      /* if(row != undefined && row != null){
                deviceName = Object.values(row)[0].AverageLabel.split(', ')[1];
            } */
      //uncomment back
      var obj;
      let nameDevice;
      listdevices.map((object) => {
        if (Object.keys(object).toString() == Object.keys(row).toString()) {
          nameDevice = devices.find(
            (d) => d.deviceid == Object.keys(object).toString()
          ).deviceNameAlias;
          obj = object;
        }
      });
      // console.log("===this.state.statusData", this.state.statusData);
      return (
        <div>
          {/* {listdevices.map((object) => (  
                <Button variant="primary" color="custom" onClick={this.updatedevicedata.bind(this,object)}>{Object.keys(object)}</Button>
              ))
                <Button variant="primary" color="custom" onClick={this.updatedevicedata.bind(this,object)}>{Object.values(object)[0].AverageLabel.split(', ')[1]}</Button>
            } */}
          {/* {listdevices.map((object) => (  
                <a href={'#/pages/SnowApp'} onClick={this.updatedevicedata.bind(this,object)}>{Object.values(object)[0].AverageLabel.split(', ')[1]}</a>
              ))
            } */}
          <a
            href={"#/pages/SnowApp"}
            onClick={this.updatedevicedata.bind(this, obj)}
          >
            {nameDevice}
          </a>
        </div>
      );
    }
  };
  devicenameFormat = (cell, row) => {
    var deviceName;
    var obj = Object.values(row)[0];
    if (row != undefined && row != null) {
      //console.log('devcie name adaghd')
      //console.log(Object.values(row)[0])
      //console.log(Object.values(row)[0].AverageLabel.split(', ')[1])
      deviceName = Object.values(row)[0].AverageLabel.split(", ")[1];
    }
    // console.log("===OBJ===", obj);
    // console.log("===OBJ===", deviceName);
    return (
      /* deviceName */
      <a
        href={"#/pages/SnowApp"}
        onClick={this.updatedevicedata.bind(this, deviceName)}
      >
        {deviceName}
      </a>
    );
  };
  devicestatusFormat = (cell, row) => {
    var deviceStatus;
    var donutColor;
    var snowMessage;
    let current;
    let obj = Object.values(row)[0];
    if (row != undefined && row != null) {
      let alarm = obj.Alarm;
      current = parseFloat(Object.values(row)[0].Current);

      if (current == 0) {
        donutColor = GREY_COLOR;
        snowMessage = "OFF";
      } else if (current > 0 && current <= 0.2) {
        donutColor = RED_COLOR;
        snowMessage = "Asleep";
      } else if (current >= 1.7 && current <= 3.3) {
        donutColor = GREEN_COLOR;
        snowMessage = "ON";
      } else if (obj.Current === null || obj.Current === undefined) {
        donutColor = GREY_COLOR;
        snowMessage = "OFF";
      } else {
        donutColor = YELLOW_COLOR;
        snowMessage = "Start UP / Issues";
      }
    }

    return (
      <div
        style={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          verticalAlign: "top",
        }}
      >
        <DonutWidget
          style={{
            width: "35px",
            alignItems: "stretch",
            justifyContent: "flex-start",
            alignContent: "flex-start",
          }}
          color={donutColor}
        />
        {snowMessage}
      </div>
      //`${snowMessage}`
    );
  };
  lastMessage = (cell, row) => {
    var lastTimeReceived;

    if (row != undefined && row != null) {
      //console.log('devcie name adaghd')
      //console.log(Object.values(row)[0])

      lastTimeReceived = Object.values(row)[0].LastMessageTime;

      if (lastTimeReceived) {
        lastTimeReceived !== null
          ? (lastTimeReceived = new Date(lastTimeReceived).toLocaleString(
              "en-US",
              { timeZone: TIME_ZONE }
            ))
          : (lastTimeReceived = "null");
      }
    }

    return (
      // <span>{lastTimeReceived}</span>
      `${lastTimeReceived}`
    );
  };
  handleDateRangeChange = (value) => {
    //{startDate     endDate}
    if (value.startDate == null && value.endDate == null) {
      this.setState({ focusedInput: "startDate" });
    }
    this.setState({
      startDate: value.startDate,
      endDate: value.endDate,
      startTimeOptions: TIME_OPTION.filter((item, index) => index !== 24),
      endTimeOptions: TIME_OPTION.filter((item, index) => index !== 0),
    });
    this.props.handleFilterChange({
      startDateMoment: value.startDate,
      endDateMoment: value.endDate,
      startTime: TIME_OPTION[0],
      endTime: TIME_OPTION[24],
    });
    setTimeout(() => {
      if (value.startDate != null && value.endDate == null) {
        this.setState({ focusedInput: "endDate" });
      }
    }, 300);
  };

  handleStartTimeChange = (value) => {
    if (!value) {
      this.setState({
        endTimeOptions: TIME_OPTION,
      });
    } else {
      let selectedIndex = value.index;
      this.setState({
        endTimeOptions: TIME_OPTION.filter(
          (item, index) => index > selectedIndex
        ),
      });
    }
    this.props.handleFilterChange({
      startTime: value,
      endTime: null,
    });
  };

  handleEndTimeChange = (value) => {
    this.props.handleFilterChange({
      endTime: value,
    });
  };
  renderTimeFilter = () => {
    const customStyles = {
      control: (base) => ({
        ...base,
        // height: 35,
        // minHeight: 35
        height: 35,
        minHeight: 35,
      }),
      indicatorsContainer: (base) => ({
        ...base,
        padding: "0",
      }),
    };
    var startDate = this.props.startDateMoment;

    return (
      <div style={{ width: "100%", margin: "20px" }}>
        <Form md={12}>
          <Row form>
            <Col md={5}></Col>
            <Col md={3}>
              <DateRangePicker
                isOutsideRange={(date) => {
                  if (
                    startDate &&
                    startDate._d.getTime() > date._d.getTime() &&
                    this.state.focusedInput !== "startDate"
                  ) {
                    return true;
                  }
                  return false;
                }}
                startDate={this.props.startDateMoment} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.props.endDateMoment} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                minimumNights={0}
                onDatesChange={this.handleDateRangeChange} //{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => {
                  if (
                    focusedInput == "endDate" &&
                    this.state.startDate == null
                  ) {
                    this.setState({
                      focusedInput: "startDate",
                    });
                  } else {
                    this.setState({ focusedInput });
                  }
                }} // PropTypes.func.isRequired,
                showClearDates={true}
                small={true}
                block={true}
                //disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
              />
            </Col>
            <Col md={3}>
              <Row style={{ padding: "0px 10px" }}>
                <Col md={6} style={{ padding: "0px 5px" }}>
                  <Select
                    isClearable
                    isDisabled={this.props.isTimeDisabled}
                    options={this.state.startTimeOptions}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    placeholder={"Start..."}
                    value={this.props.startTime}
                    onChange={this.handleStartTimeChange}
                    styles={customStyles}
                  />
                </Col>
                <Col md={6} style={{ padding: "0px 5px" }}>
                  <Select
                    isClearable
                    isDisabled={this.props.isTimeDisabled}
                    options={this.state.endTimeOptions}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    placeholder={"End..."}
                    value={this.props.endTime}
                    onChange={this.handleEndTimeChange}
                    styles={customStyles}
                    minDate={this.props.startTime}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };
  showAssetData = (listdevices) => {
    if (
      this.state.assetClick.length > 0 &&
      listdevices.length > 0 &&
      this.state.displayAsset == true
    ) {
      var onDevices = 0;
      var offDevices = 0;
      var startupDevices = 0;
      var downDevices = 0;
      var arrayTime = [];
      //console.log('listdevices')

      for (var i = 0; i < listdevices.length; i++) {
        var elt = Object.values(listdevices[i])[0];
        //console.log(elt)
        if (elt.Alarm == "GREEN") {
          onDevices++;
        } else if (elt.Alarm == "RED") {
          offDevices++;
        } else if (elt.Alarm == "YELLOW") {
          startupDevices++;
        } else if (elt.Alarm == "GREY" || elt.Alarm == "OFF") {
          downDevices++;
        }
        arrayTime.push(new Date(elt.LastMessageTime));
      }
      arrayTime = arrayTime.sort((a, b) => b - a);

      if(this.props.rolling24HCountData) this.formatAssetChartData(this.props.rolling24HCountData);
      else this.formatAssetChartData(this.props.alarm24HCounts);
      return (
        <div style={{ lineHeight: "220%", fontWeight: "500" }}>
          <h4 style={{ fontWeight: "bold" }}>
            {this.state.assetClick[0].assetname}
          </h4>
          <br />
          <BootstrapTable data={listdevices} tableHeaderClass="header_white">
            <TableHeaderColumn
              dataField="deviceNameAlias"
              dataFormat={this.deviceFormat}
              dataAlign="center"
              csvHeader="Device Name"
              isKey
            >
              Device Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="Alarm"
              dataAlign="center"
              dataFormat={this.devicestatusFormat}
              devicestatusFormat
              csvHeader="Current Operating Status"
            >
              Current Operating Status
            </TableHeaderColumn>
            {/* <TableHeaderColumn dataField='attributes' dataFormat={this.batteryRender} dataAlign='center' csvFormat={this.batteryRenderCSV} csvHeader='Battery life'>Battery Life</TableHeaderColumn> */}
            <TableHeaderColumn
              dataField="LastMessageTime"
              dataAlign="center"
              dataFormat={this.lastMessage}
              csvHeader="Last Operating Time"
            >
              Last Operating Time
            </TableHeaderColumn>
          </BootstrapTable>
          {/* {(onDevices>0)?( <div><FaCircle style={{
                    fill: 'GREEN',
                    height: '1.5em',
                    width: '2em',
                    }}  
                /> {onDevices} SCDs Current operating status is ON. </div>):null }
            {(offDevices>0)?( <div><FaCircle style={{
                    fill: 'RED',
                    height: '1.5em',
                    width: '2em',
                    }}  
                /> {offDevices} SCDs Current operating status is OFF. </div>):null }
            {(startupDevices>0)?( <div><FaCircle style={{
                    fill: 'YELLOW',
                    height: '1.5em',
                    width: '2em',
                    }}  
                /> {startupDevices} SCDs Current operating status is startup. </div>):null }
            {(downDevices>0)?( <div><FaCircle style={{
                    fill: 'GREY',
                    height: '1.5em',
                    width: '2em',
                    }}  
                /> {downDevices} SCDs Current operating status is down. </div>):null }
            <MdOutlineMoreTime style={{
                    height: '1.5em',
                    width: '2em',
                    }}  
                />   Last SCD operating time : {new Date(arrayTime[0]).toLocaleString('en-US', { timeZone: TIME_ZONE })} */}
          <br />
          <br />
          <br />

          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="area"
            height={350}
          />

          <br />
          {this.renderSpinner()}
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  renderSpinner = () => {
    return (
        <Fragment>
            {this.props.alarm24HCountLoading  &&
                <div style={{
                    position: 'Absolute ',
                    height: '100%',
                    width: '100%',
                    top: '0px',
                    left: '0px',
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    backgroundColor: LIGHT_GREY_COLOR,
                    opacity: '0.6',
                }}
                >
                    <Spinner
                        className="spinner"
                        as="span"
                        animation="border"
                        variant="success"
                        // size="sm"
                        // role="status"
                        // aria-hidden="true"
                        style={{ display: 'inline-block', margin: 'auto' }}
                    />
                </div>
            }
        </Fragment>
    );
}

  showdevicedata = () => {
    // Reset fields to default if closing
    if (this.state.displayDevice == true) {
      var device = Object.values(this.state.deviceObject)[0];
      this.state.displayAsset = false;
      let vibrationData = device.Vibration;
      let current = parseFloat(device.Current);
      var alarmColor = "GREEN";
      var alarmText = "ON";
      var vibration = "ON";
      if (current == 0) {
        // Device OFF: 0 Amps
        alarmColor = "GREY";
        alarmText = "OFF";
      } else if (current > 0 && current <= 0.2) {
        // Device Asleep: 0.005 to 0.200 Amps
        alarmColor = "RED";
        alarmText = "Asleep";
      } else if (current >= 1.7 && current <= 3.3) {
        // Device ON: 1.7 to 3.3 Amps
        alarmColor = "GREEN";
        alarmText = "ON";
      } else if (device.current == null) {
        alarmColor = "GREY";
        alarmText = "N/A";
      } else {
        alarmColor = "YELLOW";
        alarmText = "Start Up / Device operating outside normal parameters";
      }

      if (vibrationData > 30) {
        vibration = "ON";
      } else {
        vibration = "OFF";
      }
      /* if(device.Alarm=='RED'){
                alarmColor='RED'
                alarmText='OFF'
                vibration='OFF'
            }else if(device.Alarm=='YELLOW'){
                alarmColor='YELLOW'
                alarmText='Startup'
                vibration='OFF'
            }else if(device.Alarm=='OFF' || device.Alarm=='GREY'){
                alarmColor='GREY'
                alarmText='Down'
                vibration='OFF'
            } */
      var batteryicon = (
        <FaBatteryFull
          style={{
            height: "1.5em",
            width: "2em",
          }}
        />
      );
      var batterytext = "N/A";
      if (device.OccupancyBattery == 4) {
        batteryicon = (
          <FaBatteryHalf
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />
        );
        batterytext = "Low";
      } else if (device.OccupancyBattery == 0) {
        batteryicon = (
          <FaBatteryEmpty
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />
        );
        batterytext = "Empty";
      } else if (device.current == null) {
        batteryicon = (
          <FaBatteryEmpty
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />
        );

        device.OccupancyBattery = 0;
      }

      var date = new Date(device.LastMessageTime);
      var lmt = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
      if (device.AverageData != null && device.AverageData != undefined) {
        var averageData = device.AverageData.split("-");
      }
      if (averageData) {
        var upTodayMins = parseInt(averageData[1]) * 15;
        var upMonthMins = parseInt(averageData[0]) * 15;
      }
      upTodayMins = this.minutesToDhm(upTodayMins);
      upMonthMins = this.minutesToDhm(upMonthMins);
      this.formatDeviceChartData(Object.keys(this.state.deviceObject)[0]);
      var lastOperationMins = this.minutesToDhm(device.AverageLODLast);
      // console.log("===device", device);
      if (upTodayMins.length == 0) {
        upTodayMins = "0 minutes";
      }
      if (upMonthMins.length == 0) {
        upMonthMins = "0 minutes";
      }
      if (lastOperationMins.length == 0) {
        lastOperationMins = "0 minutes";
      }

      let deviceid = Object.keys(this.state.deviceObject);
      var assetid = this.state.assetClick[0].assetid;
      var updatedAsset = [];
      for (var z = 0; z < this.state.statusData.length; z++) {
        if (this.state.statusData[z].assetid == assetid) {
          updatedAsset.push(this.state.statusData[z]);
          break;
        }
      }
      this.state.assetClick = updatedAsset;
      var data1 = this.state.assetClick;
      var data = this.state.assetClick[0];
      var devices = data.devices;
      var deviceName;
      var keys = Object.keys(this.state.deviceObject);
      for (let i = 0; i < devices.length; i++) {
        if (keys == devices[i].deviceid) {
          deviceName = devices[i].deviceNameAlias;
        }
      }


      return (
        <div style={{ lineHeight: "220%", fontWeight: "500" }}>
          <h4 style={{ fontWeight: "bold" }}>
            {/* {device.AverageLabel} */}
            {deviceName}
          </h4>
          <br />
          <FaCircle
            style={{
              fill: alarmColor,
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Operating status
          :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {alarmText}
          <br />
          <FaFacebookMessenger
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Last message received
          :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {lmt}
          <br />
          <MdOutlineMoreTime
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Uptime today
          :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {upTodayMins}
          <br />
          <FaClock
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Total Uptime this month
          :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {upMonthMins}
          <br />
          <FaTemperatureHigh
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Current blower Temperature
          :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {device.Temperature} &#8451;
          <br />
          <FaTemperatureLow
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Average blower Temperature(past month) :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {device.AverageTemperature} &#8451;
          <br />
          <HiOutlineLightningBolt
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Current Amperage :
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {device.Current} Amps
          <br />
          <MdVibration
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          High Vibration:
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          {vibration}
          <br />
          <MdOutlineAvTimer
            style={{
              height: "1.5em",
              width: "2em",
            }}
          />{" "}
          Last operation duration
          :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {lastOperationMins}
          <br />
          {batteryicon} Battery Level :
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <BatteryWidget
            level={parseInt(device.OccupancyBattery)}
            style={{ width: "23px", height: "45px" }}
          />
          : {batterytext}
          <br />
          {/* <BatteryWidget level={parseInt(device.OccupancyBattery)} style={{ width: '23px', height: '45px' }}/>  Battery */}
          {/* {this.renderTimeFilter()}
                    <br /> */}
          <ReactApexChart
            options={this.state.optionsDevice}
            series={this.state.seriesDevice}
            type="bar"
            height={350}
          />
          <br />
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  //--------------------------------------------------------------------
  renderButtons = () => {
    //console.log('this.state.assetClick')
    //console.log(this.state.assetClick)
    if (this.state.assetClick.length > 0) {
      var assetid = this.state.assetClick[0].assetid;
      //console.log(assetid)
      var updatedAsset = [];
      for (var z = 0; z < this.state.statusData.length; z++) {
        if (this.state.statusData[z].assetid == assetid) {
          updatedAsset.push(this.state.statusData[z]);
          break;
        }
      }

      //console.log(updatedAsset)
      this.state.assetClick = updatedAsset;
      //     var obj={value:asset,
      //         label:asset.assetname}
      var data = this.state.assetClick[0];
      for (var value of Object.keys(data)) {
        //console.log(value+":"+data[value])
        if (value == "attributes") {
          var map1 = new Map();
          var set1 = new Set();
          for (var key of Object.keys(data[value])) {
            //console.log((key+":"+data[value][key]))
            for (var obj of Object.keys(data[value][key])) {
              //console.log((obj+":"+data[value][key][obj].deviceid))
              map1.set(
                data[value][key][obj].deviceid + ":" + key,
                data[value][key][obj].value
              );
              set1.add(data[value][key][obj].deviceid);
            }
          }
        }
      }
      var listdevices = [];
      for (const device of set1) {
        //console.log(device)
        this.device = {};
        for (const [key, value] of map1) {
          if (key.startsWith(device)) {
            var loca = key.split(":")[1];
            this.device[loca] = value;
          }
        }

        var pushObject = {};
        pushObject[device] = this.device;
        listdevices.push(pushObject);
      }

      if (
        this.state.deviceObject != null &&
        this.state.deviceObject != undefined
      ) {
        var divid = Object.keys(this.state.deviceObject)[0];
        for (var k = 0; k < listdevices.length; k++) {
          if (Object.keys(listdevices[k])[0] == divid) {
            this.state.deviceObject = listdevices[k];
          }
        }
      }
      //  {listdevices.map((object) => (
      //  console.log(Object.values(object)[0])
      //    ))
      //  }
      //console.log(this.state.deviceObject)
      return (
        <div>
          {/* {listdevices.map((object) => (  
                <Button variant="primary" color="custom" onClick={this.updatedevicedata.bind(this,object)}>{Object.keys(object)}</Button>
              ))
              <a href={'#/pages/SnowApp'} onClick={this.updatedevicedata.bind(this,object)}>{Object.values(object)[0].AverageLabel.split(', ')[1]}</a>
              <Button
              variant="primary" color="custom"
              onClick={this.backOnClick}
              //color='black'
              > {'< Back'} </Button>
              
            {listdevices.map((object) => (  
            <Button variant="primary" color="custom" onClick={this.updatedevicedata.bind(this,object)}>{Object.values(object)[0].AverageLabel.split(', ')[1]}</Button>
              ))
            }
            } */}
          {(this.state.displayDevice =
            true && this.state.displayAsset == false) ? (
            <Button
              variant="primary"
              color="custom"
              onClick={this.backOnClick}
              //color='black'
            >
              {" "}
              {"< Back"}{" "}
            </Button>
          ) : null}
          {/* <h4 style={{fontWeight: "bold"}}>
            {data.assetname}
            
            </h4>
            <br/> */}
          {listdevices.length > 0 ? this.showAssetData(listdevices) : null}
          {this.state.deviceObject != null &&
          this.state.deviceObject != undefined
            ? this.showdevicedata()
            : null}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  //--------------------------------------------------------------------
  render() {
    this.id = [];
    this.state.statusData = this.props.statusDataProp;
    // for(let i=0;i<statusData.length;i++){
    //     let data=statusData[i];
    //     let deviceid=null;
    //     data.attributes['LastOccupancy'].sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
    //     //console.log('data.attributesLastOccupanc')
    //     //console.log(data.attributes['LastOccupancy'])
    //     this.id.push(data.attributes['LastOccupancy'][0].deviceid)
    // }
    this.state.assetName = [];
    this.state.assetName.push({
      value: "-1",
      label: "- no selection  -",
    });
    this.state.statusData.map((asset) => {
      var obj = {
        value: asset,
        label: asset.assetname,
      };
      this.state.assetName.push(obj);
      return 0;
    });
    return (
      <Card>
        <CardBody>
          {/* <BootstrapTable data = { statusData } tableHeaderClass='header_white' exportCSV csvFileName='Snow Monitoring.csv'>
                        <TableHeaderColumn dataField='assetname' dataFormat={this.assetnameFormat} filter={{type: 'TextFilter', placeholder: ' ', delay: 1000}} dataAlign='center' csvHeader='Station Name' isKey>Station Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.occupancyStatus} dataAlign='center' csvFormat={this.occupancyStatusCSV} csvHeader='Current occupancy status'>Current Occupancy Status</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.batteryRender} dataAlign='center' csvFormat={this.batteryRenderCSV} csvHeader='Battery life'>Battery Life</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.lastOccupied} dataAlign='center' csvFormat={this.lastOccupiedCSV} csvHeader='Last amount of time occupied' filterFormatted filter={{type: 'TextFilter', placeholder: ' '}}>Last Amount Of Time Occupied</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.lastMessage} dataAlign='center' csvFormat={this.lastMessageCSV} csvHeader='Last message received' filterFormatted filter={{type: 'TextFilter', placeholder: ' '}}>Last Message Received</TableHeaderColumn>
                    </BootstrapTable> */}
          <Col md={12}>
            <Col md={3}>
              <Select
                value={this.state.selectedAssetOption}
                options={this.state.assetName}
                onChange={this.handleAssetChange}
                placeholder={"- select station -"}
                styles={this.state.customStyles}
              />
            </Col>
            <br />
            <Col md={12}>{this.renderButtons()}</Col>
            <br />
            <br />
          </Col>
        </CardBody>
      </Card>
    );
  }
}

export default SnowBoard;
