/* eslint-disable */
import axios from 'axios';
import {
    ATTRI_MGMT_TOGGLE_ERROR,
    ATTRI_MGMT_TOGGLE_SUCCESS,
    ATTRI_MGMT_MSG_TOGGLE_OFF,
    ATTRI_MGMT_MSG_TOGGLE_ON,
    ATTRI_MGMT_GET_ATTRIBUTE_SUCCEED,
    ATTRI_MGMT_GET_ATTRIBUTE_FAILED,
    ATTRI_MGMT_POST_ATTRIBUTE_SUCCEED,
    ATTRI_MGMT_POST_ATTRIBUTE_FAILED,
    ATTRI_MGMT_DELETE_ATTRIBUTE_SUCCEED,
    ATTRI_MGMT_DELETE_ATTRIBUTE_FAILED,
    ATTRI_MGMT_LOAD_DEVICETYPE_SUCCEED,
    ATTRI_MGMT_LOAD_DEVICETYPE_FAILED,
    ATTRI_MGMT_LOAD_ASSET_SUCCEED,    
    ATTRI_MGMT_LOAD_ASSET_FAILED,
    ATTRI_MGMT_CLEAR_ATTRIBUTE,
    USER_GET_FAILED,
} from '../../constants/actionTypes';

import {
    WEB_SERVICE_ROOT,
    WEB_SERVICE_ATTRIBUTES,
    WEB_SERVICE_DEVICETYPE,
    WEB_SERVICE_ASSET,
} from '../../constants/appConstants'

import {toggleAlertOff} from './alertAction';
import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
});

// ----------------------------------------------
export function closeAlertOff () {
    return (toggleAlertOff(ATTRI_MGMT_MSG_TOGGLE_OFF));
}
// ----------------------------------------------
function loadDeviceTypeSucceed(response){
    const action = {
        type: ATTRI_MGMT_LOAD_DEVICETYPE_SUCCEED,
        payload: response.data,
    };

    return action;
}

function loadDeviceTypeFailed(error){
    const action = {
        type: ATTRI_MGMT_LOAD_DEVICETYPE_FAILED,
        error: error.message
    };

    return action;
}

export function loadDeviceTypes(){

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
      
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    // const urlStr = 'http://192.168.2.7:8080/sigfox/Devicetypes';
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_DEVICETYPE}`;

     
    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ( (dispatch) => {
            request.then( (response) => {  dispatch(loadDeviceTypeSucceed(response))})
                   .catch( (error) => {dispatch(loadDeviceTypeFailed(error))})
        }
    );
}
// ----------------------------------------------
function loadAssetSucceed(response){
    const action = {
        type: ATTRI_MGMT_LOAD_ASSET_SUCCEED,
        payload: response.data
    };

    return action;
}

function loadAssetFailed(error){
    const action = {
        type: ATTRI_MGMT_LOAD_ASSET_FAILED,
        error: error.message
    };

    return action;
}

export function loadAssets(criteria){

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
        queryString = '?' + queryString;
    }
    // const urlStr = 'http://192.168.2.7:8080/sigfox/Devicetypes';
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ASSET}${queryString}`;

     
    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ( (dispatch) => {
            request.then( (response) => {  dispatch(loadAssetSucceed(response))})
                   .catch( (error) => {dispatch(loadAssetFailed(error))})
        }
    );
}
// ----------------------------------------------
function getAttributeSucceed (response) {
     
     
    let action;
    if (response.status == 200){
         
        action = {
            type: ATTRI_MGMT_GET_ATTRIBUTE_SUCCEED,
            payload: response.data
        };
    }else{
         
        action = {
            type: ATTRI_MGMT_GET_ATTRIBUTE_FAILED,
            error: response.statusText
        };
    }
   

    return action;

}

function getAttributeFailed (error) {
    //  
    //  
    const action = {
        type: ATTRI_MGMT_GET_ATTRIBUTE_FAILED,
        error: error.message
    };
   
    return action;

}

export function getAttributes (assetid) {
     
    let criteria = '';
    if (assetid && assetid.length > 0){
        // criteria = `?assetid=${assetid}`;
        criteria = `?devicetypeid=${assetid}`;
    }
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ATTRIBUTES}${criteria}`;
     
    const request = axios({
        url: urlStr,
        method: 'get',
    });
    return (
        (dispatch)=>{
            request.then((response)=>{dispatch(getAttributeSucceed(response))})
                    .catch((error)=>{dispatch(getAttributeFailed(error))})
        }
    );
}
// ----------------------------------------------------------------------------
export function clearAttributes () {
     
    return (
        dispatch => {
            dispatch({
                type: ATTRI_MGMT_CLEAR_ATTRIBUTE,
            });
        }
    )
}
// ----------------------------------------------------------------------------

function upsertAttributeSucceed (response) {
    let action ; 
    if (response.status == 200){
        action = {
            type: ATTRI_MGMT_POST_ATTRIBUTE_SUCCEED,
            payload: response.data
        };
    }else{
        action = {
            type: ATTRI_MGMT_POST_ATTRIBUTE_FAILED,
            error: response.statusText
        };
    }
    return action;
}

function upsertAttributeFailed (error) {
    const action = {
        type: ATTRI_MGMT_POST_ATTRIBUTE_FAILED,
        error: error.message
    };
   
    return action;
}

export function upsertAttributes (attriObj) {

    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ATTRIBUTES}`;

    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {'Accept': 'application/json'},
        data: attriObj,
    });
    return (
        (dispatch)=>{
            request.then(
                        (response)=>{
                            // alert('chined-1');
                            dispatch(upsertAttributeSucceed(response));
                            // alert('chined-2');
                            if (response.status == 200){
                                // alert('chined-3');
                                dispatch(getAttributes(attriObj.devicetype));
                            }
                        }
            ).catch(
                        (error)=>{dispatch(upsertAttributeFailed(error))}
            )
        }
    );
}

// ----------------------------------------------------------------------------

function deleteAttributeSucceed (response) {
    let action;
    if (response.status == 200){
         action = {
             type: ATTRI_MGMT_DELETE_ATTRIBUTE_SUCCEED,
             payload: response.data
         };
     }else{
         action = {
             type: ATTRI_MGMT_DELETE_ATTRIBUTE_FAILED,
             error: response.statusText
         };
     }
     return action;
 }

function deleteAttributeFailed (error) {
    const action = {
        type: ATTRI_MGMT_DELETE_ATTRIBUTE_FAILED,
        error: error.message
    };
   
    return action;
}

export function deleteAttributes(attributeid) {
    const  criteria = `?attributeid=${attributeid}`;

    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ATTRIBUTES}${criteria}`;

    const request = axios({
        url: urlStr,
        method: 'delete',
    });

    return (
        (dispatch)=>{
            request.then(
                (response)=>{
                     
                     
                    //  
                    dispatch(deleteAttributeSucceed(response));
                    //  
                    if (response.status == 200 && response.data == true) {
                        //  
                        dispatch(getAttributes());
                    }
                }
            ).catch(
                (error)=>{dispatch(deleteAttributeFailed(error))}
            )
        }
    );
}