/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Col, Container, Row } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AxiosNVR } from '../../../constants/myContexts';
import axios from 'axios';
import { io } from "socket.io-client";
import { VIDEO_WS_ROOT, VIDEO_ROOT, WEB_SERVICE_SOCKET_ROOT, WEB_SERVICE_USER_ROOT, WEB_UPLOAD_ROOT } from '../../../constants/appConstants';
import { PlayIcon, DoneIcon, ErrorIcon } from 'mdi-react';
import { toast } from "react-toastify";
import { useInterval } from '../../../factories/utils';
import { getCurrentUser } from '../../../factories/auth';
import { PlayButton, StopButton } from '../../../shared/components/Button/IconButton';

const user = getCurrentUser();
const useLinearIndeterminateStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const LinearIndeterminate = () => {
  const classes = useLinearIndeterminateStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
};

const useButtonStyles = makeStyles((theme) => ({
//   root: {
//     "& .mdi-icon": {
//       width: "18px",
//       height: "18px",
//     },
//   },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  successfullDownload: {
    color: '#fff !important',
    boxShadow: 'none !important',
    backgroundColor: '#303f9f !important'
  },
  unsuccessfullDownload: {
    color: '#fff !important',
    boxShadow: 'none !important',
    backgroundColor: 'rgb(220, 0, 78) !important'
  },
  defaultDisabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  }
}));

const useProgresStyle = makeStyles((theme) => ({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#198754"
    },
  },
  colorPrimary: {
    backgroundColor: "#65c89a"
  }
}));

const ButtonFactory = (props) => {
  // const uses = getCurrentUser();
  const classes = useButtonStyles();
  let disabledClasses = {};

  if (props.children === "Download successfull" && props.disabled) {
    disabledClasses = { disabled: classes.successfullDownload }
  } else {
    disabledClasses = { disabled: classes.defaultDisabled }
  }

  return (
    <Button outline color="custom" className={classes.margin} {...props} classes={disabledClasses}>
      {props.children}
    </Button>
  );
};

const SuccessProgressBarFactory = (props) => {
  const classes = useProgresStyle();

  return (
    <LinearProgress {...props} className={classes.root} classes={{colorPrimary: classes.colorPrimary}} />
  );
};

// const WS_ROOT = 'wss://wssdemo.itsp-inc.com';
const WS_ROOT = 'ws://192.168.161.9';

class VideoMpegPlaybackPlayer extends Component {
  constructor(props) {
    super(props);
    this.myPlayer = React.createRef();
    this.handleLoad = this.handleLoad.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.setupSocketClient = this.setupSocketClient.bind(this);
    this.getDownloadProgress = this.getDownloadProgress.bind(this);
    this.socket = null;
    this.state = {
      videoSrc: "",
      label: "",
      socketId: "1",
      chunkProgress: null,
      downloadLabel: "Download",
      downloadReady: false,
      downloadRequested: false,
      downloadError: "",
      downloadJobId: null,
      showErrorToast: true,
      showDownloadToast: true,
      downloadEnabled: true,
      downloadProgress: 0,
    };
    this.userObj = getCurrentUser();
  }

  componentDidMount() {
    // this.getDownloadProgress();
    // this.timer = setInterval(this.getDownloadProgress, 1000)
    // this.ws = new WebSocket('ws://192.168.2.63:8081/webSocketService');
    // // this.ws = new WebSocket(this.props.url);
    // this.ws.onopen = (e) => {
    //     console.log('web socket connection established!')
    // }
    // this.ws.onmessage = (e) => {
    //     var data = JSON.parse(e.data);
    //     if (data.code === 0) {
    //         console.log(e.data)
    //     } else if (data.code === 201) {
    //         // $("#show_video").attr("src", "data:image/*;base64," + data.data)
    //         this.setState({
    //             videoSrc:  "data:image/*;base64," + data.data
    //         });
    //     }
    // }
    // const canvas = this.myPlayer.current;
    // const ctx = canvas.getContext("2d");
    // ctx.scale(1, 1);
    // let wsUrl = `${WS_ROOT}:${this.props.port}`;
    // console.log('chid - video player',this.props.chid);
    // console.log('ch - video player',this.props.ch);
    // let ch = this.props.ch ? this.props.ch : 'nvr';
    // // let wsUrl = `wss://localhost.itsp-inc.com:8443/socketSecure/${ch}/${this.props.chid}`;
    // let wsUrl = `${VIDEO_WS_ROOT}/socketSecure/${ch}/${this.props.chid}`;
    // this.player = new JSMpeg.Player(wsUrl, {
    //     canvas: this.myPlayer.current
    // });
    // this.loadPalyBackDate();
    // window.addEventListener('load', this.handleLoad);
    // var c = this.refs.player;
    // var ctx = c.getContext("2d");
    // ctx.scale(0.5, 0.5);
    // const canvas = this.myPlayer.current
    // // const canvas = this.MyCanvas
    // console.log("get canvas", canvas)
    // to scale the <canvas> element itself
    // canvas.width = 500;
    // canvas.height = 500;
    // to scale the drawings, use the context
    // const ctx = canvas.getContext("webgl");
    // console.log("get ctx", ctx);
    // // ctx.scale(1, 1);
    // ctx.viewport(0, 0, canvas.width, canvas.height);
  }

  componentDidUpdate(prevProps,prevState){
    if((prevState.downloadRequested !== this.state.downloadRequested) ){
      if(this.state.downloadRequested){
        // console.log('DID UPDATE', this.state, prevState)
        this.timer = setInterval(this.getDownloadProgress, 2000);
      }else{
        clearInterval(this.timer)
        this.timer = null;
      }
    }
    if(prevState.downloadProgress !== this.state.downloadProgress){
      // console.log('PROGRESS UPDATED', this.state.downloadProgress)
    }
  }
  componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.ch &&
      nextProps.chid &&
      nextProps.date &&
      nextProps.time &&
      nextProps.timelen
    ) {
      if (
        nextProps.ch !== this.props.ch ||
        nextProps.chid !== this.props.chid ||
        nextProps.date !== this.props.date ||
        nextProps.time !== this.props.time ||
        nextProps.timelen !== this.props.timelen
      ) {
        console.log("reload player");
        if (this.player) {
          this.player.stop();
        }

        // reset state for downloading a video segment again
        // when the user selects a new time interval
        this.setState(preState => ({
          // test to see if this label is changed during download
          downloadLabel: "Download",
          downloadRequested: false,
          chunkProgress: null,
          downloadDate: nextProps.downloadDate,
          startTime: nextProps.startTime,
          endTime: nextProps.endTime
        }));

        // reset date picker and time inputs
        this.props.onDisableTimeInputs(false);

        // this.setupSocketClient();
        this.setPlayBackViewer(
          nextProps.ch,
          nextProps.chid,
          nextProps.date,
          nextProps.time,
          nextProps.timelen
        );
      }
    } else {
      if (this.player) {
        try {
          //this.player.stop();
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.timer = null;
    try {
      console.log('Testing unmount');
      // if (this.state.downloadRequested) {
      //   toast.error("Download cancelled!");
      // }
      if (this.player) {
        this.player.destroy();
        console.log("web socket closed!");
      }
    } catch (e) {
      console.log('Testing unmount error', e);
    } finally {
      if (this.state.downloadJobId && this.socket) {
        console.log(`sending a job cancel request`);        
        this.socket.emit("jobCancelRequest", { downloadJobId: this.state.downloadJobId });
      }
      if (this.socket) {
        this.socket.close(); 
      }
    }
  }

  getDownloadProgress(){
    try{
      axios.get(`${VIDEO_ROOT}/downloadProgress`).then(res=>{
        if(res.status == 200){
          let progress = parseInt(res.data)
          this.setState({downloadProgress: progress})
        }
      })
    }catch(err){
      toast.error("Internal API error")
    }
  }

  setupSocketClient() {
    // setup socket client
    this.socket = io(WEB_SERVICE_SOCKET_ROOT);

    this.socket.on("connect", (data) => {
      console.log(`Socket ID: ${JSON.stringify(this.socket.id)}`);
      this.setState(preState => ({
        socketId: this.socket.id,
      }));

      this.socket.emit("message", { data: "I from react am connected!" });

      this.socket.on("welcome" + this.state.socketId, (message) => {
        console.log(message);
      });
      
      this.socket.on(
        "chunkProgressMessageForClient" + this.state.socketId,
        (progressMsg) => {
          console.log(`Chunk progress: ${progressMsg.percent}`)
          
          this.props.onDisableTimeInputs(true);
          
          this.setState(preState => ({
            downloadLabel: "Downloading video...",
            chunkProgress: progressMsg.percent,
          }));
        }
      );

      this.socket.on(
        "downloadErrorMessageForClient" + this.state.socketId,
        (fileMsg) => {
          this.setState(preState => ({
            downloadLabel: "Download failed",
          }));

          // show an error toast once, then cancel any jobs
          const { showErrorToast } = this.state
          if (showErrorToast) {
            toast.error("Download failed. Try again.");
            console.log(`sending a job cancel request`);
            this.setState({ showErrorToast: false })
            this.socket.emit("jobCancelRequest", { downloadJobId: this.state.downloadJobId });
          }

          setTimeout(() => {
            this.setState(preState => ({
              downloadLabel: "Try again",
              downloadRequested: false,
              chunkProgress: null,
            }));
            this.props.onDisableTimeInputs(false);
          }, 2000);
        }
      );

      this.socket.on("downloadMessageForClient" + this.state.socketId, (fileMsg) => {
        console.log(fileMsg.file);

        console.log(`Download URL: ${`${VIDEO_ROOT}/testFileDownload?fileName=${encodeURIComponent(
          fileMsg.file
        )}`}`)

        /* Using axios to get the file */
        axios({
          url: `${VIDEO_ROOT}/testFileDownload?fileName=${encodeURIComponent(
            fileMsg.file
          )}`,
          method: "get",
          responseType: "blob",
        })
          .then((resp) => {
            // console.log(resp);

            let fileInfoHeader = resp.headers["content-disposition"];
            console.log(fileInfoHeader);

            // getting filename from header
            let fileName = fileInfoHeader
              .split(";")[1]
              .trim()
              .split("=")[1];
            console.log(fileName);
            fileName = fileName.substring(1, fileName.length - 1);
            console.log(fileName);

            const downloadUrlForBlob = window.URL.createObjectURL(
              new Blob([resp.data])
            );
            const downloadAnchor = document.createElement("a");
            downloadAnchor.style.display = "none";
            downloadAnchor.href = downloadUrlForBlob;
            downloadAnchor.download = fileName;
            document.body.appendChild(downloadAnchor);
            downloadAnchor.click();
            /**/

            setTimeout(() => {
              document.body.removeChild(downloadAnchor);
              window.URL.revokeObjectURL(downloadUrlForBlob);
              console.log(`Video segment downloaded successfully. ${fileName}`);

              // need to show this success toast only once
              const { showDownloadToast } = this.state
              if (showDownloadToast) {
                toast.success('Download successfull. Check your downloads folder.');
                this.setState({ showDownloadToast: false })
              }

              this.setState(preState => ({
                downloadLabel: "Download successfull",
                downloadRequested: false,
                chunkProgress: null,
              }));

              this.props.onDisableTimeInputs(false);

              // emit delete message here
              // the download can also be cleaned up when the server restarts
              this.socket.emit("fileDeleteRequest", { file: fileMsg.file });

            }, 1000);
            

          })
          .catch((err) => {
            console.error("error", err);
            this.setState(preState => ({
              downloadLabel: "Download failed",
            }));

            // show an error toast once, then cancel any jobs
            const { showErrorToast } = this.state
            if (showErrorToast) {
              toast.error("Download failed. Try again.");
              this.setState({ showErrorToast: false })
            }
            // reset showErrorToast on next download click

            setTimeout(() => {
              this.setState(preState => ({
                downloadLabel: "Try again",
                downloadRequested: false,
                chunkProgress: null,
              }));
              this.props.onDisableTimeInputs(false);
            }, 2000);
          });
      });
    });
  }

  setPlayBackViewer(propsCh, chid, date, time, timelen) {
    // if (this.player){
    //     this.player.destroy();
    // }

    console.log("chid - video player", chid);
    console.log("ch - video player", propsCh);
    let ch = propsCh ? propsCh : "nvr";
    // let wsUrl = `wss://localhost.itsp-inc.com:8443/socketSecure/${ch}/${this.props.chid}`;
    let wsUrl = `${VIDEO_WS_ROOT}/socketSecure/${ch}/${chid}?date=${date}&time=${time}&timelen=${timelen}`;
    console.log("wsUrl", wsUrl);
    this.player = new JSMpeg.Player(wsUrl, {
      canvas: this.myPlayer.current,
      onPlay: () => {
        this.setState({
          label: "PAUSE",
        });
      },
      onPause: () => {
        this.setState({
          label: "PLAY",
        });
      },
      onEnded: () => {
        this.setState({
          label: "PLAY",
        });
      },
    });
  }

  onPlay = () => {
    if (this.player) {
      if (this.state.label == "PLAY") {
        this.player.play();
        this.setState({
          label: "PAUSE",
        });
      } else if (this.state.label == "PAUSE") {
        this.player.pause();
        this.setState({
          label: "PLAY",
        });
      }
    }
  };

  onStop = () => {
    if (this.player) {
      this.player.stop();
    }
  };
  // ---------------------------------------
  loadPalyBackDate() {
    // AxiosNVR({
    //     url: `SearchRecordDate/${this.props.chid}`,
    //     method: 'get',
    // }).then( resp => {
    //     console.log("date load", resp);
    // }).catch(err => {

    // });
    fetch("http://192.168.2.55/SearchRecordDate/3")
      .then((resp) => {
        console.log("date load", resp);
      })
      .catch((err) => {});
  }
  // ---------------------------------------
  handleLoad() {
    console.log("windows load");
    const canvas = this.myPlayer.current;
    const ctx = canvas.getContext("2d");
    console.log("ctx", ctx);
    ctx.scale(0.5, 0.5);
  }
  // ---------------------------------------
  handleDownload(event) {
    event.preventDefault();
    this.setState({downloadLabel: "Preparing download file", downloadRequested: true})

    // stop the video before play
    // this.onStop();

    let ch = this.props.ch;
    let chid = this.props.chid;
    let startDate = this.state.downloadDate;
    let endDate = this.state.downloadDate;
    let startTime = this.state.startTime;
    let endTime = this.state.endTime;

    // console.log(`DOWNLOAD URL----${VIDEO_ROOT}/videoDownload/${ch}/${chid}?startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}`);

    axios.get(`${VIDEO_ROOT}/videoDownload/${ch}/${chid}?startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}`).then(response=>{
      if(response.status == 200){
        toast.success("Downloading playback...")
        this.setState({downloadReady:true});
        this.setState({downloadLabel: "Download"})
        console.log("Download request response", response);
        let downloadLink = document.createElement('a');
        downloadLink.href = `${WEB_UPLOAD_ROOT}${response.data}`
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.setState({downloadProgress:100, downloadReady:false});
        downloadLink.parentNode.removeChild(downloadLink);
      }else{
        this.setState({downloadReady:false, downloadProgress:0})
        toast.error(response.data)
      }
    }).finally(()=>{
      this.setState({downloadRequested: false})
    })

  }
  // ---------------------------------------
  renderMpegPlayer = () => {
    return (
      <Row>
        <Col md={12} style={{ textAlign: "center" }}>
          {this.player && this.userObj.userName == 'admin' && (
            <div>
              <ButtonFactory
                variant="contained"
                size="small"
                disabled={this.state.downloadLabel === "Preparing download file" ? true : false }
                onClick={this.handleDownload}
                color={this.state.downloadReady ? "primary" : "success"}
                // startIcon={this.state.downloadLabel !== "Preparing download file" && this.state.downloadReady ? 
                // <DoneIcon style={{ width: "18px", height: "18px", marginTop: "0px" }} /> : }
                startIcon={<CloudDownloadIcon />}
              >
                {this.state.downloadLabel}
              </ButtonFactory>
              <div style={{ height: "4px" }} />
              <div style={{ height: "4px" }} />
              <div style={{ height: "4px" }} />
              <div style={{ height: "4px", width: "650px" }}>
                {this.state.downloadLabel === "Preparing download file" &&  
                    <SuccessProgressBarFactory value={this.state.downloadProgress} variant="determinate"/>}
              </div>
            </div>  
          )}
          {/* <canvas id="canvas" ref='player' width="300" height="300"></canvas> */}
          <canvas
            id="canvas"
            ref={this.myPlayer}
            style={{ width: "650px", height: "500px" }}
          ></canvas>
          {/* <canvas id="canvas" ref={this.myPlayer} style={{"width": "100%", "height": "100%"}}></canvas> */}
          {/* <canvas id="canvas" ref={node => this.MyCanvas = node} width="100" height="100"></canvas> */}
          {this.player && (
            <div>
              {/* <ButtonFactory
                variant="contained"
                size="small"
                onClick={this.onPlay}
                color="primary"
                startIcon={
                  this.state.label === "PAUSE" ? (
                    <PauseIcon />
                  ) : (
                    <PlayIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        marginTop: "0px",
                      }}
                    />
                  )
                }
              >
                {this.state.label}
              </ButtonFactory> */}
              {
                this.state.label === "PAUSE" &&
                <PlayButton
                  onClick={this.onPlay}
                  isPlay
                  label={this.state.label}
                />
              }
              {
                this.state.label === "PLAY" &&
                <PlayButton
                  onClick={this.onPlay}
                  isPlay={false}
                  label={this.state.label}
                />
              }
              {/* <ButtonFactory
                variant="contained"
                size="small"
                onClick={this.onStop}
                color="primary"
                startIcon={<StopIcon />}
              >
                STOP
              </ButtonFactory> */}
              <StopButton
                onClick={this.onPlay}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <Container style={{ padding: "20px" }}>
        {this.renderMpegPlayer()}
      </Container>
    );
  }
}

export default VideoMpegPlaybackPlayer