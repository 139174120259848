/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label } from 'recharts';
import { renderSpinner } from './PeopleCountBoard';
import moment from 'moment';
import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { groupByArray } from '../../../../factories/utils';
import IndividualCamChart from './IndividualCamChart';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


const PeopleCountGraph = ({localData, limit}) => {
    const [chartData, setchartData] = useState([])
    const [FilterDate, setFilterDate] = useState(moment(new Date()).format('MM-DD-YYYY'))
    // const [SortedData, setSortedData] = useState([])
    const [camNum, setcamNum] = useState(3)

    useEffect(() => {
        if(localData && localData.length > 0){
            //convert sql timestamp to normal time
            localData.map(data=>{
                data.time = moment(data.timestamp).utc().subtract(4, 'hours').format('MM-DD-YYYY HH:00')
                data.timeLabel = moment(data.timestamp).utc().subtract(4, 'hours').format('HH:00')
            })
            // console.log('===LOCAL DATA TIMESTAMP===,',localData[0].timestamp )
            let groupedDataByDate = groupByTime(localData)
            console.log('GROUPED BY DATE', groupedDataByDate)
            // let sortedData = []
            let currentChartData = getCurrentData(groupedDataByDate);
            console.log('CURRENT DATA CHART', currentChartData)
            let tempChartData = currentChartData.filter(data=>data.time.includes(FilterDate.toString()))
            console.log('TEMP CHART DATA', tempChartData)
            setchartData(tempChartData);

        }
    }, [localData, limit, FilterDate])

    const getCurrentData = (groupedDataByDate)=>{
        let tempChartData = []
        for(const[key,value] of Object.entries(groupedDataByDate)){
            let entranceCount = 0
            let exitCount = 0
            //get current data for each camera in group
            for(var i = 1 ; i <= camNum && i<= value.length; i++){
                let mostCurrent = value[value.length - i];
                entranceCount += mostCurrent.entranceCount
                exitCount += mostCurrent.exitCount
            }
            let tempData = {
                time: key,
                timeLabel: value[0].timeLabel,
                Limit: limit ? limit : 0, 
                entranceCount: entranceCount,
                exitCount: exitCount, 
                "On Premises": entranceCount - exitCount < 0 ? 0 : entranceCount - exitCount
            }
            tempChartData.push(tempData);
        }
        return tempChartData;
    }

    const groupByTime = (arr) =>{
        let groupedArray = groupByArray(arr, 'time')
        return groupedArray
    }

    const handleDateChange =(date) =>{
        date = moment(date).format('MM-DD-YYYY')
        setFilterDate(date);
        console.log('========DATE CHANGE=========', date.toString());
        // debugger;
    }
    return (
        <>
        <Row className="container center" style={{marginTop: "20px", width: "100%"}}>
            <MuiPickersUtilsProvider utils={MomentUtils}>

                <KeyboardDatePicker 
                    autoOk
                    inputVariant="outlined"
                    label="Choose Date"
                    onChange={date=>handleDateChange(date)}    
                    variant="inline"
                    format="MM/DD/YYYY"
                    InputAdornmentProps={{ position: "start" }}
                    value={FilterDate}
                    maxDate={new Date()}
                />
            </MuiPickersUtilsProvider>
            {/*TODO TEST RETREE !!!!!!!!!!!!!!!!!*/}
            {/*{<Button color="seconday" className="box" onClick={(event) => {
                this.props.getResellersTree({isGetAll:true})
            }}>getResellersTree</Button>}*/}
        </Row>
        <ResponsiveContainer height={250} width="98%" maxWidth={300}>
            <LineChart
                data={chartData}
                width={250} height={230}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timeLabel" />
                    <Label value="Pages of my website" offset={0} position="insideBottom" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {/* <Line type="monotone" dataKey="entranceCount" legendType="circle" stroke="#0066ff" />
                    <Line type="monotone" dataKey="exitCount" legendType="circle" stroke="#ff3300" /> */}
                    <Line type="monotone" dataKey="Limit" legendType="circle" stroke="#4f4443" />
                    <Line type="monotone" dataKey="On Premises" stroke="#33cc33" legendType="circle" strokeWidth={3} />
            </LineChart>
        </ResponsiveContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = (dispatch) => {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleCountGraph)
