/* eslint-disable */
import axios from "axios";
import PropTypes from "prop-types";
import {
  MAP_DEVICE_LOAD_SUCCEED,
  MAP_DEVICE_LOAD_FAILED,
  MAP_LOAD_DEVICE_ATTRIBUTE_FAILED,
  MAP_LOAD_DEVICE_ATTRIBUTE_SUCCEED,
  MAP_DEVICE_ATTR_LOAD_SUCCEED,
  MAP_DEVICE_ATTR_LOAD_FAILED,
  DEVICE_ADD_ATTR_DEVICE,
  DEVICE_UPDATE_ATTR_DEVICE,
  MAP_DEVICE_MAP_LOAD_SUCCEED,
  MAP_DEVICE_MAP_LOAD_FAILED,
  MAP_DEVICE_UPDATE_ONE_DEVICE,
  MAP_FILTER_THEME_INPUT_BIND,
  MAP_FILTER_MESSAGE_TYPE_INPUT_BIND,
  MAP_FILTER_DEVICE_TYPE_INPUT_BIND,
  MAP_FILTER_FROM_INPUT_BIND,
  MAP_FILTER_TO_INPUT_BIND,
  MAP_FILTER_ATTRIBUTE_INPUT_BIND,
  MAP_FILTER_ATTRIBUTE_CHANGE,
  ASSET_LOAD_DEVICE_SUCCEED,
  ASSET_LOAD_DEVICE_FAILED,
  FENCE_LOAD_DEVICE_SUCCEED,
  MAP_LOAD_ASSETS_SUCCEED,
  MAP_LOAD_ASSETS_FAILED,
  MAP_LOAD_ASSET_ATTRIBUTE_SUCCEED,
  MAP_LOAD_ASSET_ATTRIBUTE_FAILED,
  MAP_GET_ASSETS_DATA_SUCCEED,
  MAP_GET_ASSETS_DATA_FAILED,
  MAP_LOAD_ASSETCLASS_SUCCEED,
  MAP_LOAD_ASSETCLASS_FAILED,
  MAP_FILTER_ASSETCLASS_INPUT_BIND,
  MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED,
  MAP_GET_ASSETCLASS_ATTRIBUTE_FAILED,
  MAP_CLEAR_ASSETCLASS_ATTRIBUTE,
  MAP_GET_THEME_SUCCEED,
  MAP_GET_THEME_FAILED,
  MAP_FILTER_ASSET_ATTRIBUTE_CHANGE,
  MAP_REFRASH_STARTED,
  MAP_GET_DEVICE_GROUP_DATA_SUCCEED,
  MAP_GET_DEVICE_GROUP_DATA_FAILED,
  MAP_CLEAR_DEVICE_GROUP_DATA,
  MAP_GET_ASSET_GROUP_DATA_SUCCEED,
  MAP_GET_ASSET_GROUP_DATA_FAILED,
  MAP_CLEAR_ASSET_GROUP_DATA,
  MAP_FILTER_ASSET_GROUP_ATTRIBUTE_CHANGE,
  MAP_GET_DEVICE_BY_GROUP_SUCCEED,
  MAP_GET_DEVICE_BY_GROUP_FAILED,
  MAP_CLEAR_GROUP_DEVICES,
  USER_GET_FAILED,
  MAP_DEVICE_MAP_LOAD_CLEAR,
} from "../../constants/actionTypes";

import {
  WEB_SERVICE_ROOT,
  WEB_SERVICE_ATTRIBUTES,
  WEB_SERVICE_DEVICETYPE,
  WEB_SERVICE_ASSET,
  WEB_SERVICE_ASSET_PURE,
  WEB_SERVICE_DEVICE,
  WEB_SERVICE_GET_ASSETS_DATA,
  WEB_SERVICE_GET_ASSETCLASS,
  WEB_SERVICE_GET_ASSETCLASS_ATTRIBUTES,
  WEB_SERVICE_GET_THEMES,
  WEB_SERVICE_GET_GROUP_DEVICES_DATA,
  WEB_SERVICE_GET_GROUP_ASSETS_DATA,
  WEB_SERVICE_GET_DEVICES_BY_GROUP,
  WEB_SERVICE_DEVICE_ATTRIBUTE,
} from "../../constants/appConstants";

// import {axiosGet, base64Encode} from '../../factories/utils';
import {
  axiosGet,
  getCurrentUser,
  base64Encode,
  getJwttoken,
} from "../../factories/utils";
import { CommentArrowLeftIcon } from "mdi-react";
import { dispatch } from "d3";
import { toast } from "react-toastify";
import { geoPropTypes } from "react-geolocated";
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function(config) {
  const token = getJwttoken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const deviceAttributeRoute = `${WEB_SERVICE_ROOT}DeviceAttribute`;
const deviceRoute = `${WEB_SERVICE_ROOT}Device/Tree`;

//-----------------------------------------------------
const deviceParams = {
  deviceid: PropTypes.string,
  devicename: PropTypes.string,
  devicetype: PropTypes.string,
  devicenamealias: PropTypes.string,
  parentdeviceid: PropTypes.string,
  hasparent: PropTypes.string,
  subdevices: PropTypes.arrayOf(PropTypes.object),
  deviceattributes: PropTypes.arrayOf(PropTypes.Object),
};
export const updateOneDevice = (payload) => (dispatch) =>
  dispatch({ type: MAP_DEVICE_UPDATE_ONE_DEVICE, payload });
updateOneDevice.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.shape(deviceParams),
  }),
};
export function postDevice(data) {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.legnth === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  if (data.devices) {
    let filteredDevice = data.devices.map(({ id }) => ({ deviceid: id }));
    if (
      !filteredDevice ||
      filteredDevice.length === 0 ||
      !filteredDevice[0].deviceid
    ) {
      filteredDevice = data.devices.map(({ deviceid }) => ({
        deviceid: deviceid,
      }));
    }

    data.devices = filteredDevice;
  }

  console.log(data);
  return (dispatch) =>
    axios({
      url: deviceRoute,
      headers: {
        "Content-Type": "application/json",
        uTk: base64Encode(JSON.stringify(userTokenObj)),
      },
      method: "post",
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadDevice());
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err.response;
      });
}

export function getDeviceAttributes(deviceid) {
  let criteria = "";
  if (deviceid) {
    criteria = `?deviceid=${deviceid}`;
  }
  const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_DEVICE_ATTRIBUTE}${criteria}`;

  let request = axios({
    url: urlStr,
    method: 'get',
  })
  return (
    (dispatch) => {
    request.then((response) => {
        dispatch({
          type: MAP_LOAD_DEVICE_ATTRIBUTE_SUCCEED,
          payload: response.data,
        });
        return response;

      })
      .catch((error) => {
        dispatch({
          type: MAP_LOAD_DEVICE_ATTRIBUTE_FAILED,
          payload: error.message,
        });
      });
  
}
  )};

export function postDeviceAttribute(data) {
  return (dispatch) =>
    axios({
      url: deviceAttributeRoute,
      method: "post",
      data: data,
    })
      .then((res) => {
        console.log(res);
        return res.data;
      })
      .catch((err) => {
        toast.error(err.message);
      });
}
//-----------------------------------------------------
export function test() {
  return (dispatch) => {
    dispatch({
      type: "aaaa",
      data: "bbbb",
    });
  };
}

export function bindFilterTheme(value) {
  // alert('action: ' + value);

  return (dispatch) => {
    dispatch({
      type: MAP_FILTER_THEME_INPUT_BIND,
      data: value,
    });
  };
}

export function bindFilterMessageType(value) {
  return (dispatch) => {
    dispatch({
      type: MAP_FILTER_MESSAGE_TYPE_INPUT_BIND,
      data: value,
    });
  };
}

export function bindFilterDeviceType(value) {
  return (dispatch) => {
    dispatch({
      type: MAP_FILTER_DEVICE_TYPE_INPUT_BIND,
      data: value,
    });
  };
}

export function bindFilterFrom(value) {
  return (dispatch) => {
    dispatch({
      type: MAP_FILTER_FROM_INPUT_BIND,
      data: value,
    });
  };
}

export function bindFilterTo(value) {
  return (dispatch) => {
    dispatch({
      type: MAP_FILTER_TO_INPUT_BIND,
      data: value,
    });
  };
}

export function bindFilterAttribute(value) {
  return (dispatch) => {
    dispatch({
      type: MAP_FILTER_ATTRIBUTE_INPUT_BIND,
      data: value,
    });
  };
}

export function bindFilterAssetClass(value) {
  return (dispatch) => {
    dispatch({
      type: MAP_FILTER_ASSETCLASS_INPUT_BIND,
      data: value,
    });
  };
}
//-----------------------------------------------------
function loadingDataStart() {
  return {
    type: MAP_REFRASH_STARTED,
  };
}

function loadDeviceSucceed(response) {
  const action = {
    type: MAP_DEVICE_LOAD_SUCCEED,
    payload: response,
  };

  return action;
}

function loadDeviceFailed(error) {
  const action = {
    type: MAP_DEVICE_LOAD_FAILED,
    payload: error.message,
  };

  return action;
}

export function loadDevice() {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  const urlStr = `${WEB_SERVICE_ROOT}Devicetypes`;
  // const urlStr = 'http://13.88.246.7:8080/sigfox/Devicetypes';
  // const urlStr = 'http://192.168.2.103:8080/Devicetypes';

  const request = axios({
    url: urlStr,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      uTk: base64Encode(JSON.stringify(userTokenObj)),
    },
  });

  return (dispatch) => {
    request
      .then((response) => {
        dispatch(loadDeviceSucceed(response));
      })
      .catch((error) => {
        dispatch(loadDeviceFailed(error));
      });
  };
}

function loadDeviceAttrSucceed(response) {
  const action = {
    type: MAP_DEVICE_ATTR_LOAD_SUCCEED,
    data: response,
  };
  return action;
}

function loadDeviceAttrFailed(error) {
  return {
    type: MAP_DEVICE_ATTR_LOAD_FAILED,
    error: error.message,
  };
}

export function loadDeviceAttr(criteria) {
  // const urlStr = 'http://13.88.246.7:8080/sigfox/Getattributes?';
  const urlStr = `${WEB_SERVICE_ROOT}Getattributes?`;
  // const params = `devicetypeid=${deviceTypeId}`;
  let queryString = "";
  if (criteria) {
    queryString = Object.keys(criteria)
      .map((key) => key + "=" + criteria[key])
      .join("&");
  }

  const request = axios({
    url: urlStr + queryString,
    method: "get",
  });
  return (dispatch) => {
    request
      .then((response) => {
        dispatch(loadDeviceAttrSucceed(response));
      })
      .catch((error) => {
        dispatch(loadDeviceAttrFailed(error));
      });
  };
}

function loadDivceMapDataSucceed(response) {
  let mapModifyData = [];
  //
  //
  response.data.map((item, i) => {
    let obj = Object.assign({}, item, { attributename: "", color: "#ff0000" });
    mapModifyData.push(obj);
  });
  const action = {
    type: MAP_DEVICE_MAP_LOAD_SUCCEED,
    payload: mapModifyData,
    // payload: response,
  };
  return action;
}

function loadDivceMapDataFailed(error) {
  const action = {
    type: MAP_DEVICE_MAP_LOAD_FAILED,
    payload: error.message,
  };
  return action;
}

export function loadDivceMapData(criteria) {
  // const urlStr = 'http://192.168.2.7:8080/sigfox/Getdata?';
  // const request = axios({
  //     url: urlStr,
  //     method: 'get',
  // });
  let queryString = "";
  if (criteria) {
    queryString = Object.keys(criteria)
      .map((key) => key + "=" + criteria[key])
      .join("&");
  }

  return (dispatch, getState) => {
    // const state = getState();
    // const deviceTypeId = state.mapfilter.currentDeviceTypeId
    // const urlStr = `http://13.88.246.7:8080/sigfox/Getdata?${queryString}`;
    dispatch(loadingDataStart());
    const urlStr = `${WEB_SERVICE_ROOT}Getdata?${queryString}`;

    const request = axios({
      url: urlStr,
      method: "get",
    });

    request
      .then((response) => {
        dispatch(loadDivceMapDataSucceed(response));
      })
      .catch((error) => {
        dispatch(loadDivceMapDataFailed(error));
      });
  };
}

export function clearDivceMapData() {
  return (dispatch) => {
    dispatch({
      type: MAP_DEVICE_MAP_LOAD_CLEAR,
    });
  };
}

// export function filterMapData(obj){
//     const urlStr = 'http://192.168.2.7:8080/sigfox/Getdata?';
// }

export function filterMapDataByAttribute(attrName) {
  return (dispatch, getState) => {
    const state = getState();
    const currentData = state.mapboard.mapData;

    if (currentData && Array.isArray(currentData) && currentData.length > 0) {
      let mapModifyData = [];
      currentData.map((item, i) => {
        let colorValue = "#ff0000";
        if (item.msg[attrName]) {
          colorValue = item.msg[attrName].themeattributevalue;
          if (!colorValue || colorValue.length === 0) {
            colorValue = "#ff0000";
          }
        }

        let obj = Object.assign({}, item, {
          attributename: attrName,
          color: colorValue,
        });
        mapModifyData.push(obj);
      });
      const action = {
        type: MAP_FILTER_ATTRIBUTE_CHANGE,
        payload: mapModifyData,
        // payload: response,
      };
      dispatch(action);
    }
  };
}

export function filterAssetDataByAttribute(attrid) {
  return (dispatch, getState) => {
    const state = getState();
    const rawData = state.mapboard.assetRawData;

    const defaultcolor = "#ff0000";
    let data = JSON.parse(JSON.stringify(rawData));
    if (!attrid || attrid < 0) {
      //   data = JSON.parse(JSON.stringify(rawData));
    } else {
      data.forEach((assetclass, acindex) => {
        assetclass.asset.forEach((asset, aindex) => {
          let color = defaultcolor;
          asset.device.forEach((device, dindex) => {
            device.msgs.forEach((msg, mindex) => {
              if (msg.msg) {
                Object.keys(msg.msg).forEach((key) => {
                  //
                  //
                  //
                  if (msg.msg[key].id == attrid) {
                    //
                    let colorValue = msg.msg[key].themeattributevalue;
                    if (
                      msg.msg[key].display !== false &&
                      colorValue &&
                      colorValue.length > 0
                    ) {
                      //
                      color = colorValue;
                    }
                  } else {
                    // delete msg.msg[key];
                  }
                });
              }
            });
            // device.msgs = device.msgs.filter((msg, mfindex)=>{
            //     return(msg.msg && Object.keys(msg.msg).length>0);
            // });
          });

          // asset.device = asset.device.filter((device, dfindex)=>{
          //     return(device.msgs && device.msgs.length > 0);
          // });
          asset["color"] = color;
        });
      });
    }

    dispatch({
      type: MAP_FILTER_ASSET_ATTRIBUTE_CHANGE,
      payload: data,
    });
  };
}
export function filterGroupAssetDataByAttribute(attrid) {
  return (dispatch, getState) => {
    const state = getState();
    const rawData = state.mapboard.assetRawGroupData;

    const defaultcolor = "#ff0000";
    let data = JSON.parse(JSON.stringify(rawData));
    if (!attrid || attrid < 0) {
      //   data = JSON.parse(JSON.stringify(rawData));
    } else {
      data.forEach((assetclass, acindex) => {
        assetclass.asset.forEach((asset, aindex) => {
          let color = defaultcolor;
          asset.device.forEach((device, dindex) => {
            device.msgs.forEach((msg, mindex) => {
              if (msg.msg) {
                Object.keys(msg.msg).forEach((key) => {
                  //
                  //
                  //
                  if (msg.msg[key].id == attrid) {
                    //
                    let colorValue = msg.msg[key].themeattributevalue;
                    if (
                      msg.msg[key].display !== false &&
                      colorValue &&
                      colorValue.length > 0
                    ) {
                      //
                      color = colorValue;
                    }
                  } else {
                    // delete msg.msg[key];
                  }
                });
              }
            });
            // device.msgs = device.msgs.filter((msg, mfindex)=>{
            //     return(msg.msg && Object.keys(msg.msg).length>0);
            // });
          });

          // asset.device = asset.device.filter((device, dfindex)=>{
          //     return(device.msgs && device.msgs.length > 0);
          // });
          asset["color"] = color;
        });
      });
    }

    dispatch({
      type: MAP_FILTER_ASSET_GROUP_ATTRIBUTE_CHANGE,
      payload: data,
    });
  };
}
// ---------------------------------------------------------------------------------

function SucceedHandler(response, type) {
  const action = {
    type: type,
    payload: response.data,
  };

  return action;
}
// function FailedHandler(error){
//     const action = {
//         type: ASSET_LOAD_DEVICE_FAILD,
//         payload: error.message
//     };

//     return action;
// }

function FailedHandler(error, type) {
  const action = {
    type: type,
    payload: error.message,
  };

  return action;
}
export function loadDeviceInfo(criteria, reducertype = "asset") {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  let queryString = "";
  if (criteria) {
    queryString = Object.keys(criteria)
      .map((key) => key + "=" + criteria[key])
      .join("&");
  }
  // /Device?hasparent=Y&parentdeviceid=dgsdfgsdfs
  // const urlStr = `http://13.88.246.7:8080/sigfox/Device?${queryString}`;
  const urlStr = `${WEB_SERVICE_ROOT}Device?${queryString}`;
  //
  const request = axios({
    url: urlStr,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      uTk: base64Encode(JSON.stringify(userTokenObj)),
    },
  });
  const type_asset = ASSET_LOAD_DEVICE_SUCCEED;
  const type_fence = FENCE_LOAD_DEVICE_SUCCEED;
  const type_asset_failed = ASSET_LOAD_DEVICE_FAILED;
  return (dispatch) => {
    request
      .then((response) => {
        if (reducertype === "asset") {
          dispatch(SucceedHandler(response, type_asset));
        } else if (reducertype === "fence") {
          dispatch(SucceedHandler(response, type_fence));
        }
      })
      .catch((error) => {
        dispatch(FailedHandler(error, type_asset_failed));
      });
  };
}

export function loadSubDeviceInfo(criteria, reducertype = "asset") {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  let queryString = "";
  if (criteria) {
    queryString = criteria;
  }

  // /Device?hasparent=Y&parentdeviceid=dgsdfgsdfs
  // const urlStr = `http://13.88.246.7:8080/sigfox/Device?${queryString}`;
  const urlStr = `${WEB_SERVICE_ROOT}Device?${queryString}`;
  //
  const request = axios({
    url: urlStr,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      uTk: base64Encode(JSON.stringify(userTokenObj)),
    },
  });
  const type_asset = ASSET_LOAD_DEVICE_SUCCEED;
  const type_fence = FENCE_LOAD_DEVICE_SUCCEED;
  const type_asset_failed = ASSET_LOAD_DEVICE_FAILED;
  return (dispatch) => {
    request
      .then((response) => {
        if (reducertype === "asset") {
          dispatch(SucceedHandler(response, type_asset));
        } else if (reducertype === "fence") {
          dispatch(SucceedHandler(response, type_fence));
        }
      })
      .catch((error) => {
        dispatch(FailedHandler(error, type_asset_failed));
      });
  };
}

// ---------------------------------------------------------------------

export function loadAllAssets(criteria) {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  let queryString = "";
  if (criteria) {
    queryString = Object.keys(criteria)
      .map((key) => key + "=" + criteria[key])
      .join("&");
  }

  let assetUrl = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ASSET_PURE}${queryString}`;

  return (dispatch) =>
    axios({
      url: assetUrl,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        uTk: base64Encode(JSON.stringify(userTokenObj)),
      },
    })
      .then((res) => {
        dispatch({
          type: MAP_LOAD_ASSETS_SUCCEED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MAP_LOAD_ASSETS_FAILED,
          payload: err.message,
        });
      });
}
// ---------------------------------------------------------------------
export function getAttributes(assetid) {
  let criteria = "";
  if (assetid && assetid.length > 0) {
    criteria = `?assetid=${assetid}`;
  }
  const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ATTRIBUTES}${criteria}`;

  const request = axios({
    url: urlStr,
    method: "get",
  });
  return (dispatch) => {
    request
      .then((response) => {
        dispatch({
          type: MAP_LOAD_ASSET_ATTRIBUTE_SUCCEED,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: MAP_LOAD_ASSET_ATTRIBUTE_FAILED,
          payload: error.message,
        });
      });
  };
}
// ---------------------------------------------------------------------
export function getAssetsData(criteria) {
  let queryString = "";
  // let LocalRoot = 'http://192.168.2.91:8080/';
  if (criteria) {
    queryString = Object.keys(criteria)
      .map((key) => key + "=" + criteria[key])
      .join("&");
  }
  const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETS_DATA}?${queryString}`;

  const request = axios({
    url: urlStr,
    method: "get",
  });

  return (despatch) => {
    despatch(loadingDataStart());
    request
      .then((response) => {
        if (response.status == 200) {
          despatch({
            type: MAP_GET_ASSETS_DATA_SUCCEED,
            payload: response.data,
          });
        } else {
          despatch({
            type: MAP_GET_ASSETS_DATA_FAILED,
            error: "Failed to get the assets data",
          });
        }
      })
      .catch((error) => {
        despatch({
          type: MAP_GET_ASSETS_DATA_FAILED,
          error: error.message,
        });
      });
  };
}
// ---------------------------------------------------------------------
export function loadAssetClass(criteria, isAttr = true) {
  //
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  let queryString = "";
  // let LocalRoot = 'http://192.168.2.91:8080/';
  if (criteria) {
    queryString = Object.keys(criteria)
      .map((key) => key + "=" + criteria[key])
      .join("&");
    queryString = "?" + queryString;
  }
  const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETCLASS}${queryString}`;

  const request = axios({
    url: urlStr,
    method: "get",
    // headers: {
    //     'Content-Type': 'application/json',
    //     'uTk': base64Encode('Admin'),
    // },
    headers: {
      "Content-Type": "application/json",
      uTk: base64Encode(JSON.stringify(userTokenObj)),
    },
  });

  return (despatch) => {
    request
      .then((response) => {
        if (response.status == 200) {
          despatch({
            type: MAP_LOAD_ASSETCLASS_SUCCEED,
            payload: response.data,
          });

          if (response.data && response.data.length > 0 && isAttr) {
            let assetclassid = response.data[0].assetclassid;
            despatch(getAssetClassAttribute({ assetclassid: assetclassid }));
          }
        } else {
          despatch({
            type: MAP_LOAD_ASSETCLASS_FAILED,
            error: "Failed to get the assets data",
          });
        }
      })
      .catch((error) => {
        despatch({
          type: MAP_LOAD_ASSETCLASS_FAILED,
          error: error.message,
        });
      });
  };
}
// ---------------------------------------------------------------------
export function getAssetClassAttribute(creteria) {
  //
  // console.trace()
  let paramObj = {
    url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETCLASS_ATTRIBUTES}`,
    criteria: creteria,
    logText: "Get AssetClass Attribute Url ",
    sccessType: MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED,
    failedType: MAP_GET_ASSETCLASS_ATTRIBUTE_FAILED,
    errorMessage: "Failed to get asset class attributes.",
  };

  return axiosGet(paramObj);
}

export function clearAssetClassAttribute() {
  return (dispatch) => {
    dispatch({
      type: MAP_CLEAR_ASSETCLASS_ATTRIBUTE,
    });
  };
}
// ---------------------------------------------------------------------
export function getAssetClassAttributeAjax(assetclassid) {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    };
  }
  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  let queryStr = `?assetclassid=${assetclassid}`;
  return (dispatch) =>
    axios({
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETCLASS_ATTRIBUTES}${queryStr}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        uTk: base64Encode(JSON.stringify(userTokenObj)),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return null;
        }
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
}

const addUpdateAttributePropType = {
  payload: PropTypes.shape({
    id: PropTypes.number.isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.object,
    }),
  }),
};

export const addDeviceAttribute = (payload) => (dispatch) =>
  dispatch({ type: DEVICE_ADD_ATTR_DEVICE, payload });
export const updateDeviceAttribute = (payload) => (dispatch) =>
  dispatch({ type: DEVICE_UPDATE_ATTR_DEVICE, payload });
addDeviceAttribute.propTypes = addUpdateAttributePropType;
updateDeviceAttribute.propTypes = addUpdateAttributePropType;

// ---------------------------------------------------------------------
export function getThemes() {
  let localRoot = "http://192.168.2.91:8080/"; //WEB_SERVICE_ROOT
  let paramObj = {
    url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_THEMES}`,
    logText: "Get theme list Url ",
    sccessType: MAP_GET_THEME_SUCCEED,
    failedType: MAP_GET_THEME_FAILED,
    errorMessage: "Failed to get theme list.",
  };

  return axiosGet(paramObj);
}
// ---------------------------------------------------------------------
export function getGroupDeviceData(creteria) {
  console.log("getGroupDeviceData method : ", creteria);
  if (!creteria) {
    return (dispatch) => {
      dispatch({
        type: MAP_GET_DEVICE_GROUP_DATA_FAILED,
        error: "input object is null",
      });
    };
  }

  const request = axios({
    url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_GROUP_DEVICES_DATA}`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: creteria,
  });

  return (dispatch) => {
    dispatch(loadingDataStart());
    request
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: MAP_GET_DEVICE_GROUP_DATA_SUCCEED,
            payload: response.data,
          });
        } else {
          dispatch({
            type: MAP_GET_DEVICE_GROUP_DATA_FAILED,
            error: "Web Service Get Wrong Response",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: MAP_GET_DEVICE_GROUP_DATA_FAILED,
          error: error.message,
        });
      });
  };
}
export function clearGroupDeviceData() {
  console.log("clearGroupDeviceData method.");
  return (dispatch) => {
    dispatch({
      type: MAP_CLEAR_DEVICE_GROUP_DATA,
      message: "clear device group data",
    });
  };
}

export function getDevicesByGroup(creteria) {
  console.log("getDevicesByGroup method : ", creteria);
  if (!creteria) {
    return (dispatch) => {
      dispatch({
        type: MAP_GET_DEVICE_BY_GROUP_FAILED,
        error: "input object is null",
      });
    };
  }
  const request = axios({
    url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_DEVICES_BY_GROUP}`,
    header: {
      "Content-Type": "application/json",
    },
    method: "post",
    data: creteria,
  });

  return (dispatch) => {
    request
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: MAP_GET_DEVICE_BY_GROUP_SUCCEED,
            payload: response.data,
          });
        } else {
          dispatch({
            type: MAP_GET_DEVICE_BY_GROUP_FAILED,
            error: "Web Service Get Wrong Response",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: MAP_GET_DEVICE_BY_GROUP_FAILED,
          error: error.message,
        });
      });
  };
}

export function clearGroupDevices() {
  return (dispatch) => {
    dispatch({
      type: MAP_CLEAR_GROUP_DEVICES,
      message: "clear devices by group",
    });
  };
}

export function getGroupAssetData(creteria) {
  console.log("getGroupAssetData method : ", creteria);
  if (!creteria) {
    return (dispatch) => {
      dispatch({
        type: MAP_GET_ASSET_GROUP_DATA_FAILED,
        error: "input object is null",
      });
    };
  }

  const request = axios({
    url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_GROUP_ASSETS_DATA}`,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: creteria,
  });

  return (dispatch) => {
    dispatch(loadingDataStart());
    request
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: MAP_GET_ASSET_GROUP_DATA_SUCCEED,
            payload: response.data,
          });
        } else {
          dispatch({
            type: MAP_GET_ASSET_GROUP_DATA_FAILED,
            error: "Web Service Get Wrong Response",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: MAP_GET_ASSET_GROUP_DATA_FAILED,
          error: error.message,
        });
      });
  };
}

export function clearGroupAssetData() {
  console.log("clearGroupAssetData method.");
  return (dispatch) => {
    dispatch({
      type: MAP_CLEAR_ASSET_GROUP_DATA,
      message: "clear asset group data",
    });
  };
}
// ---------------------------------------------------------------------
export function loadDeviceInfoI(criteria) {
  const mockData = [
    {
      deviceid: "10464FB",
      devicename: "Alps Demo 1",
      state: 0,
      comstate: 5,
      locationlat: 0,
      locationlng: 0,
      devicetype: "5d1f5bd8c563d62829dcadb6",
      devicegroup: "5b7717430499f507697fd700",
      lqi: 2,
      creationtime: "2019-07-05T14:17:49.000+0000",
      modemcertificate: "5b460aff6025185d10252743",
      prototype: true,
      automaticrenewal: true,
      automaticrenewalstatus: 0,
      createdby: "5cab66462564322b9a5e53a5",
      lasteditiontime: "2019-07-05T14:17:49.000+0000",
      lasteditedby: "5cab66462564322b9a5e53a5",
      activable: true,
      lastmessagetime: "2019-07-05T14:17:49.000+0000",
      lastseqnumber: 0,
      geofence: 0,
    },
    {
      deviceid: "10464FD",
      devicename: "Alps Demo 2",
      state: 0,
      comstate: 5,
      locationlat: 0,
      locationlng: 0,
      devicetype: "5d1f5bd8c563d62829dcadb6",
      devicegroup: "5b7717430499f507697fd700",
      lqi: 4,
      creationtime: "2019-07-05T14:18:46.000+0000",
      modemcertificate: "5b460aff6025185d10252743",
      prototype: true,
      automaticrenewal: true,
      automaticrenewalstatus: 1,
      createdby: "5cab66462564322b9a5e53a5",
      lasteditiontime: "2019-07-05T14:18:46.000+0000",
      lasteditedby: "5cab66462564322b9a5e53a5",
      activable: true,
      lastmessagetime: "2019-07-05T14:18:46.000+0000",
      lastseqnumber: 0,
      geofence: 0,
    },
    {
      deviceid: "11D4E1E",
      devicename: "Unaprotect",
      state: 0,
      comstate: 5,
      locationlat: 0,
      locationlng: 0,
      devicetype: "5d1cec02e833d91f989067f7",
      devicegroup: "5b7717430499f507697fd700",
      lqi: 2,
      creationtime: "2019-07-03T18:13:52.000+0000",
      modemcertificate: "59c01eb7c55ce63e6811fbe7",
      prototype: false,
      automaticrenewal: true,
      automaticrenewalstatus: 0,
      createdby: "5cab66462564322b9a5e53a5",
      lasteditiontime: "2019-07-03T18:13:52.000+0000",
      lasteditedby: "5cab66462564322b9a5e53a5",
      activable: true,
      lastmessagetime: "2019-07-03T18:13:52.000+0000",
      lastseqnumber: 0,
      geofence: 0,
    },
  ];

  return (dispatch) => {
    dispatch({
      type: ASSET_LOAD_DEVICE_SUCCEED,
      payload: mockData,
    });
  };
}
