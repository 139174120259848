/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
// import { toast } from "react-toastify";  
import { Col, Row, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { EditTextButton } from '../../../shared/components/Button/IconButton';
import { hasRole, getCurrentUser } from '../../../factories/auth';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { ThreeSixtySharp } from '@material-ui/icons';
import styles from './InvoicingSteps/SelectQuantity.module.css';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class ClientInventoryBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showItemModal: false,
            showOrderModal: false,
            simId: 0,
            simPrice: 0,
            simItemDescription: "",
            simItemName: "",
            carrier: -1,
            itemParentId: -1,
            orders: null,

            deviceDescription: "",
            downlinkMode: 2,
            downlinkData: "",
            payloadType: 2,
            payloadConfig: "",
            keepAlive: 3600,
            alertEmail: "",
            autoRenew: true,
            geolocPayloadConfigId: "",
        };
    }
    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 365) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }
    cellItemEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={''} onClick={(e) => { this.onEditItemOpen(row) }} />
            </div>
        )
    }
    cellItemDeleteButton = (cell, row) => {
        return (
            <div>
                <ConfirmDeleteDialog
                    label={""}
                    onConfirm={(e) => { this.onDeleteItem(row); }}
                    msg={'Do you want to delete the select type ? '}
                />
            </div>
        )
    }
    onQuantityChange = (e, cell, row, enumObject, index) => {
        let newOrders = new Map();
        const regex = /^[1-9]+[0-9]*$/;
        if (e.target.value == '' || regex.test(e.target.value)) {
            this.state.orders.forEach((value, key) => {
                if (key == row.itemId)
                    newOrders.set(key, {
                        "name": row.itemName,
                        "unitPrice": row.unitPrice,
                        "quantity": e.target.value
                    });
                else
                    newOrders.set(key, value);
            });
            this.setState({
                orders: newOrders
            });
        }
    }
    SetQuantityButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <Input
                    name="quantity"
                    id="quantity"
                    placeholder=""
                    className="form-control"
                    value={(this.state.orders.get(row.itemId)) ? this.state.orders.get(row.itemId).quantity : ""}
                    onChange={(e) => { this.onQuantityChange(e, cell, row, enumObject, index) }}
                />
            </div>
        )
    }
    onOrderOpen = () => {
        let orders = new Map();
        this.props.SIMTypeList.map(e => {
            orders.set(e.itemId, {
                "name": e.itemName,
                "unitPrice": e.unitPrice,
                "quantity": ""
            });
        })

        this.setState({
            showOrderModal: true,
            orders: orders
        })
    }
    onItemOpen = () => {
        if (this.props.typeList.length > 0) {
            var parent_item = this.props.typeList[0];
            this.setState({
                showItemModal: true,
                simId: 0,
                companyCode: this.props.userObj.companyCode,
                simItemDescription: parent_item.itemDescription,
                simPrice: "",
                simItemName: "",
                carrier: parent_item.carrier.carrierId,
                itemParentId: parent_item.itemId,
            });
        } else {
            this.setState({
                showItemModal: true,
                simId: 0,
                companyCode: this.props.userObj.companyCode,
                simItemDescription: "",
                simPrice: "",
                simItemName: "",
                carrier: -1,
                itemParentId: -1
            });

        }
        this.setState({
            deviceDescription: "",
            downlinkMode: 2,
            downlinkData: "",
            payloadType: 2,
            payloadConfig: "",
            keepAlive: 3600,
            alertEmail: "",
            autoRenew: true,
            geolocPayloadConfigId: "",
        })
    }
    onEditItemOpen = (row) => {
        this.setState({
            showItemModal: true,
            simId: row.itemId,
            companyCode: this.props.userObj.companyCode,
            simItemDescription: row.itemDescription,
            simPrice: row.unitPrice,
            simItemName: row.itemName,
            carrier: row.carrier.carrierId,
            itemParentId: row.itemParentId,
        })

        row.extensions.map(item => {
            item.type == 'description' ? this.setState({ deviceDescription: item.description }) : null
            item.type == 'downlinkMode' ? this.setState({ downlinkMode: parseInt(item.description) }) : null
            item.type == 'dataString' ? this.setState({ downlinkData: item.description }) : null
            item.type == 'payloadType' ? this.setState({ payloadType: parseInt(item.description) }) : null
            item.type == 'payloadConfig' ? this.setState({ payloadConfig: item.description }) : null
            item.type == 'keepAlive' ? this.setState({ keepAlive: parseInt(item.description) }) : null
            item.type == 'alertEmail' ? this.setState({ alertEmail: item.description }) : null
            item.type == 'autoRenew' ? this.setState({ autoRenew: JSON.parse(item.description) }) : null
            item.type == 'geolocPayloadConfigId' ? this.setState({ geolocPayloadConfigId: item.description }) : null
        })

    }
    onDeleteItem = (row) => {
        let data = {
            "id": row.itemId,
            "companyCode": this.props.userObj.companyCode,
            "resellerId": row.resellerId,
            "itemName": row.itemName,
            "itemDescription": row.itemDescription,
            "unitPrice": row.unitPrice,
            "carrierId": row.carrier.carrierId,
            "companyId": row.companyId
        }
        this.props.DeleteDeviceType(data);
    }
    onItemClose = () => {
        this.setState({
            showItemModal: false
        })
    }

    onOrderClose = () => {
        this.setState({
            showOrderModal: false
        })
    }

    onInputChange = (event) => {
        if (event.target.name == "itemParentId") {
            const parentItem = this.props.typeList.filter(value => value.itemId == event.target.value)
            if (parentItem.length > 0) {
                const carrierObj = parentItem[0].carrier;
                this.setState({
                    [event.target.name]: event.target.value,
                    carrier: carrierObj.carrierId,
                    simItemDescription: parentItem[0].itemDescription

                });
            } else {
                this.setState({
                    [event.target.name]: event.target.value,
                    carrier: -1,
                    simItemDescription: ""

                });
            }

        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    };

    onAutoRenewChange = (event) => {
        this.setState({"autoRenew": event.target.checked})
    }

    validateForm = (Management) => {
        if (Management == "type") {
            if (this.state.itemParentId >= 0 && this.state.simItemName != "" && !isNaN(this.state.simPrice)) {
                const digitreg = /^[0-9\b]+$/;
                const emailreg = /[\w-]+@([\w-]+\.)+[\w-]+/;
                if (this.props.pageContentList.includes("Client Inventory Description Input")) {
                    if (this.state.deviceDescription == "") {
                        return false
                    }
                }
                if (this.props.pageContentList.includes("Client Inventory Keep-Alive Input")) {
                    if (!digitreg.test(this.state.keepAlive)) {
                        return false
                    }
                }
                if (this.props.pageContentList.includes("Client Inventory Alert Email Input")) {
                    if (!emailreg.test(this.state.alertEmail)) {
                        return false
                    }
                }

                if (
                    this.state.deviceDescription.length <= 40
                    && this.state.simItemName.length <= 40
                    && this.state.alertEmail.length <= 40
                    && this.state.downlinkData.length <= 40
                    && this.state.payloadConfig.length <= 40
                    && this.state.geolocPayloadConfigId.length <= 40
                    && parseFloat(this.state.simPrice) >= 0 && parseFloat(this.state.simPrice) <= 1000000) { // set a million price limit
                    return true;
                }
            }
        }
        if (Management == "order") {
            if (this.state.orders) {
                let valid = false;
                this.state.orders.forEach((value, key) => {
                    if (parseInt(value.quantity) > 0 && parseInt(value.quantity) < 1000000 && !isNaN(value.quantity))
                        valid = true;
                });
                return valid;
            }
        }

        return false;
    }
    onOrderSubmit = () => {
        var data = {};
        data = {
            "companyId": this.props.companyId,
            "resellerId": this.props.reseller.id,
            "parent_resellerId": 0, //Parent reseller not implemented yet
            "orderList": Array.from(this.state.orders.values()).filter(device => device.quantity > 0)
        }
        this.props.EmailPurchaseOrder(data);
        this.onOrderClose();
    }
    onItemSubmit = () => {
        var data = {};
        if (this.state.simId > 0) {
            data = {
                "id": this.state.simId,
                "itemParentId": this.state.itemParentId,
                "companyCode": this.props.userObj.companyCode,
                "resellerId": this.props.reseller.id,
                "itemName": this.state.simItemName,
                "itemDescription": this.state.simItemDescription,
                "unitPrice": this.state.simPrice,
                "carrierId": this.state.carrier,
                "companyId": this.props.selectedRow.companyId
            }
        }
        else {
            data = {
                "itemParentId": this.state.itemParentId,
                "companyCode": this.props.userObj.companyCode,
                "resellerId": this.props.reseller.id,
                "itemName": this.state.simItemName,
                "itemDescription": this.state.simItemDescription,
                "unitPrice": this.state.simPrice,
                "carrierId": this.state.carrier,
                "companyId": this.props.selectedRow.companyId

            }
        }

        this.props.pageContentList.includes("Client Inventory Description Input") ? data["deviceDescription"] = this.state.deviceDescription : null
        this.props.pageContentList.includes("Client Inventory Keep-Alive Input") ? data["keepAlive"] = this.state.keepAlive : null
        this.props.pageContentList.includes("Client Inventory Auto Renewal Input") ? data["autoRenew"] = this.state.autoRenew : null
        this.props.pageContentList.includes("Client Inventory Alert Email Input") ? data["alertEmail"] = this.state.alertEmail : null
        this.props.pageContentList.includes("Client Inventory Downlink Mode Input") ? data["downlinkMode"] = this.state.downlinkMode : null
        this.props.pageContentList.includes("Client Inventory Downlink Data Input") ? data["dataString"] = this.state.downlinkData : null
        this.props.pageContentList.includes("Client Inventory Payload Paring Input") ? data["payloadType"] = this.state.payloadType : null
        this.props.pageContentList.includes("Client Inventory Payload Configuration Input") ? data["payloadConfig"] = this.state.payloadConfig : null
        this.props.pageContentList.includes("Client Inventory Geoloc Payload Configuration Input") ? data["geolocPayloadConfigId"] = this.state.geolocPayloadConfigId : null


        this.props.SetDeviceType(data);
        this.onItemClose();
    }

    parentFormatter = (cell, row) => {
        for (let type of this.props.typeList) {
            if (cell == type.itemId) {
                return type.itemName
            }
        }
    }

    renderTypeModal() {
        const downlinkModeOptions = [{ id: 0, name: "DIRECT" }, { id: 1, name: "CALLBACK" }, { id: 2, name: "NONE" }, { id: 3, name: "MANAGED" }]
        const payloadParingOptions = [{ id: 2, name: "Regular" }, { id: 3, name: "Custom grammer" }, { id: 4, name: "Geolocation" }, { id: 5, name: "Display in ASCII" }, { id: 9, name: "Sensitve" }]

        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showItemModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onItemClose}>{(this.state.simId != 0) ? `Customize` : `Add New`}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Type<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='itemParentId'
                                    id='itemParentId'
                                    placeholder='itemParentId'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    value={this.state.itemParentId}
                                >
                                    <option key={-1} value={-1}>None Selected</option>
                                    {this.props.typeList.map((type, index) => (
                                        (this.state.itemParentId == type.itemId) ? <option key={index} value={type.itemId} selected>{type.itemName}</option> : <option key={index} value={type.itemId}>{type.itemName}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row hidden>
                            <Col md={2} >
                                <span>Carrier <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrier'
                                    id='carrier'
                                    placeholder='carrier'
                                    className="form-control"
                                    disabled={true}
                                    onChange={this.onInputChange}
                                    value={this.state.carrier}
                                >
                                    <option key={-1} value={-1}>None Selected</option>
                                    {this.props.carrierList.map((carrier, index) => (
                                        (this.state.carrier == carrier.carrierId) ? <option key={index} value={carrier.carrierId} selected>{carrier.carrierName}</option> : <option key={index} value={carrier.carrierId}>{carrier.carrierName}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2} >
                                <span>Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simItemName'
                                    id='simItemName'
                                    placeholder='Item Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.simItemName}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>

                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Price <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simPrice'
                                    id='simPrice'
                                    placeholder='Price'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.simPrice}
                                />

                            </Col>
                        </Row>
                        <br />

                        <Row hidden={this.props.pageContentList.includes("Client Inventory Description Input") ? false : true}>
                            <Col md={2} >
                                <span>Description <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='deviceDescription'
                                    id='deviceDescription'
                                    placeholder='Device Description'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.deviceDescription}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Description Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Keep-Alive Input") ? false : true}>
                            <Col md={2} >
                                <span>Keep-Alive <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='keepAlive'
                                    id='keepAlive'
                                    placeholder='Keep-Alive'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.keepAlive}
                                />
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Keep-Alive Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Auto Renewal Input") ? false : true}>
                            <Col md={2} >
                                <span>Auto Renewal <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={1}>
                                <div style={{ display: 'inline-flex' }}>
                                    <Input
                                        type='checkbox'
                                        name='autoRenew'
                                        id='autoRenew'
                                        placeholder='Auto Renewal'
                                        onChange={this.onAutoRenewChange}
                                        className="form-control"
                                        checked={this.state.autoRenew}
                                        style={{ accentColor: '#000000', width: '20px', marginTop: '-10px', marginLeft: '0px' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Auto Renewal Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Alert Email Input") ? false : true}>
                            <Col md={2} >
                                <span>Alert Email <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='alertEmail'
                                    id='alertEmail'
                                    placeholder='Alert Email'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.alertEmail}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Alert Email Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Downlink Mode Input") ? false : true}>
                            <Col md={2} >
                                <span>Downlink Mode <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='downlinkMode'
                                    id='downlinkMode'
                                    placeholder='Downlink Mode'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.downlinkMode}
                                >
                                    {downlinkModeOptions.map((option) => {
                                        return <option key={option.id} value={option.id} selected={option.id == this.state.downlinkMode ? true : false}>{option.name}</option>
                                    })}
                                </Input>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Downlink Mode Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Downlink Data Input") ? false : true}>
                            <Col md={2} >
                                <span>Downlink Data</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='downlinkData'
                                    id='downlinkData'
                                    placeholder='Downlink Data'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.downlinkData}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Downlink Data Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Payload Paring Input") ? false : true}>
                            <Col md={2} >
                                <span>Payload Paring <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type="select"
                                    name='payloadType'
                                    id='payloadType'
                                    placeholder=''
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.payloadType}
                                >
                                    {payloadParingOptions.map((option) => {
                                        return <option key={option.id} value={option.id} selected={option.id == this.state.payloadType ? true : false}>{option.name}</option>
                                    })}
                                    <option key={2} value={2} selected>Regular</option>
                                    <option key={3} value={3} >Custom grammer</option>
                                    <option key={4} value={4} >Geolocation</option>
                                    <option key={5} value={5} >Display in ASCII</option>
                                    <option key={9} value={9} >Sensitve</option>
                                </Input>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Payload Paring Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Payload Configuration Input") ? false : true}>
                            <Col md={2} >
                                <span>Payload Configuration</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='payloadConfig'
                                    id='payloadConfig'
                                    placeholder='Payload Configuration'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.payloadConfig}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Payload Configuration Input") ? false : true} />
                        <Row hidden={this.props.pageContentList.includes("Client Inventory Geoloc Payload Configuration Input") ? false : true}>
                            <Col md={2} >
                                <span>Geolocation Payload Configuration</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='geolocPayloadConfigId'
                                    id='geolocPayloadConfigId'
                                    placeholder='Geolocation Payload Configuration'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.geolocPayloadConfigId}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <br hidden={this.props.pageContentList.includes("Client Inventory Geoloc Payload Configuration Input") ? false : true} />
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-withhover" outline color='custom' type="reset" onClick={this.onItemSubmit} disabled={!this.validateForm("type")}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onItemClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
    renderOrderModal() {
        const options = {
            sizePerPageList: [{
                text: 10, value: 10
            }, {
                text: 50, value: 50
            }, {
                text: 100, value: 100
            }, {
                text: 500, value: 500
            }]
        };

        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showOrderModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onOrderClose}>Order Device(s)</ModalHeader >
                <ModalBody >

                    <BootstrapTable
                        data={this.props.SIMTypeList}
                        tableHeaderClass='header_white'
                        bordered={false}
                        height='100%'
                        options={options}
                    >
                        <TableHeaderColumn row='0' rowSpan='1' dataField='itemParentId' caretRender={sortCaret} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Type' }} dataSort csvHeader='itemParentId' className={styles.simpurchaseth} hidden>itemParentId</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' isKey={true} caretRender={sortCaret} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort csvHeader='SIM Name' className={styles.simpurchaseth}>Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' caretRender={sortCaret} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Description' }} dataSort csvHeader='SIM Description' className={styles.simpurchaseth}>Description</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' caretRender={sortCaret} dataFormat={this.priceFormatter.bind(this)} dataAlign='center' dataSort filter={{ type: 'NumberFilter', delay: 1000 }} csvHeader='Price' className={styles.simpurchaseth}>Price</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='quantity'
                            dataAlign="center"
                            dataFormat={this.SetQuantityButton.bind(this)}
                            export={false}
                            className={styles.simpurchaseth}
                        // hidden={ !hasRole(this.props.userObj, ['SUPERADMIN','ADMIN']) || !this.props.pageContentList.includes("Client Inventory Operations") ? true : false}
                        >
                            Quantity
                        </TableHeaderColumn>
                    </BootstrapTable>

                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="reset" onClick={this.onOrderSubmit} disabled={!this.validateForm("order")}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onOrderClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
    renderPaginationPanel = (props) => {
        let dropdown = props.components.sizePerPageDropdown;
        let beginning;
        let totalData;
        beginning = (props.currPage - 1) * dropdown.props.currSizePerPage + 1;
        totalData = (parseInt(beginning) + parseInt(dropdown.props.currSizePerPage)) - 1;
        return (
            <>
                {
                    (this.props.SIMTypeList.length == 0) ?
                        <>
                            <Col md={12} xs={12} sm={12} lg={12} style={{ display: "block", fontSize: "14px" }}>
                                <span style={{ float: "right" }}> 0 - 0 of 0</span>
                            </Col>
                        </>
                        : <>
                            <Col md={6} xs={6} sm={6} lg={6}>
                                {props.components.sizePerPageDropdown}
                            </Col>
                            <Col md={6} xs={6} sm={6} lg={6} style={{ display: "block", fontSize: "14px" }}>

                                <span style={{ float: "right" }}>{props.components.pageList}</span>
                                <span style={{ float: "right", fontSize: "14px", verticalAlign: "text-top", marginRight: "10px", marginTop: "9px" }}> {beginning} - {totalData} of {this.props.SIMTypeList.length}</span>
                            </Col>

                        </>

                }
            </>
        );
    }
    render() {
        //        console.log("this.props.reseller:",this.props.reseller)

        const options = {
            sizePerPageList: [{
                text: 10, value: 10
            }, {
                text: 50, value: 50
            }, {
                text: 100, value: 100
            }, {
                text: 500, value: 500
            }],
            paginationPanel: this.renderPaginationPanel

        }
        return (
            <div>
                {this.renderTypeModal()}
                {this.renderOrderModal()}
                <Card>
                    <CardBody className="mainCardBody">
                        {
                            (hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']) && this.props.pageContentList.includes("Client Add Device Type")) ?
                                <Button color='custom' type="button" onClick={this.onItemOpen}>
                                    <PlusIcon /> Add New
                                </Button>
                                :
                                null
                        }
                        {
                            (this.props.pageContentList.includes("Client Order Device Type")) ?
                                <Button color='custom' type="button" onClick={this.onOrderOpen}>
                                    <PlusIcon /> Order Device(s)
                                </Button>
                                :
                                null
                        }
                        <BootstrapTable
                            data={this.props.SIMTypeList}
                            tableHeaderClass='header_white'
                            hover
                            bordered={false}
                            height='100%'
                            exportCSV
                            csvFileName='SIM_Card_Types.csv'
                            pagination
                            options={options}
                        >
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemParentId' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Type' }} hidden dataSort export={hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']) ? true : false} csvFormat={this.parentFormatter.bind(this)} csvHeader='Type'>itemParentId</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemId' dataAlign='center' isKey={true} filter={{ type: 'TextFilter', placeholder: 'Id' }} hidden dataSort csvHeader='SIM Id'>ID</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort caretRender={sortCaret} csvHeader='Name'>Name</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Description' }} dataSort caretRender={sortCaret} csvHeader='Description'>Description</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' csvFormat={this.priceFormatter.bind(this)} dataFormat={this.priceFormatter.bind(this)} dataAlign='center' dataSort caretRender={sortCaret} filter={{ type: 'NumberFilter', delay: 1000 }} csvHeader='Price'>Price</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='Operations'
                                dataAlign="center"
                                dataFormat={this.cellItemEditButton.bind(this)}
                                export={false}
                                hidden={!hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']) || !this.props.pageContentList.includes("Client Inventory Operations") ? true : false}
                            >
                                Operations
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='action'
                                dataAlign="center"
                                width="10%"
                                dataFormat={this.cellItemDeleteButton.bind(this)}
                                export={false}
                                hidden={!hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']) }
                            >
                                Delete
                            </TableHeaderColumn>
                        </BootstrapTable>


                    </CardBody>
                </Card>
            </div>

        )
    };
}
export default ClientInventoryBoard;