/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import { Card, CardBody, Col } from 'reactstrap';
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { loadDevice, loadDeviceInfo, loadDivceMapData, getAssetsData } from '../../../../redux/actions/mapActions';
import { getActionLog } from '../../../../redux/actions/jobAction';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
// import {groupBy} from '../../../factories/utils';
import { Bar } from 'react-chartjs-2';
import { HorizontalBar } from 'react-chartjs-2';
import DashboardMap from './DashboardMap';
import DasBoardEditModal from './DashBoardEditModal';
import AssetMapWidget from './AssetMapWidget';
import AssetTableWidget from './AssetTableWidget';
import DeviceTableWidget from './DeviceTableWidget';
import DeviceCountChartWidget from './DeviceCountChartWidget';
import DeviceUsageDonutWidget from './DeviceUsageDonutWidget';
import DeviceMsgChartWidget from './DeviceMsgChartWidget';
import SubscribeValueWidget from './SubscribeValueWidget';
import AttrValueWidget from './AttrValueWidget';
import AssetWidget from './AssetWidget';
import AttrChartWidget from './AttrChartWidget';
import { loadDashBoardLayout, updateDashBoardLayout, loadDashBoardLayoutList } from '../../../../redux/actions/dashboardActions';
import { groupBy } from '../../../../factories/utils';
import PlaylistEditIcon from 'mdi-react/PlaylistEditIcon'
import GridLayout from 'react-grid-layout';
// import { Responsive, WidthProvider } from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";
import BasicDashboadEditIcon from './BasicDashboadEditIcon';
// import SimplePackDeviceEditIcon from './SimplePackDeviceEditIcon';
// import {SquareEditOutlineIcon} from 'mdi-react';
import { WEB_SUB_CHANNEL, WEB_SUB_EVENT } from '../../../../constants/appConstants';
import {
  deleteDashBoardTemplateById, deleteDashBoardTemplateEntryById,
  getDashBoardTemplateLayoutById,
  getDashBoardTemplateUserLists, updateDashBoardTemplateLayout
} from "../../../../redux/actions/dashboardTemplateAction";

import { CheckIcon, EditIcon } from 'mdi-react';

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReactGridLayout = WidthProvider(RGL);


class DashboardCard extends Component {
  constructor(props) {

    super(props);
    console.log('props in constructor', this.props)
    console.log('this.props.dashboardTemplateLayout in constructor', this.props.dashboardTemplateLayout)

    //this.finalResponse = [...this.props.dashboardTemplateLayout]

    // this.props.dashboardTemplateLayout.forEach((item, index) => {
    //   this.props.dashboardTemplateLayout[index].layoutid = item.id;
    // });
    // console.log('finalResponse initial in constructor',this.finalResponse)
    this.state = {
      idDeleted: null,
      dashBoardModal: false,
      widgetEditModal: false,
      dropdownOpen: false,
      closeAll: false,
      currentLayoutName: 'My Layout',
      layoutList: [
        { layoutname: 'My Layout', layoutid: 1 },
        { layoutname: 'Template 1', layoutid: 2 },
        { layoutname: 'Template 3', layoutid: 3 }
      ],
      mapMockData: [
        {
          devicename: 'devcie1',
          locationlat: 43.8148024,
          locationlng: -79.5509472,
          msg: { speed: 10, temp: 20, vbat: 1 }
        },
        {
          devicename: 'devcie1',
          locationlat: 43.8138024,
          locationlng: -79.5529472,
          msg: { speed: 10, temp: 20, vbat: 1 }
        },
        {
          devicename: 'devcie1',
          locationlat: 43.8128024,
          locationlng: -79.5549472,
          msg: { speed: 10, temp: 20, vbat: 1 }
        }
      ],
      mapProps: {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: { attr: {} },
      },
      dashBoardSet: [
        {
          title: 'Map',
          hidden: false,
          render: this.rendermapcard,
          type: 'map',
          width: 7,
          layout: { x: 0, y: 0, w: 5, h: 5 }
        },
        {
          title: 'Alert',
          hidden: false,
          render: this.renderAlertTable,
          type: 'table',
          width: 6,
          layout: { x: 5, y: 0, w: 5, h: 5 }
        },
        {
          title: 'Action',
          hidden: true,
          render: this.renderActionTable,
          type: 'table',
          width: 5,
          layout: { x: 0, y: 5, w: 5, h: 5 }
        },
        {
          title: 'Total Asset',
          hidden: false,
          render: this.renderAssetTable,
          type: 'table',
          width: 6,
          layout: { x: 5, y: 5, w: 5, h: 5 }
        },
        {
          title: 'Device Type Usage',
          hidden: false,
          render: this.renderDeviceTypeChart,
          type: 'chart',
          width: 6,
          layout: { x: 0, y: 10, w: 5, h: 5 }
        },
      ],
      currentLayout: [
        {
          name: "Asset Map",
          type: "AssetMap",
          display: 1,
          width: 12,
          position: '{ "w": 9, "h": 15, "x": 0, "y": 0 }',
          parameter: { assetid: "1" },
          company_code: "002",
          layoutid: 2,
          itemid: 2,
          userid: "001"
        },
        {
          name: "Alert",
          type: "AlertTable",
          display: 1,
          width: 6,
          position: '{"w": 6, "h": 15, "x": 6, "y": 30}',
          parameter: { assetid: "7" },
          company_code: "002",
          layoutid: 3,
          itemid: 0,
          userid: "001"
        },
        {
          name: "Action",
          type: "ActionTable",
          display: 0,
          width: 5,
          position: '{"w": 6, "h": 15, "x": 0, "y": 45}',
          parameter: { assetid: "7" },
          company_code: "002",
          layoutid: 4,
          itemid: 0,
          userid: "001"
        },
        {
          name: "Device",
          type: "DeviceTable",
          display: 1,
          width: 6,
          position: '{"w": 6, "h": 15, "x": 0, "y": 15}',
          parameter: { devicetype: "5cf828390499f50b995ae851" },
          company_code: "002",
          layoutid: 5,
          itemid: 0,
          userid: "001"
        },

        {
          name: "Device Chart",
          type: "DeviceChart",
          display: 1,
          width: 6,
          position: '{"w": 6, "h": 15, "x": 6, "y": 15}',
          parameter: {},
          company_code: "002",
          layoutid: 7,
          itemid: 0,
          userid: "001"
        },
        {
          name: "Asset",
          type: "AssetTable",
          display: 1,
          width: 12,
          position: '{"w": 6, "h": 15, "x": 0, "y": 30}',
          parameter: { assetid: "1" },
          company_code: "002",
          layoutid: 6,
          itemid: 6,
          userid: "001"
        },
        {
          name: "Device Usage",
          type: "DviceUsage",
          display: 1,
          width: 3,
          position: '{"w": 3, "h": 7, "x": 9, "y": 0}',
          parameter: {},
          company_code: "002",
          layoutid: 6,
          itemid: 6,
          userid: "001"
        },
      ],
    }

    console.log('this.state in constructor')
    console.log(this.state)
    this.interval = null;
    // this.eventSource = new EventSource(WEB_SUB_CHANNEL);
  }

  componentDidMount() {
    // this.props.loadDeviceInfo();
    // this.props.loadDivceMapData({assetid: 5,latest: 1});
    // this.props.getAssetsData({assetclassid: 1, latest: 1});
    // this.props.loadDeviceInfo();
    //  
    // this.props.loadDashBoardLayout('001');
    console.log('this.props.templateId', this.props.templateId)
    this.props.getDashBoardTemplateLayoutById(this.props.templateId);
    console.log('props layout in did mount', this.props.currentLayout)
    this.props.loadDashBoardLayout();
    this.props.loadDashBoardLayoutList();
    this.props.getActionLog();

    // this.interval = setInterval(() => { 

    // this.eventSource.addEventListener(WEB_SUB_EVENT, (e)=> {
    //    
    //    
    //   // this.props.updatePubSubDevice(e.data) ;
    //   this.pubSubUpdate(e.data);
    // })
  }

  componentWillUnmount() {

    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentDeviceList && nextProps.currentDeviceList !== this.state.rawCurrentDeviceList) {
      const grouped = groupBy(nextProps.currentDeviceList, device => device.devicetypename);


      const keys = Array.from(grouped.keys());

      let values = [];
      keys.forEach((key) => {
        values.push(grouped.get(key).length);
      });
      this.setState({
        chartlabel: keys,
        chartdata: values,
        rawCurrentDeviceList: nextProps.currentDeviceList
      });
    }
  }
  // static getDerivedStateFromProps(props, state) {

  //   function groupBy(list, keyGetter) {
  //     const map = new Map();
  //     list.forEach((item) => {
  //          const key = keyGetter(item);
  //          const collection = map.get(key);
  //          if (!collection) {
  //              map.set(key, [item]);
  //          } else {
  //              collection.push(item);
  //          }
  //     });
  //     return map;
  //   }

  //   if (props.currentDeviceList && props.currentDeviceList !== state.rawCurrentDeviceList){
  //     const grouped = groupBy(props.currentDeviceList , device => device.devicetypename);
  //      
  //      
  //     const keys = Array.from(grouped.keys());
  //      
  //     let values = [];
  //     keys.forEach((key) =>{
  //       values.push(grouped.get(key).length);
  //     });
  //     return ({
  //       chartlabel : keys,
  //       chartdata : values,
  //       rawCurrentDeviceList: props.currentDeviceList
  //     });
  //   }else{
  //     return state;
  //   }
  // }

  pubSubUpdate = (data) => {
    this.setState({
      pubSubData: data,
    });
  }

  modalToggle = () => {
    this.setState(prevState => ({
      dashBoardModal: !prevState.dashBoardModal,
    }));
  }

  editModalToggle = () => {

    this.setState(prevState => ({
      widgetEditModal: !prevState.widgetEditModal,
    }));
  }

  toggleAll = () => {
    this.setState(prevState => ({
      widgetEditModal: !prevState.widgetEditModal,
      closeAll: true,
    }));
  }

  onDashBoardUpdateClick = (dashBoardList) => {


    console.log(dashBoardList)
    this.props.updateDashBoardTemplateLayout(dashBoardList, this.props.templateId);
  }

  renderAssetTable = () => {
    const deviceIdLinkFormatter = (cell, row) => {
      let path = `/pages/device/${cell}`;
      return (
        // <a href={path} onClick = {() => {
        //                                   // alert(cell);
        //                                   // alert(row['devicename']);
        //                                 }}>{cell}</a>
        //  <Button color="link" onClick = {() => {
        //                                             this.props.history.push(path);
        //                                         }}>{cell}</Button>
        <Link to={path}>{cell}</Link>
      )
    }

    const options = {
      // page: 1,  // which page you want to show as default
      // sizePerPageList: [ {
      //   text: '5', value: 5
      // }, {
      //   text: '10', value: 10
      // }, {
      //   text: 'All', value: this.props.currentDeviceList.length
      // } ], // you can change the dropdown list for size per page
      // sizePerPage: 5,  // which size per page you want to locate as default
      // pageStartIndex: 1, // where to start counting the pages
      // paginationSize: 5,  // the pagination bar size.
      // prePage: '<', // Previous page button text
      // nextPage: '>', // Next page button text
      // firstPage: '<<', // First page button text
      // lastPage: '>>', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    return (
      <div>
        {/* <Col > */}
        {/* <Card>
              <CardBody> */}
        <BootstrapTable data={this.props.currentDeviceList} tableHeaderClass='header_white' options={options} bordered={false} search maxHeight='450' >
          <TableHeaderColumn dataField='deviceid' isKey={true} dataAlign="center" dataFormat={deviceIdLinkFormatter}  >Device ID</TableHeaderColumn>
          <TableHeaderColumn dataField='devicename' dataAlign="center" dataSort  >Device Name</TableHeaderColumn>
          <TableHeaderColumn dataField='devicetypename' dataAlign="center" dataSort  >Device Type</TableHeaderColumn>
          <TableHeaderColumn dataField='lastmessagetime' dataAlign="center" dataSort >Last Message Time</TableHeaderColumn>
        </BootstrapTable>
        {/* </CardBody>
          </Card> */}
        {/* </Col> */}
      </div>
    );
  }

  timeColFormater = (cell, row) => {
    let time = ''
    if (cell) {
      // time = cell.split('.')[0].replace('T', ' ');
      // time = new Date(cell).toLocaleString('en-US', { timeZone: 'America/New_York' });
      time = new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' });
    }

    return (
      <span>{time}</span>
    )
  }

  renderAlertTable = (layout) => {
    const options = {
      // page: 1,  // which page you want to show as default
      // sizePerPageList: [ {
      //   text: '5', value: 5
      // }, {
      //   text: '10', value: 10
      // }, {
      //   text: 'All', value: this.props.currentDeviceList.length
      // } ], // you can change the dropdown list for size per page
      // sizePerPage: 3,  // which size per page you want to locate as default
      // pageStartIndex: 1, // where to start counting the pages
      // paginationSize: 5,  // the pagination bar size.
      // prePage: '<', // Previous page button text
      // nextPage: '>', // Next page button text
      // firstPage: '<<', // First page button text
      // lastPage: '>>', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    let actionLogs = [];
    if (this.props.actionLogs) {
      actionLogs = this.props.actionLogs.reverse();
    }
    let maxHeight = JSON.parse(layout.position).h * 30 - 20;

    return (
      <Col md={12}>
        {/* <Card>
              <CardBody> */}
        <BootstrapTable data={actionLogs} tableHeaderClass='header_white' pagination={false} options={options} search bordered={false} maxHeight={maxHeight.toString()}>
          <TableHeaderColumn dataField='actionid' isKey={true} dataAlign="center" width='15%' hidden >Action ID</TableHeaderColumn>
          {/* <TableHeaderColumn dataField='assetname' dataAlign="center" dataSort>Asset Name</TableHeaderColumn>                        */}
          <TableHeaderColumn dataField='alerttime' dataAlign="center" dataFormat={this.timeColFormater} dataSort >Alert Time</TableHeaderColumn>
          <TableHeaderColumn dataField='alertmsg' dataAlign="center" dataSort>Message</TableHeaderColumn>
        </BootstrapTable>
        {/* </CardBody>
          </Card> */}
      </Col>
    );
  }

  renderActionTable = (layout) => {
    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: this.props.currentDeviceList.length
      }], // you can change the dropdown list for size per page
      sizePerPage: 3,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    let maxHeight = JSON.parse(layout.position).h * 30 - 20;

    return (
      <Col md={12}>
        {/* <Card>
              <CardBody> */}
        <BootstrapTable data={this.props.actionList} tableHeaderClass='header_white' pagination={true} options={options} bordered={false} maxHeight={maxHeight.toString()}>
          <TableHeaderColumn dataField='deviceid' isKey={true} dataAlign="center" >Device ID</TableHeaderColumn>
          <TableHeaderColumn dataField='devicename' dataAlign="center" dataSort>Device Name</TableHeaderColumn>
          <TableHeaderColumn dataField='attributename' dataAlign="center" dataSort>Attribute Name</TableHeaderColumn>
          <TableHeaderColumn dataField='lastmessagetime' dataAlign="center">Action Time</TableHeaderColumn>
          <TableHeaderColumn dataField='message' dataAlign="center" dataSort>Message</TableHeaderColumn>
        </BootstrapTable>
        {/* </CardBody>
          </Card> */}
      </Col>
    );
  }

  renderDeviceTypeChart = () => {
    const data = {
      labels: this.state.chartlabel,
      datasets: [
        {
          label: 'Number of Devices',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 0.5,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: this.state.chartdata
        }
      ]
    };
    return (
      // <Card>
      // <CardBody>
      <div>
        {/* <h5 className="bold-text">Device Type Usage</h5> */}
        <Bar
          data={data}
          width={60}
          height={450}
          options={{ maintainAspectRatio: false }}
        />
      </div>
      // </CardBody>
      // </Card>
    );
  }

  renderhbartest() {
    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 0.5,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [65, 59, 80, 81, 56, 55, 40]
        }
      ]
    };
    return (
      <div>
        <h2>Horizontal Bar Example</h2>
        <HorizontalBar data={data} />
      </div>
    );
  }
  // -----------------------------------------------------------------------------------
  handleMapMarkerClick = (props, marker, e) => {
    //  
    //  

    //  
    this.setState({
      mapProps: {
        showingInfoWindow: true,
        activeMarker: marker,
        selectedPlace: props,
        lat: props.position.lat,
        lng: props.position.lng,
      },
    });

  }

  handleMapInfoWinClick = (props) => {
    if (this.state.mapProps.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  }
  rendermapcard = () => {
    // let data = this.props.currentDeviceList.filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
    let data = this.props.mapData; //filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
    return (
      // <DashboardMap
      //   onMarkerClick={this.handleMapMarkerClick}
      //   onInfoWinClick={this.handleMapInfoWinClick}
      //   values = {this.state.mapProps}
      //   // mapData = {this.state.mapMockData}
      //   mapData = {data}
      // />
      <AssetMapWidget assetclassId={"1"} dashName={"  "} />
    )
  }

  rendertablecard = () => {
    return (
      <AssetTableWidget assetclassId={"93"} dashName={"  "} />
    )
  }
  // ----------------------------------------------------------------
  renderDashBoad = (list) => {
    return (
      <Row>
        {/* {this.state.dashBoardSet && this.state.dashBoardSet.map((item, index) => { */}
        {list && list.map((item, index) => {
          return (
            !item.hidden &&
            // Array.isArray(item) ?
            //   this.renderDashBoad(item)
            // :
            <Col md={item.width}>
              <Card >
                <CardBody style={{ padding: 15 }}>
                  <div className="card__title">
                    <h5 className="bold-text">{item.title} </h5>
                  </div>
                  {item.render()}
                </CardBody>
              </Card>
            </Col>
          );
        })
        }
      </Row>
    );
  }
  // ----------------------------------------------------------------
  // renderDeviceWidget = () => {
  //   return(
  //     <AssetMapWidget assetId={6} dashName={"dashTest"}/>
  //   );
  // }


  onDeleteSubmit = (id) => {


    this.setState(() => ({
      idDeleted: id
    }), () => {
      this.props.deleteDashBoardTemplateEntryById(id);
    })







  }


  onEditSubmit = (newLayout) => {


    // let layoutClone = JSON.parse(JSON.stringify(this.props.currentLayout));
    let layoutClone = JSON.parse(JSON.stringify(this.props.dashboardTemplateLayout));
    // layoutClone.forEach((item,index)=>{
    //   let layoutItem = layout.find((element, lindex)=>(item.layoutid.toString() === element.i));
    //   if (layoutItem){
    //     let {w,h,x,y} = layoutItem;
    //     item.position = JSON.stringify({w,h,x,y})
    //   }
    // });


    // let selectedLayout = layoutClone.find((item, index)=>(item.layoutid === newLayout.layoutid));
    // if (selectedLayout){
    //   selectedLayout = newLayout;
    // }

    let selectedIndex = layoutClone.findIndex((item, index) => (item.id === newLayout.id && item.templateid === newLayout.templateid));
    if (selectedIndex > -1) {
      layoutClone[selectedIndex] = newLayout;
    }

    //  
    //  
    //  
    // this.props.updateDashBoardLayout(layoutClone);
    this.onDashBoardUpdateClick(layoutClone);
  }



  renderWidgets = (layout) => {
    let type = layout.type;
    let parameter = JSON.parse(layout.parameter);
    // let parameter = (layout.parameter);



    switch (type) {
      case 'AssetMap':


        return (
          // layout.display == 1 &&
          <Fragment>
            <AssetMapWidget assetclassId={parameter.assetid} dashName={layout.name}
              layout={layout}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit}
            />
          </Fragment>
        );
      case 'AssetTable':

        console.log(parameter)
        // let parameter = JSON.parse(layout.parameter);
        return (
          // layout.display === 1 &&
          <Fragment>
            <AssetTableWidget assetclassId={parameter.assetid}
              dashName={layout.name}
              layout={layout}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit}
            />
          </Fragment>
        );
      case 'AlertTable':
        //  
        return (
          // layout.display === 1 &&

          <Fragment>
            <Card >
              <CardHeader className={'dragBar'}>
                {/* <div className="card__title"> */}
                <h5 className="bold-text">
                  {layout.name}
                  {layout && 0 == layout.locked &&
                    <BasicDashboadEditIcon
                      size={17} style={{ float: 'right', display: 'inline', cursor: 'pointer' }}
                      layout={layout}
                      onEditSubmit={this.onEditSubmit}
                      onDeleteSubmit={this.onDeleteSubmit}
                    />
                  }
                </h5>
                {/* </div> */}
              </CardHeader>
              <CardBody style={{ padding: 15 }}>
                {/* <div className="card__title">
                    <h5 className="bold-text">{layout.name} </h5>
                  </div> */}
                {this.renderAlertTable(layout)}
              </CardBody>
            </Card>
          </Fragment>
        );
      case 'ActionTable':
        return (
          // layout.display === 1 &&
          <Fragment>
            <Card >
              <CardHeader className={'dragBar'}>
                {/* <div className="card__title"> */}
                <h5 className="bold-text">
                  {layout.name}
                  {layout && <BasicDashboadEditIcon
                    size={17} style={{ float: 'right', display: 'inline', cursor: 'pointer' }}
                    layout={layout}
                    onEditSubmit={this.onEditSubmit}
                    onDeleteSubmit={this.onDeleteSubmit}
                  />
                  }
                </h5>
                {/* </div> */}
              </CardHeader>
              <CardBody style={{ padding: 15 }}>
                {/* <div className="card__title">
                    <h5 className="bold-text">{layout.name} </h5>
                  </div> */}
                {this.renderActionTable(layout)}
              </CardBody>
            </Card>
          </Fragment>
        );
      case 'DeviceTable':
        return (
          // layout.display === 1 &&
          // <Fragment>
          //   <Card >
          //           <CardBody style={{padding:15}}>
          //             <div className="card__title">
          //               <h5 className="bold-text">{layout.name} </h5>
          //             </div>
          //             {this.renderAssetTable()}
          //           </CardBody>
          //         </Card>
          // </Fragment>
          <Fragment>
            <DeviceTableWidget devicetypeId={parameter.devicetype} dashName={layout.name}
              layout={layout}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit}
            />
          </Fragment>
        );
      case 'DeviceChart':
        return (
          // layout.display === 1 &&
          // <Fragment>
          //   <Card >
          //         <CardBody style={{padding:15}}>
          //           <div className="card__title">
          //             <h5 className="bold-text">{layout.name} </h5>
          //           </div>
          //           {this.renderDeviceTypeChart()}
          //         </CardBody>
          //       </Card>
          // </Fragment>
          <Fragment>
            <DeviceCountChartWidget
              dashName={layout.name}
              layout={layout}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit}
            />
          </Fragment>
        );
      case 'MsgCountChart':
        return (
          <Fragment>
            <DeviceMsgChartWidget
              deviceid={parameter.deviceid}
              dashName={layout.name}
              layout={layout}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit} />
          </Fragment>
        )
      case 'DviceUsage':
        return (
          <Fragment>
            <DeviceUsageDonutWidget
              dashName={layout.name}
              layout={layout}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit} />
          </Fragment>
        );
      case 'SubscribeValue':
        return (
          <Fragment>
            <SubscribeValueWidget dashName={layout.name} layout={layout} />
          </Fragment>
        );
      case 'AttributeValue':
        return (
          <Fragment>
            <AttrValueWidget
              dashName={layout.name}
              layout={layout}
              parameter={parameter}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit}
              pubSubData={this.state.pubSubData}
            />
          </Fragment>
        );
      case 'AssetElement':
        return (
          <Fragment>
            <AssetWidget
              dashName={layout.name}
              layout={layout}
              parameter={parameter}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit}
              pubSubData={this.state.pubSubData}
            />
          </Fragment>
        );
      case 'AttributeChart':
        return (
          <Fragment>
            <AttrChartWidget
              dashName={layout.name}
              layout={layout}
              parameter={parameter}
              onEditSubmit={this.onEditSubmit}
              onDeleteSubmit={this.onDeleteSubmit}
              pubSubData={this.state.pubSubData}
            >
            </AttrChartWidget>
          </Fragment>
        );
      default:
        return null;
    }
  }







  renderDashboardLayout = (layoutList) => {
    return (
      <Row>
        {layoutList && layoutList.map((layout, index) => {
          return (
            layout.display === 1 &&
            <Col md={layout.width}>
              {this.renderWidgets(layout)}
            </Col>
          );
        })}
      </Row>
    );
  }
  onLayoutChange = (layout, layouts) => {


    // 



    let layoutClone = JSON.parse(JSON.stringify(this.props.dashboardTemplateLayout));
    layoutClone.forEach((item, index) => {
      // let layoutItem = layout.find((element, lindex)=>(item.layoutid.toString() === element.i));

      let layoutItem = layout.find((element, index) => {
        let layoutid = element.i.split('-')[0];
        console.log('layout id', layoutid)
        console.log('item.layoutid.toString()', item.layoutid.toString())

        return (item.layoutid.toString() === layoutid)
      });
      console.log('layoutItem', layoutItem)


      if (layoutItem) {
        let { w, h, x, y } = layoutItem;
        item.position = JSON.stringify({ w, h, x, y })
      }
    });
    console.log('layout clone is ,', layoutClone)
    this.props.updateDashBoardTemplateLayout(layoutClone, this.props.templateId);
  }



  renderDashboardJustableLayout = (layoutList) => {
    if (!layoutList) {
      return null;
    }
    console.log('layoutList', layoutList)
    //let layoutSet = layoutList ? layoutList.filter((item,index)=>(item.id != this.state.idDeleted)) : [];
    //console.log('layoutSet',layoutSet)

    let layoutSet = layoutList;
    return (
      <ReactGridLayout isDraggable={true} draggableHandle={".dragBar"} verticalCompact={true}
        className="layout" cols={12} rowHeight={30} onLayoutChange={this.onLayoutChange} >
        {layoutSet.map((layout, index) => {

          // JSON.parse('[2,3,4]');


          let locationArr = JSON.parse(layout.position);



          let isLocked = (layout.locked === 1);

          locationArr.static = isLocked;
          let key = layout.layoutid.toString() + '-' + layout.locked.toString();
          console.log('key is', key)
          let minHeight = Math.min(7, locationArr.h);
          console.log('layout.id', layout.id)

          return (
            // layout.display === 1 &&
            // <div key={layout.name} data-grid={{x: locationArr[0], y:locationArr[1], w: locationArr[2], h:locationArr[3], minH: locationArr[3], maxH: locationArr[3]}}> minH: locationArr.h, maxH: locationArr.h

            <div key={key} data-grid={{ ...locationArr, minH: minHeight }}>
              {this.renderWidgets(layout)}
            </div>

          );
        })}
      </ReactGridLayout>

    )
  }







  // ----------------------------------------------------------------
  dropdownToggle = () => {
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen, }));
  }
  onTitleClick = (layout) => {
    const layoutname = layout.templateName ? layout.templateName : 'My Dashboard';
    this.setState({
      currentLayoutName: layoutname
    });
    this.dropdownToggle();
  }
  renderTitle = () => {
    let title = 'Dashboard Template Edit';
    // if (this.props.currentLayoutList){
    //   const selectedLayout = this.props.currentLayoutList.find(item => (item.selected == true));
    //   if (selectedLayout){
    //     title = selectedLayout.layoutname ? selectedLayout.layoutname : 'My Dashboard';
    //   }
    //
    // }
    let templateName = this.props.templateName
    return (
      <Row>
        <Col md={4}>
          {/* <h3 className="page-title">Dashboard</h3> */}
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.dropdownToggle}>
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              aria-expanded={this.state.dropdownOpen}
            >
              {/* <h3 className="page-title">My Dashboard</h3> */}
              {/* <h3 className="page-title">{this.state.currentLayoutName}</h3> */}
              {/* <h3 className="page-title">{title}</h3> */}
              <h3 className="page-title">Template Name: {templateName}</h3>
            </DropdownToggle>
            <DropdownMenu>
              {this.props.currentLayoutList && this.props.currentLayoutList.map(layout => (
                <div onClick={() => { this.onTitleClick(layout) }}><h3 className="page-title">{layout.layoutname ? layout.layoutname : 'My Dashboard'}</h3></div>
              ))}
              {/* <div onClick={this.onTitleClick}><h3 className="page-title">My Dashboard</h3></div>
              <div onClick={this.onTitleClick}><h3 className="page-title">Template 1</h3></div> */}
            </DropdownMenu>
          </Dropdown>
        </Col>
        <Col md={2}>

        </Col>
      </Row>
    );
  }
  // ----------------------------------------------------------------

  onReturnClick = () => {
    console.log('on return click')

    this.props.onReturn();
  }

  onDashButtonClick = () => {
    this.modalToggle();
  }

  //-----------------------------------------------------------------
  render() {
    const circleButton = {
      width: '70px',
      height: '70px',
      padding: '10px 16px',
      borderRadius: '35px',
      fontSize: '24px',
      lineHeight: 1.33,
      position: 'fixed',
      // position: '-webkit-sticky',
      // position: 'sticky',
      top: '90px',
      right: '30px',
      zIndex: '99',
    }
    const circleButton2 = {
      width: '70px',
      height: '70px',
      padding: '10px 16px',
      borderRadius: '35px',
      fontSize: '24px',
      lineHeight: 1.33,
      position: 'fixed',
      // position: '-webkit-sticky',
      // position: 'sticky',
      top: '190px',
      right: '30px',
      zIndex: '99',
    }

    console.log('id deleted in render is', this.state.idDeleted)
    console.log('this.props.dashboardTemplateDeleteResult in render', this.props.dashboardTemplateDeleteResult)
    console.log('this.props.dashboardTemplateLayout 1', this.props.dashboardTemplateLayout)

    if (this.props.dashboardTemplateDeleteResult === this.state.idDeleted) {

      console.log('getDashBoardTemplateLayoutById will be called again')

      this.props.getDashBoardTemplateLayoutById(this.props.templateId);
      this.setState({ idDeleted: null })

    }

    console.log('this.props.dashboardTemplateDeleteResult in render', this.props.dashboardTemplateDeleteResult)


    //let dashboardTemplateLayout = [...this.props.dashboardTemplateLayout]
    this.props.dashboardTemplateLayout.forEach((item, index) => {
      this.props.dashboardTemplateLayout[index].layoutid = item.id;
    });

    // if(this.props.dashboardTemplateLayout.length != this.state.dashboardTemplateLayout.length){
    // this.setState(()=>({dashboardTemplate: this.props.dashboardTemplateLayout}))
    // }

    // this.finalResponse = [...this.props.dashboardTemplateLayout];
    // console.log('this.finalResponse',this.finalResponse)
    //  let dashboardLayout = this.props.dashboardTemplateLayout.filter(im=>{
    //     console.log('im is ', im)
    //    console.log('idOfDeleted is ', idOfDeleted)
    //    let tmp = idOfDeleted.filter(item=>(item === im.id)).length
    //    console.log('tmp is' ,tmp)
    //    return  tmp === 0
    //
    //    })





    console.log('this.props.dashboardTemplateLayout 2', this.props.dashboardTemplateLayout)
    console.log('this.props.currentLayout in render is:', this.props.currentLayout)

    return (
      <Fragment>
        {this.renderTitle()}
        <div>

          {this.renderDashboardJustableLayout(this.props.dashboardTemplateLayout)}

          <button type="button" class="btn-primary" style={circleButton} onClick={this.onDashButtonClick}>
            <PlaylistEditIcon color='#ffffff' size={50} />
          </button>

          <button type="button" className="btn-primary" style={circleButton2} onClick={this.onReturnClick}>
            <CheckIcon color='#ffffff' size={50} />
          </button>




          <DasBoardEditModal
            modalSetVisible={this.state.dashBoardModal}
            nestedVisible={this.state.widgetEditModal}
            closeAll={this.state.closeAll}
            // modalList = {this.state.dashBoardSet}
            // modalList = {this.state.currentLayout}
            //modalList={this.props.currentLayout}
            modalList={this.props.dashboardTemplateLayout.map((item) => { item.checked = true; return item })}
            toggle={this.modalToggle}
            editModalToggle={this.editModalToggle}
            toggleAll={this.toggleAll}
            onDashBoardUpdateClick={this.onDashBoardUpdateClick}
            templateId={this.props.templateId}
            templateName={this.props.templateName}
          />

        </div>
      </Fragment>
    );
  }
}

// export default DashboardCard;

function mapStateToProps(state) {





  const dashboardTemplateLayout = state.dashboardTemplate.dashboardTemplateLayout;
  const dashboardTemplateDeleteResult = state.dashboardTemplate.dashboardTemplateDeleteResult

  const deviceTypes = state.mapboard.types;
  // const mapData = state.mapboard.mapData;
  const mapData = state.mapboard.assetData;
  const currentLayout = state.dashboard.currentLayout;
  console.log('props layout in map t props', currentLayout)
  const currentLayoutList = state.dashboard.currentLayoutList;
  const actionLogs = state.job.actionLogs;
  const { currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, currentDeviceDetailsList, error, message, type } = state.asset;
  const props = { dashboardTemplateDeleteResult, dashboardTemplateLayout, deviceTypes, mapData, currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, currentDeviceDetailsList, error, message, type, currentLayout, actionLogs, currentLayoutList };
  return props;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadDevice, loadDeviceInfo, loadDivceMapData,
    getAssetsData, loadDashBoardLayout, updateDashBoardLayout,
    getActionLog, loadDashBoardLayoutList, getDashBoardTemplateLayoutById,
    updateDashBoardTemplateLayout, deleteDashBoardTemplateEntryById
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardCard));
