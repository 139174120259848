/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import VideoPlayer from './VideoPlayer';
import VideoMpegPlayer from './VideoMpegPlayer';
import { CameraIcon } from 'mdi-react';

class VideoModalIcon extends Component {
    constructor(props){
        super(props);
        this.state={
            modal: false
        }
    }

    toggle = () => {
        this.setState(prevState=>({
            modal: ! prevState.modal,
        }));
    }

    render(){
        const {url, icon, ...props} = this.props;
        return (
            <div>
                { icon ?
                    <>
                    {icon({size:20,style:{float: 'right', display:'inline', cursor: 'pointer'},onClick:(e)=>{toggle()}})} 
                    </>
                    : 
                    <div style={{cursor: 'pointer'}}>
                    <CameraIcon size={20} style={{cursor: 'pointer'}} onClick={this.toggle} {...props}/>
                    </div>
                }
                <Modal
                    animationType="slide"
                    transparent={false}
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    size = {'lg'}
                >
                    <ModalHeader
                        toggle={this.toggle}
                        //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                    >
                        Camera
                        {/* <CameraIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/> */}
                    </ModalHeader>
                    <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                        {/* <VideoPlayer url={'ws://192.168.2.89:8081/webSocketService'}/> */}
                        <VideoPlayer url={url}/>
                        {/* <VideoMpegPlayer/> */}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default VideoModalIcon;