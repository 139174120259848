/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Col, Row, Button, Container } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { removeUser, getUserAssetclassList, upsertUserAssetclassList } from '../../../redux/actions/userAction';
import { getCurrentUser, hasRole } from '../../../factories/auth';

class UserAccessForm extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            assetclassMap: [],
        }

    }

    componentDidMount() {
        // alert('user access form mount ');

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //alert('user access form ');
        if (nextProps.assetClasses === prevState.rawAssetClasses && nextProps.userAssetClassList === prevState.rawUserAssetClassList) {
            return null;
        }
        let assetclassMap = [];
        let restUserClassIds = [];

        if (nextProps.assetClasses) {
            nextProps.assetClasses && nextProps.assetClasses.map((assetclass, index) => {
                let mapItem = {
                    assetclassid: assetclass.assetclassid,
                    assetclassname: assetclass.assetclassname,
                    companycode: assetclass.companycode,
                    companyname: assetclass.companyname,
                    access: 2,
                };
                assetclassMap.push(mapItem);
            });
            if (nextProps.userAssetClassList) {
                if (assetclassMap.length > 0) {
                    assetclassMap.map((assetclass, index) => {
                        let found = nextProps.userAssetClassList.find((element) => {
                            return (element.assetclassid === assetclass.assetclassid);
                        });
                        if (found) {
                            assetclass.access = 1;
                        }
                    });
                }
                else {
                    // nextProps.userAssetClassList.forEach((item, index)=>{
                    //     let mapItem = {
                    //         assetclassid : item.assetclassid,
                    //         assetclassname: item.assetclassname,
                    //         companycode: '',
                    //         companyname: '',
                    //         access: 1,
                    //     };
                    //     assetclassMap.push(mapItem);
                    // });
                }
                // --------------------------------------------------------------
                if (nextProps.companyAssetClasses && nextProps.assetClasses) {
                    let restAssetClasses = nextProps.companyAssetClasses.filter((cItem, cIndex) => (nextProps.assetClasses.findIndex((a, i) => (cItem.assetclassid == a.assetclassid)) === -1));

                    nextProps.userAssetClassList.forEach((item, index) => {
                        if (restAssetClasses.findIndex((rItem, ri) => (item.assetclassid === rItem.assetclassid)) !== -1) {
                            // restUserClassIds.push(item.devicetypeid);
                            restUserClassIds.push(item.assetclassid);
                        }
                    });
                }
                // --------------------------------------------------------------
            }

        }
        return ({
            rawAssetClasses: nextProps.assetClasses,
            rawUserAssetClassList: nextProps.userAssetClassList,
            assetclassMap: assetclassMap,
            restUserClassIds: restUserClassIds,
        });

    }

    // componentWillReceiveProps(nextProps) {
    //      
    //      
    // // componentWillReceiveProps(nextProps, nextContext) {
    //     alert('user access form ');
    // }

    onSelectChange = (e, row) => {
        e.preventDefault();

        row.access = Number(e.target.value);

        this.setState((preState) => {

            return ({
                assetclassMap: preState.assetclassMap,
            });
        }
        );
    }

    onGroupSelectChange = (e, row) => {

    }

    cellAuthSelection(cell, row, enumObject, index) {



        // console.log('cell-row-enumObject-index',cell,row,enumObject,index)

        // console.log('index+user role',`${index},user_role`)
        return (
            <select
                name={`${index},user_role`}
                // value={this.state.data[index]['user_role']}
                onChange={(e) => this.onSelectChange(e, row)}
                value={cell}
            >
                {/*<option value='RO'><Trans i18nKey='read-access'>Read</Trans></option>*/}
                {/*<option value='RW'><Trans i18nKey='rw-access'>Read and Write</Trans></option>*/}
                {/* <option value=''>None Selected</option>
                <option value='RO'>Read</option>
                <option value='RW'>Read and Write</option> */}
                {/* <option value={0}>None Selected</option> */}
                <option value={1}>Allow</option>
                <option value={2}>Deny</option>
            </select>
        );
    }

    cellGroupSelection = (cell, row, enumObject, index) => {

        return (
            // <select
            //     name={`${index},user_group`}
            //     value={this.state.groupdata[index]['user_group']}
            //     onChange={this.onSelectChange}
            // >
            //     {/*<option value='NO'><Trans i18nKey='headquarter'>HQ</Trans></option>*/}
            //     {/*<option value=''><Trans i18nKey='none-selected'>None Selected</Trans></option>*/}
            //     <option value=''>None Selected</option>
            //     <option value='NO'>HQ</option>
            // </select>

            <input className='react-bs-select-all'
                type='checkbox'
                // name={`${index},user_group`}
                name={`${row.id},user_group`}
                id={'checkbox' + this.props.rowIndex}
                // checked={this.state.groupdata[index]['user_group']}
                checked={cell}
                onChange={this.onGroupSelectChange} />

        );
    }

    onSubmit = () => {
        if (sessionStorage.getItem('currentUserObj')) {
            try {
                // let userObj = JSON.parse(sessionStorage.getItem('currentUserObj'));
                let classids = [];
                this.state.assetclassMap.map((item, index) => {
                    if (item.access === 1) {
                        classids.push(item.assetclassid);
                    }
                });
                let mapObj = {
                    userId: this.props.userId,
                    classid: classids.concat(this.state.restUserClassIds),
                };
                this.props.upsertUserAssetclassList(mapObj);
            } catch (e) {
                console.error(e);
            }

        }

        // this.props.upsertUserAssetclassList(mapObj);
        // this.props.getUserAssetclassList("test123");
        // this.props.removeUser("test52347");
        this.props.toggle();
    }

    onClose = () => {
        // alert('inside');
        this.props.toggle();
    }

    render() {
        let data = [];
        // data = this.props.modules.groupPayload;
        const options = {
            // page: 1,  // which page you want to show as default
            // sizePerPageList: [ {
            //   text: '5', value: 5
            // }, {
            //   text: '10', value: 10
            // }, {
            //   text: '15', value: 15
            // } ], // you can change the dropdown list for size per page
            // sizePerPage: 5,  // which size per page you want to locate as default
            // pageStartIndex: 1, // where to start counting the pages
            // paginationSize: 5,  // the pagination bar size.
            // prePage: '<', // Previous page button text
            // nextPage: '>', // Next page button text
            // firstPage: '<<', // First page button text
            // lastPage: '>>', // Last page button text
        };

        return (
            <div>
                <br />
                <BootstrapTable data={this.state.assetclassMap} options={options} height={400}>
                    <TableHeaderColumn dataField='assetclassname'>
                        {/* <Trans i18nKey="system">System</Trans> */}
                        Asset Class Name
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='assetclassid' isKey={true}>
                        <Trans i18nKey="id">Asset Class ID</Trans>
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='companyname'>
                        Company Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        // dataField='user_role'
                        dataField='access'
                        dataFormat={this.cellAuthSelection.bind(this)}
                    >
                        <Trans i18nKey="action">Access</Trans>
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                                dataField='user_group'
                                dataFormat={this.cellGroupSelection.bind(this)}
                            >
                                <Trans i18nKey="action">Group</Trans>
                            </TableHeaderColumn> */}
                </BootstrapTable>
                <br />
                <hr />
                <div class="d-flex justify-content-end">

                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.state.assetclassMap || this.state.assetclassMap.length === 0} >
                            Submit
                        </Button>}
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.state.assetclassMap || this.state.assetclassMap.length === 0} >
                            Submit
                        </Button>}
                    <Button outline color='secondary' type="reset" onClick={this.onClose}>
                        Close
                    </Button>

                </div>
            </div>
        );
    }
}

// export default UserAccessForm;

const mapStateToProps = (state) => {
    return state.user;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUser, getUserAssetclassList, upsertUserAssetclassList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccessForm);
