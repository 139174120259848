/* eslint-disable */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import { Alert, Col, Row } from "reactstrap";
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { userLogin } from '../../../redux/actions/userAction';
import { FORGOT_PASSWORD_URL } from '../../../constants/appConstants';
import { getCurrentUser } from '../../../factories/auth';
class LogInForm extends PureComponent {
  // static propTypes = {
  //   handleSubmit: PropTypes.func.isRequired,
  // };

  constructor() {
    super();
    this.state = {
      showPassword: false,
      showErrors: false,
      isLoading: false,
      username: '',
      password: '',
      errors: {},
    };
  }

  //   componentWillReceiveProps(nextProps) {
  //      
  //     if (nextProps.isAuthenticated) {
  //         // this.context.router.history.push('/pages/dashboard');
  //         // alert(nextProps.isAuthenticated);
  //          
  //         nextProps.history.push(`/pages/dashboard`);
  //         // alert('logged in');
  //         // alert(nextProps.isAuthenticated);
  //     }
  //     else{
  //       this.setState(
  //             {showErrors: true}
  //         );
  //     }
  // }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //    
  //     if (nextProps.isAuthenticated) {
  //         // this.context.router.history.push('/pages/dashboard');
  //         // alert(nextProps.isAuthenticated);
  //          
  //         nextProps.history.push(`/pages/dashboard`);
  //         // alert('logged in');
  //         // alert(nextProps.isAuthenticated);
  //         return null;
  //     }
  //     // else{
  //     //   return(
  //     //         {showErrors: true}
  //     //     );
  //     // }
  // }

  componentWillUnmount() {
    this.setState({
      showPassword: false,
      showErrors: false,
      isLoading: false,
      username: '',
      password: '',
      errors: {},
      loggedIn: 'false',

    });
  }

  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
      showErrors: false,
    });
  };

  onInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    // let errors = this.state.errors;
    switch (name) {
      case 'username':
        this.setState({
          username: value,
          showErrors: false
        });
        sessionStorage.setItem('username', event.target.value);
        break;
      case 'password':
        this.setState({
          password: value,
          showErrors: false
        });
        break;
      default:
        return this.state;
    }
  };

  handleTouch = (e) => {
    e.preventDefault();
    this.setState({ showErrors: false })
  }

  handleSubmit = (e) => {
    // alert('okay');
    e.preventDefault();

    // alert('okay');
    this.setState(
      { isLoading: true, showErrors: true }
    );
    this.props.userLogin(this.state.username, this.state.password);
    // debugger;
  }

  renderError = () => {
    if (!this.props.isAuthenticated && this.props.error === 1) {
      return (
        <Alert className='form__form-group-field alert-danger text-center'>
          {/* <Col md={5}/> */}
          {/* <span className='text-center'>Log In Failed</span> */}
          <span className='text-center'>{this.props.message}</span>
          {/* <Col md={4}/> */}
        </Alert>
      )
    } else {
      return null;
    }
  };
  render() {
    let userObj = getCurrentUser();
    var route = "";
    if (userObj) {
      if (!userObj.sidebarContent.includes("Dashboard")) {
        if (userObj.sidebarContent.includes("Snow App")) {
          route = "/SnowApp";
        }
        if (userObj.sidebarContent.includes("Garage App")) {
          route = "/ParkingApp";
        }
        if (userObj.sidebarContent.includes("Occupancy App")) {
          route = "/OccupancyApp";
        }
        if (userObj.sidebarContent.includes("Weather App")) {
          route = "/WeatherApp";
        }
        if (userObj.sidebarContent.includes("Telecom App")) {
          route = "/TelecomApp";
        }
        if (userObj.sidebarContent.includes("Smart Building")) {
          route = "/surveillance";
        }
        if (userObj.sidebarContent.includes("Billing App")) {
          route = "/BillingApp";
        }

      } else {
        route = "/Dashboard"
      }
    }
    if (sessionStorage.getItem('isAuthenticated') && 'true' == sessionStorage.getItem('isAuthenticated')) {
      //  
      //  
      return <Redirect push to={"/pages" + route} />;
    }
    return (
      <form className="form" onSubmit={e => this.handleSubmit(e)}>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon style={{marginLeft: '8px', marginBottom: '10px'}} />
            </div>
            {/* <Field
              name="name"
              component="input"
              type="text"
              placeholder="Name"
            /> */}
            <input
              name="username"
              className="custom_input"
              type="text"
              placeholder="Name"
              onChange={this.onInputChange}
              onClick={this.handleTouch}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon style={{marginLeft: '8px', marginBottom: '10px'}} />
            </div>
            {/* <Field
              name="password"
              component="input"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder="Password"
            /> */}
            <input
              name="password"
              className="custom_input"
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder=""
              onChange={this.onInputChange}
              onClick={this.handleTouch}
            />
            <button
              className={`form__form-group-button ${this.state.showPassword? "show-password-btn":""}`}
              type="button"
              onClick={this.showPassword}
            ><EyeIcon style={{marginLeft: '8px', marginBottom: '10px'}} />
            </button>

          </div>

          <div className="account__forgot-password">
            <a href={FORGOT_PASSWORD_URL}>Forgot my password?</a>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            {/* <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
            /> */}
          </div>
        </div>
        <div className='form__form-group-field'>
          {this.state.showErrors === true && this.renderError()}
        </div>
        <div className='form__form-group-field'>
          <br />
        </div>
        {/* <Link className="btn btn-primary account__btn account__btn--small" to="/pages/dashboard">Sign In</Link> */}
          <button className="btn btn-primary account__btn account__btn--small" type="submit" >Submit</button>
        {/*
        <Link className="btn btn-outline-primary account__btn account__btn--small" to="/log_in">Create Account</Link>
        */}
      </form>
    );
  }

}

const mapStateToProps = (state) => {
  return state.user;
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin }, dispatch);
};

// LogInForm = connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(LogInForm);

// export default reduxForm({
//   form: 'log_in_form',
// })(LogInForm);

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default enhance(LogInForm);
