/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableLargeIcon from 'mdi-react/TableLargeIcon';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';
import {loadDevice, loadAssetClass} from '../../../redux/actions/mapActions';

class DeviceTypeEditModal extends Component{
    constructor(props){
        super(props);
        this.state={
            tital: 'Dashboard Element',
            modalList: this.props.modalList,
            selectedAssetClassId: null,
            selectedDeviceTypeId: null,
        }
    }

    componentDidMount(){
        this.props.loadDevice();
        // this.props.loadAssetClass();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.layout && nextProps.layout !== this.state.layout){
            if (nextProps.layout.parameter){
                let parameter = JSON.parse(nextProps.layout.parameter);
                // if (parameter.assetid){
                //     this.setState({
                //         currentAssetClassId: parameter.assetid,
                //         currentParameter: nextProps.layout.parameter
                //     });
                // }else 
                if (parameter.devicetype){
                    this.setState({
                        currentDeviceTypeId: parameter.devicetype,
                        currentParameter: nextProps.layout.parameter
                    });
                }
            }
            
            this.setState({
                currentName: nextProps.layout.name,
                layout: nextProps.layout
            });
        }
    }


    // onAssetClassChange = (e) => {
    //     let value = e.target.value;
    //     this.setState({
    //         currentAssetClassId: value,
    //         currentParameter : JSON.stringify({assetid: value})
    //     });
    // }
    onDeviceTypeChange = (e) => {
        let value = e.target.value;
        this.setState((prevState)=>{
            let parameter = JSON.stringify({devicetype: value});
            prevState.layout.parameter = parameter;
            return({
                currentDeviceTypeId: value,
                currentParameter : parameter,
                layout: prevState.layout,
            })
        });
    }

    handleInputChange =(newVal) => {
        this.setState((prevState)=>{
            if (prevState.layout){
                prevState.layout.name = newVal;
                return ({
                    currentName: newVal,
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        });
    }

    renderTitle =()=> {
        return(
            
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                         type='text'
                        //  defaultValue={this.props.layout ? this.props.layout.name : ''} 
                         defaultValue={this.state.currentName} 
                         exitOnEnterKeyPress={false}
                         onSave={newVal => {this.handleInputChange(newVal) }}
                    />
                </Col>
            </Row>           
        )
    }
    renderDeviceType = () => {
        return (
            <Row>
            <Col md={3} style={{textAlign: 'left' }}>
                <Label for="deviceType" style={{display: 'inline', width: "100%"}} ><h4><b>Device Type :</b></h4></Label>
            </Col>
            <Col md={8}>
            <Input type="select" id="deviceType" name="deviceType" width="100%" value={this.state.currentDeviceTypeId} onChange={this.onDeviceTypeChange} >      
                {/* {this.state.deviceTypeDisable && <option value={'0'}> </option>}                               */}
                {/* {this.state.showEmpgy && <option value={'0'}> No Selection </option>}   */}
                <option key={''} value={''}>NONE</option>
                {this.props.types && this.props.types.map((type,i) => (<option key={type.id} value={type.id}>{type.name}</option>))}
            </Input>
            </Col>
            </Row>
        )
    }
    // renderAssetClass = () => {
    //     return (
    //         <Row>
    //             <Col md={3} style={{textAlign: 'left' }}>
    //                 <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
    //             </Col>
    //             <Col md={8}>
    //                 <Input type="select" id="asset" name="asset" width="100%" value={this.state.currentAssetClassId} onChange={this.onAssetClassChange} >                                    
    //                     {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
    //                     <option value={0}>NONE</option> 
    //                     {this.props.assetClasses && this.props.assetClasses.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
    //                 </Input>
    //             </Col>
    //         </Row>
    //     )
    // }

    onCancleClick = (e) => {
        this.props.toggle();
    }
    onOkClick = (e) => {
        // this.props.onEditOkayClick(this.props.index, this.state.currentParameter, this.state.currentName);
        this.props.onEditSubmit(this.state.layout);
        this.props.toggle();
    }
    render(){
        return(
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                // onClosed={this.props.closeAll ? toggle : undefined}
                size = {'lg'}
            >
                <ModalHeader 
                    toggle={this.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    {this.renderTitle()}
                    <Row>
                    <Col md={12}> 
                        {/* {this.props.layout && this.renderParameterInput(this.props.layout.type)} */}
                        {this.renderDeviceType()}
                    </Col>
                    </Row>
                    <br/>
                    {/* <Row>
                    <Col md={12}>
                    </Col>
                    </Row>
                    <br/> */}
                
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
function mapStateToProps(state) {
    const types = state.mapboard.types;
    const assetClasses = state.mapboard.assetClass;
    const props = {types,  assetClasses};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            loadDevice, 
            loadAssetClass,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypeEditModal);