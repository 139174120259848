/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Client } from '@stomp/stompjs';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table, Col, Container, Row, Card, CardBody, CardTitle, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse, Dropdown, DropdownMenu, DropdownToggle, Badge } from 'reactstrap';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import Icon from '@mdi/react'
import { mdiRefresh } from '@mdi/js';
import axios from 'axios';
import { name } from 'file-loader';
import { LabelTextInput } from '../cameraList/CameraCreateModal';
import { sendSMS } from '../../../../factories/utils';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { AI_API_ROOT_HTTPCONTROL, WEB_SERVICE_GET_PASSLINE_COUNT } from '../../../../constants/appConstants';
import { postAssetAttribute, loadAssets } from '../../../../redux/actions/assetActions';
import { bindActionCreators } from 'redux';





function PeopleCountDisplay({location, cameraList, postAssetAttribute, loadAssets}) {
    const [plazaLocation, setplazaLocation] = useState('ITSP Office')
    const [plazaCameras, setplazaCameras] = useState(
        [{ chlNum: 1, cameraName: "IPC1", interval: 3, entranceCount: 0, exitCount: 0 }],
    )
    const [PeopleLimit, setPeopleLimit] = useState(300)
    const [PeopleLimitInput, setPeopleLimitInput] = useState(null)
    const [LimitModal, setLimitModal] = useState(false)
    const [alertReceivers, setalertReceivers] = useState([])

    useEffect(() => {
        if (location) {
            console.log('LOCATION PPL COUNT', location)
            setplazaLocation(location.assetname);
            let capacityLimit = location.assetattributes.find(el=>el.attmetaname == 'capacity')
            if(capacityLimit) {
                console.log('CAPACITY LIMIT: ',capacityLimit)
                setPeopleLimit(parseInt(capacityLimit.value))
            } 
        }
    }, [location]);

    useEffect(() => {
        let stayCount = plazaCameras[0].entranceCount - plazaCameras[0].exitCount;
        if (PeopleLimit !== null && stayCount >= PeopleLimit) {
            console.log('SHOULD SEND SMS', PeopleLimit, stayCount)
            // sendSMS(['6475511693'], `Warning: A capacity limit of ${PeopleLimit} has been reached at the ${plazaLocation}`)
            sendSMS(alertReceivers, `Warning: A capacity limit of ${PeopleLimit} has been reached at the ${plazaLocation}`)
            // setPeopleLimit(null)
        }
    }, [plazaCameras, PeopleLimit])

    useEffect(() => {
        console.log("====UPDATE DATA", plazaCameras)
    }, [plazaCameras])

    useEffect(() => {
        console.log('Num allowed', PeopleLimit)
    }, [PeopleLimit])

    useEffect(() => {
        // const timer = setInterval(getCountData, 5000)
        // return ()=> clearInterval(timer);
    }, [])

    const stayCountFormatter = (cell, row) => {
        return (
            <span style={{ fontWeight: "bold", fontSize: "1.2em" }}>{row.entranceCount - row.exitCount < 0 ? 0 : row.entranceCount - row.exitCount}</span>
        )
    }

    const getCountData = async () => {
        try {
            // console.log('FIRING COUNTING')
            const [firstResponse, secondResponse, thirdResponse] = await Promise.all([
                axios.get(`${AI_API_ROOT_HTTPCONTROL}${WEB_SERVICE_GET_PASSLINE_COUNT}?ip=10.160.105.213&port=80&account=admin&password=ONWaivadm4$`),
                axios.get(`${AI_API_ROOT_HTTPCONTROL}${WEB_SERVICE_GET_PASSLINE_COUNT}?ip=10.160.105.219&port=80&account=admin&password=ONWaivadm4$`),
                axios.get(`${AI_API_ROOT_HTTPCONTROL}${WEB_SERVICE_GET_PASSLINE_COUNT}?ip=10.160.105.238&port=80&account=admin&password=ONWaivadm4$`),
            ])
            // const res = await axios.get("https://minh.itsp-inc.com:8181/api/httpController/GetPassLineCountStatistics?ip=192.168.2.127&port=80&account=admin&password=ONWaivadm4$");
            // const res = await axios.get("https://minh.itsp-inc.com:8181/api/httpController/GetPassLineCountStatistics?ip=192.168.2.127&port=80&account=admin&password=ONWaivadm4$");
            // const data = await res.data;
            // console.log(data.entranceCount.person, data.exitCount.person);
            let tempData = { ...plazaCameras[0] }
            tempData.entranceCount = parseInt(firstResponse.data.entranceCount.person) + parseInt(secondResponse.data.entranceCount.person) + parseInt(thirdResponse.data.entranceCount.person)
            tempData.exitCount = parseInt(firstResponse.data.exitCount.person) + parseInt(secondResponse.data.exitCount.person) + parseInt(thirdResponse.data.exitCount.person)
            console.log('TEMP DATA', tempData)
            setplazaCameras([tempData])
        } catch (err) {
            console.warn(err);
        }
    }

    const reloadCount = async () => {
        try {
            // const header = {'Access-Control-Allow-Origin': 'http://127.0.0.1:8181', 'Access-Control-Allow-Headers': 'http://127.0.0.1:8181'}
            const countingRequest = await axios.get("http://127.0.0.1:8181/api/httpController/GetPassLineCountStatistics?ip=192.168.2.127&port=80&account=admin&password=ONWaivadm4$");
            const counter = countingRequest.data;
            // console.log('PEOPLE COUNTER', counter)
            setplazaCameras([{ ...plazaCameras[0], exitCount: counter.exitCount.person, entranceCount: counter.exitCount.person }])

        } catch (err) {
            console.error(err);
        }
        // debugger;
    }

    const onRefreshCamera = () => {
        reloadCount();
    }

    const dateFormatter = (cell, row) => {
        let currentDate = new Date();
        let time = currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();
        let cDay = currentDate.getDate();
        let cMonth = currentDate.getMonth() + 1;
        let cYear = currentDate.getFullYear();
        return (
            <b> {`${cDay}/${cMonth}/${cYear} ${time}`}</b>
        )
    }

    const toggleCountLimit = () => {
        setLimitModal(!LimitModal)
    }

    const handlePeopleLimitChange = e => {
        setPeopleLimitInput(e.target.value);
    }

    const handleLimitSubmit = () => {
        setPeopleLimit(PeopleLimitInput);
        if (location) {
            let attribute = location.assetattributes.find(el => el.attmetaname == 'capacity')
            if (attribute) {
                let param = {
                    assetid: attribute.assetid,
                    attmetaid: attribute.attmetaid,
                    value: PeopleLimitInput
                }

                updateCapacity(param)

            }
        }
        setLimitModal(false);
    }

    const updateCapacity = (data) => {
        console.log('UPDATE CAPACITY')

        postAssetAttribute(data).then(
            res => { loadAssets() }
        );
    }

    const columns = [
        // {
        //     dataField: 'chlNum',
        //     text: 'Camera Number',
        //     style: {
        //         fontWeight: 'bold',
        //         fontSize: '15px'
        //     }
        // },
        // {
        //     dataField: 'cameraName',
        //     text: 'Camera Name',
        //     style: {
        //         fontWeight: 'bold',
        //         fontSize: '15px',
        //         editable:false,
        //     }
        // },
        // {
        //     dataField: 'interval',
        //     text: 'Interval Time',
        //     editor: {
        //         type: Type.SELECT,
        //         options: [
        //             {
        //                 value: 5,
        //                 label: 5,
        //             },
        //             {
        //                 value: 3,
        //                 label: 3,
        //             },
        //             {
        //                 value: 1,
        //                 label: 1,
        //             },
        //         ]
        //     }
        // },
        {
            dataField: 'entranceCount',
            text: 'Entrance Count',
            editable: false,
        },
        {
            dataField: 'exitCount',
            text: 'Exit Count',
            editable: false,
        },
        {
            text: 'NO. of People at Premises',
            isDummyField: true,
            formatter: stayCountFormatter,
            editable: false,
        },
        {
            text: 'Last Updated Date',
            isDummyField: true,
            formatter: dateFormatter,
            editable: false,
        },
    ]

    const handleAddAlertReceiver = (phone) => {
        // console.log("=======ITEM ADDED", phone);
        let tempArr = alertReceivers;
        tempArr.push(phone);
        setalertReceivers(tempArr)
    }

    const handleDeleteReceiver = (phone) => {
        // console.log("ITEM DELETED",phone);
        let tempAlertReceivers = alertReceivers;
        if (tempAlertReceivers) {
            const index = tempAlertReceivers.indexOf(phone);
            if (index > -1) {
                tempAlertReceivers.splice(index, 1)
            }
            setalertReceivers(tempAlertReceivers)
        }
    }

    return (
        <Collapse isOpen={true}>
            <Card>
                <CardBody>
                    <Row>
                        <CardTitle tag="h3" style={{ fontWeight: 'bold', marginLeft: "20px" }}>{plazaLocation}</CardTitle>
                        {/* <Stack spacing={1} alignItems="center">
                        <Stack direction="row" spacing={1}>
                            <Chip label="primary" color="primary" />
                            <Chip label="success" color="success" />
                        </Stack>
                        <Stack direction="row" spacing={1}>
                            <Chip label="primary" color="primary" variant="outlined" />
                            <Chip label="success" color="success" variant="outlined" />
                        </Stack>
                    </Stack> */}
                    </Row>
                    <br />
                    <Row>
                        <Col md={2}>
                            <Button
                                tag="h3"
                                onClick={toggleCountLimit}
                                style={{ cursor: 'pointer' }}
                            >
                                Capacity Limit
                                <span
                                    class="badge badge-danger badge-pill"
                                    tag="h3"
                                    style={{ margin: '5px', padding: '10px' }}
                                >
                                    {PeopleLimit}
                                </span>
                            </Button>
                        </Col>
                    </Row>
                    <BootstrapTable
                        tableHeaderClass='header_white'
                        keyField='chlNum'
                        data={plazaCameras}
                        columns={columns}
                        cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
                    />
                </CardBody>
            </Card>
            <Modal toggle={toggleCountLimit} isOpen={LimitModal}>
                <ModalHeader toggle={toggleCountLimit} tag="h5">Set Building Capacity Limit</ModalHeader>
                <ModalBody>
                    <LabelTextInput style={{ fontSize: '14px' }} tag="h5" forIdName="peopleLimit" label="Number of People Allowed" value={PeopleLimitInput} type="text" onChange={handlePeopleLimitChange} />
                    <FormGroup row>
                        <Col md={3}>
                            <Label style={{ fontSize: '14px' }} tag="h5" htmlFor={"alertReceiver"}>Alert Receiver(s)</Label>
                        </Col>
                        <Col md={8}>
                            <MultipleValueTextInput
                                placeholder='Enter a Valid Phone Number Here'
                                style={{fontSize:'14px'}}
                                name="receivers-input"
                                values={alertReceivers}
                                onItemAdded={(item, allItems) => handleAddAlertReceiver(item)}
                                onItemDeleted={(item, allItems) => handleDeleteReceiver(item)}
                            />
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={handleLimitSubmit}>Set Limit</Button>
                </ModalFooter>
            </Modal>
        </Collapse>
    )
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ postAssetAttribute, loadAssets }, dispatch)
}

export default connect(null, mapDispatchToProps)(PeopleCountDisplay)
