/* eslint-disable */
import React, { Fragment } from 'react'
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import { DeleteTextButton } from '../Button/IconButton';

export default function ConfirmDeleteDialog({ onConfirm, label, msg, noIcon, icon, link, login,...props }) {
    const [modalVisible, setModalVisible] = React.useState(false)
    let editRef;
    // React.useEffect(() => { editRef && editRef.focus() })

    const toggle = () => {
        setModalVisible(!modalVisible);
    }
    const onConfirmClick = (e) => {
        onConfirm(e);
        setModalVisible(false);
    }

    const onCancelClick = () => {
        // onCancel();
        setModalVisible(false);
    }

    return (
        <Fragment>
            {noIcon ?
                link ?
                    <a href={'#'} onClick={(e) => { e.preventDefault(); toggle() }} style={{ color: 'red' }}>
                        {label}
                    </a>
                    :
                    <Button outline color="custom" type="button" style={{ margin: 'auto' }} size='sm' onClick={(e) => { toggle() }} {...props} >
                        {label}
                    </Button>
                :
                icon ?
                    <>
                        {icon({ onClick: (e) => { toggle() } })}
                    </>
                    :
                    <DeleteTextButton label={label} onClick={(e) => { toggle() }} />
            }
            {/* <DeleteTextButton label={label} onClick={(e)=>{toggle()}}/> */}
            <Modal isOpen={modalVisible}
                toggle={toggle}>
            {login ?
                <ModalHeader
                    toggle={toggle}
                >
                    Confirmation
                </ModalHeader>
            :
                <ModalHeader
                toggle={toggle}
                className={'confirm-dialog'}
                >
                    Confirmation
                </ModalHeader>
            }
                <ModalBody>
                    <br /><br />
                    <h4 className='text-center'>
                        {msg}
                    </h4>
                    <br /><br />
                    <Row>
                        <Col className='text-center'>
                            <Button outline color="secondary" style={{ width: '200px', marginLeft: 10, positon: 'relative' }} onClick={onCancelClick}>Cancel</Button>
                            {login ?
                            <Button className="btn-withhover" outline color="custom" style={{ width: '200px', marginRight: 10, position: 'relative' }} onClick={onConfirmClick}>Confirm</Button>
                            :
                            <Button color="custom" style={{ width: '200px', marginRight: 10, position: 'relative' }} className="confirm-dialog-btn"  onClick={onConfirmClick}>Confirm</Button>
                            }
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}