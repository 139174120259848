/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableLargeIcon from 'mdi-react/TableLargeIcon';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';
import { EditIcon } from 'mdi-react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';
import {loadDevice, loadAssetClass} from '../../../redux/actions/mapActions';
import {getDevices} from '../../../redux/actions/dashboardActions';
import { toast } from "react-toastify";
// import { loadDevice } from '../../../redux/actions/mapActions'

class SimplePackDeviceEditIcon extends Component{
    constructor(props){
        super(props);
        this.state={
            tital: 'Dashboard Element',
            modalList: this.props.modalList,
            selectedAssetClassId: null,
            // selectedDeviceTypeId: null,
            currentDeviceTypeId: null,
            currentDeviceId: null,
            currentParameterObj: null,
            modal: false,
        }
    }

    componentDidMount(){
        // this.props.loadDevice();
        // this.props.loadAssetClass();
        this.props.loadDevice(); 
        // this.props.getDevices('5d2886f9e833d96cfbf46327');
    }

    componentWillUnmount(){
         
    }

    // static getDerivedStateFromProps(nextProps, prevState){}

    componentWillReceiveProps(nextProps){
         
        if (nextProps.layout && nextProps.layout !== this.state.rawLayout){
            
            if (nextProps.layout.parameter){
                let parameter = JSON.parse(nextProps.layout.parameter);
                if (parameter){
                    if (parameter.devicetype) {
                        this.props.getDevices(parameter.devicetype);
                    }
                    
                    this.setState({
                        currentDeviceTypeId: parameter.devicetype,
                        currentDeviceId: parameter.deviceid,
                        currentParameterObj: parameter,
                    });
                }
                // else if (parameter.devicetype){
                //     this.setState({
                //         currentDeviceTypeId: parameter.devicetype
                //     });
                // }
                this.setState({
                    currentParameter: nextProps.layout.parameter
                });
            }

            let layoutClone = JSON.parse(JSON.stringify(nextProps.layout));
            
            this.setState({
                currentName: nextProps.layout.name,
                layout: layoutClone,
                // layout: nextProps.layout,
                rawLayout: nextProps.layout,
            });
        }
    }

    initialForm = () => {
        if (this.props.layout){           
            if (this.props.layout.parameter){
                let parameter = JSON.parse(this.props.layout.parameter);
                if (parameter){
                    if (parameter.devicetype) {
                        this.props.getDevices(parameter.devicetype);
                    }
                    
                    this.setState({
                        currentDeviceTypeId: parameter.devicetype,
                        currentDeviceId: parameter.deviceid,
                        currentParameterObj: parameter,
                    });
                }
                // else if (parameter.devicetype){
                //     this.setState({
                //         currentDeviceTypeId: parameter.devicetype
                //     });
                // }
                this.setState({
                    currentParameter: this.props.layout.parameter
                });
            }

            let layoutClone = JSON.parse(JSON.stringify(this.props.layout));
            
            this.setState({
                currentName: this.props.layout.name,
                layout: layoutClone,
                // layout: nextProps.layout,
                rawLayout: this.props.layout,
            });
        }
    }
    
    handleInputChange =(newVal) => {
        this.setState((prevState)=>{
            if (prevState.layout){
                prevState.layout.name = newVal;
                return ({
                    currentName: newVal,
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        });
    }

    renderTitle =()=> {
        return(
            
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                         type='text'
                         defaultValue={this.props.layout ? this.props.layout.name : ''} 
                         exitOnEnterKeyPress={false}
                         onSave={newVal => {this.handleInputChange(newVal) }}
                    />
                </Col>
            </Row>           
        )
    }

    manufactureOnChange = (e) => {
        let value = e.target.value;
        this.props.getDevices(value);
        this.setState(prevState => {
            let parameterObj = {
                ...prevState.currentParameterObj,
                devicetype: value,
                deviceid: '',
            };
            let parameter = JSON.stringify(parameterObj);
            prevState.layout.parameter = parameter;
            return({
                currentDeviceTypeId: value,
                currentDeviceId: null,
                currentParameter : parameter,
                currentParameterObj: parameterObj,
                layout: prevState.layout,
            })            
        });
    }

    onDeviceChange = (e) => {
        let value = e.target.value;
        this.setState((prevState)=>{
            let parameterObj = {
                ...prevState.currentParameterObj,
                deviceid: value,
            };
            // let parameter = JSON.stringify({deviceid: value});
            let parameter = JSON.stringify(parameterObj);
            prevState.layout.parameter = parameter;
            return({
                currentDeviceId: value,
                currentParameter : parameter,
                currentParameterObj: parameterObj,
                layout: prevState.layout,
            })
        });
    }

    renderDevice = () => {
        return (
            <Fragment>
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="deviceType" style={{display: 'inline', width: "100%"}}><h4><b>Device Type :</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="deviceType" name="deviceType" width="100%" value = {this.state.currentDeviceTypeId} onChange={this.manufactureOnChange}>
                        {/* {this.props.devicetypes && this.props.devicetypes.map((type, i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))} */}
                        {/* <option value={''} >ALL</option> */}
                        <option value={''} >NONE</option>
                        {this.props.devicetypes && this.props.devicetypes.map((type, i) => (<option key={type.id} value={type.id}>{type.name}</option>))}
                    </Input>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="device" style={{display: 'inline', width: "100%"}} ><h4><b>Devices :</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="device" name="device" width="100%" value={this.state.currentDeviceId} onChange={this.onDeviceChange} >      
                        {/* {this.state.deviceTypeDisable && <option value={'0'}> </option>}                               */}
                        {/* {this.state.showEmpgy && <option value={'0'}> No Selection </option>}   */}
                        {/* {this.props.types && this.props.types.map((type,i) => (<option key={type.id} value={type.id}>{type.name}</option>))} */}
                        <option value={''} >No Selection</option>
                        {this.props.deviceList && this.props.deviceList.map((item,i) => (<option key={item.deviceid} value={item.deviceid}>{item.devicename}</option>))}
                    </Input>
                </Col>
            </Row>
            </Fragment>
        )
    }

    onCancleClick = (e) => {
        this.toggle();
    }
    onOkClick = (e) => {
        if (this.state.currentDeviceTypeId && this.state.currentDeviceId) {
             
             
            let layoutClone = JSON.parse(JSON.stringify(this.state.layout));
            // this.props.onEditSubmit(this.state.layout);
            this.props.onEditSubmit(layoutClone);
            this.toggle();
        }else{
            toast.error('Please select a device type and a device .');
        }

    }
    toggle = () => {
        if (this.state.modal) {
            this.initialForm();
        }
        this.setState(prevState=>({
            modal: ! prevState.modal,
        }));
    }
    render(){
         
        let {layout, onEditSubmit, ...rest} = this.props
        return(
            <Fragment>
            {/* <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/> */}
            <EditIcon {...rest} onClick={this.toggle}/>
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={this.state.modal}
                toggle={this.toggle}
                size = {'lg'}
            >
                <ModalHeader 
                    toggle={this.props.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    {this.renderTitle()}
                    <Row>
                        <Col md={12}>                            
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                    <Col md={12}> 
                        {/* {this.props.layout && this.renderParameterInput(this.props.layout.type)} */}
                        {this.renderDevice()}
                    </Col>
                    </Row>
                
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </Fragment>
        )
    }
}

// export default SimplePackDeviceEditIcon;

function mapStateToProps(state) {
    // const types = state.mapboard.types;
    // const assetClasses = state.mapboard.assetClass;
    const devicetypes =  state.mapboard.types;
    const {deviceList} = state.dashboard;
    const props = {devicetypes, deviceList};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getDevices, loadDevice}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SimplePackDeviceEditIcon);