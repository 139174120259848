/* eslint-disable */
import React from 'react';
import {
    compose, 
    bindActionCreators
} from "redux";
import {connect} from "react-redux";
import ContactForm from './ContactForm';
import {Row, Col, Card, CardTitle, CardBody} from 'reactstrap';
import {sendEmail} from '../../../redux/actions/supportAction';

class SupportMain extends React.Component {

    submit = values => {
        // print the form values to the console
        console.log('Form values', values)
      }

    render(){
        return (
            <Row className="justify-content-center">
                <Col md={6} sm={12}>
                    <Card>
                        <CardBody>
                            <h3>Contact Us</h3>
                            <br/>
                            <ContactForm onSubmit={this.props.sendEmail} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendEmail, 
    }, dispatch);
};

export default connect(null, mapDispatchToProps)(SupportMain)