/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Label, Input, Button, FormGroup } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { DeviceMultiSelection, ClassMultiSelection } from './SelectionComponents';
import Select from 'react-select';
import {getCurrentUser, hasRole} from '../../../factories/auth';

// TODO: Add icon selection
class ClassCreateModal extends Component {
   constructor(props) {
      super(props)
      this.state = this.initialState;
      this.userObj = getCurrentUser();
     /* this.state.customStyles = {
         option: (provided, state) => ({
            ...provided,
            color:  "black",
            '&:active': {backgroundColor: this.userObj.detail ? this.userObj.detail.appButtonColorHover : null},
            backgroundColor:  state.isFocused && this.userObj.detail ? this.userObj.detail.appButtonColorHover: "white",
         }),
         control: (base,selectState) => ({
            ...base,
            // height: 35,
            // minHeight: 35
            height: 35,
            minHeight: 35,
            borderColor: selectState.isFocused && this.userObj.detail ? this.userObj.detail.appButtonColorHover : base.borderColor,
            // This line disable the blue border
            boxShadow: selectState.isFocused ? 0 : 0,
            '&:hover': {borderColor: selectState.isFocused && this.userObj.detail ? this.userObj.detail.appButtonColorHover: base.borderColor}
         }),
         indicatorsContainer:  (base,selectState)  => ({
            ...base,
            padding: "0",
         }),
      }*/

   }
   initialState = {
      validationErrors: {
         nameErr: false,
      },
      fields: {
         name: '',
         description: '',
         selectedDeviceTypes: [],
      },
   }

   toggleModalVisible = () => {
      // Reset modal if closing
      if (this.state.modalVisible) { this.setState(this.initialState) }
      this.setState({ modalVisible: !this.state.modalVisible })
   }

   validate = () => {
      let errors = {
         nameErr: false,
      }
      let valid = true
      if (this.state.fields.name === '') {
         errors.nameErr = true
         valid = false
      }

      if (!valid)
         this.setState({ validationErrors: errors })
      return valid
   }
   onSaveClick = () => {
      if (this.validate()) {
         let defaultData = {
            id: 0,
            name: '',
            description: '',
            iconid: 1,
            allowedDeviceTypes: [],
           // assets: [],
           // attributes: [],
           parentclass: null,
         }
         let data = {
            name: this.state.fields.name,
            description: this.state.fields.description,
            allowedDeviceTypes: this.state.fields.selectedDeviceTypes,
            parentclass: this.state.fields.parentclass,
         }

         if (this.state.fields.companycode && this.state.fields.companyname ) {
            data.companycode = this.state.fields.companycode;
            data.companyname = this.state.fields.companyname;
         }
         this.props.addClassToStore({ ...defaultData, ...data })
         this.toggleModalVisible()
      }
   }

   handleChange = (event) => {
      const { id, value } = event.currentTarget
      if (id == 'name') {
         let errs = this.state.validationErrors
         this.setState({ validationErrors: { ...errs, nameErr: false } })
      }
      this.setState({
         fields: { ...this.state.fields, [id]: value }
      })
   }
   handleDeviceTypeSelectionChange = (selectedOptions) => {
      this.setState({
         fields: { ...this.state.fields, selectedDeviceTypes: selectedOptions }
      })
   }

   handleParentChange = (val) => {
      let value = null;
      if (val) {
         value = Number(val.id);
      }
       
      this.setState({
            fields: { ...this.state.fields, parentclass: value }    
      })
   }

   
   handleCompanyChange = (val) => {
      let name = this.props.userObj ? this.props.userObj.company.companyName : '';
      let code = this.props.userObj ? this.props.userObj.companyCode : '';
      if (val) {
         name = val.companyName;
         code = val.companyCode
      }
      this.setState({
            fields: { ...this.state.fields, companycode: code, companyname: name }    
      })
   }


   render() {
      // let deviceTypeDefaultValue = this.props.deviceTypes[0]
      return (
         <>
            <Button variant="primary" style={{float:'left'}} color="custom" onClick={this.toggleModalVisible}>Create New Class</Button>
            <Modal isOpen={this.state.modalVisible}
               toggle={this.toggleModalVisible}>
               <ModalHeader>New Class</ModalHeader>
               <ModalBody>
                  <LeftLabelTextInput
                     forIdName="name" label="Class Name"
                     err={this.state.validationErrors.nameErr}
                     onChange={this.handleChange}
                     defaultValue={this.state.fields.name}
                     //className="form-control"
                  />
                  <LeftLabelTextInput big
                     forIdName="description" label="Description"
                     onChange={this.handleChange}
                     defaultValue={this.state.fields.description}
                     //className="form-control"
                  />
                  <LeftLabelRightInput label="Allowed Device Types">
                     {/* <DeviceMultiSelection */}
                     <ClassMultiSelection
                        options={this.props.deviceTypes}
                        onChange={this.handleDeviceTypeSelectionChange}
                        //styles={this.state.customStyles}
                        //className="form-control"
                        // isDisabled={this.state.fields.selectedDeviceTypes && this.state.fields.selectedDeviceTypes.length > 2 ? true : false}
                        // isOptionDisabled={this.state.fields.selectedDeviceTypes && this.state.fields.selectedDeviceTypes.length > 2 ?
                        //    (option) => {
                        //       console.log(option)
                        //       return option.name == "Adeunis test unit"
                        //    }
                        //    : () => { false }}
                     // defaultValue={deviceTypeDefaultValue}
                     />
                  </LeftLabelRightInput>
                  <LeftLabelRightInput label="Parent Class">
                        <Select
                              isClearable
                              isSearchable
                              options={this.props.assetClassList}
                              getOptionLabel={({ name }) => name}
                              getOptionValue={({ id }) => id}
                              onChange={this.handleParentChange}
                              /*styles={this.state.customStyles}*/
                        />
                  </LeftLabelRightInput>

                  {this.props.userObj &&  hasRole(this.props.userObj,  ['SUPERADMIN']) && 
                        <LeftLabelRightInput label="Company">
                              <Select
                                    isClearable
                                    isSearchable
                                    options={this.props.companyList}
                                    getOptionLabel={({ companyName }) => companyName}
                                    getOptionValue={({ companyCode }) => companyCode}
                                    onChange={this.handleCompanyChange}
                              />
                        </LeftLabelRightInput>
                  }
                  

               </ModalBody>
               <ModalFooter>
                  <Button outline color="custom" onClick={this.onSaveClick}>Save</Button>{' '}
                  <Button outline color="secondary" onClick={this.toggleModalVisible}>Cancel</Button>
               </ModalFooter>
            </Modal>
         </>
      );
   }
}

function LeftLabelRightInput({ label, children }) {
   return (
      <FormGroup row>
         <Col md={3} style={{ textAlign: 'left' }}>
            <Label htmlFor='react-select-2-input'>{label}</Label>
         </Col>
         <Col md={8}>
            {children}
         </Col>
      </FormGroup>
   )
}

function LeftLabelTextInput({ forIdName, onChange, label, err, defaultValue, big }) {
   return (
      <FormGroup row>
         <Col md={3} style={{ textAlign: 'left' }}>
            <Label htmlFor={forIdName}>{label}</Label>
         </Col>
         <Col md={8}>
            <Input type={`text${big ? 'area' : ''}`} width="90%"
               style={{ borderColor: err ? 'red' : '' }}
               id={forIdName}
               name={forIdName}
               onChange={onChange}
               value={defaultValue} />
         </Col>
      </FormGroup>
   )
}

const mapToStateProps = state => {
   return {
      companyList: state.user.companyList,
   }
}

export default connect(mapToStateProps, null)(ClassCreateModal)