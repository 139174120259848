/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Label, Input, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import { ChromePicker } from 'react-color';

import { DeviceMultiSelection, SingleSelection } from './SelectionComponents';
import Select from 'react-select';
import { toast } from 'react-toastify';

export class AssetCreateModal extends Component {
   constructor(props) {
      super(props)
      console.log(props.devices)
      this.state = this.initialState
   }

   initialState = {
      filteredDevices: [],
      fields: {
         name: '',
         description: '',
         // devices: '',
         devices: [],
         isEmpty: false
      },
   }

   toggleModalVisible = () => {
      // Reset fields to default if closing
      let { devices, deviceTypes } = this.props
      let groupedDevices = deviceTypes.map(dt => ({
         label: dt.name,
         options: devices.filter(d => d.devicetype == dt.id)
      }))
      if (this.state.modalVisible) {this.setState(this.initialState)}
      this.setState({ modalVisible: !this.state.modalVisible })
   }

   render() {
      //  
      return (
         <div>
            <Button variant="primary" color="custom" style={{position:'relative',marginRight:'10px',marginLeft:'15px'}} onClick={this.toggleModalVisible}>New Asset</Button>
            <AssetModal
               assetClassList={this.props.assetClassList}
               devices={this.props.devices}
               deviceTypes={this.props.deviceTypes}
               groupedDevices={dtList => dtList.map(dt => ({
                  label: dt.name,
                  options: this.props.devices.filter(d => d.devicetype == dt.id)
               }))}
               title="New Asset"
               modalVisible={this.state.modalVisible}
               toggleModalVisible={this.toggleModalVisible}
               saveAssetToStore={this.props.saveAssetToStore}
               assetList={this.props.assetList}
            />
         </div>
      );
   }
}

export default class AssetModal extends Component {
   constructor(props) {
      super(props)
      this.state = this.initialState
   }

   initialState = {
      validationErrors: {
         nameErr: false,
         descriptionErr: false,
         assetClassErr: false,
      },
      fields: {
         name: '',
         description: '',
         assetClass: '',
         // devices: '',
         devices: [],
      },
   }

   componentWillReceiveProps(nextProps){
      //    if (nextProps.modalVisible && nextProps.modalVisible !== this.props.modalVisible){
      //       this.setState(this.initialState);
      //    }

   }

   toggleModalVisible = () => { 
      if (this.props.modalVisible) {
            this.setState(this.initialState)
      }
      this.props.toggleModalVisible() 
   }

   onSaveClick = () => {
      if (this.validate()) {
         let defaultData = {
            attributes: [],
            assetid: 0,                                //if assetid =0, will create new record
            assetname: "",
            assetclassid: -1,
            assetclassname: "",
            description: "",
            devices: [],
            parentasset: null,
            // geofence: 0,
         }
         // SPECIFY CORRECT ATTRIBUTES
         let data = {
            assetname: this.state.fields.name,
            assetclassid: parseInt(this.state.fields.assetClass),
            assetclassname: this.props.assetClassList.find(ac => ac.id == this.state.fields.assetClass).name,
            description: this.state.fields.description,
            devices: this.state.fields.devices,
            parentasset: this.state.fields.parentasset,
         }
         this.props.saveAssetToStore({ ...defaultData, ...data })
         this.toggleModalVisible()
      }
   }
   validate = () => {
      let errors = {
         nameErr: false,
         descriptionErr: false,
         assetClassErr: false,
         assetParentErr: false,
      }
      let valid = true
      let { fields } = this.state;
      //  
      //  
      if (!fields.name || fields.name.length === 0 || fields.name === '') {
         //  
         errors.nameErr = true
         valid = false
      }
      // if (fields.description === '') {
      //    errors.descriptionErr = true
      //    valid = false
      // }
      if (fields.assetClass === '') {
         errors.assetClassErr = true
         valid = false
      }

      let assetClassInfo = this.props.assetClassList.find(ac => ac.id == this.state.fields.assetClass);
      let parentOptions = null;
      if (assetClassInfo) {
            parentOptions = this.props.assetList.filter((item, index) => (item.assetclassid == assetClassInfo.parentclass));
            if (assetClassInfo.parentclass && (!parentOptions || parentOptions.length === 0) ) {
               errors.assetParentErr = true
               valid = false
            }
      }
      if (parentOptions && parentOptions.length > 0 ) {
            if (!fields.parentasset){
                  errors.assetParentErr = true
                  valid = false
            }
      }
       

      if (!valid)
         this.setState({ validationErrors: errors })
      return valid
   }

   handleChange = (event) => {
      // console.log(event.currentTarget)
      const { id, value } = event.currentTarget
       
      // Undo error once user starts changing
      let errs = this.state.validationErrors
      errs[id + 'Err'] = false
      this.setState({ validationErrors: errs })
      this.setState({ fields: { ...this.state.fields, [id]: value, devices: []}, selectedParrentAsset: null })
      // , () => {
      //    if (id == 'assetClass') {
      //       this.setState({ filteredDevices: this.props.fetchDevicesOfType(this.state.fields.deviceType) }, () => {
      //          this.setState({ fields: { ...this.state.fields, devices: [this.state.filteredDevices[0]] } })
      //       })
      //       this.setState({ showDeviceSelect: true })
      //    }
      // })
   }

   handleMultiSelectionChange = (selectedOptions) => {
      let err = this.state.validationErrors
      this.setState({
         validationErrors: { ...err, assetsErr: false },
         fields: { ...this.state.fields, devices: selectedOptions || [] }
      })
   }

   handleParentAssetChange = (val) => {
      let value = null;
      if (val) {
         // value = Number(val.assetid);
         value = val.assetid;
      }
       
      this.setState({
            fields: { ...this.state.fields, parentasset: value },
            selectedParrentAsset: val
      },()=> {
          
      });
   }

   

   render() {
      // let assetClassInfo = this.props.assetClassList.find(ac => ac.id == this.state.fields.assetClass)
      let assetClassInfo = this.props.assetClassList.find(ac => ac.id == this.state.fields.assetClass);
      let parentOptions = null;
      let showParent = false;
      let parentErr = false;
      if (assetClassInfo){
            showParent = (assetClassInfo.parentclass != undefined && assetClassInfo.parentclass != null) ;
            parentOptions = this.props.assetList.filter((item, index) => (item.assetclassid == assetClassInfo.parentclass));
            if (assetClassInfo.parentclass && (!parentOptions || parentOptions.length ===0) ) {
               parentErr = true;
               toast.error('Parent Asset is empty, Please set Parent Asset first!');
            }
      }
      let devices = this.state.fields.devices;
      let isEmpty = devices.length;
     

      
      //  
      //
      
      // console.log("===initial", this.state.fields.devices);
      // console.log("===initial", isEmpty);
      // console.log("===isEmpt", this.state.fields.isEmpty);
      return (
         <Modal isOpen={this.props.modalVisible}
            toggle={this.toggleModalVisible}>
            <ModalHeader>{this.props.title}</ModalHeader>
            <ModalBody>
               <LabelTextInput
                  forIdName={'name'} label={'Name'}
                  onChange={this.handleChange}
                  value={this.state.fields.name}
                  err={this.state.validationErrors.nameErr}
               />
               <LabelTextInput big
                  forIdName={'description'} label={'Description'}
                  onChange={this.handleChange}
                  value={this.state.fields.description}
                  err={this.state.validationErrors.descriptionErr}
               />
               <LabelSelectionInput
                  forIdName="assetClass" label="Asset Class"
                  onChange={this.handleChange}
                  value={this.state.fields.assetClass}
                  options={this.props.assetClassList}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  err={this.state.validationErrors.assetClassErr}
               />
               {this.state.fields.assetClass != '' &&
                  <FormGroup row>
                     <Col md={3} style={{ textAlign: 'left' }}>
                        <Label htmlFor='react-select-2-input'>Devices</Label>
                     </Col>
                     <Col md={8} style={{ textAlign: 'left' }}>
                        <DeviceMultiSelection
                           // options={this.props.groupedDevices}
                           options={this.props.groupedDevices(
                              this.props.deviceTypes.filter(dt =>
                                 assetClassInfo.allowedDeviceTypes.some(dT => dT.id == dt.id))
                           )}
                           onChange={this.handleMultiSelectionChange}
                           value={this.state.fields.devices}
                        />
                     </Col>
                  </FormGroup>
               }
               {/* {parentOptions && parentOptions.length > 0 && */}
               {showParent &&
                  <LabelSelectionInput2
                     forIdName="parentAsset" label="Parent Asset"
                     // options={this.props.assetList}
                     options={parentOptions}
                     getOptionLabel={({ assetname }) => assetname}
                     getOptionValue={({ assetid }) => assetid}
                     isClearable
                     isSearchable
                     value={this.state.selectedParrentAsset}
                     // // defaultValue={}
                     onChange = {this.handleParentAssetChange}
                     err = {parentErr || this.state.validationErrors.assetParentErr}
                  />
               }

            </ModalBody>
            <ModalFooter>
               <Button outline color="custom" onClick={this.onSaveClick} /* disabled={!isEmpty} */>Save</Button>{' '}
               <Button outline color="secondary" onClick={this.toggleModalVisible}>Cancel</Button>
            </ModalFooter>
         </Modal>
      );
   }
}

function LabelSelectionInput(props) {
   return (
      <FormGroup row>
         <Col md={3} style={{ textAlign: 'left' }}>
            <Label htmlFor={props.forIdName}>{props.label}</Label>
         </Col>
         <Col md={8}>
            <SingleSelection
               {...props} />
         </Col>
      </FormGroup>
   )
}

function LabelSelectionInput2(props) {
   //  
   const customStyles = {
         control: (provided, state) => ({ // Mimic reactstrap input styles
           ...provided,
           borderColor: props.err ? 'red' : state.isFocused && !state.isDisabled ? '#80bdff' : 'lightgray',
         //   borderColor: props.err ? 'red' : 'lightgray',
         //   borderColor:'#ff0000' ,
          /* boxShadow: state.isFocused && !state.isDisabled ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
           '&:hover': { borderColor: state.isFocused && '#ff0000' },*/
         }),
   }
   return (
      <FormGroup row>
         <Col md={3} style={{ textAlign: 'left' }}>
            <Label htmlFor={props.forIdName}>{props.label}</Label>
         </Col>
         <Col md={8}>
            {/* <SingleSelection
               {...props} /> */}
            <Select 
               // isClearable
               // isSearchable
               // options = {this.state.assets}
               // getOptionLabel={({ assetname }) => assetname}
               // getOptionValue={({ assetid }) => assetid}
               // value={this.state.selectedAsset}
               // // defaultValue={}
               // onChange = {this.handleAssetChange}
               styles={customStyles}
               {...props}
            />
         </Col>
      </FormGroup>
   )
}


function LabelTextInput({ forIdName, onChange, label, value, big, err }) {
   return (
      <FormGroup row>
         <Col md={3} style={{ textAlign: 'left' }}>
            <Label htmlFor={forIdName}>{label}</Label>
         </Col>
         <Col md={8}>
            <Input type={`text${big ? 'area' : ''}`} width="90%"
               style={{ marginBottom: 5, borderColor: err ? 'red' : '' }}
               value={value}
               id={forIdName}
               name={forIdName}
               onChange={onChange} 
               />
         </Col>
      </FormGroup>
   )
}