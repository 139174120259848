/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import {connect } from 'react-redux';
// import {bindActionCreators } from 'redux';
import { Card, CardTitle, CardBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container, Input } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import { ExitTextButton } from '../../../shared/components/Button/IconButton';

class CompanyEditBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps.showAccount', nextProps.showAccount);
        // console.log('test formula', nextProps.showAccount !== this.state.showAccount)
        if (nextProps.showAccount && nextProps.showAccount !== this.state.showAccount) {
            // console.log('nextProps.showAccount int', nextProps.showAccount)
            this.setState({
                activeTab: '5',
                showAccount: nextProps.showAccount,
            }, () => {
                console.log('inti tab', this.state.activeTab)
            });
        }
        if (nextProps.showPayment && nextProps.showPayment !== this.state.showPayment) {
            this.setState({
                activeTab: '7',
                showPayment: nextProps.showPayment,
            });
        }
    }

    goToPlan = () => {
        this.setState({
            activeTab: '5',
        })
    }

    tabToggle = (tab) => {
        if (this.state.activeTab !== tab) {

            this.setState({
                activeTab: tab
            });
        }
    }

    renderTab = () => {
        return (
            <Fragment>
                <Nav tabs>
                    <TabLabel
                        tabId='1'
                        activeTab={this.state.activeTab}
                        disabled={false}
                        tabToggle={this.tabToggle}
                    // label={'Edit Info'}
                    >
                        Edit Info
                    </TabLabel>
                    <TabLabel
                        tabId='2'
                        activeTab={this.state.activeTab}
                        disabled={false}
                        tabToggle={this.tabToggle}
                    // label={'Assign Asset Class'}
                    >
                        Assign Asset Class
                    </TabLabel>
                    <TabLabel
                        tabId='3'
                        activeTab={this.state.activeTab}
                        disabled={false}
                        tabToggle={this.tabToggle}
                    // label={'Assign Device Type'}
                    >
                        Assign Device Type
                    </TabLabel>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        {this.props.EditRender()}
                    </TabPane>
                    <TabPane tabId="2">
                        content asset class
                    </TabPane>
                    <TabPane tabId="3">
                        content device type
                    </TabPane>
                </TabContent>
            </Fragment>
        );
    }

    renderTabs = () => {
        return (
            <Fragment>
                {(this.props.onExitCompany != null) ?
                    <div style={{ float: "right" }}>
                        <ExitTextButton label={''} onClick={(e) => { this.props.onExitCompany() }} />{' '}
                    </div>
                    :
                    null
                }

                <div style={{ padding: "10px" }}>
                    {(this.props.companyObj != null) ?
                        <h3>{this.props.companyObj.companyName}'s Profile</h3>
                        :
                        null}

                </div>
                <Nav tabs>
                    {this.props.tabObjs && this.props.tabObjs.map((item, index) => (
                        <TabLabel
                            tabId={item.tabId}
                            activeTab={this.state.activeTab}
                            disabled={false}
                            tabToggle={this.tabToggle}
                        >
                            {item.label}
                        </TabLabel>
                    ))}
                </Nav>
                <TabContent
                    activeTab={this.state.activeTab}
                >
                    {this.props.tabObjs && this.props.tabObjs.map((item, index) => (
                        <TabPane tabId={item.tabId}>
                            {item.renderTab({ goToPlan: this.goToPlan, activeTab: this.state.activeTab })}
                        </TabPane>
                    ))}
                </TabContent>
            </Fragment>
        )
    }

    render() {
        return (
            <Col md={12}>
                <Card>
                    <CardBody>
                        {/* {this.renderTab()} */}
                        {this.renderTabs()}
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

const TabLabel = (props) => {
    return (
        <NavItem style={{ cursor: 'pointer' }}>
            <NavLink
                className={classnames({ active: props.activeTab === props.tabId })}
                onClick={() => { props.tabToggle(props.tabId); }}
            // disabled={props.disabled}
            >
                <h4><b>{props.children}</b></h4>
            </NavLink>
        </NavItem>
    )
}

export default CompanyEditBoard;