/* eslint-disable */
import axios from 'axios';
import https from 'https';
import { toast } from "react-toastify";
import { axiosGet, axiosPost, getCurrentUser, base64Encode, getJwttoken } from '../../factories/utils';
import {
    ASSET_GET_RECURSIVE_DATA_SUCCEED,
    ASSET_GET_RECURSIVE_DATA_FAILED, 
    ASSET_RESET_RECURSIVE_DATA,
    ASSET_REMOVE_RECURSIVE_LEVEL,
    ASSET_RECURSIVE_START,
} from '../../constants/actionTypes';
import {
    WEB_SERVICE_ROOT,
    WEB_SERVICE_USER_ROOT,
    WEB_SERVICE_GET_RECURSIVE_ASSET_DATA,
} from '../../constants/appConstants';


export function getAssetRecursiveData(assetid){
    let criteria = {assetid: assetid};
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_RECURSIVE_ASSET_DATA}`,
        criteria: criteria,
        logText: 'Get Asset Recursive data url : ',
        startType: ASSET_RECURSIVE_START,
        sccessType: ASSET_GET_RECURSIVE_DATA_SUCCEED,
        failedType: ASSET_GET_RECURSIVE_DATA_FAILED,
        errorMessage: 'Failed to get asset recursive data.',
        // successMessage: 'Successfully get asset recursive data.',

    };
    return(axiosGet(paramObj)); 

}

export function clearAssetRecursiveData(){
    return dispatch => {
        dispatch({
          type: ASSET_RESET_RECURSIVE_DATA,
          message: "Clear Asset Recursive Data",
        });
    }
}

export function removeRecursiveLeve(levelindex){
    return dispatch => {
        dispatch({
            type: ASSET_REMOVE_RECUSIVE_LEVEL,
            payload: levelindex,
        });
    }
}



