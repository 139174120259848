/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import { Card, CardBody, Col } from 'reactstrap';
import { Col, Container, Row, Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import { EditIcon, WindowCloseIcon } from 'mdi-react';
import {chunk} from '../../../factories/utils';
import {
    getAssetsData, 
    // loadAssetList
    getAttributeThreshold,
    updatePubSubDevice,
    deleteDashBoardWidget,
} from '../../../redux/actions/dashboardActions';
// import {getAssetsData} from '../../../redux/actions/mapActions';
import AttrParamEditModal from './AttrParamEditModal';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import {WEB_SUB_CHANNEL, WEB_SUB_EVENT, WEB_UPLOAD_ROOT} from '../../../constants/appConstants';
import { toast } from 'react-toastify';

class AttrValueWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            modal : false,
            dataValue: "0",
            textcolor: 'black',
            backgroudcolor: 'white',
            threshlold: "",
            // threshold: 20,
        }
        // this.eventSource = new EventSource("http://192.168.2.103:8080/sendRedisMessageTest/get");
        // this.eventSource = new EventSource(WEB_SUB_CHANNEL);
    }

    componentDidMount(){
         
        if (this.props.dashName && this.props.parameter && this.props.parameter.assetid){
            // this.props.getAttributeThreshold( this.props.parameter.assetid, this.props.parameter.attrid , this.props.dashName);
             
            // this.props.getAssetsData({assetid: this.props.parameter.assetid, latest: 1}, this.props.dashName);
            // this.loadInterval = setInterval(() => {
            //       
            //     this.props.getAssetsData({assetid: this.props.parameter.assetid, latest: 1}, this.props.dashName);
            // }, 15000);
        }       
        // this.eventSource.addEventListener("devicemessage", (e)=> {
        // this.eventSource.addEventListener(WEB_SUB_EVENT, (e)=> {
        //       
        //       
        //     // this.props.updatePubSubDevice(e.data) ;
        //     this.updateAssetValue(e.data);
        // })
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.dashName && nextProps.parameter && nextProps.parameter.assetid && nextProps.parameter.attrid) {
            if (nextProps.dashName !== this.state.dashName || nextProps.parameter.assetid !== this.state.assetid) {
                this.setState({
                    dashName: nextProps.dashName,
                    assetid: nextProps.parameter.assetid,
                });
                this.props.getAssetsData({assetid: this.props.parameter.assetid, latest: 1}, this.props.dashName);
            }
            if (nextProps.parameter.attrid !== this.state.attrid){
                this.setState({
                    attrid: nextProps.parameter.attrid,
                });
                this.props.getAttributeThreshold( nextProps.parameter.assetid, nextProps.parameter.attrid , nextProps.dashName);
            }
        }
        if (nextProps.parameter) {
             
        }
        if (nextProps.dashName && nextProps.parameter) {
            let dashname = nextProps.dashName;
            if (nextProps.attributeValue && nextProps.attributeValue[dashname] && nextProps.attributeValue[dashname] != this.props.attributeValue[dashname]) {
                let assetData = nextProps.attributeValue[dashname];
                 
                this.setAttributes(assetData, nextProps.parameter);
            }

            if (nextProps.threshold && nextProps.threshold[dashname] && nextProps.threshold[dashname] != this.props.threshold[dashname]) {
                let rule = nextProps.threshold[dashname];
                 
                // this.setAttributes(assetData, nextProps.parameter);
                this.setThreshold(rule);
            }
        }
        // if (nextProps.pubsubDevice){
        //     this.updateAssetValue(nextProps.pubsubDevice);
        // }
        if (nextProps.pubSubData){
             
            this.updateAssetValue(nextProps.pubSubData);
        }
    }

    componentWillUnmount(){
         
        if (this.flashInterval){
            clearInterval(this.flashInterval);
        }
        if (this.loadInterval){
            clearInterval(this.loadInterval);
        }
        if (this.eventSource){
            this.eventSource.close();
        }
        
    }

// --------------------------------------------------------------------------
    updateAssetValue(pubData) {
         
         
         
         
        // let dataObj = JSON.parse(JSON.stringify(pubData));
        let dataObj = JSON.parse(pubData);
         
         
        if (dataObj && this.props.parameter && (dataObj.deviceid == this.props.parameter.deviceid || dataObj.assetid == this.props.parameter.assetid)) {
            if (dataObj.msgs && dataObj.msgs.length > 0) {
                let msgObj = dataObj.msgs[dataObj.msgs.length-1];
                 
                Object.entries(msgObj.msg).forEach(([attr, attrObj],mindex)=>{
                     
                     
                    // if (attrObj.id == this.props.parameter.attrid){
                    if (attrObj.id == this.props.parameter.attrid || attrObj.attributename == this.props.parameter.attrname){
                        // this.setState({
                        //     dataValue: attrObj.value,
                        // });
                        let type = null;
                        if (attrObj.type){
                            type = attrObj.type;
                            if (attrObj.attributename == 'image' || attrObj.type == 'image') {
                                type = 'image'
                            }
                        }
                        this.setValue(attrObj.value, this.state.threshold, type);
                    }
                });
            }
        }

    }

    setThreshold = (ruleList) => {
         
        if(ruleList && ruleList.length > 0){
            let ruleObj = ruleList[0];
             
            if ((ruleObj.operator == 2 || ruleObj.operator == 3) && !isNaN(ruleObj.value)){
                let value = Number(ruleObj.value);
                 
                this.setState({
                    threshold: value
                });
            }
        }
    }

    setValue = (value, threshold, type = null) => {
        if (this.flashInterval){
            clearInterval(this.flashInterval);
        }
        if (threshold && !isNaN(value) && Number(value) >= threshold) {
            this.flashInterval = setInterval(() => {
                //   
                this.setAlarmValue(value);
            }, 500);
        }else {
            this.setState({
                dataValue: value,
                dataType: type,
                flash: false,
                textcolor: 'black',
                backgroudcolor: 'white',
            });
        }
    }

    setAlarmValue = (value) => {
        this.setState(prevState => ({
            flash: !prevState.flash,
            textcolor: prevState.flash ? 'red': 'white',
            backgroudcolor:  prevState.flash ? 'white': 'red',
            dataValue: value,
        }))
    }

// --------------------------------------------------------------------------
    setAttributes = (assetData, parameter) => {
        if (assetData && assetData.length > 0 && assetData[0].asset) {
            //  
            if (assetData[0].asset.length > 0 && assetData[0].asset[0].device) {
                let deviceArr = assetData[0].asset[0].device; 
                for (let i=0; i < deviceArr.length; i++){
                    let deviceObj = assetData[0].asset[0].device[i];
                    if (deviceObj && parameter && deviceObj.deviceid == parameter.deviceid){
                        if (deviceObj.msgs && deviceObj.msgs.length > 0){
                            let msgObj = deviceObj.msgs[0];
                            Object.entries(msgObj.msg).forEach(([attr, attrObj],mindex)=>{
                                // if (attrObj.id == parameter.attrid){
                                if (attrObj.attributename == parameter.attrname){
                                    // this.setState({
                                    //     dataValue: attrObj.value,
                                    // });
                                    let type = null;
                                    if (attrObj.type){
                                        type = attrObj.type;
                                        if (attrObj.attributename == 'image' || attrObj.type == 'image') {
                                            type = 'image'
                                        }
                                    }
                                    this.setValue(attrObj.value, this.state.threshold, type);
                                }
                            });
                        }
                        break;
                    }
                }
            }
        }
    }
// --------------------------------------------------------------------------
    toggle = () => {
         
        this.setState((prevState) => ({
            modal : !prevState.modal
        }))
    }

// ---------------------------------------------------------------------------
    renderAttrCard = (label, sublabel, value, cal) => {
        let h = JSON.parse(this.props.layout.position).h;
        let height = h * 30 - 20;
        return (
            <Fragment>
            {/* <div style={{  borderStyle: 'solid', borderWidth: '1px',}}>
            <Card style={{paddingBottom:10}} > */}
                <CardBody style={{padding:10, backgroundColor: this.state.backgroudcolor}}>
                    {/* <CardTitle><span style={{fontSize:'20px'}}>{label}</span></CardTitle>
                    <CardSubtitle style={{textAlign: 'right'}}><span style={{fontSize:'20px'}}>{sublabel}</span></CardSubtitle> */}
                    <CardTitle>
                        <Row>
                            <Col md={12}>
                            <span style={{textAlign: 'left', fontSize:'20px', color: this.state.textcolor}}>{label}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{textAlign: 'right'}}>
                            <span style={{fontSize:'20px', color: this.state.textcolor}}>{sublabel}</span>
                            </Col>
                        </Row>
                    </CardTitle>

                    {this.state.dataType && this.state.dataType == 'image' ?
                        <img src={`${WEB_UPLOAD_ROOT}${value}`} alt="Card image cap" width="100%" height={height.toString()}></img>
                    :
                    <Fragment>
                    <CardText style={{textAlign: 'center'}} ><span style={{fontSize:'50px', color: this.state.textcolor}}>{value}</span></CardText>
                    <CardText style={{textAlign: 'right'}}>
                        <Col md={12}>
                        {cal ?
                            <span style={{fontSize:'20px'}}>{cal}</span>
                            : 
                            <br/>
                        }
                        
                        </Col>
                    </CardText>
                    </Fragment>
                    }
                </CardBody>
            {/* </Card>
            </div> */}
            </Fragment>
        )
    }

    onDeleteWidget = () => {
        if (this.props.layout){
            const {layoutid, templateid} = this.props.layout
            this.props.deleteDashBoardWidget(layoutid, templateid);
        }else{
            toast.error('Could not get the layout ID! Please try again.');
        }
    }

    render(){
        let subtitle = this.state.threshold ? this.state.threshold : null;
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                            {this.props.dashName} 
                            {this.props.layout && 0 == this.props.layout.locked &&
                            <>
                                {/* <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>     */}
                                <ConfirmDeleteDialog 
                                    // label={""} 
                                    icon = {(props)=>(<WindowCloseIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} {...props}/>)}
                                    style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                    onConfirm={(e)=>{this.onDeleteWidget()}}
                                    msg={'Do you want to delete the selected widget ? '}
                                />
                                <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} onClick={this.toggle}/>    
                            </>
                            }
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                {/* <CardBody style={{padding:15}}>   */}
                    {/* {this.renderAttributes()} */}
                    {this.renderAttrCard(this.props.dashName, subtitle, this.state.dataValue, null)}
                {/* </CardBody>   */}
          </Card>
          <AttrParamEditModal            
            // assetList = {this.props.assetList}
            layout = {this.props.layout}
            parameter = {this.props.parameter}
            modal={this.state.modal}
            toggle={this.toggle}
            onEditSubmit={this.props.onEditSubmit}
          />
          </Fragment>
        );
    }
}

function mapStateToProps(state) { 
    // const farmattribute = state.dashboard.farmattribute;
    // const props = {farmattribute};
    // // const assetData = state.mapboard.assetData;
    // // const props = {assetData};
    // return props;

    const props = {...state.dashboard};
    return props;
};
    
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAssetsData, 
        // loadAssetList
        getAttributeThreshold,
        updatePubSubDevice,
        deleteDashBoardWidget,
    }, dispatch);
};
  
export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(AttrValueWidget));