/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableLargeIcon from 'mdi-react/TableLargeIcon';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';
import { EditIcon } from 'mdi-react';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';
import {loadDevice, loadAssetClass} from '../../../redux/actions/mapActions';

class BasicDashboadEditIcon extends Component{
    constructor(props){
        super(props);
        this.state={
            tital: 'Dashboard Element',
            modalList: this.props.modalList,
            selectedAssetClassId: null,
            // selectedDeviceTypeId: null,
            modal: false,
        }
    }

    componentDidMount(){
        // this.props.loadDevice();
        // this.props.loadAssetClass();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.layout && nextProps.layout !== this.state.rawLayout){
            
            this.setState({
                currentName: nextProps.layout.name,
                layout: nextProps.layout,
                rawLayout: nextProps.layout,
            });
        }
    }
    
    handleInputChange =(newVal) => {
        this.setState((prevState)=>{
            if (prevState.layout){
                prevState.layout.name = newVal;
                return ({
                    currentName: newVal,
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        });
    }

    renderTitle =()=> {
        return(
            
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                         type='text'
                         defaultValue={this.props.layout ? this.props.layout.name : ''} 
                         exitOnEnterKeyPress={false}
                         onSave={newVal => { this.handleInputChange(newVal)}}
                    />
                </Col>
            </Row>           
        )
    }

    onCancleClick = (e) => {
        this.toggle();
    }
    onOkClick = (e) => {
        this.props.onEditSubmit(this.state.layout);
        this.toggle();
    }
    toggle = () => {
        this.setState(prevState=>({
            modal: ! prevState.modal,
        }));
    }
    render(){
        let {layout, onEditSubmit, ...rest} = this.props
        return(
            <Fragment>
            {/* <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/> */}
            <EditIcon {...rest} onClick={this.toggle}/>
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={this.state.modal}
                toggle={this.toggle}
                size = {'lg'}
            >
                <ModalHeader 
                    toggle={this.props.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    {this.renderTitle()}
                    <Row>
                        <Col md={12}>                            
                        </Col>
                    </Row>
                    <br/>
                
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
            </Fragment>
        )
    }
}

export default BasicDashboadEditIcon;

// function mapStateToProps(state) {
//     const types = state.mapboard.types;
//     const assetClasses = state.mapboard.assetClass;
//     const props = {types,  assetClasses};
//     return props;
// };

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({
//             loadDevice, 
//             loadAssetClass,
//     }, dispatch);
// };

// export default connect(mapStateToProps, mapDispatchToProps)(BasicDashboadEditIcon);