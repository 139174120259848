/* eslint-disable */

import React, {useEffect, useState} from 'react'
import JSMpeg from "@cycjimmy/jsmpeg-player";
import {VIDEO_WS_ROOT} from '../../../constants/appConstants'

function ReactPlayer({ch, chid, height, width}) {

    useEffect(() => {
        let videoUrl = `${VIDEO_WS_ROOT}/socketSecure/${ch}/${chid}`
        let player = (ch && chid && height && width)? new JSMpeg.VideoElement("#video-canvas", videoUrl, {
            autoplay: true,
        }) : null;
        console.log(player);
    }, [ch, chid])
    if(ch && height && width){
        return (
            <div id="video-canvas" style={{ height: `${height}`, width: `${width}` }}></div>
        )
    }else{
        return null
    }
}

export default ReactPlayer
