/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { compose, bindActionCreators } from 'redux'
import {connect} from "react-redux";
import { Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import {Container, Row, Col, Button, Table, Card, CardBody, Input, Label} from 'reactstrap';
import Select from 'react-select';
import {loadAllGeoFences} from '../../../redux/actions/fenceAction';
import {generateSelectOptions} from '../../../factories/utils';
import {getCurrentUser} from '../../../factories/auth';
import { GOOGLE_API_KEY } from '../../../constants/appConstants'; 
import Geocode from 'react-geocode';




//implementing branding styles for Select
export const style = {
    control: (provided,state) => ({
        ...provided,
        border: state.isFocused ? '1px solid var(--appButtonColor, #ced4da)' : '1px solid #ced4da',
        // This line disable the blue border
        boxShadow: "none",
        '&:hover': {
            border: '1px solid var(--appButtonColor, #ced4da)'
        }
    }),
    option: (provided,state)=>({
        ...provided,
        backgroundColor: state.isFocused ? 'var(--appButtonColor)' : 'none',
        opacity: state.isFocused ? 0.7 : 1
    })
};
export class DashboardMap extends Component{
    constructor(props){
        super(props);
        this.myMap = React.createRef();
        // this.inputSearch = React.createRef();
        this.initMap = this.initMap.bind(this);
        this.getMarkers = this.getMarkers.bind(this);
        this.generateIcon = this.generateIcon.bind(this);
        this.handleMapMarkerMouseOver = this.handleMapMarkerMouseOver.bind(this);
        this.handleMarkerClick = this.handleMarkerClick.bind(this);
        this.handleMarkerMouseOut = this.handleMarkerMouseOut.bind(this);
        this.state={
            fenceOptions: [],
            renderMarkers: [],
        }
        this.userObj = getCurrentUser();
        this.mapStyle = {
            width: '100%',
            height: (JSON.parse(this.props.layout.position).h * 30 + 60).toString() + 'px', //'510px',
            position : 'relative',
        };  
        if (!this.userObj) {
            toast.error('Can not fetch login user information, Please re-login.');
            this.props.history.push('/log_in');
        }else{
             
              
        }    
        this.infoWindowRef = React.createRef();
        this.contentElement = document.createElement('div');
    }

    selectedShape;

    componentDidMount(){
        this.props.loadAllGeoFences();
        if (this.props.mapData && this.props.mapData.length > 0) {
            // console.log('MAP DATA DASHBOARDMAP.JSX', this.props.mapData)
            this.getMarkers(this.props.mapData)
        }
    }
    
    handleMarkerClick(){
        // e.preventDefault();
        // console.log('layout clicked')
        let path = '/pages/surveillance';
        this.props.history.push({
            pathname: path,
            state:{selectedPlace: this.state.selectedPlace}
        });
    }

    componentWillReceiveProps(nextProps){
        let fenceOptions = [];
        if (nextProps.allfences){
            fenceOptions = generateSelectOptions(nextProps.allfences, 'fencename', 'fenceid');
        }
        fenceOptions.unshift({
            label: "None", 
            value: 0,
        });
        this.setState({
            fenceOptions: fenceOptions,
        });
        if (nextProps.mapData && nextProps.mapData.length > 0 && nextProps.mapData !== this.props.mapData) {
            // console.log('MAP DATA', nextProps.mapData)
            this.getMarkers(nextProps.mapData)
        }
    }

    handleMapMarkerMouseOver = (props, marker, e) => {
        // console.log('MARKER CLICKED, ', props, marker)
        setTimeout(()=>{
            this.setState({
                ...this.state,
                showingInfoWindow: true,
                activeMarker: marker,
                selectedPlace: props,
                infowindowLat: props.position.lat,
                infowindowLng: props.position.lng,
                infowindowAddress: props.address
            });  
        }, 700)
               
    }

    handleMarkerMouseOut = (props, marker, e) => {
        // console.log("==MOUSE OUT====")
        this.setState({
            ...this.state,
            showingInfoWindow: false
        })
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };

      
    // handleMapInfoWinClick = (props) => {
    //   console.log('INFO WINDOW CLICKED, ', props)
    //   if(this.state.mapProps.showingInfoWindow){
    //       this.setState({
    //           showingInfoWindow: false,
    //           activeMarker: null
    //       });
    //   }
    // }

    handleInfoWindowClose = () =>{
        this.setState({
            ...this.state,
            showingInfoWindow: false
        })
    }

    //--------------------------------------------------------------------
    setSelection = (shape) => {        
        this.selectedShape = shape;
    }
    deleteSelectedShape = () => {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
        }
    }
    showShapOnMap = (shape) => {
        if (shape && this.myMap && this.myMap.current){
            // alert('yes');
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            this.deleteSelectedShape();
            this.setShap(google, map, shape);
        }else{
            this.deleteSelectedShape();
        }
    }
    //--------------------------------------------------------------------
    setShap = (google, map, shape) => {
        // let shape = this.props.shape;

        let self = this;
        let newCenter;
        let bounds;
        if (shape) {
            // self.deleteSelectedShape();
            switch(shape.type) {
                case 'circle':
                    const initCircle=new google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        center: shape.data.center,
                        radius: shape.data.radius,
                        editable: false
                      });
                    
                    bounds = initCircle.getBounds();
                    newCenter = initCircle.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initCircle);
                    // this.setCircleData(initCircle); 
                    break;
                case 'rectangle':
                    const initRectangle=new google.maps.Rectangle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        bounds: shape.data,
                        editable: false
                      });

                      bounds = initRectangle.getBounds();
                      newCenter = bounds.getCenter();
                      map.setCenter(newCenter);
                      map.fitBounds(bounds);
                      this.setSelection(initRectangle);
                    //   this.setRectangleData(initRectangle); 
                    break;
                case 'polygon':
                    google.maps.Polygon.prototype.getBoundingBox = function(){
                        var bounds = new google.maps.LatLngBounds();
                        this.getPath().forEach(function(element,index) {
                          bounds.extend(element)
                        });
                        return(bounds);
                    }
                    const initPolygon=new google.maps.Polygon({
                        paths: shape.data,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        editable: false
                    });
                    
                    bounds = initPolygon.getBoundingBox();
                    newCenter = bounds.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initPolygon);
                    // this.setPloygonData(initPolygon); 
                    break;
            }
        }
    }

    // -------------------------------------------------------------------
    getMapBounds = (google, map) => {
        let bounds = null;
        let assetClass;

        if (this.props.mapData && this.props.mapData.length > 0) {
            bounds = new google.maps.LatLngBounds();
            assetClass = this.props.mapData[0];
            assetClass.asset.forEach((location) => {
                bounds.extend(
                  new google.maps.LatLng(location.locationlat, location.locationlng),
                );
            });
        }
      
       
        return bounds;
    };

    getCompanyBounds = (google, map) => {
         
        let bounds = null;
        if (this.userObj.company && this.userObj.company.businessArea){
             
            let points = JSON.parse(this.userObj.company.businessArea);
            bounds = new google.maps.LatLngBounds();
            points.forEach((point,index) => {
                bounds.extend(
                  new google.maps.LatLng(point.lat, point.lng),
                );
            });
        }
        return bounds;
    }

    getFirstOne = (google, map) => {
        let mylocation = null;
        let assetClass;

        if (this.props.mapData && this.props.mapData.length > 0) {
            // bounds = new google.maps.LatLngBounds();
            assetClass = this.props.mapData[0];
            assetClass.asset.forEach((location) => {
                // bounds.extend(
                //   new google.maps.LatLng(location.locationlat, location.locationlng),
                // );
                 
                mylocation =  new google.maps.LatLng(location.locationlat, location.locationlng);
                 
            });
        }
      
       
        return mylocation;
    };
       
    //--------------------------------------------------------------------
    initMap(mapProps, map) {
        var self = this;
        const {google} = mapProps;
               
        // self.setShap(google, map, self.state.shape);
        // --------------------------------------------
        // let bounds = this.getMapBounds(google, map);
        // if (bounds){
        //     map.fitBounds(bounds);       
        //     // map.panToBounds(bounds);  
        // }

        let bounds = this.getCompanyBounds(google, map);
        if (bounds){
            map.fitBounds(bounds);       
            // map.panToBounds(bounds);  
        }

        // let location = this.getFirstOne(google, map);
        //  
        //  
        // if (location){
        //     // map.setCenter(location);       
        //     map.panToBounds(bounds);  
        // }
       
        // --------------------------------------------
            var input = ReactDOM.findDOMNode(self.refs.inputTest);
            var searchBox = new google.maps.places.SearchBox(input);
            // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            map.addListener('bounds_changed', function() {
                searchBox.setBounds(map.getBounds());
            });
            searchBox.addListener('places_changed', () => {
                var places = searchBox.getPlaces();
                if (places.length == 0) {
                    return;
                }

                // this.markers.forEach(function(marker) {
                //     marker.setMap(null);
                // });
                // this.markers = [];

                // for (var i = 0; i < places.length; i++) 
                // {
                //  //   createMarker(results[i]);
                //    var marker = new google.maps.Marker({
                //      map: map,
                //      position: places[i].geometry.location
                //    });
                //    this.markers.push(marker);
                // }
                map.setCenter(places[0].geometry.location);
            });
   
        //   const createMarker = (place) => {
        //     var marker = new google.maps.Marker({
        //       map: map,
        //       position: place.geometry.location
        //     });
        // }
        // --------------------------------------------
    
            // google.maps.event.addListener(drawingManager, 'circlecomplete', function(circle) {
            //     var radius = circle.getRadius();
            //     alert(radius);
            // });
    }
    //--------------------------------------------------------------------
    //--------------------------------------------------------------------
    async getMarkers(assetClasses){
        if(!assetClasses) return null;
        // console.log('ASSET CLASSES TABLE')
        let markers = []
        // console.table(assetClasses);
        const defaultIconPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0";
        const defaultColor = "#FF0000";
        Geocode.setApiKey(GOOGLE_API_KEY);
        Geocode.setLanguage("en");

        await Promise.all(assetClasses.map(async(ac, index)=>{
            // console.log('ASSET CLASS SHOWN ON MAP ', ac)
            if(ac.asset){
                // console.log('AC ASSET ,', ac)
                await Promise.all(ac.asset.map(async(m, i) => {
                    if ( !ac.className.includes('Layout') && m.locationlat == 0 && m.locationlng == 0){
                        // console.log('NOT LAYOUT OR HAVE LOCATION', m)
                        return null;
                    }

                    let layoutCoords = {lat: null, lng: null};
                    let position = null;
                    if(ac.className.includes('Layout')){  
                        //console.log('ISLAYOUT')
                        //get address from asset attribute
                        let assetAddressAttr = m.assetattributes.filter(attr=> attr.attmetaname.includes('address'));
                        let assetAddress = assetAddressAttr.length > 0 ? assetAddressAttr[0].value : null;
                        // console.log('ASSET CLASS IS LAYOUT')
                        if(assetAddress){ //if address is set
                            let response = await Geocode.fromAddress(assetAddress);
                            layoutCoords = response.results? response.results[0].geometry.location : null;
                            // console.log('LAYOUT COORD', layoutCoords)
                            position = layoutCoords
                            // console.log('POSITION IN LOOP', position)
                            let path = defaultIconPath;
                            let color = defaultColor;
                            if (ac.assetIcon && ac.assetIcon.iconsvg){
                                //console.log('ICON DATA', ac, ac.assetIcon.data)
                                path = ac.assetIcon.iconsvg;
                            }
                            if (m.color){
                                color = m.color;
                            }
                            // console.log('POSITION', position)
                            //  
                            markers.push(
                                <Marker 
                                    onMouseover={this.handleMapMarkerMouseOver}
                                    onClick={this.handleMarkerClick}
                                    // onMouseout={this.handleMarkerMouseOut}
                                    assetname={m.assetname} 
                                    // attr={m.msg} 
                                    address={assetAddress}
                                    device={m.device}
                                    position={position} 
                                    // key={index+i} 
                                    icon={ac.assetIcon.data ? this.generateIcon(color, path, ac.assetIcon.data) : this.generateIcon(color,path, null)}
                                >
                                </Marker>
                            );

                            
                            
                        }else{
                            return null;
                        }
                    }else{
                        position = {lat:m.locationlat,lng:m.locationlng}
                        let path = defaultIconPath;
                        let color = defaultColor;
                        if (ac.assetIcon && ac.assetIcon.iconsvg){
                            path = ac.assetIcon.iconsvg;
                        }
                        if (m.color){
                            color = m.color
                        }
                        markers.push(
                            <Marker 
                                onMouseover={this.handleMapMarkerMouseOver}
                                onMouseout={this.handleMarkerMouseOut}
                                // onClick={this.handleMarkerClick}
                                assetname={m.assetname} 
                                // attr={m.msg} 
                                // address={assetAddress}
                                device={m.device}
                                position={position} 
                                // key={index+i} 
                                icon={ac.assetIcon.data ? this.generateIcon(color, path, ac.assetIcon.data) : this.generateIcon(color,path, null)}
                            >
                            </Marker>
                        );
                    }

                }) )
            }
        }))
        this.setState({
            ...this.state,
            renderMarkers: markers
        })
        // console.log('MARKER', markers)
    }

    generateIcon(color, path, data){
        // let myPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0"; //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0"
        let myPath ="m12 0c-4.4183 2.3685e-15 -8 3.5817-8 8 0 1.421 0.3816 2.75 1.0312 3.906 0.1079 0.192 0.221 0.381 0.3438 0.563l6.625 11.531 6.625-11.531c0.102-0.151 0.19-0.311 0.281-0.469l0.063-0.094c0.649-1.156 1.031-2.485 1.031-3.906 0-4.4183-3.582-8-8-8zm0 4c2.209 0 4 1.7909 4 4 0 2.209-1.791 4-4 4-2.2091 0-4-1.791-4-4 0-2.2091 1.7909-4 4-4z";
        let myColor = '#ff0000'; //'#e74c3c';

        if (path && path.length > 0){
            myPath = path;
        }
        if (color && color.length){
            myColor = color;
        }
        if(data){
            return({
                url: data,
                scaledSize: new google.maps.Size(50,40)
            })
        }
        return (
            {               
                path: myPath,
                fillColor: myColor,
                fillOpacity: .6,
                anchor: new google.maps.Point(0,0),
                strokeWeight: 0,
                scale: 1,
            }
        )                            
    };

    handleFenceChange = (val) => {
        let id = val.value;        
        let fence = this.props.allfences.find((e, i) => (e.fenceid == id));
        let currentPolygonData = null;
        let currentRectangleData = null;
        let currentCircleData = null;
        let selectedShape = null;
        let type = '' ;
        if (fence) {
            switch(fence.shape){
                case 0:
                    type = 'circle';
                    currentCircleData = JSON.parse(fence.geodata);
                    break;
                case 1:
                    type = 'rectangle';
                    currentRectangleData = JSON.parse(fence.geodata);
                    break;
                case 2:
                    type = 'polygon';
                    currentPolygonData = JSON.parse(fence.geodata);
                    break;
            }
    
            selectedShape = {type: type, data: JSON.parse(fence.geodata)};
        }    
         
        this.setState({
            shape: selectedShape,
        });

        this.showShapOnMap(selectedShape)
    }

    //--------------------------------------------------------------------
    // 70vh
    render(){
        // let height = JSON.parse(this.props.layout.position).h * 30 - 20;

        // console.log('RENDER MARKERS', this.state.renderMarkers)
        // console.log(this.props)
        // let v = this.props.infowindow.selectedPlace;
        // const defaultIconPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0";
        // const defaultColor = "#FF0000";


        return(
            <div>
                {this.userObj.companyCode !== 'ONRT' && this.userObj.userName !== 'ONrouteLegal' && <Row>
                    {/* <Col lg={6} style={{paddingBottom: '7px'}}>
                        { <Label   style={{ display: 'inline'}}>Fence</Label> }
                        <Select
                            styles={style}         
                            value= {this.state.selectedFenceOption}           
                            options={this.state.fenceOptions}
                            onChange={this.handleFenceChange}
                            placeholder={'Select Geofence...'}
                        />
                    </Col>
                    <Col lg={6} style={{paddingBottom: '7px'}}>
                        <Input id="pac-input" class="controls" type="text" placeholder="Search by Address" ref='inputTest'/>
                    </Col> */}
                </Row>}
                <Map
                    ref={this.myMap} 
                    google={this.props.google}
                    zoom={7}  
                    onReady={this.initMap}  
                    containerStyle={this.mapStyle}
                    style={this.mapStyle}
                    // initialCenter={{lat:43.8148024, lng:-79.5509472}}
                    initialCenter={{ lat: 43.6905867, lng: -79.4751292 }}
                    onClick={this.onMapClicked}
                    >
                        {/* {this.props.mapData && this.props.mapData.slice(0,10).map((m,i)=>{
                        return(
                            <Marker onClick={this.props.onMarkerClick} name={m.devicename} attr={m.msg} position={{lat:m.locationlat,lng:m.locationlng}} key={i} icon={generateIcon(null,m.asseticonsvg)}>
                            </Marker>
                        );})} */}
                        {
                            
                           this.state.renderMarkers.map((m,i)=>{
                               return m;
                           })
                        }
                        {/* <Marker 
                            onClick={this.props.onMarkerClick} 
                            // attr={m.msg} 
                            position={{lat: 43.8750231, lng: -79.5570918}} 
                            icon={this.generateIcon(defaultColor, defaultIconPath)}
                        >
                        </Marker> */}
                        <InfoWindow marker={this.state.activeMarker} 
                                    visible={this.state.showingInfoWindow}
                                    onClose = {this.handleInfoWindowClose}
                                    >
                            <div >
                                <h4>{this.state.selectedPlace? this.state.selectedPlace.assetname : null }</h4>
                                <Table>
                                    <tbody>
                                        {/* <tr>
                                            <td>attr</td>
                                            <td>{this.props.infowindow.selectedPlace.name}</td>
                                        </tr> */}
                                        {/* {   
                                            Object.keys(this.props.infowindow.selectedPlace.attr).map((key,i)=>{
                                                
                                                <tr>
                                                    <td>{key}</td>
                                                    <td>{this.props.infowindow.selectedPlace.attr[key]}</td>
                                                </tr>
                                            })
                                        } */}
                                        {/* {renderInfoWin()} */}
                                        {this.state.infowindowAddress &&
                                            <tr>
                                                <td>
                                                    <span style={{fontWeight: "bold"}}>Address:</span>
                                                </td>
                                                <td>
                                                    {this.state.infowindowAddress}
                                                </td>
                                            </tr>
                                        }
                                        <tr>
                                            <td>
                                                lat:
                                            </td>
                                            <td>
                                                {this.state.infowindowLat}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                lng:
                                            </td>
                                            <td>
                                                {this.state.infowindowLng}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </InfoWindow>
                    {/* {this.state.markers.map((m,i)=>{
                         
                         
                        return(
                            <Marker onClick={this.onMarkerClick} name={m.info} position={{lat:m.lat,lng:m.lng}} key={i}>
                                <InfoWindow visible={this.state.showingInfoWindow} >
                                    <div>
                                        <h4>{m.info}</h4>
                                    </div>
                                </InfoWindow>
                            </Marker>
                        );})} */}
                    {/* <Marker onClick={this.onMarkerClick} name={'Map Test'} position={{lat: -1.2884, lng: 36.8233}}/> */}
                        {/* <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow} conClick={this.state.onClose}>
                            <div>
                                <h4>{this.state.selectedPlace.name}</h4>
                            </div>
                        </InfoWindow> */}
                    
                    {/* <Marker onClick={this.onMarkerClick} name={'Map Test'} position={{lat: -1.2684, lng: 36.8033}}>
                        <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow} conClick={this.state.onClose}>
                            <div>
                                <h4>{this.state.selectedPlace.name}</h4>
                            </div>
                        </InfoWindow>
                    </Marker> */}
                </Map>
            </div>    
        )
    }
}

// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
//     libraries: ['drawing','places'],
// })(DashboardMap);

function mapStateToProps(state) {   
    const allfences = state.fence.fenceData;
    const fenceObj = state.fence.fenceObj;
    const fenceStatus = state.fence.type;
    const props = {allfences, fenceObj, fenceStatus};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAllGeoFences,}, dispatch);
};
// export default connect(mapStateToProps, mapDispatchToProps)(StepCondition);

const enhance = compose(
    GoogleApiWrapper({
        // apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
        // apiKey: 'AIzaSyDXBuKv70EhwKMyHzrj6wc7_qPQFwmdyB4',
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['drawing','places'],
    }),
    connect(mapStateToProps, mapDispatchToProps)
);

export default withRouter(enhance(DashboardMap));