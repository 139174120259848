/* eslint-disable */
import React, { Component, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Collapse,
} from "reactstrap";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
// import { SortIcon, SortAscendingIcon, SortDescendingIcon } from 'mdi-react';
// import { Link } from 'react-router';
import FileInputModal from "./FileInputModal";
import ConfirmDeleteDialog from "../../../shared/components/Dialog/ConfirmationDialog";
import { itemFinder, dropdownOnChangeHandler } from "../../../factories/utils";
import { loadDevice, loadDeviceInfo } from "../../../redux/actions/mapActions";
import {
  deviceNameChangeAjax,
  deviceActiveAjax,
} from "../../../redux/actions/assetActions";
import {
  sortCaret,
  AdjustableTableHeaderColumn,
  AdjustableTableHeaderColumn2,
  // AdjustableDiv
} from "../../../shared/components/Table/tableElements";
import { Resizable, ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import Select from "react-select";
import EditDevicePanel from "./EditDevicePanel";
import EditDevicePanelx from "./EditDevicePanelx";
import { PencilIcon, EditIcon } from "mdi-react";
// import $ from 'jquery';
// import 'http://netdna.bootstrapcdn.com/bootstrap/3.0.2/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

class AssetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      currentDeviceTypeId: "", //'5ca26a380499f5276444fc51',
      width: 200,
      isSort: {},
      modalName: false,
      currentName: null,
      currentDeviceObj: null,
      assetToEdit: null,
      deviceToEdit: null,
      // currentDeviceTypeName : '',
      // height: 200
      // isSort: true,
    };
  }

  componentDidMount() {
  }

  deviceIdLinkFormatter = (cell, row) => {
    // console.log('===current', this.state.currentDeviceObj);
    let path = `/pages/device/${cell}`;
    return (
      // <a href={path} onClick={() => {
      //     // alert(cell); alert(row['devicename']);
      // }}>{cell}</a>
      <Link to={path}>{cell}</Link>
    );
  };

  deviceStatusFormatter = (cell, row) => {
    if (cell) {
      return "Active";
    } else {
      return "Inactive";
    }
  };

  deviceStatusActionFormatter = (cell, row) => {
    let checked;
    switch (cell) {
      case "unassign":
        return (
          <Button
            outline
            color="secondary"
            type="reset"
            onClick={this.onSignUp}
          >
            Sign Up
          </Button>
        );
      case "inactive":
        checked = false;
        return (
          // <ConfirmDeleteDialog
          //     label={"Activate"}
          //     onConfirm={(e)=>{this.onActivate(e, row, true);}}
          //     msg={`The device ${row.deviceid} will be ACTIVATED !`}
          //     noIcon
          // />
          <SwichConfirmation
            key={checked}
            checked={checked}
            onConfirm={(e, value) => {
              this.onActivate(e, row, value);
            }}
            label={"In-Activated"}
            msg={`The device ${row.deviceid} will be ACTIVATED !`}
          />
        );
      default:
        checked = true;
        return (
          // <ConfirmDeleteDialog
          //     label={"In-Activate"}
          //     onConfirm={(e)=>{this.onActivate(e, row, false);}}
          //     msg={`The device ${row.deviceid} will be IN-ACTIVATED !`}
          //     noIcon
          // />
          <SwichConfirmation
            key={checked}
            checked={checked}
            onConfirm={(e, value) => {
              this.onActivate(e, row, value);
            }}
            label={"Activated"}
            msg={`The device ${row.deviceid} will be IN-ACTIVATED !`}
          />
        );
    }
  };

  onSignUp = (e) => {
    this.props.history.push(`/pages/devicesignup/` + this.props.deviceId);
  };
  onActivate = (e, row, activate) => {
    let status = "inactive";
    if (activate) {
      status = "active";
    }
    if (row && row.deviceid) {
      this.props
        .deviceActiveAjax(row.deviceid, status)
        .then((res) => {
          if (res.status === 200) {
            this.refreshDeviceInfo();
            toast.success(`Successfully activate the device ${row.deviceid}`);
          } else {
            toast.error(
              `Error occurred during activating the device ${row.deviceid}`
            );
          }
        })
        .catch((err) => {
          console.error(err.message);
          toast.error(
            `Error occurred during during activating the device ${row.deviceid}`
          );
        });
    }
  };

  toggle = () => {
    this.setState((prevState) => ({
      modalVisible: !prevState.modalVisible,
    }));
  };

  toggleNameEdit = () => {
    this.setState((prevState) => ({
      modalName: !prevState.modalName,
    }));
  };

  handleLoadFileClick = (e) => {
    // alert('yes');
    this.setState({
      modalVisible: true,
    });
  };

  manufactureOnChangeHandler = (e) => {
    let selectedValue = dropdownOnChangeHandler(e); //alert(selectedValue.id);
    // this.props.loadAssets({devicetypeid: selectedValue.id});

    this.setState({
      currentDeviceTypeId: selectedValue.id,
      currentDeviceTypeName: selectedValue.name,
    });
    if (selectedValue.id && selectedValue.id.length > 0) {
      this.props.loadDeviceInfo({ devicetype: selectedValue.id });
    } else {
      this.props.loadDeviceInfo();
    }
  };

  refreshDeviceInfo = () => {
    if (
      this.state.currentDeviceTypeId &&
      this.state.currentDeviceTypeId.length > 0
    ) {
      this.props.loadDeviceInfo({ devicetype: this.state.currentDeviceTypeId });
    } else {
      this.props.loadDeviceInfo();
    }
  };

  onSearchClick = (e) => {
    if (
      this.state.currentDeviceTypeId &&
      this.state.currentDeviceTypeId.length > 0
    ) {
      this.props.loadDeviceInfo({ devicetype: this.state.currentDeviceTypeId });
    }
  };

  renderFilterBar = () => {
    return (
      <Card>
        <CardBody>
          <Row
            style={{
              flexGrow: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col md={3}>
              <Label for="deviceType" style={{ display: "inline" }}>
                Device Type
              </Label>
              <Input
                type="select"
                id="deviceType"
                name="deviceType"
                width="60%"
                value={this.state.currentDeviceTypeId}
                onChange={this.manufactureOnChangeHandler}
              >
                {/* {this.props.devicetypes && this.props.devicetypes.map((type, i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))} */}
                {this.props.devicetypes &&
                  this.props.devicetypes.map((type, i) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
              </Input>
            </Col>

            <Col md={3}></Col>
            <Col md={3}></Col>
            <Col
              md={3}
              style={{
                textAlign: "left",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Label for="theme" style={{ display: "block" }}>
                &nbsp;
              </Label>
              <Button
                variant="primary"
                color="custom"
                type="button"
                style={{ margin: "auto" }}
                onClick={this.onSearchClick}
              >
                Search
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  };

  tbSearchPanel = (props) => {
    return (
      <Row>
        <Col
          md={12}
          style={{
            textAlign: "left",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Label for="deviceType" style={{ display: "inline" }}>
            &nbsp;
          </Label>
          {props.searchField}
          {/* { props.components.searchPanel } */}
        </Col>
      </Row>
    );
  };

  tbToolBar = (props) => {
    return (
      <Row>
        <Col md={6}>
          <Label for="deviceType" style={{ display: "inline" }}>
            <h5>
              <b>Device Type</b>
            </h5>
          </Label>
          <Select
            id="deviceType"
            name="deviceType"
            width="60%"
            value={this.state.currentDeviceTypeId}
            onChange={this.manufactureOnChangeHandler}
          >
            {this.props.devicetypes &&
              this.props.devicetypes.map((type, i) => (
                <option key={type.devicetypeid} value={type.devicetypeid}>
                  {type.devicetypename}
                </option>
              ))}
            <option value={""}>ALL</option>
            {this.props.devicetypes &&
              this.props.devicetypes.map((type, i) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
          </Select>
        </Col>
        {/* <Col md={6}></Col>
                <Col md={3}>
                    { props.searchField }
                    { props.components.searchPanel }
                </Col> */}
      </Row>
    );
  };

  inUseFormat = (cell, row) => {
    if (cell > 0) {
      return <span>Used</span>;
    } else {
      return <span>Unused</span>;
    }
  };

  editSelectedDevice =  (assetid, deviceid) => {
    // await this.props.getCalculateAttribute({ assetid: id });
    this.setState({
      assetToEdit: assetid,
      deviceToEdit: deviceid
    });
  };

  // editSelectedDevice = async (id) => {
  //   this.setState({ assetToEdit: id }, async () => {
  //     await this.props.getCalculateAttribute({ "assetid": id })
  //   })
  // }

  editForm = (cell, row) => {
    if(cell > 0) {
      return (
        <div className="actions" style={{ cursor: "pointer" }}>
          <a onClick={() => this.editSelectedDevice(row.assetid, row.deviceid)}>
            <EditIcon color="#0066cc" />
            Edit Device
          </a>
        </div>
      );
    } else {
      return (
        <div className="actions" style={{ alignItems: 'center'}}>
            Device Unused
        </div>
      );
    }
  };

  closePanel = () => this.setState({ deviceToEdit: null });

  TimeFormat = (cell, row) => {
    let UTCTime = new Date(cell).toLocaleString("en-US", { timeZone: "UTC" });
    return <span>{UTCTime}</span>;
  };

  // renderCaret = (direction, fieldName) => {
  //     if (direction === 'asc') {
  //       return (
  //          <SortAscendingIcon/>
  //       );
  //     }
  //     if (direction === 'desc') {
  //       return (
  //          <SortDescendingIcon/>
  //       );
  //     }
  //     return (
  //        <SortIcon/>
  //     );
  //   }

  onResize = (event, { element, size, handle }, colKey) => {
    if (event.nativeEvent) {
      event.nativeEvent.preventDefault();
      event.nativeEvent.stopPropagation();
    }
    event.stopPropagation();
    event.preventDefault();

    // console.log('isPropagationStopped', event.isPropagationStopped)
    this.setState({ [colKey]: size.width });
  };

  onResizeStart = (event, data, colKey) => {
    if (this.refs[colKey].props.dataSort) {
      this.setState((prevState) => {
        prevState.isSort[colKey] = false;
        return { isSort: prevState.isSort };
      });
    }
    // let ele = $("th.sort-column");
    // if (ele){
    //     // ele.bind("click.prevent",
    //     //     function(event) { event.preventDefault(); }
    //     // );
    //     ele.off("click");
    //
    // }
    // this.isDragging = true;
  };

  onResizeStop = (event, data, colKey) => {
    // this.isDragging = false;
    setTimeout(
      function() {
        // this.isDragging = false;
        this.setState((prevState) => {
          prevState.isSort[colKey] = true;
          return { isSort: prevState.isSort };
        });
      }.bind(this),
      300
    );
  };

  revertSortFunc = (a, b, order, field) => {
    // order is desc or asc
    //
    //
    // if (this.isDragging) {
    //     return ;
    // }
    const valueA = a[field] == null ? "" : a[field];
    const valueB = b[field] == null ? "" : b[field];
    let result;
    if (order === "desc") {
      if (typeof valueB === "string") {
        result = valueB.localeCompare(valueA);
      } else {
        result = valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    } else {
      if (typeof valueA === "string") {
        result = valueA.localeCompare(valueB);
      } else {
        result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }
    }

    return result;
  };

  // -----------------------------------------------------------------
  nameCellFormatter = (cell, row) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          this.onNameCellClick(e, cell, row);
        }}
      >
        <span>{cell}</span>
      </div>
    );
  };
  onNameCellClick = (e, cell, row) => {
    this.setState({
      currentName: cell,
      currentDeviceObj: row,
    });
    this.toggleNameEdit();
  };
  onCellEdit = (row, fieldName, value) => {};

  onNameChange = (newName) => {
    let newDeviceObj = {
      ...this.state.currentDeviceObj,
      devicenamealias: newName,
      // devicename: newName
    };
    this.setState({
      currentName: newName,
      currentDeviceObj: newDeviceObj,
    });
    this.props
      .deviceNameChangeAjax(newDeviceObj)
      .then((res) => {
        if (res.status === 200) {
          this.refreshDeviceInfo();
          toast.success(
            `Successfully changed the display name of the device ${newDeviceObj.deviceid}`
          );
        } else {
          toast.error(
            `Error occurred during changing the display name of device ${newDeviceObj.deviceid}`
          );
        }
      })
      .catch((err) => {
        console.error(err.message);
        toast.error(
          `Error occurred during changing the display name of device ${newDeviceObj.deviceid}`
        );
      });
  };
  // logic to hide battery expire date column if no battery expire date is present
  columnoption(dataValue) {
    var i = 0;
    for (i = 0; i < this.props.deviceList.length; i++) {
      if (this.props.deviceList[i].batteryexpiredate != null) {
        return false;
      }
    }
    return true;
  }

  render() {
    // console.log("===this.props.currentDeviceList", this.props.currentDeviceList);
    const options = {
      // page: 2,  // which page you want to show as default
      // sizePerPageList: [{
      //     text: '5', value: 5
      // }, {
      //     text: '10', value: 10
      // }, {
      //     text: 'All', value: this.props.deviceList.length
      // }], // you can change the dropdown list for size per page
      // sizePerPage: 10,  // which size per page you want to locate as default
      // pageStartIndex: 1, // where to start counting the pages
      // paginationSize: 5,  // the pagination bar size.
      // prePage: '<', // Previous page button text
      // nextPage: '>', // Next page button text
      // firstPage: '<<', // First page button text
      // lastPage: '>>', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      // searchPosition: 'left',
      btnGroup: this.tbToolBar,
      searchPanel: this.tbSearchPanel,
      sortIndicator: true,
      noDataText: this.props.loading
        ? "Loading..."
        : "There is no Data to display",
      // toolBar: this.tbToolBar,
      // onCellEdit: this.onCellEdit
    };

    const cellEditProp = {
      mode: "click",
    };
    // console.log("===this.props.deviceList", this.props.deviceList);
    return (
      <>
        {/* <Resizable className="box" height={this.state.height} width={this.state.width} onResize={this.onResize2} resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}>
                <div className="box" style={{width: this.state.width + 'px', height: this.state.height + 'px'}}>
                <span className="text">{"Raw use of <Resizable> element. 200x200, all Resize Handles."}</span>
                </div>
            </Resizable> */}

        {/* <ResizableBox className="box" width={200} height={200}>
                <span className="text">{"<ResizableBox>"}</span>
            </ResizableBox> */}

        <FileInputModal
          modalSetVisible={this.state.modalVisible}
          toggle={this.toggle}
        />
        {/* {this.renderFilterBar()} */}
        <Collapse
          isOpen={
            this.state.deviceToEdit == undefined ||
            this.state.deviceToEdit == null
          }
        >
          <Card>
            <div className="card-pg">
              {/* <Button variant="primary" color="custom" type="button" onClick={this.handleLoadFileClick}>Load Data</Button> */}
              <BootstrapTable
                data={this.props.deviceList}
                tableHeaderClass="header_white"
                pagination={false}
                options={options}
                search
                bordered={true}
                height="600"
                printable
                bodyStyle={{ overflow: "overlay" }}
                // remote={ true }
                // cellEdit={ cellEditProp }
              >
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="deviceid"
                  isKey={true}
                  dataFormat={this.deviceIdLinkFormatter}
                  dataAlign="center"
                  width="7%"
                >
                  Device ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="devicenamealias"
                  dataAlign="center"
                  dataSort
                  caretRender={sortCaret}
                  dataFormat={this.nameCellFormatter}
                >
                  Device Name{" "}
                </TableHeaderColumn>
                {/* <TableHeaderColumn row='0' rowSpan='1' dataField='devicename' dataAlign='center' dataSort caretRender={ sortCaret } dataFormat={this.nameCellFormatter}>Device Name </TableHeaderColumn> */}
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="devicetypename"
                  dataAlign="center"
                  dataSort
                  caretRender={sortCaret}
                >
                  Device Type{" "}
                </TableHeaderColumn>
                {/* <AdjustableTableHeaderColumn2 
                                ref='deviceid' 
                                onResize = {(e,data) =>{this.onResize(e,data,'deviceid')}} 
                                width={this.state['deviceid']} 
                                row='0' rowSpan='1' dataField='deviceid' isKey={true} dataFormat={this.deviceIdLinkFormatter} dataAlign='center' 
                                // width='7%'
                            >
                                Device ID
                            </AdjustableTableHeaderColumn2>
                            <AdjustableTableHeaderColumn2 
                                ref='devicename' 
                                onResizeStart = {(e,data) =>{this.onResizeStart(e,data,'devicename')}}
                                onResizeStop =  {(e,data) =>{this.onResizeStop(e,data,'devicename')}}
                                onResize = {(e,data) =>{this.onResize(e,data,'devicename')}} 
                                width={this.state['devicename']} 
                                row='0' rowSpan='1' dataField='devicename' dataAlign='center' dataSort={this.state.isSort['devicename']  != undefined ?this.state.isSort['devicename']:true} caretRender={ sortCaret } 
                            >
                                Device Name
                            </AdjustableTableHeaderColumn2>
                            <AdjustableTableHeaderColumn2 
                                ref='devicetypename' 
                                onResizeStart = {(e,data) =>{this.onResizeStart(e,data,'devicetypename')}}
                                onResizeStop =  {(e,data) =>{this.onResizeStop(e,data,'devicetypename')}}
                                onResize = {(e,data) =>{this.onResize(e,data,'devicetypename')}} 
                                width={this.state['devicetypename']} 
                                row='0' rowSpan='1' dataField='devicetypename' dataAlign='center' dataSort={this.state.isSort['devicetypename'] != undefined ? this.state.isSort['devicetypename']:true} caretRender={ sortCaret }
                            >
                                Device Type 
                            </AdjustableTableHeaderColumn2> */}

                {/* <Resizable 
                                // style={{backgroundColor :'blue'}}
                                
                                id='testid'
                                className='box'
                                axis='x'
                                width={this.state.width}
                                // height={this.state.height}
                                onResize = {(e, data) => {this.onResize(e, data, 'testid')}}
                                // resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}
                                resizeHandles={['e']}
                            >
                                <TableHeaderColumn name='test' id='testid' draggable width={this.state.width} row='0' rowSpan='1' dataField='devicetypename' dataAlign='center' dataSort caretRender={ sortCaret }>Device Type </TableHeaderColumn>
                            </Resizable> */}

                {/*<TableHeaderColumn row='0' colSpan='2' headerAlign='center' >Position111</TableHeaderColumn>*/}
                {/*<TableHeaderColumn row='1' dataField='locationlat' dataAlign='center' >Latitude111</TableHeaderColumn>*/}
                {/*<TableHeaderColumn row='1' dataField='locationlng' dataAlign='center' >Longitude111</TableHeaderColumn>*/}

                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="activationtime"
                  dataAlign="center"
                  dataSort
                  caretRender={sortCaret}
                >
                  Activation Time
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="lastmessagetime"
                  dataAlign="center"
                  dataSort
                  caretRender={sortCaret}
                >
                  Last Message Time
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="batteryexpiredate"
                  dataAlign="center"
                  hidden={this.columnoption()}
                >
                  Battery Expire Date
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="status"
                  dataFormat={this.deviceStatusActionFormatter}
                  dataAlign="center"
                >
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="assetid"
                  dataFormat={this.inUseFormat}
                  dataAlign="center"
                  dataSort
                  caretRender={sortCaret}
                >
                  Is Used
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataField="assetid"
                  dataFormat={this.editForm}
                  dataAlign="center"
                  dataSort caretRender={ sortCaret }
                >
                  Edit
                </TableHeaderColumn>

                {/* <AdjustableTableHeaderColumn2 
                                ref='activationtime' 
                                onResizeStart = {(e,data) =>{this.onResizeStart(e,data,'activationtime')}}
                                onResizeStop =  {(e,data) =>{this.onResizeStop(e,data,'activationtime')}}
                                onResize = {(e,data) =>{this.onResize(e,data,'activationtime')}} 
                                width={this.state['activationtime']} 
                                row='0' rowSpan='1' dataField='activationtime' dataAlign='center' dataFormat={this.TimeFormat} dataSort={this.state.isSort['activationtime'] != undefined ? this.state.isSort['activationtime']:true} caretRender={ sortCaret }
                            >
                                Activation Time 
                            </AdjustableTableHeaderColumn2>

                            <AdjustableTableHeaderColumn2 
                                ref='lastmessagetime' 
                                onResizeStart = {(e,data) =>{this.onResizeStart(e,data,'lastmessagetime')}}
                                onResizeStop =  {(e,data) =>{this.onResizeStop(e,data,'lastmessagetime')}}
                                onResize = {(e,data) =>{this.onResize(e,data,'lastmessagetime')}} 
                                width={this.state['lastmessagetime']} 
                                row='0' rowSpan='1' dataField='lastmessagetime' dataAlign='center' dataFormat={this.TimeFormat} dataSort={this.state.isSort['lastmessagetime'] != undefined ? this.state.isSort['lastmessagetime']:true} caretRender={ sortCaret }
                            >
                                Last Message Time 
                            </AdjustableTableHeaderColumn2>
                            <AdjustableTableHeaderColumn2 
                                ref='batteryexpiredate' 
                                onResize = {(e,data) =>{this.onResize(e,data,'batteryexpiredate')}} 
                                width={this.state['batteryexpiredate']} 
                                row='0' rowSpan='1' dataField='batteryexpiredate' dataFormat={this.TimeFormat} dataAlign='center'
                            >
                                Battery Expire Date
                            </AdjustableTableHeaderColumn2>

                            <AdjustableTableHeaderColumn2 
                                ref='activable' 
                                onResize = {(e,data) =>{this.onResize(e,data,'activable')}} 
                                width={this.state['activable']} 
                                row='0' rowSpan='1' dataField='activable' dataFormat={this.deviceStatusFormatter} dataAlign='center'                                 
                            >
                                Status
                            </AdjustableTableHeaderColumn2> */}

                {/* <AdjustableTableHeaderColumn2 
                                ref='assetid' 
                                onResizeStart = {(e,data) =>{this.onResizeStart(e,data,'assetid')}}
                                onResizeStop =  {(e,data) =>{this.onResizeStop(e,data,'assetid')}}
                                onResize = {(e,data) =>{this.onResize(e,data,'assetid')}} 
                                width={this.state['assetid']} 
                                row='0' rowSpan='1' dataField='assetid' dataFormat={this.inUseFormat}  dataAlign='center' dataSort={this.state.isSort['assetid'] != undefined ? this.state.isSort['assetid']:true} caretRender={ sortCaret }
                            >
                                Is Used 
                            </AdjustableTableHeaderColumn2> */}
                {/* <TableHeaderColumn row='0' rowSpan='1' dataField='assetid' dataFormat={this.inUseFormat} dataAlign='center' dataSort caretRender={ sortCaret }>Is Used</TableHeaderColumn> */}
              </BootstrapTable>
            </div>
          </Card>
        </Collapse>
        <Collapse
          isOpen={
            this.state.deviceToEdit != undefined &&
            this.state.deviceToEdit != null
          }
        >
          {this.state.deviceToEdit && (
            <EditDevicePanel
              assetClassList={this.props.assetClassList}
              deviceAttributes={this.props.deviceAttributes}
              assetInfo={this.props.assetList.find(({ assetid }) => assetid == this.state.assetToEdit)}
              deviceInfo={this.props.deviceList.find(({ deviceid }) => deviceid == this.state.deviceToEdit)}
              selectedDevice={this.state.deviceToEdit}
              deviceList={this.props.deviceList}
              devices={this.props.devices}
              closePanel={this.closePanel}
              updateDeviceOnStore={this.props.updateDeviceOnStore}
              updateDeviceAttribute={this.props.updateDeviceAttribute}
              deleteDeviceAttribute={this.props.deleteDeviceAttribute}
            />
            // <EditDevicePanelx
            //   assetClassList={this.props.assetClassList}
            //   deviceAttributes={this.props.deviceAttributes}
            //   assetInfo={this.props.assetList.find(({ assetid }) => assetid == this.state.assetToEdit)}
            //   deviceInfo={this.props.deviceList.find(({ deviceid }) => deviceid == this.state.deviceToEdit)}
            //   selectedDevice={this.state.deviceToEdit}
            //   deviceList={this.props.deviceList}
            //   devices={this.props.devices}
            //   closePanel={this.closePanel}
            //   updateDeviceOnStore={this.props.updateDeviceOnStore}
            //   updateDeviceAttribute={this.props.updateDeviceAttribute}
            //   deleteDeviceAttribute={this.props.deleteDeviceAttribute}
            // />
          )}
        </Collapse>
        <NameEditModal
          toggle={this.toggleNameEdit}
          modal={this.state.modalName}
          name={this.state.currentName}
          onChange={this.onNameChange}
        ></NameEditModal>
      </>
    );
  }
}

// export default AssetList;

function mapStateToProps(state) {
  const devicetypes = state.mapboard.types;
  const currentDeviceList = state.asset.currentDeviceList;
  // const props = state.attributemgmt;
  const props = { devicetypes, currentDeviceList };
  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { loadDevice, loadDeviceInfo, deviceNameChangeAjax, deviceActiveAjax },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssetList)
);

const NameEditModal = (props) => {
  const { toggle, modal, name, onChange } = props;
  let editRef;
  React.useEffect(() => {
    editRef && editRef.focus();
  });

  const onAddClick = (e) => {
    onChange(editRef.value);
    toggle();
  };
  const onCancleClick = (e) => {
    toggle();
  };

  return (
    <Modal
      animationType="slide"
      transparent={false}
      isOpen={modal}
      toggle={toggle}
    >
      <ModalBody>
        <Col md={8}>
          <Input
            type="text"
            id="displayname"
            name="displayname"
            width="90%"
            defaultValue={name}
            innerRef={(input) => {
              editRef = input;
            }}
            // onChange={this.onDisplayNameClick}
          ></Input>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onAddClick} disabled={false}>
          Submit
        </Button>{" "}
        <Button color="custom" onClick={onCancleClick}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const SwichConfirmation = ({
  checked,
  onConfirm,
  label,
  msg,
  ...props
}) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [stateChanged, setStateChanged] = React.useState(checked);
  // React.useEffect(() => { setStateChanged(checked) },[checked]);
  let editRef;

  let checkedBuffer = checked;
  // React.useEffect(() => { editRef && editRef.focus() })

  const toggle = () => {
    setModalVisible(!modalVisible);
  };

  const onConfirmClick = (e) => {
    onConfirm(e, !checked);
    //
    // setStateChanged(checkedBuffer);
    setModalVisible(false);
  };

  const onCancelClick = () => {
    // onCancel();
    setModalVisible(false);
  };

  const handleChange = (e) => {
    // checkedBuffer = e.target.checked;
    //
    // setStateChanged(checkedBuffer);
    toggle();
    //
  };

  return (
    <Fragment>
      {/* {   noIcon ?           
                <Button outline variant="primary" color="danger" type="button" style={{margin:'auto'}} size='sm' onClick={(e)=>{toggle()}} {...props} >
                    {label}
                </Button>
                : <DeleteTextButton label={label} onClick={(e)=>{toggle()}}/>
            } */}
      {/* <DeleteTextButton label={label} onClick={(e)=>{toggle()}}/> */}
      {/* <Button outline variant="primary" color="danger" type="button" style={{margin:'auto'}} size='sm' onClick={(e)=>{toggle()}} {...props} >
                    {label}
                </Button> */}
      <GreenSwitch
        checked={stateChanged}
        onChange={handleChange}
        value="Lock Screen"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {label}
      <Modal isOpen={modalVisible} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          //style={{ backgroundColor: '#70bbfd', color: 'white',}}
        >
          Confirmation
        </ModalHeader>
        <ModalBody>
          <br />
          <br />
          <h4 className="text-center">{msg}</h4>
          <br />
          <br />
          <Row>
            <Col>
              <Button
                color="custom"
                style={{ width: "200px", marginLeft: 10 }}
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </Col>
            <Col className="text-right">
              <Button
                color="secondary"
                style={{ width: "200px", marginRight: 10 }}
                onClick={(e) => {
                  onConfirmClick(e);
                }}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export const GreenSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(18px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    //   border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[200],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
