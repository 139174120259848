/* eslint-disable */
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ChromePicker } from "react-color";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import ActionEditStepper from "../components/ActionEditStepper";
import AnalysisStepper from "../components/AnalysisStepper";
import BasicAlertEditForm from "./BasicAlertEditForm";
import { setActionId } from "../../../redux/actions/jobAction";
import SidebarLink from "../../Layout/sidebar/SidebarLink";
import BackToAction from "./BackToAction";

class ActioinEdit extends Component {
  constructor(props) {
    super(props);
    let activeTab = "1";
    // if(this.props.actionID && this.props.actionID == 0) {
    //     activeTab = '0';
    // }
    this.state = {
      // activeTab: '1',
      activeTab: activeTab,
    };
  }

  componentDidMount() {
    this.props.setActionId(this.props.actionID);
  }

  componentWillReceiveProps(nextProps) {}

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        // selectedShape: data,
      });
    }
  }

  renderBasic() {
    return (
      <div style={{ width: "100%" }}>
        <BasicAlertEditForm actionPassId={0} />
      </div>
    );
  }

  renderAdvanced = () => {
    return (
      <div style={{ width: "100%" }}>
        <Nav tabs>
          {/* {this.props.actionID == 0 &&
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '0' })}
                            onClick={() => { this.toggle('0'); }}
                        >
                            Basic Alert
                        </NavLink>
                    </NavItem>
                } */}
          <BackToAction title="< Back" route="/pages/action/" />
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "1" })}
              onClick={() => {
                this.toggle("1");
              }}
              style={{ cursor: "pointer" }}
            >
              Advance Alert
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === "2" })}
              onClick={() => {
                this.toggle("2");
              }}
              style={{ cursor: "pointer" }}
            >
              Analysis
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          {/* {this.props.actionID == 0 &&
                        <TabPane tabId="0">
                            <BasicAlertEditForm actionPassId={this.props.actionID}/>
                        </TabPane>
                    } */}
          <TabPane tabId="1">
            <Row>
              <ActionEditStepper actionPassId={this.props.actionID} />
            </Row>
          </TabPane>
          <TabPane tabId="2">
            Coming Soon ...
            {/* <AnalysisStepper actionId={this.props.actionID}/> */}
            {/* <BasicAlertEditForm actionPassId={this.props.actionID}/> */}
          </TabPane>
        </TabContent>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        {this.props.actionID >= 0 ? this.renderAdvanced() : this.renderBasic()}
      </Fragment>
    );
  }
}

// export default ActioinEdit

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setActionId }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(ActioinEdit);
