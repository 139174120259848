/* eslint-disable */
import React, {useState, useEffect} from 'react'
import { Row } from 'reactstrap';
import './SmartBuilding.css'
import {renderSpinner} from './PeopleCountBoard'
import {groupByArray} from '../../../../factories/utils'

function PeopleCountInfo({localData, handleStayCountChange}) {
    const [groupedListByCam, setgroupedListByCam] = useState(null)
    const [EntranceCount, setEntranceCount] = useState(null);
    const [ExitCount, setExitCount] = useState(null);
    const [Stay, setStay] = useState(null)
    const [CamNum, setCamNum] = useState(null)
    

    useEffect(() => {
        if(localData && localData.length > 0) {
            //console.log("Change data...")
            let groupedCountListByCam = groupByArray(localData, 'cameraChannel');
            setgroupedListByCam(groupedCountListByCam);
        }
    }, [localData]);

    useEffect(() => {
        //console.log('GROUPED LIST', groupedListByCam)
        let countListCams
        let entranceTotal = 0
        let exitTotal = 0
        if(groupedListByCam){
            countListCams = Object.entries(groupedListByCam)
            //console.log(countListCams);
            setCamNum(countListCams.length)
            countListCams.forEach(element => {
                let peopleCountData = element[1][element[1].length - 1] //  last people count instance of each camera
                entranceTotal += peopleCountData.entranceCount
                exitTotal += peopleCountData.exitCount
            });
            setEntranceCount(entranceTotal)
            setExitCount(exitTotal)
            setStay(entranceTotal - exitTotal < 0 ? 0 : entranceTotal - exitTotal)
        }
    }, [groupedListByCam])

    useEffect(() => {
        handleStayCountChange(Stay)
    }, [Stay])

    //Grouping array by key

    return (
        <>
            <div className="box" >
                <p>Entrance Count</p>
                <p className="stat">{EntranceCount? EntranceCount: 0}</p>

            </div>

            <div className="box">
                <p>Exit Count</p>
                <p className="stat">{ExitCount ? ExitCount : 0}</p>
            </div>

            <div className="box">
                <p>NO. of People at Premises</p>
                {/* {this.props.loading?this.renderSpinner():null} */}
                {<p className="stat total">{Stay ? Stay : 0}</p>}
            </div>
            <div className="box">
                <p>NO. of Cameras Used</p>
                {/* {this.props.loading?this.renderSpinner():null} */}
                {CamNum ? <p className="stat">{CamNum}</p>: renderSpinner()}
            </div>
        </>
    )
}

export default PeopleCountInfo

