/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlusIcon from 'mdi-react/PlusIcon';
import moment from 'moment';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import {
    ClearClientInventoryInformation,
    getFullSIMList, getCarrierPlans, activateMultipleSIM,
    setSimName, ClearCarrierPlans, uploadInventory, updateCarrierInfo,
    SetSimType, SetDeviceType,
    getDeviceList, ChangeDeviceStatus, registerDevice,DeleteDeviceType,
    resetFullSIMList
} from '../../../redux/actions/inventoryActions';
import {
    ClearClientUserInformation, updatePaymentMethod,
    getUsageReport, payInvoice, getCustomerInvoiceHistory,
    getCustomerInvoiceDetails, createReseller, getAllResellers,
    getServicePlanItem, getDiscountList, setDiscount, SetServicePlan,
    DeleteServicePlan, getAccountsByEntity, clientCheckout, clientPurchaseOrder,
    getAddress, setAddress, getConsumers, getPaymentAccounts,
    setDefaultPaymentMethod, getCarrierList, getBusinessUnits,
    updateBusinessUnits, UpdateBusinessUnitRelations,
    getSpecialLine, SetSpecialLine, DeleteSpecialLine, getPageContent, loadTransactionHistory,
    generatePurchaseOrder,
    EmailPurchaseOrder
}
    from '../../../redux/actions/userAction';
import { clearSelectedCompantToSesstion } from '../../../factories/utils';
import Spinner from 'react-bootstrap/Spinner';
import { LIGHT_GREY_COLOR } from '../../../constants/dataConstants';

import ClientList from './ClientList'
import ClientNavigation from './ClientNavigation'
import ClientActiveSIMBoard from './ClientActiveSIMBoard'
import ClientInactiveSIMBoard from './ClientInactiveSIMBoard'
import ClientInventoryBoard from './ClientInventoryBoard'
import ClientDiscountBoard from './ClientDiscountBoard';
import ClientPlanBoard from './ClientPlanBoard';
import ClientEditBoard from './ClientEditBoard';
import ClientInvoiceBoard from './ClientInvoiceBoard';
import ClientPaymentBoard from './ClientPaymentBoard';
import ClientCarrierBoard from './ClientCarrierBoard';
import ClientBusinessUnitBoard from './ClientBusinessUnitBoard';
import ClientSpecialLineBoard from './ClientSpecialLineBoard';
import { toast } from 'react-toastify';
import ClientPurchaseBoard from './ClientPurchaseBoard';

export function renderSpinner(loading) {
    return (
        <Fragment >
            {loading &&
                <div style={{
                    zIndex: 9999,
                    position: 'Absolute ',
                    height: '100%',
                    width: '100%',
                    top: '0px',
                    left: '0px',
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    backgroundColor: LIGHT_GREY_COLOR,
                    opacity: '1.0',
                }}
                >
                    <Spinner
                        className="spinner"
                        as="span"
                        animation="border"
                        variant="custom"
                        // size="sm"
                        // role="status"
                        // aria-hidden="true"
                        style={{ display: 'inline-block', margin: 'auto' }}
                    />
                </div>
            }
        </Fragment>
    );
}
class ClientBoard extends Component {
  constructor(props) {
    super(props);
    this.userObj = getCurrentUser();
    this.state = {
      showAddModal: false,
      isAdmin: false,
      listView: true,
      selectedRow: null,
      loading: true,
    };
  }
  async componentDidMount() {
    await this.props.resetFullSIMList(); // delete this existing full inventory list
    await this.props.getPageContent({ content: "Client" });
    await this.props.getAllResellers({
      resellerCode: this.props.userObj.resellerCode,
    });
    const check =
      this.props.userObj &&
      hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"]);
    clearSelectedCompantToSesstion();
    if (
      this.props.resellerList.length === 1 &&
      this.props.userObj &&
      hasRole(this.props.userObj, ["CLIENTADMIN", "CLIENTUSER"])
    ) {
      this.setState({ isAdmin: check });
      await this.handleRowEditClick(this.props.resellerList[0]);
    } else {
      this.setState({ isAdmin: check, loading: false });
    }

        //window.addEventListener('resize', this.updateDimensions.bind(this));

    }
    updateDimensions() {
    }

    toggle = () => {
        this.setState(prevState => {
            return ({
                listView: !prevState.listView,
            })
        }, () => {
            if (this.state.listView === true) {
                clearSelectedCompantToSesstion();
            }
        });
    }
    handleRowEditClick = (row, showAccount = false) => {
        this.setState({
            showAccount: showAccount,
            selectedRow: row,
            loading: true
        }, async () => {
            await this.props.ClearCarrierPlans();
            await this.props.getCustomerInvoiceHistory({ "objId": row.id, "fromDate": moment().subtract(1, 'years'), "toDate": moment() })
            await this.props.getConsumers({ businessUnit: "", companyCode: row.companyCode, department: "", resellerCode: row.resellerCode })
            await this.props.getDiscountList({ "companyId": row.companyId, "resellerId": row.id })
            await this.props.getFullSIMList({ "companyId": row.companyId, "resellerId": row.id, "customerId": null, "fullInventory": "false" });
            await this.props.getServicePlanItem({ "companyCode": row.companyCode, "resellerCode": row.resellerCode })
            await this.props.getAddress({ "companyId": row.companyId, "resellerId": row.id, "customerId": null, "userId": null });
            await this.props.getBusinessUnits("", { "companyId": row.companyId, "resellerId": row.id });
            await this.props.getCarrierList({ companyCode: row.companyCode });
            await this.props.getPaymentAccounts(row.id);

            if (this.props.pageContentList.includes("Special Lines")/* TEISA */) {
                this.props.getSpecialLine({ "companyCode": row.companyCode, "resellerCode": row.resellerCode });
            }
            await this.props.loadTransactionHistory({ "objId": row.companyId, "resellerId": row.id })
            this.toggle();
            this.setState({
                loading: false

            })
        });
        return true;
    }

    OnSortChange = (a, b, order, sortField) => {
        var firstDeviceName = "";
        var secondDeviceName = "";
        if (a[sortField] != null && a[sortField] != "") {
            firstDeviceName = a[sortField];
        }
        if (b[sortField] != null && b[sortField] != "") {
            secondDeviceName = b[sortField];
        }

        if (order === 'asc') {
            return firstDeviceName.length - secondDeviceName.length || firstDeviceName.localeCompare(secondDeviceName);
        } else {
            return secondDeviceName.length - firstDeviceName.length || secondDeviceName.localeCompare(firstDeviceName);
        }
    }


    handleAddNewButtonClick = (onClick) => {
        this.setState(preState => ({
            showAddModal: !preState.showAddModal,
        }));
        setTimeout(() => {
            this.setState({ showAddModal: false });
        }, 1);

    }

    addNewUserButton = (onClick) => {

        const { pageContentList } = this.props;
        let textDisplay = [];

        if (pageContentList.includes("Reseller")) {
            textDisplay = ['Reseller']
        } else {
            textDisplay = ['Client']
        }

        return (
            <InsertButton
                btnContextual='btn-success'
                btnGlyphicon='glyphicon-edit'
                onClick={() => this.handleAddNewButtonClick(onClick)}
            >
                <PlusIcon /> Add {textDisplay[0]}
            </InsertButton>
        );
    }

    handleEditUser = (data) => {
        this.props.createReseller(data).then(res => {
            if (res.reseller != null) {
                this.setState({ selectedRow: res.reseller },
                    () => { this.props.getAddress({ "companyId": res.reseller.companyId, "resellerId": res.reseller.id, "customerId": null, "userId": null }) });
            }
            else {
                toast.error("Issue with save")

            }
        });
    }
    handleRefreshInvoiceList = async () => {
        await this.props.getCustomerInvoiceHistory({ "objId": this.state.selectedRow.id, "fromDate": moment().subtract(1, 'years'), "toDate": moment() })
    }
    onExitClient = () => {
        this.setState({
            listView: true,
            selectedRow: null
        }, async () => {
            await this.props.ClearClientUserInformation();
            await this.props.ClearClientInventoryInformation();
        })
    }
    renderClientList() {
        return (
            <ClientList
                addNewUserButton={this.addNewUserButton}
                isAdmin={this.state.isAdmin}
                userObj={this.props.userObj}
                showAddModal={this.state.showAddModal}
                handleRowEditClick={this.handleRowEditClick}
            />
        )
    }
    renderNavigation(tabObjs) {
        return (
            <ClientNavigation
                tabObjs={tabObjs}
                selectedRow={this.state.selectedRow}
                resellerList={this.props.resellerList}
                onExitClient={this.onExitClient}
            />

        )
    }
    renderDiscountBoard() {
        return (
            <ClientDiscountBoard
                discountList={this.props.discountList}
                getDiscountList={this.props.getDiscountList}
                setDiscount={this.props.setDiscount}
                selectedRow={this.state.selectedRow}
                userObj={this.props.userObj}
            />
        )
    }
    renderActiveSIMBoard() {
        return (
            <ClientActiveSIMBoard
                OnSortChange={this.OnSortChange}
                selectedRow={this.state.selectedRow}
                activateMultipleSIM={this.props.activateMultipleSIM}
                getFullSIMList={this.props.getFullSIMList}
                activeSIMList={this.props.activeSIMList}
                suspendedSIMList={this.props.suspendedSIMList}
                isAdmin={this.state.isAdmin}
                consumerList={this.props.consumerList}
                ServicePlanList={this.props.ServicePlanList}
                setSimName={this.props.setSimName}
                userObj={this.props.userObj}
                BURelationshipList={this.props.BURelationshipList}
                getDeviceList={this.props.getDeviceList}
                ChangeDeviceStatus={this.props.ChangeDeviceStatus}
                SIMTypeList={this.props.SIMTypeList}
                clientActiveDeviceList={this.props.clientActiveDeviceList}
                itemType={this.props.itemType}
                registerDevice={this.props.registerDevice}
            />
        )
    }
    renderInactiveSIMBoard() {
        return (
            <ClientInactiveSIMBoard
                selectedRow={this.state.selectedRow}
                inactiveSIMList={this.props.inactiveSIMList}
                companySIMList={this.props.companySIMList}
                ServicePlanList={this.props.ServicePlanList}
                isAdmin={this.state.isAdmin}
                clientCheckout={this.props.clientCheckout}
                clientPurchaseOrder={this.props.clientPurchaseOrder}
                getAccountsByEntity={this.props.getAccountsByEntity}
                getFullSIMList={this.props.getFullSIMList}
                uploadInventory={this.props.uploadInventory}
                SIMTypeList={this.props.SIMTypeList}
                getCustomerInvoiceHistory={this.props.getCustomerInvoiceHistory}
                getServicePlanItem={this.props.getServicePlanItem}
                consumerList={this.props.consumerList.filter((consumer) => consumer.resellerCode === this.state.selectedRow.resellerCode)}
                activateMultipleSIM={this.props.activateMultipleSIM}
                discountList={this.props.discountList}
                getAddress={this.props.getAddress}
                setSimName={this.props.setSimName}
                userObj={this.props.userObj}
                accountList={this.props.paymentAccountList}
                carrierList={this.props.carrierList}
            />
        )
    }
    renderInventoryBoard() {
        const resellers = this.props.resellerList.filter(value => value.resellerCode == this.state.selectedRow.resellerCode);

        return (
            <ClientInventoryBoard
                selectedRow={this.state.selectedRow}
                SetDeviceType={this.props.SetDeviceType}
                getFullSIMList={this.props.getFullSIMList}
                SIMTypeList={this.props.SIMTypeList}
                typeList={this.props.typeList ? this.props.typeList : []}
                pageContentList={this.props.pageContentList}
                EmailPurchaseOrder={this.props.EmailPurchaseOrder}
                DeleteDeviceType={this.props.DeleteDeviceType}
                userObj={this.props.userObj}
                reseller={resellers[0]}
                companyId={this.state.selectedRow.companyId}
                carrierList={this.props.carrierList}
            />
        )
    }
    renderPlanBoard() {
        return (
            <ClientPlanBoard
                ServicePlanList={this.props.ServicePlanList}
                isAdmin={this.state.isAdmin}
                carrierPlanList={this.props.carrierPlanList}
                selectedRow={this.state.selectedRow}
                SetServicePlan={this.props.SetServicePlan}
                DeleteServicePlan={this.props.DeleteServicePlan}
                getServicePlanItem={this.props.getServicePlanItem} //need this for SetServicePlan to work
                getCarrierList={this.props.getCarrierList}
                carrierList={this.props.carrierList}
                getCarrierPlans={this.props.getCarrierPlans}
                pageContentList={this.props.pageContentList}
            />
        )
    }
    renderSpecialLineBoard() {
        return (
            <ClientSpecialLineBoard
                isAdmin={this.state.isAdmin}
                selectedRow={this.state.selectedRow}
                specialLineList={this.props.specialLineList}
                SetSpecialLine={this.props.SetSpecialLine}
                getSpecialLine={this.props.getSpecialLine}
                DeleteSpecialLine={this.props.DeleteSpecialLine}
            />
        )
    }

    renderClientEditBoard() {
        return (
            <ClientEditBoard
                handleEditUser={this.handleEditUser}
                isAdmin={this.state.isAdmin}
                selectedRow={this.state.selectedRow}
                provinceList={this.props.provinceList}
                addressList={this.props.addressList}
                setAddress={this.props.setAddress}
                getAddress={this.props.getAddress}
                userObj={this.props.userObj}

            />
        )
    }

    renderClientPaymentBoard() {
        return (
            <ClientPaymentBoard
                selectedRow={this.state.selectedRow}
                isAdmin={this.state.isAdmin}
                accountList={this.props.paymentAccountList}
                transactionHistory={this.props.transactionHistory}
                setDefaultPaymentMethod={this.props.setDefaultPaymentMethod}
                updatePaymentMethod={this.props.updatePaymentMethod}
                getPaymentAccounts={this.props.getPaymentAccounts}
            />
        );
    }

    /* this is where the invoice list is printed */
    renderClientInvoiceBoard() {
        return (
            <ClientInvoiceBoard
                selectedRow={this.state.selectedRow}
                paymentInvoice={this.props.paymentInvoice}
                getCustomerInvoiceDetails={this.props.getCustomerInvoiceDetails}
                paymentInvoiceDetails={this.props.paymentInvoiceDetails}
                payInvoice={this.props.payInvoice}
                isAdmin={this.state.isAdmin}
                handleRefreshInvoiceList={this.handleRefreshInvoiceList}
                usageReportList={this.props.usageReportList}
                getUsageReport={this.props.getUsageReport}
                generatePurchaseOrder={this.props.generatePurchaseOrder}
                addressList={this.props.addressList}
                provinceList={this.props.provinceList}
                SIMTypeList={this.props.SIMTypeList}
                paymentAccountList={this.props.paymentAccountList}
                pageContentList={this.props.pageContentList}


            />
        )
    }


    renderClientBusinessUnitBoard() {
        return (
            <ClientBusinessUnitBoard
                isAdmin={this.state.isAdmin}
                updateBusinessUnit={this.props.updateBusinessUnits}
                UpdateBusinessUnitRelations={this.props.UpdateBusinessUnitRelations}
                selected={this.state.selectedRow}
                BUCountryList={this.props.BUCountryList}
                BULOBList={this.props.BULOBList}
                BUDivisionList={this.props.BUDivisionList}
                BURelationshipList={this.props.BURelationshipList}
                userObj={this.props.userObj}

            />
        )
    }


    renderClientCarrierBoard() {
        return (
            <ClientCarrierBoard
                carrierList={this.props.carrierList}
                selectedRow={this.state.selectedRow}
                getAllResellers={this.props.getAllResellers}
                updateCarrierInfo={this.props.updateCarrierInfo}

            />

        )
    }
    renderClientPurchaseBoard() {
        return (
            <ClientPurchaseBoard
                SIMTypeList={this.props.SIMTypeList}
                discountList={this.props.discountList}
                accountList={this.props.paymentAccountList}

            />
        )
    }
    render() {
        const { pageContentList } = this.props
        const checkPermission = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'USER']);

        let textDisplay = [];
        if (pageContentList.includes("Reseller")) {
            textDisplay = ['Reseller'];
        } else {
            textDisplay = ['Client'];
        }

        let inventoryTabName = '';
        if (pageContentList.includes("Client Inventory Tab Device Type")) {
            inventoryTabName = 'Device Type';
        } else {
            inventoryTabName = 'Inventory';
        }

        let activatedTabName = '';
        if (pageContentList.includes("Client Active Tab Device Activation")) {
            activatedTabName = 'Device Activation';
        } else {
            activatedTabName = 'Activated';
        }

        let tabObjs = [
            {
                tabId: '1',
                label: `${textDisplay[0]} Info`,
                renderTab: (props) => { return (this.renderClientEditBoard(props)) },
            },
        ];

        //if (pageContentList.includes("Carrier Info") && checkPermission == true) {
        if (pageContentList.includes("Carrier Info")) {
            tabObjs.push(
                {
                    tabId: '8',
                    label: 'Carrier Info',
                    renderTab: (props) => { return (this.renderClientCarrierBoard(props)) }
                }
            );
        }

        if (pageContentList.includes("Business Unit")) {
            tabObjs.push(
                {
                    tabId: '9',
                    label: 'Business Unit',
                    renderTab: (props) => { return (this.renderClientBusinessUnitBoard(props)) },
                }
            );
        }

        if (pageContentList.includes("Discount")) {
            tabObjs.push(
                {
                    tabId: '2',
                    label: 'Discount',
                    renderTab: (props) => { return (this.renderDiscountBoard(props)) }
                }
            );
        }

        // if (pageContentList.includes("Client Inventory")) {
        if (true) {
            tabObjs.push(
                {
                    tabId: '10',
                    label: inventoryTabName,
                    renderTab: (props) => { return (this.renderInventoryBoard(props)) }
                }
            );
        }

        if (pageContentList.includes("Inactive")) {
            tabObjs.push(
                {
                    tabId: '3',
                    label: 'Inactive',
                    renderTab: (props) => { return (this.renderInactiveSIMBoard(props)) }
                }
            );
        }


        if (pageContentList.includes("Active") || pageContentList.includes("Orders")) {

            let tab4Name;
            if (pageContentList.includes("Orders")) {
                tab4Name = 'Contract'
            }
            else {
                tab4Name = 'Activated'
            }

            tabObjs.push(
                {
                    tabId: '4',
                    label: activatedTabName,
                    renderTab: (props) => { return (this.renderActiveSIMBoard(props)) }
                }
            );
        }
        // -------------------------------------------------------------------------------------------

        if (pageContentList.includes("Plan")) {
            tabObjs.push(
                {
                    tabId: '5',
                    label: 'Plan',
                    renderTab: (props) => { return (this.renderPlanBoard(props)) },
                }
            );
        }

        if (pageContentList.includes("Payment")) {
            tabObjs.push(
                {
                    tabId: '6',
                    label: 'Payment',
                    renderTab: (props) => { return (this.renderClientPaymentBoard(props)) }
                }
            );
        }

        if (pageContentList.includes("Invoice")) {
            tabObjs.push(
                {
                    tabId: '7',
                    label: 'Invoice',
                    renderTab: (props) => { return (this.renderClientInvoiceBoard(props)) },
                }
            );
        }

     if (pageContentList.includes("Special Lines")/* TEISA */) {
         tabObjs.push(
             {
                 tabId: '8',
                 label: 'Special Lines',
                 renderTab: (props) => { return (this.renderSpecialLineBoard(props)) },
             }
         );
     };

    /*
        tabObjs.push(
            {
                tabId: '8',
                label: 'Business Unit',
                renderTab: (props) => { return (this.renderClientBusinessUnitBoard(props)) },
            }
        );

        /*
        tabObjs.push(
            {
                tabId: '9',
                label: 'Business Unit',
                renderTab: (props) => { console.log("Payment History") },
            }
        );
        */

        return (
            <div>
                <div style={{ display: this.state.loading ? "block" : "none" }}>
                    {renderSpinner(this.state.loading)}
                </div >
                <div style={{ display: this.state.listView ? "block" : "none" }}>
                    {this.renderClientList()}
                </div>
                <div style={{ display: !this.state.listView ? "block" : "none" }}>
                    {!this.state.listView ? this.renderNavigation(tabObjs) : null}
                </div>

            </div>
        )

    }
}
const mapStateToProps = (state) => {
    let { BUCountryList, BULOBList, BUDivisionList,
        BURelationshipList, usageReportList, ServicePlanList,
        discountList, accountList, resellerList, consumerList,
        paymentInvoice, paymentInvoiceDetails, addressList,
        paymentAccountList, carrierList, paymentInvoiceEditDetails,
        specialLineList, pageContentList, transactionHistory, countryList } = state.user;


    let { SIMTypeList, typeList, inactiveSIMList, activeSIMList,
        suspendedSIMList, companySIMList, carrierPlanList,
        clientActiveDeviceList, itemType } = state.inventory;

    let props = {
        BUCountryList, BULOBList, BUDivisionList,
        BURelationshipList, usageReportList, addressList,
        resellerList, SIMTypeList, typeList, inactiveSIMList,
        activeSIMList, suspendedSIMList, companySIMList,
        ServicePlanList, discountList, carrierPlanList,
        accountList, consumerList, paymentInvoice,
        paymentInvoiceDetails, paymentAccountList, carrierList,
        paymentInvoiceEditDetails, specialLineList, pageContentList,
        transactionHistory,countryList,
        clientActiveDeviceList, itemType
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators({
        uploadInventory, ClearClientInventoryInformation,
        ClearClientUserInformation, updatePaymentMethod,
        UpdateBusinessUnitRelations, updateBusinessUnits,
        getBusinessUnits, getUsageReport, ClearCarrierPlans, setSimName, payInvoice,
        getCustomerInvoiceDetails, getCustomerInvoiceHistory, createReseller, getAllResellers,
        getFullSIMList, getServicePlanItem, getDiscountList, setDiscount, getCarrierPlans,
        SetServicePlan, DeleteServicePlan, getAccountsByEntity, clientCheckout, clientPurchaseOrder,
        getAddress, setAddress, getConsumers, activateMultipleSIM, getPaymentAccounts,
        setDefaultPaymentMethod, getCarrierList, updateCarrierInfo, getSpecialLine, SetSpecialLine,
        DeleteSpecialLine, getPageContent, loadTransactionHistory, SetSimType, SetDeviceType, EmailPurchaseOrder, generatePurchaseOrder,
        getDeviceList, ChangeDeviceStatus, registerDevice,DeleteDeviceType, resetFullSIMList
    }, dispatch);

};
export default connect(mapStateToProps, mapDispatchToProps)(ClientBoard);
