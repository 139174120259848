/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import { Card, CardBody, Col } from 'reactstrap';
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import {loadDevice, loadDeviceInfo, loadDivceMapData, getAssetsData} from '../../../redux/actions/mapActions';
import {getActionLog} from '../../../redux/actions/jobAction';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
// import {groupBy} from '../../../factories/utils';
import {Bar} from 'react-chartjs-2';
import {HorizontalBar} from 'react-chartjs-2';
import DashboardMap from './DashboardMap';
import DasBoardEditModal from './DashBoardEditModal';
import AssetMapWidget from './AssetMapWidget';
import AssetTableWidget from './AssetTableWidget';
import DeviceTableWidget from './DeviceTableWidget';
import DeviceCountChartWidget from './DeviceCountChartWidget';
import DeviceUsageDonutWidget from './DeviceUsageDonutWidget';
import DeviceMsgChartWidget from './DeviceMsgChartWidget';
import SubscribeValueWidget from './SubscribeValueWidget';
import AttrValueWidget from './AttrValueWidget';
import AssetWidget from './AssetWidget';
import AttrChartWidget from './AttrChartWidget';
import AlertTableWidget from './DashboardWidgets/AlertTableWidget';
import {loadDashBoardLayout, updateDashBoardLayout, loadDashBoardLayoutList, setDefaultLayout} from '../../../redux/actions/dashboardActions'; 
import {groupBy} from '../../../factories/utils';
import PlaylistEditIcon from 'mdi-react/PlaylistEditIcon'
import GridLayout from 'react-grid-layout';
import { Responsive, WidthProvider } from "react-grid-layout";
// import RGL, { WidthProvider } from "react-grid-layout";
import BasicDashboadEditIcon from './BasicDashboadEditIcon';
// import SimplePackDeviceEditIcon from './SimplePackDeviceEditIcon';
// import {SquareEditOutlineIcon} from 'mdi-react';
import {WEB_SERVICE_ROOT, WEB_SUB_CHANNEL, WEB_SUB_EVENT} from '../../../constants/appConstants';
import { toast } from 'react-toastify';
import AssetWeatherWidget from "./AssetWeatherWidget";
import { getCurrentUser } from '../../../factories/utils';
import axios from "axios";

const ResponsiveGridLayout  = WidthProvider(Responsive);
// const ReactGridLayout = WidthProvider(RGL);



class DashboardCard extends Component {
  constructor(props){

    super(props);
    this.state={
      dashBoardModal: false,
      widgetEditModal : false,
      dropdownOpen: false,
      closeAll : false,
      currentLayoutName: 'Dashboard',
      layoutList:[
        {layoutname:'My Layout', layoutid: 1},
        {layoutname:'Template 1', layoutid: 2},
        {layoutname:'Template 3', layoutid: 3}
      ],
      mapMockData:[
        {
          devicename: 'devcie1',
          locationlat: 43.8148024,
          locationlng: -79.5509472,
          msg:{speed:10,temp:20,vbat:1}
        },
        {
          devicename: 'devcie1',
          locationlat: 43.8138024,
          locationlng: -79.5529472,
          msg:{speed:10,temp:20,vbat:1}
        },
        {
          devicename: 'devcie1',
          locationlat: 43.8128024,
          locationlng: -79.5549472,
          msg:{speed:10,temp:20,vbat:1}
        }
      ],
      mapProps: {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {attr:{}},
      },
      dashBoardSet: [
        {
          title: 'Map',
          hidden: false,
          render: this.rendermapcard,
          type: 'map',
          width: 7,
          layout: {x: 0, y: 0, w: 5, h: 5}
        },
        {
          title: 'Weather',
          hidden: false,
          render: this.renderweathercard,
          type: 'weather',
          width: 7,
          layout: {x: 0, y: 0, w: 5, h: 10}
        },

        {
          title: 'Alert',
          hidden: false,
          render: this.renderAlertTable,
          type: 'table',
          width: 6,
          layout: {x: 5, y: 0, w: 5, h: 5}
        },
        {
          title: 'Action',
          hidden: true,
          render: this.renderActionTable,
          type: 'table',
          width: 5,
          layout: {x: 0, y: 5, w: 5, h: 5}
        },
        {
          title: 'Total Asset',
          hidden: false,
          render: this.renderAssetTable,
          type: 'table',
          width: 6,
          layout: {x: 5, y: 5, w: 5, h: 5}
        },
        {
          title: 'Device Type Usage',
          hidden: false,
          render: this.renderDeviceTypeChart,
          type: 'chart',
          width: 6,
          layout: {x: 0, y: 10, w: 5, h: 5}
        },
      ],
      // currentLayout: [
      //   {
      //     name: "Asset Map",
      //     type: "AssetMap",
      //     display: 1,
      //     width: 12,
      //     position: '{ "w": 9, "h": 15, "x": 0, "y": 0 }',
      //     parameter: {assetid:"1"},
      //     company_code: "002",
      //     layoutid: 2,
      //     itemid: 2,
      //     userid: "001"
      //   },
      //   {
      //     name: "Alert",
      //     type: "AlertTable",
      //     display: 1,
      //     width: 6,
      //     position: '{"w": 6, "h": 15, "x": 6, "y": 30}',
      //     parameter: {assetid:"7"},
      //     company_code: "002",
      //     layoutid: 3,
      //     itemid: 0,
      //     userid: "001"
      //   },
      //   {
      //     name: "Action",
      //     type: "ActionTable",
      //     display: 0,
      //     width: 5,
      //     position: '{"w": 6, "h": 15, "x": 0, "y": 45}',
      //     parameter: {assetid:"7"},
      //     company_code: "002",
      //     layoutid: 4,
      //     itemid: 0,
      //     userid: "001"
      //   },
      //   {
      //     name: "Device",
      //     type: "DeviceTable",
      //     display: 1,
      //     width: 6,
      //     position: '{"w": 6, "h": 15, "x": 0, "y": 15}',
      //     parameter: {devicetype:"5cf828390499f50b995ae851"},
      //     company_code: "002",
      //     layoutid: 5,
      //     itemid: 0,
      //     userid: "001"
      //   },
       
      //   {
      //     name: "Device Chart",
      //     type: "DeviceChart",
      //     display: 1,
      //     width: 6,
      //     position: '{"w": 6, "h": 15, "x": 6, "y": 15}',
      //     parameter: {},
      //     company_code: "002",
      //     layoutid: 7,
      //     itemid: 0,
      //     userid: "001"
      //   },
      //   {
      //     name: "Asset",
      //     type: "AssetTable",
      //     display: 1,
      //     width: 12,
      //     position: '{"w": 6, "h": 15, "x": 0, "y": 30}',
      //     parameter: {assetid:"1"},
      //     company_code: "002",
      //     layoutid: 6,
      //     itemid: 6,
      //     userid: "001"
      //   },
      //   {
      //     name: "Device Usage",
      //     type: "DviceUsage",
      //     display: 1,
      //     width: 3,
      //     position: '{"w": 3, "h": 7, "x": 9, "y": 0}',
      //     parameter: {},
      //     company_code: "002",
      //     layoutid: 6,
      //     itemid: 6,
      //     userid: "001"
      //   },
      // ],
    }
    this.interval = null;
    // var EventSource = require("eventsource");
    this.eventSource = new EventSource(WEB_SUB_CHANNEL);
    this.userObj = getCurrentUser();
  }

  componentDidMount(){
    // this.props.loadDeviceInfo();
    // this.props.loadDivceMapData({assetid: 5,latest: 1});
    // this.props.getAssetsData({assetclassid: 1, latest: 1});
    // this.props.loadDeviceInfo();
    // //console.log('asset load');
    // this.props.loadDashBoardLayout('001');
    this.props.loadDashBoardLayout();
    this.props.loadDashBoardLayoutList();
    this.props.getActionLog();
    // this.interval = setInterval(() => {//console.log('load action log !');this.props.getActionLog()}, 10000);
    this.eventSource.addEventListener(WEB_SUB_EVENT, (e)=> {
      // this.props.updatePubSubDevice(e.data) ;
      this.pubSubUpdate(e.data);
    })
  }





  componentWillUnmount(){
    if (this.interval){
        clearInterval(this.interval);
    }
    if (this.eventSource){
      this.eventSource.close();
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.currentDeviceList && nextProps.currentDeviceList !== this.state.rawCurrentDeviceList){
      const grouped = groupBy(nextProps.currentDeviceList , device => device.devicetypename);

      const keys = Array.from(grouped.keys());
      let values = [];
      keys.forEach((key) =>{
        values.push(grouped.get(key).length);
      });
      this.setState({
        chartlabel : keys,
        chartdata : values,
        rawCurrentDeviceList: nextProps.currentDeviceList
      });
    }

    if (nextProps.currentLayoutList){
      let currentLayout = null; 
      if (this.state.currentLayout){
        currentLayout = nextProps.currentLayoutList.find(item => (item.templateId == this.state.currentLayout.templateId));
      }else{
        currentLayout = nextProps.currentLayoutList.find(item => (item.selected == true)); 
      }
      if (currentLayout) {
        // let title = currentLayout.templateName === 'System' ? 'My Dashboard': currentLayout.templateName ;
        // //console.log('currentLayout', currentLayout);
        let title = (currentLayout.templateName && currentLayout.templateName.length > 0) ? (currentLayout.templateName === 'System' ? 'My Dashboard': currentLayout.templateName) : 'Dashboard';
        this.setState({
          currentLayoutName: title,
          currentTemplateId: currentLayout.templateId,
          currentLayout: currentLayout,
        });
      }
      // const defaultLayout = nextProps.currentLayoutList.find(item => (item.selected == true));
      // const selectedLayout = nextProps.currentLayoutList.find(item => (item.templateId == this.state.currentTemplateId));
      // const currentLayout = selectedLayout ? selectedLayout: defaultLayout;
      // if (defaultLayout){
      //   let title = defaultLayout.templateName === 'System' ? 'My Dashboard': defaultLayout.templateName ;
      //   if (title !== this.state.defaultLayoutName){
      //     this.setState({
      //       // currentLayout: defaultLayout,
      //       // currentTemplateId: defaultLayout.templateId,
      //       // currentLayoutName: title,
      //       defaultLayoutName: title,
      //       defaultLayout: defaultLayout,
      //     });
      //   }       
      // }
      
    }
  }
  // static getDerivedStateFromProps(props, state) {

  //   function groupBy(list, keyGetter) {
  //     const map = new Map();
  //     list.forEach((item) => {
  //          const key = keyGetter(item);
  //          const collection = map.get(key);
  //          if (!collection) {
  //              map.set(key, [item]);
  //          } else {
  //              collection.push(item);
  //          }
  //     });
  //     return map;
  //   }

  //   if (props.currentDeviceList && props.currentDeviceList !== state.rawCurrentDeviceList){
  //     const grouped = groupBy(props.currentDeviceList , device => device.devicetypename);
  //     //console.log('grouped');
  //     //console.log(grouped);
  //     const keys = Array.from(grouped.keys());
  //     //console.log(keys);
  //     let values = [];
  //     keys.forEach((key) =>{
  //       values.push(grouped.get(key).length);
  //     });
  //     return ({
  //       chartlabel : keys,
  //       chartdata : values,
  //       rawCurrentDeviceList: props.currentDeviceList
  //     });
  //   }else{
  //     return state;
  //   }
  // }

  pubSubUpdate = (data) => {
    this.setState({
      pubSubData: data,
    });
  }

  modalToggle = () => {
    this.setState(prevState => ({
      dashBoardModal: !prevState.dashBoardModal,
    }));
  }

  editModalToggle = () => {
    this.setState(prevState => ({
      widgetEditModal: !prevState.widgetEditModal,
    }));
  }

  toggleAll = () => {
    this.setState(prevState => ({
      widgetEditModal: !prevState.widgetEditModal,
      closeAll: true,
    }));
  }

  onDashBoardUpdateClick = (dashBoardList) => {
    // this.setState(prevState => ({
    //   dashBoardSet: dashBoardList,
    // }));
    // this.setState(prevState => ({
    //   currentLayout: dashBoardList,
    // }));
    // alert('update');
    // console.log('UPDATE DASHBOARD, ', dashBoardList)
    this.props.updateDashBoardLayout(dashBoardList);
  }

  renderAssetTable = () => {
    const deviceIdLinkFormatter = (cell, row) => {
      let path = `/pages/device/${cell}`;
      return(
          // <a href={path} onClick = {() => {
          //                                   // alert(cell); 
          //                                   // alert(row['devicename']);
          //                                 }}>{cell}</a>
        //  <Button color="link" onClick = {() => {
        //                                             this.props.history.push(path);
        //                                         }}>{cell}</Button>     
          <Link to={path}>{cell}</Link>                   
      )
    }

    const options = {
      // page: 1,  // which page you want to show as default
      // sizePerPageList: [ {
      //   text: '5', value: 5
      // }, {
      //   text: '10', value: 10
      // }, {
      //   text: 'All', value: this.props.currentDeviceList.length
      // } ], // you can change the dropdown list for size per page
      // sizePerPage: 5,  // which size per page you want to locate as default
      // pageStartIndex: 1, // where to start counting the pages
      // paginationSize: 5,  // the pagination bar size.
      // prePage: '<', // Previous page button text
      // nextPage: '>', // Next page button text
      // firstPage: '<<', // First page button text
      // lastPage: '>>', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    return(
      <div>
      {/* <Col > */}
          {/* <Card>
              <CardBody> */}
                  <BootstrapTable data={ this.props.currentDeviceList } tableHeaderClass = 'header_white' options={ options }      bordered={ false } search maxHeight='450' >
                      <TableHeaderColumn dataField='deviceid' isKey={ true } dataAlign="center" dataFormat={ deviceIdLinkFormatter }  >Device ID</TableHeaderColumn>
                      <TableHeaderColumn dataField='devicename' dataAlign="center" dataSort  >Device Name</TableHeaderColumn> 
                      <TableHeaderColumn dataField='devicetypename' dataAlign="center" dataSort  >Device Type</TableHeaderColumn>
                      <TableHeaderColumn dataField='lastmessagetime' dataAlign="center" dataSort >Last Message Time</TableHeaderColumn>
                  </BootstrapTable>
              {/* </CardBody>
          </Card> */}
      {/* </Col> */}
      </div>
    );
  }

  timeColFormater = (cell, row) => {
    let time = ''
    if (cell){
      // time = cell.split('.')[0].replace('T', ' ');
      // time = new Date(cell).toLocaleString('en-US', { timeZone: 'America/New_York' });  
      time = new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' });  
    }
    
    return (
      <span>{time}</span>
    )
  }

  renderAlertTable = (layout) => {
    const options = {
      // page: 1,  // which page you want to show as default
      // sizePerPageList: [ {
      //   text: '5', value: 5
      // }, {
      //   text: '10', value: 10
      // }, {
      //   text: 'All', value: this.props.currentDeviceList.length
      // } ], // you can change the dropdown list for size per page
      // sizePerPage: 3,  // which size per page you want to locate as default
      // pageStartIndex: 1, // where to start counting the pages
      // paginationSize: 5,  // the pagination bar size.
      // prePage: '<', // Previous page button text
      // nextPage: '>', // Next page button text
      // firstPage: '<<', // First page button text
      // lastPage: '>>', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    let actionLogs =  [];
    if (this.props.actionLogs ){
      actionLogs = this.props.actionLogs.reverse(); 
    }
    let maxHeight = JSON.parse(layout.position).h * 30 - 20;

    return(
      <Col md={12}>
          {/* <Card>
              <CardBody> */}
                  <BootstrapTable data={ actionLogs } tableHeaderClass = 'header_white' pagination = {false} options={ options }     search bordered={ false } maxHeight={maxHeight.toString()}>
                      <TableHeaderColumn dataField='actionid' isKey={ true } dataAlign="center" width='15%' hidden >Action ID</TableHeaderColumn>
                      {/* <TableHeaderColumn dataField='assetname' dataAlign="center" dataSort>Asset Name</TableHeaderColumn>                        */}
                      <TableHeaderColumn dataField='alerttime' dataAlign="center" dataFormat = {this.timeColFormater} dataSort >Alert Time</TableHeaderColumn>
                      <TableHeaderColumn dataField='alertmsg' dataAlign="center" dataSort>Message</TableHeaderColumn>
                  </BootstrapTable>
              {/* </CardBody>
          </Card> */}
      </Col>
    );
  }

  renderActionTable = (layout) => {
    const options = {
      page: 1,  // which page you want to show as default
      sizePerPageList: [ {
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: this.props.currentDeviceList.length
      } ], // you can change the dropdown list for size per page
      sizePerPage: 3,  // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5,  // the pagination bar size.
      prePage: '<', // Previous page button text
      nextPage: '>', // Next page button text
      firstPage: '<<', // First page button text
      lastPage: '>>', // Last page button text
      // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
      // paginationPosition: 'top'  // default is bottom, top and both is all available
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
    };
    let maxHeight = JSON.parse(layout.position).h * 30 - 20;

    return(
      <Col md={12}>
          {/* <Card>
              <CardBody> */}
                  <BootstrapTable data={ this.props.actionList } tableHeaderClass = 'header_white' pagination = {true} options={ options }     bordered={ false } maxHeight={maxHeight.toString()}>
                      <TableHeaderColumn dataField='deviceid' isKey={ true } dataAlign="center" >Device ID</TableHeaderColumn>
                      <TableHeaderColumn dataField='devicename' dataAlign="center" dataSort>Device Name</TableHeaderColumn>    
                      <TableHeaderColumn dataField='attributename' dataAlign="center" dataSort>Attribute Name</TableHeaderColumn>                    
                      <TableHeaderColumn dataField='lastmessagetime' dataAlign="center">Action Time</TableHeaderColumn>
                      <TableHeaderColumn dataField='message' dataAlign="center" dataSort>Message</TableHeaderColumn>
                  </BootstrapTable>
              {/* </CardBody>
          </Card> */}
      </Col>
    );
  }

  renderDeviceTypeChart = () => {
    const data = {
      labels: this.state.chartlabel,
      datasets: [
        {
          label: 'Number of Devices',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 0.5,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: this.state.chartdata
        }
      ]
    };
    return (
      // <Card>
      // <CardBody>
        <div>
        {/* <h5 className="bold-text">Device Type Usage</h5> */}
        <Bar
          data={data}
          width={60}
          height={450}
          options={{ maintainAspectRatio: false }}
        />
        </div>
      // </CardBody>          
      // </Card>
    );
  }

  renderhbartest(){
    const data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'My First dataset',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 0.5,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: [65, 59, 80, 81, 56, 55, 40]
        }
      ]
    };
    return (
      <div>
        <h2>Horizontal Bar Example</h2>
        <HorizontalBar data={data} />
      </div>
    );
  }
// -----------------------------------------------------------------------------------
handleMapMarkerClick = (props, marker, e) => {
  // //console.log("on click----------");
  // //console.log(e);
  // //console.log(marker);
  this.setState({
      mapProps: {
          showingInfoWindow: true,
          activeMarker: marker,
          selectedPlace: props,
          lat: props.position.lat,
          lng: props.position.lng,
      },
  });  
}

handleMapInfoWinClick = (props) => {
  if(this.state.mapProps.showingInfoWindow){
      this.setState({
          showingInfoWindow: false,
          activeMarker: null
      });
  }
}
  rendermapcard=()=>{
    // let data = this.props.currentDeviceList.filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
    let data = this.props.mapData; //filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
    return(
      // <DashboardMap 
      //   onMarkerClick={this.handleMapMarkerClick} 
      //   onInfoWinClick={this.handleMapInfoWinClick}
      //   values = {this.state.mapProps}
      //   // mapData = {this.state.mapMockData}
      //   mapData = {data}
      // />
      <AssetMapWidget assetclassId={"1"} dashName={"  "}/>
    )
  }

  renderweathercard=()=>{

    return(
        <AssetWeatherWidget assetclassId={"1"} dashName={"  "}/>
    )
  }

  rendertablecard = () => {
    return(
      <AssetTableWidget assetclassId={"93"} dashName={"  "}/>
    )
  }
// ----------------------------------------------------------------
  renderDashBoad=(list)=>{
    return(
      <Row>
        {/* {this.state.dashBoardSet && this.state.dashBoardSet.map((item, index) => { */}
        {list && list.map((item, index) => {
          console.log(item)

            return(
              !item.hidden && 
              // Array.isArray(item) ?
              //   this.renderDashBoad(item)
              // :
              <Col md={item.width}>
              <Card >
                <CardBody style={{padding:15}}>  
                  <div className="card__title">
                    <h5 className="bold-text">{item.title} </h5>                      
                  </div>
                  {item.render()}
                </CardBody>  
              </Card>
              </Col>
            );
        }) 
        }
      </Row>
    );
  }
// ----------------------------------------------------------------
// renderDeviceWidget = () => {
//   return(
//     <AssetMapWidget assetId={6} dashName={"dashTest"}/>
//   );
// }
onEditSubmit = (newLayout) => {
  let layoutClone = JSON.parse(JSON.stringify(this.props.currentLayout)); 
  // layoutClone.forEach((item,index)=>{
  //   let layoutItem = layout.find((element, lindex)=>(item.layoutid.toString() === element.i));
  //   if (layoutItem){
  //     let {w,h,x,y} = layoutItem;
  //     item.position = JSON.stringify({w,h,x,y})
  //   }
  // });
  // let selectedLayout = layoutClone.find((item, index)=>(item.layoutid === newLayout.layoutid));
  // if (selectedLayout){
  //   selectedLayout = newLayout;
  // }

  let selectedIndex = layoutClone.findIndex((item, index)=>(item.layoutid === newLayout.layoutid));
  if (selectedIndex > -1){
    layoutClone[selectedIndex] = {...layoutClone[selectedIndex], ...newLayout};
  }
  
  
  // //console.log(selectedLayout);
  this.props.updateDashBoardLayout(layoutClone);
}
renderWidgets = (layout) => {
  let type = layout.type;
  let parameter = JSON.parse(layout.parameter);
  // let parameter = (layout.parameter);
  // //console.log( layout);
  // //console.log(type);
  switch(type){
    case 'Weather':
      // let parameter = JSON.parse(layout.parameter);
      // //console.log('map assetid ');
      // //console.log(parameter.assetid);
      return(
          // layout.display == 1 &&
          <Fragment>
            <AssetWeatherWidget assetclassId={parameter.assetid} dashName={layout.name}
                            layout = {layout}
                            onEditSubmit = {this.onEditSubmit}
            />
          </Fragment>
      );
    case 'AssetMap':
      // let parameter = JSON.parse(layout.parameter);
      // //console.log('map assetid ');
      // //console.log(parameter.assetid);
      return(
        // layout.display == 1 &&
        <Fragment>
           <AssetMapWidget assetclassId={parameter.assetid} dashName={layout.name} 
              layout = {layout}
              onEditSubmit = {this.onEditSubmit}
            />
        </Fragment>
      );
    case 'AssetTable':
    //console.log('AssetTable');
    //console.log(parameter)
      // let parameter = JSON.parse(layout.parameter);
      return(
        // layout.display === 1 &&
        <Fragment>
          <AssetTableWidget assetclassId={parameter.assetid} dashName={layout.name}
            layout = {layout}
            onEditSubmit = {this.onEditSubmit}
          />
        </Fragment>
      );
    case 'AlertTable':
    // //console.log('alert');
      return(
        // layout.display === 1 &&
        <Fragment>
          <Card >
          <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                          {layout.name}
                          {layout && 0 == layout.locked &&
                          <BasicDashboadEditIcon 
                                size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                layout={layout}
                                onEditSubmit = {this.onEditSubmit}
                            />    
                          } 
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                <CardBody style={{padding:15}}>  
                  {/* <div className="card__title">
                    <h5 className="bold-text">{layout.name} </h5>                      
                  </div> */}
                  {this.renderAlertTable(layout)}
                </CardBody>  
              </Card>
        </Fragment>
      );
      // return(
      //   <AlertTableWidget
      //     dashName={layout.name}
      //     layout = {layout}
      //     onEditSubmit = {this.onEditSubmit}
      //   />
      // );
    case 'ActionTable':
      return(
        // layout.display === 1 &&
        <Fragment>
          <Card >
          <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                          {layout.name} 
                          {layout && 0 == layout.locked &&
                          <BasicDashboadEditIcon 
                                size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                layout={layout}
                                onEditSubmit = {this.onEditSubmit}
                            />    
                          }
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                <CardBody style={{padding:15}}>  
                  {/* <div className="card__title">
                    <h5 className="bold-text">{layout.name} </h5>                      
                  </div> */}
                  {this.renderActionTable(layout)}
                </CardBody>  
              </Card>
        </Fragment>
      );
    case 'DeviceTable':
    return(
      // layout.display === 1 &&
      // <Fragment>
      //   <Card >
      //           <CardBody style={{padding:15}}>  
      //             <div className="card__title">
      //               <h5 className="bold-text">{layout.name} </h5>                      
      //             </div>
      //             {this.renderAssetTable()}
      //           </CardBody>  
      //         </Card>
      // </Fragment>
      <Fragment>
        <DeviceTableWidget devicetypeId={parameter.devicetype} dashName={layout.name}
          layout = {layout}
          onEditSubmit = {this.onEditSubmit}
        />
      </Fragment>
    );
    case 'DeviceChart':
      return(
        // layout.display === 1 &&
        // <Fragment>
        //   <Card >
        //         <CardBody style={{padding:15}}>  
        //           <div className="card__title">
        //             <h5 className="bold-text">{layout.name} </h5>                      
        //           </div>
        //           {this.renderDeviceTypeChart()}
        //         </CardBody>  
        //       </Card>
        // </Fragment> 
        <Fragment>
          <DeviceCountChartWidget dashName={layout.name} layout={layout} onEditSubmit = {this.onEditSubmit}/>
        </Fragment>
      );
    case 'MsgCountChart':
      return(
        <Fragment>
          <DeviceMsgChartWidget 
            deviceid = {parameter.deviceid} 
            dashName={layout.name} 
            layout={layout} 
            onEditSubmit = {this.onEditSubmit}/>
        </Fragment>
      )  
    case 'DviceUsage':
      return(
        <Fragment>
          <DeviceUsageDonutWidget dashName={layout.name} layout={layout} onEditSubmit = {this.onEditSubmit}/>
        </Fragment>
      );
    case 'SubscribeValue':
      return(
        <Fragment>
          <SubscribeValueWidget dashName={layout.name} layout={layout} />
        </Fragment>
      );
    case 'AttributeValue': 
      return(
        <Fragment>
          <AttrValueWidget 
            dashName={layout.name} 
            layout={layout}
            parameter={parameter} 
            onEditSubmit = {this.onEditSubmit}
            pubSubData = {this.state.pubSubData}
          />
        </Fragment>
      );
    case 'AssetElement': 
        return(
          <Fragment>
            <AssetWidget
               dashName={layout.name} 
               layout={layout}
               parameter={parameter} 
               onEditSubmit = {this.onEditSubmit}
               pubSubData = {this.state.pubSubData}
            />
          </Fragment>
        );
    case 'AttributeChart':
      return(
        <Fragment>
          <AttrChartWidget
            dashName={layout.name} 
            layout={layout}
            parameter={parameter} 
            onEditSubmit = {this.onEditSubmit}
            pubSubData = {this.state.pubSubData}
          >
          </AttrChartWidget>
        </Fragment>
      );
    default: 
      return null;
  }
}
renderDashboardLayout = (layoutList) => {
  return (
    <Row>
      {layoutList && layoutList.map((layout, index) => {
        return(
          layout.display === 1 &&
          <Col md={layout.width}>
            {this.renderWidgets(layout)}
          </Col>
        );
      })}
    </Row>
  );
}
onResize = (layout, {element, size, handle}) => {
  // console.log("onResize");
  this.onDragStop(layout);
}
onDragStop = (layout) => {
  // console.log("onDragStop");
  this.onLayoutChange(layout);
}
onLayoutChange = (layout, layouts) => {
  //console.log('layout on change', layout);
  // //console.log(layout);
  //console.log(layouts);
  let layoutClone = JSON.parse(JSON.stringify(this.props.currentLayout)); 
  let isUpdate = false;
  layoutClone.forEach((item,index)=>{
    // let layoutItem = layout.find((element, lindex)=>(item.layoutid.toString() === element.i));
    let layoutItem = layout.find((element, lindex)=>{
      let layoutid = element.i.split('-')[0];
      return(item.layoutid.toString() === layoutid)
    });
    if (layoutItem){
      let {w,h,x,y} = layoutItem;
      item.position = JSON.stringify({w,h,x,y});
      isUpdate = true;
    }
  });
  // alert('change');
  if (isUpdate == true){
    // this.props.updateDashBoardLayout(layoutClone);
  }else{
    // toast.info("Template can not be updated");
  }
  this.props.updateDashBoardLayout(layoutClone);
  
}
renderDashboardJustableLayout = (layoutList) => {
  //console.log('render -- layoutList');
  //console.log(layoutList);
  if (! layoutList){
    return null;
  }
  let layoutSet = layoutList ? layoutList.filter((item,index)=>(item.display === 1)) : [];
  
  // let layoutSet = layoutList;
  return(
    <ResponsiveGridLayout isDraggable={true} draggableHandle={".dragBar"} verticalCompact={true}  className="layout" rowHeight={30}
       // onLayoutChange={this.onLayoutChange}
      onDragStop={this.onDragStop}
      onResize={this.onResize}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }} >
      {layoutSet.map((layout, index) => {
        
        // JSON.parse('[2,3,4]');
        //console.log('index ' + index.toString());
        //console.log(layout.position);
        let locationArr = JSON.parse(layout.position);
        //console.log('layout position');
        //console.log(layout.position);
        //console.log(layout.locked);
        let isLocked = (layout.locked ===1);
        //console.log(isLocked);
        locationArr.static = isLocked;
        let key = layout.layoutid.toString()+'-'+layout.locked.toString();
        let minHeight = Math.min(7, locationArr.h);
        return(
          // layout.display === 1 &&
          // <div key={layout.name} data-grid={{x: locationArr[0], y:locationArr[1], w: locationArr[2], h:locationArr[3], minH: locationArr[3], maxH: locationArr[3]}}> minH: locationArr.h, maxH: locationArr.h
          <div key={key} data-grid={{...locationArr, minH: minHeight}}>
            {this.renderWidgets(layout)}
          </div>
        );
      })}
    </ResponsiveGridLayout>
  )
}
// ----------------------------------------------------------------
  dropdownToggle = () => {
    this.setState(prevState => ({
      dropdownOpen : !prevState.dropdownOpen,
    }));
  }
  onTitleClick = (layout) => {
    //console.log('layout title click', layout);
    //console.log('layout title click Id', layout.templateId);
    // const layoutname = layout.templatename === 'System' ? 'My Dashboard': layout.templatename;
    const layoutname = (layout.templateName && layout.templateName.length > 0) ? (layout.templateName === 'System' ? 'My Dashboard': layout.templateName) : 'Dashboard'
    this.setState({
      currentLayoutName: layoutname,
      currentTemplateId: layout.templateId,
      currentLayout: layout,
    }, ()=>{
      this.props.loadDashBoardLayout(layout.templateId)
    // this.dropdownToggle();
    })
  }

  onSetDefaultClick = (layout) => {
    if (layout && layout.templateId) {
      this.props.setDefaultLayout(layout.templateId);
    }
    
  }

  renderTitle = () => {
    // let title = 'Dashboard';
    // if (this.props.currentLayoutList){
    //   const selectedLayout = this.props.currentLayoutList.find(item => (item.selected == true));
    //   if (selectedLayout){
    //     title = selectedLayout.layoutname ? selectedLayout.layoutname : 'My Dashboard';
    //   }
      
    // }
    //console.log('currentLayoutList', this.props.currentLayoutList);
    // //console.log('currentlayoutName',this.state.currentLayoutName);
    return(
      <Row>
        <Col md={12} style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
          {/* <h3 className="page-title">Dashboard</h3> */}
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.dropdownToggle}>
            <DropdownToggle
              caret
              // tag="span"
              // data-toggle="dropdown"
              // aria-expanded={this.state.dropdownOpen}
            >
              {/* <h3 className="page-title">My Dashboard</h3> */}
              {/* <h3 className="page-title">{this.state.currentLayoutName}</h3> */}
              {/* <h5 className="page-title"> */}
              <h7>
                {/* My Dashboard btn*/}
                {this.state.currentLayoutName} 
              </h7>
              {/* </h5> */}
            </DropdownToggle>
            <DropdownMenu>
              {this.props.currentLayoutList && this.props.currentLayoutList.map(layout => (
                // <div onClick={() => {this.onTitleClick(layout)}}><h3 className="page-title">{layout.templateName === 'System' ? 'My Dashboard': layout.templateName}</h3></div>
                <DropdownItem onClick={() => {this.onTitleClick(layout)}}>
                  {/* <h3 className="page-title"> */}
                  {/* <b> */}
                    {(layout.templateName && layout.templateName.length > 0) ? (layout.templateName === 'System' ? 'My Dashboard': layout.templateName) : 'Dashboard'}
                  {/* </b> */}
                  {/* </h3> */}
                </DropdownItem>
              ))}
              {/* <div onClick={this.onTitleClick}><h3 className="page-title">My Dashboard</h3></div>
              <div onClick={this.onTitleClick}><h3 className="page-title">Template 1</h3></div> */}
            </DropdownMenu>
          </Dropdown>
        {/* </Col>
        <Col md={2}> */}
          {this.state.currentLayout && this.state.currentLayout.selected == true && 
            // <span> (default) </span>
            // <Button color="link" onClick={()=>{}} disabled={true} ><span style={{color: 'black'}}>(default)</span></Button>
            <Button color="link" onClick={()=>{}} disabled={true} ><span style={{color: 'black'}}></span></Button> // Hide default buttom required by Savitha
          }
          {this.state.currentLayout && this.state.currentLayout.selected != true && 
            <Button color="link" onClick={()=>{this.onSetDefaultClick(this.state.currentLayout)}}>Set As Default</Button>
          }
        </Col>
    </Row>
    );
  }
// ----------------------------------------------------------------
  onDashButtonClick = () => {
    this.modalToggle();
  }
//-----------------------------------------------------------------
  render(){
    const circleButton = {
      width: '70px',
      height: '70px',
      padding: '10px 16px',
      borderRadius: '35px',
      fontSize: '24px',
      lineHeight: 1.33,
      position: 'fixed',
      // position: '-webkit-sticky',
      // position: 'sticky',
      top: '90px', 
      right: '30px', 
      zIndex: '99', 
  }
    return(
      <Fragment>
        {this.userObj && this.userObj.companyCode !== 'ONRT' && this.renderTitle()}
      <div>
      {/* {this.renderDashBoad(this.state.dashBoardSet)} */}
      {/* <Col md={8}>
        <AssetMapWidget assetId={6} dashName={"dashTest"}/> width={500}
      </Col> */}
       {/* {this.renderDashboardLayout(this.state.currentLayout)} */}
       {this.renderDashboardJustableLayout(this.props.currentLayout)}
      <button type="button" class="btn-dashboard-edit" style={circleButton} onClick={this.onDashButtonClick}>
        {/* <i class="fa fa-check"></i> */}
        <PlaylistEditIcon color='#ffffff' size={50}/>
      </button>
      <DasBoardEditModal 
        modalSetVisible={this.state.dashBoardModal}
        nestedVisible = {this.state.widgetEditModal}
        closeAll = {this.state.closeAll}
        // modalList = {this.state.dashBoardSet}
        // modalList = {this.state.currentLayout}
        modalList = {this.props.currentLayout}
        toggle={this.modalToggle}
        editModalToggle={this.editModalToggle}
        toggleAll = {this.toggleAll}
        onDashBoardUpdateClick = {this.onDashBoardUpdateClick}
      />
      
      </div>
      </Fragment>
    );
  }
}

// export default DashboardCard;

function mapStateToProps(state) {
  //console.log(state);
  //console.log('state');
  const deviceTypes = state.mapboard.types;
  // const mapData = state.mapboard.mapData;
  const mapData = state.mapboard.assetData;
  const currentLayout = state.dashboard.currentLayout;
  const currentLayoutList = state.dashboard.currentLayoutList;
  const actionLogs = state.job.actionLogs;
  const {currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, currentDeviceDetailsList, error, message, type} = state.asset;
  const props = {deviceTypes, mapData, currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, currentDeviceDetailsList, error, message, type, currentLayout, actionLogs, currentLayoutList};
  return props;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({loadDevice, loadDeviceInfo, loadDivceMapData, getAssetsData, loadDashBoardLayout, updateDashBoardLayout, getActionLog, loadDashBoardLayoutList, setDefaultLayout}, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardCard));
