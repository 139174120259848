/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Row, Button, Input } from 'reactstrap';
import { UserContext } from '../../../constants/myContexts';
import { setBrandingDetail } from '../../../factories/utils';
import { ATTRIBUTE_ORDER } from '../../../constants/dataConstants';
import { hasRole } from '../../../factories/auth';

class CompanyBrandingBoard extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            appNavLinkColor: "",
            appHyperLinkColor: "",
            appButtonColor: "",
            appButtonColorHover: "",
            appButtonText: "",
            appTableHeaderFontColor: "",
            appTableHeader: "",
            appTableFontColor: "",
            appTableRow1: "",
            appBackground: "",
            appCardBackground: "",
            appFontColor: "",
            appTextHeader: "",
            openSidebarColor: "",
            openSidebarFontColor: "",
            sidebarSelected: "",
            sidebarSelectedFontColor: "",
            sidebarHover: "",
            sidebarColor: "",
            sidebarFontColor: "",
            headerButtonHoverColor: "",
            headerFontColor: "",
            headerDropdownColor: "",
            headerBackground: "",
            headerDropdownFontColor: "",
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.branding != null) {
            this.setState({
                appNavLinkColor: nextProps.branding.appNavLinkColor,
                appHyperLinkColor: nextProps.branding.appHyperLinkColor,
                appButtonColor: nextProps.branding.appButtonColor,
                appButtonColorHover: nextProps.branding.appButtonColorHover,
                appButtonText: nextProps.branding.appButtonText,
                appTableHeaderFontColor: nextProps.branding.appTableHeaderFontColor,
                appTableHeader: nextProps.branding.appTableHeader,
                appTableFontColor: nextProps.branding.appTableFontColor,
                appTableRow1: nextProps.branding.appTableRow1,
                appBackground: nextProps.branding.appBackground,
                appCardBackground: nextProps.branding.appCardBackground,
                appFontColor: nextProps.branding.appFontColor,
                appTextHeader: nextProps.branding.appTextHeader,
                openSidebarColor: nextProps.branding.openSidebarColor,
                openSidebarFontColor: nextProps.branding.openSidebarFontColor,
                sidebarSelected: nextProps.branding.sidebarSelected,
                sidebarSelectedFontColor: nextProps.branding.sidebarSelectedFontColor,
                sidebarHover: nextProps.branding.sidebarHover,
                sidebarColor: nextProps.branding.sidebarColor,
                sidebarFontColor: nextProps.branding.sidebarFontColor,
                headerButtonHoverColor: nextProps.branding.headerButtonHoverColor,
                headerFontColor: nextProps.branding.headerFontColor,
                headerDropdownColor: nextProps.branding.headerDropdownColor,
                headerBackground: nextProps.branding.headerBackground,
                headerDropdownFontColor: nextProps.branding.headerDropdownFontColor,
            })
        }
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };


    validateForm = () => {
        for (let value of Object.values(this.state)) {
            if (value == null || value == "") {
                return false;
            }
        }
        return true;
    }

    onSubmit = () => {
        const { setTheme } = this.context;
        var obj = {
            companyCode: this.props.companyCode,
            app: {
                appNavLinkColor: this.state.appNavLinkColor,
                appHyperLinkColor: this.state.appHyperLinkColor,
                appButtonColor: this.state.appButtonColor,
                appButtonColorHover: this.state.appButtonColorHover,
                appButtonText: this.state.appButtonText,
                appTableHeaderFontColor: this.state.appTableHeaderFontColor,
                appTableHeader: this.state.appTableHeader,
                appTableFontColor: this.state.appTableFontColor,
                appTableRow1: this.state.appTableRow1,
                appBackground: this.state.appBackground,
                appCardBackground: this.state.appCardBackground,
                appFontColor: this.state.appFontColor,
                appTextHeader: this.state.appTextHeader,
            },
            header: {
                headerButtonHoverColor: this.state.headerButtonHoverColor,
                headerFontColor: this.state.headerFontColor,
                headerDropdownColor: this.state.headerDropdownColor,
                headerBackground: this.state.headerBackground,
                headerDropdownFontColor: this.state.headerDropdownFontColor,
            },
            sidebar: {
                openSidebarColor: this.state.openSidebarColor,
                openSidebarFontColor: this.state.openSidebarFontColor,
                sidebarSelected: this.state.sidebarSelected,
                sidebarSelectedFontColor: this.state.sidebarSelectedFontColor,
                sidebarHover: this.state.sidebarHover,
                sidebarColor: this.state.sidebarColor,
                sidebarFontColor: this.state.sidebarFontColor,
            }
        }

        this.props.setBrandingDetails(obj).then((res) => {
            if (res.status == 200) {
                toast.success("success");
            }
            else {
                toast.error("error");
            }
            if (this.props.userObj.companyCode == this.props.companyCode) {
                setTheme(this.props.branding);
                setBrandingDetail(this.props.branding)
            }
        });
    }


    render() {
        return (
            <Card>
                <CardBody className="mainCardBody">
                    <Col md={12}>

                        {(this.validateForm() == false) ?
                            <div>
                                <p><span style={{ fontWeight: "bold" }}>NOTE</span>: Company is missing some or all branding details. Please fill all branding colors and save.</p>
                                <br />
                            </div>
                            :
                            null
                        }
                        <h3>Header</h3>
                        <Row>
                            <Col md={2} style={{ paddingTop: "10px" }}>
                                <span style={{color:"black"}}>Background Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='headerBackground'
                                    id='headerBackground'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.headerBackground}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='headerFontColor'
                                    id='headerFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.headerFontColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Hover Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='headerButtonHoverColor'
                                    id='headerButtonHoverColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.headerButtonHoverColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Drop Down Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='headerDropdownColor'
                                    id='headerDropdownColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.headerDropdownColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Drop Down Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='headerDropdownFontColor'
                                    id='headerDropdownFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.headerDropdownFontColor}
                                />
                            </Col>
                        </Row>
                        <br />
                        <h3>Sidebar</h3>
                        <Row>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Background Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='sidebarColor'
                                    id='sidebarColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.sidebarColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='sidebarFontColor'
                                    id='sidebarFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.sidebarFontColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Hover Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='sidebarHover'
                                    id='sidebarHover'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.sidebarHover}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Drop Down Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='openSidebarColor'
                                    id='openSidebarColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.openSidebarColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Drop Down Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='openSidebarFontColor'
                                    id='openSidebarFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.openSidebarFontColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Selected Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='sidebarSelected'
                                    id='sidebarSelected'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.sidebarSelected}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Selected Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='sidebarSelectedFontColor'
                                    id='sidebarSelectedFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.sidebarSelectedFontColor}
                                />
                            </Col>
                        </Row>
                        <br />
                        <h3>Application</h3>
                        <Row>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Background Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appBackground'
                                    id='appBackground'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appBackground}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Card Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appCardBackground'
                                    id='appCardBackground'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appCardBackground}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appFontColor'
                                    id='appFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appFontColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Header Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appTextHeader'
                                    id='appTextHeader'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appTextHeader}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Table Header Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appTableHeader'
                                    id='appTableHeader'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appTableHeader}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Table Header Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appTableHeaderFontColor'
                                    id='appTableHeaderFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appTableHeaderFontColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Table Row Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appTableRow1'
                                    id='appTableRow1'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appTableRow1}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Table Row Font Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appTableFontColor'
                                    id='appTableFontColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appTableFontColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Button Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appButtonColor'
                                    id='appButtonColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appButtonColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Button Hover Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appButtonColorHover'
                                    id='appButtonColorHover'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appButtonColorHover}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Button Text Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appButtonText'
                                    id='appButtonText'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appButtonText}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>Tab Underline Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appNavLinkColor'
                                    id='appNavLinkColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appNavLinkColor}
                                />
                            </Col>
                            <Col md={2} style={{ paddingTop: "5px" }}>
                                <span style={{ color: "black" }}>HyperLink Color<span style={{ color: "red" }}>*</span>:</span>
                                <Input
                                    type="color"
                                    name='appHyperLinkColor'
                                    id='appHyperLinkColor'
                                    onChange={this.onInputChange}
                                    style={{ width: "50px", height: "27px" }}
                                    value={this.state.appHyperLinkColor}
                                />
                            </Col>
                        </Row>
                        <br />
                        <div style={{ textAlign: 'right' }}>
                            {
                                (hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"])) ?
                                    <Button color='custom' type='button' onClick={this.onSubmit} disabled={!this.validateForm()}>
                                        Save Changes
                                    </Button>
                                    :
                                    null
                            }
                        </div>
                    </Col>

                </CardBody>
            </Card>

        )
    }
}
export default CompanyBrandingBoard;