/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, FormText, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Trans } from "react-i18next";
import { addUser, getCompanyList, getRoles } from '../../../redux/actions/userAction';
import UserAccessForm from './userAccessForm';
import { getCurrentUser, hasRole } from '../../../factories/auth';

// managing autofill
const AutofillManager = (props) => {

    const handleSubmit = (event) => {
        event.preventDefault()
    }

    return (
        <form onSubmit={handleSubmit}>
            <Input type='hidden' value='' />
            {props.children}
        </form>
    )

}
const backdropStyle = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    overflow: 'auto',
};

const modalStyle = function () {
    const top = 5;
    const left = 5;

    return {
        position: 'fixed',
        width: 800,
        height: 650,
        zIndex: 1040,
        top: top + '%',
        left: left + '%',
        border: '0px solid #ffffff',
        backgroundColor: 'white',
        // boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflow: 'auto',
        resize: 'both'
    };
};

class UserAddModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showModal: false,
            data: [],
            email: '',
            fullName: '',
            userName: '',
            newUser: true,
            activeTab: '1',
            // groupdata: [],
            passConfirmed: true,
            // passconfirmValid: false,
        };
    }

    componentDidMount() {
        this.props.getCompanyList()
        this.props.getRoles();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        //console.log("test")
        this.setState({
            email: '',
            phoneNumber: '',
            loginName: '',
            fullName: '',
            userName: '',
            password: '',
            passwordConfirm: '',
        }, () => {
            if (nextProps.show === true) {
                this.setState({
                    showModal: true,
                });
            }
        })
        // alert(nextProps.modules.groupType);

        if (nextProps.companyList && nextProps.companyList.length > 0) {
            this.setState({
                company: nextProps.companyList[0].code,

            });
        }
        if (nextProps.roleList && nextProps.roleList.length > 0) {
            this.setState({
                role: nextProps.roleList[0].name,

            });
        }
    }

    renderBackdrop = (props) => {
        return <div {...props} style={backdropStyle} />;
    };

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit = () => {
        // if (this.props.userName !== '') {
        //     this.props.saveDetail({
        //         email: this.state.email,
        //         fullName: this.state.fullName,
        //         data: this.state.data,
        //         userName: this.state.loginName
        //     });
        // }
        // else {
        //     this.props.addDetail({
        //         email: this.state.email,
        //         fullName: this.state.fullName,
        //         data: this.state.data,
        //         userName: this.state.loginName
        //     });
        // }
        if (!sessionStorage.getItem('companyCode') || !sessionStorage.getItem('currentUser')) {
            // alert ('no');
            return;
        }
        let phoneNo = this.state.phoneNumber;
        if (phoneNo) {
            phoneNo = phoneNo.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
        if (this.validateForm()) {
            // alert ('v yes');
            let userObj = {
                // userId: this.state.loginName,
                userType: "pp",
                userName: this.state.loginName,
                email: this.state.email,
                status: "A",
                companyCode: this.state.company ? this.state.company : sessionStorage.getItem('companyCode'),
                department: '', //sessionStorage.getItem('department') ? sessionStorage.getItem('department'): null ,
                businessUnit: '', //sessionStorage.getItem('businessUnit') ? sessionStorage.getItem('businessUnit'): null,
                // phoneNum: this.state.phoneNumber,
                phoneNum: phoneNo,
                password: this.state.passwordConfirm,
                creator: sessionStorage.getItem('currentUser'),
                createTime: new Date().toISOString().split('.')[0].replace('T', ' '),
                role: [this.state.role], //['USER'],
            }
            if (this.props.userObj && hasRole(this.props.userObj, ["CLIENTADMIN"])) {
                userObj.resellerCode = this.props.userObj.resellerCode;
            }
            this.props.addUser(userObj, true);
            this.onClose();

        } else {
            // alert('v no');
            this.setState({
                isSubmit: true
            })
        }
    };

    onClose = () => {
        this.setState({
            showModal: false
        });
    };

    validatePassConfirm = () => {
        return (this.state.passwordConfirm && this.state.password === this.state.passwordConfirm);
    }

    validateUserId = () => {
        return (this.state.loginName && this.state.loginName !== '');
    }

    validatePhoneNumber = () => {
        var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        // return (this.state.phoneNumber && regex.test(this.state.phoneNumber));
        return (!this.state.phoneNumber || this.state.phoneNumber && regex.test(this.state.phoneNumber));
    }

    validateEmail = () => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (this.state.email && regex.test(this.state.email));
    }

    validateForm = () => {
        return this.validateUserId()
            && this.validatePhoneNumber()
            && this.validateEmail()
            && this.validatePassConfirm();
    }

    render() {
        //let labalStyle = { textAlign: 'right' };
        const { loginName, fullName, phoneNumber, email, company, role, password, passwordConfirm, isSubmit } = this.state

        return (

            <Modal
                //className={this.props.theme!=null?this.props.theme.className:null}
                // style={modalStyle()}
                isOpen={this.state.showModal}
                toggle={this.props.toggle}
                renderBackdrop={this.renderBackdrop}
                size='lg'
            >
                {/* <div> */}
                {/* <ModalHeader toggle={this.props.toggle}>New User</ModalHeader > */}
                <ModalHeader toggle={this.onClose}>New User</ModalHeader >
                <ModalBody>

                    <Col md={12}>
                        <Row>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="loginName"><span> Login Name <span style={{ color: "red" }}>*</span></span></label>
                                {/* <span>User Name</span> */}
                                <AutofillManager>
                                    <Input
                                        name='loginName'
                                        placeholder='Login Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={loginName}
                                        // disabled={this.props.userName !== ''}
                                        invalid={isSubmit && !this.validateUserId()}
                                    />
                                </AutofillManager>
                            </Col>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="fullName"><span>Contact Full Name</span></label>
                                {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                                <AutofillManager>
                                    <Input
                                        name='fullName'
                                        placeholder='Full Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={fullName}
                                    />
                                </AutofillManager>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="phoneNumber"><span>Phone Number</span></label>
                                {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                                <AutofillManager>
                                    <Input
                                        name='phoneNumber'
                                        placeholder='Phone Number'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={phoneNumber}
                                    // valid={this.validatePhoneNumber()}
                                    />
                                </AutofillManager>
                            </Col>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="email"><span> Email <span style={{ color: "red" }}>*</span></span></label>
                                <AutofillManager>
                                    <Input
                                        name='email'
                                        placeholder='Email'
                                        onChange={this.onInputChange}
                                        pattern={'^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={email}
                                        invalid={isSubmit && !this.validateEmail()}
                                    />
                                </AutofillManager>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="company"><span>Company</span></label>
                                <Input
                                    type='select'
                                    name='company'
                                    placeholder='Company'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={company}
                                    // valid = {this.validateEmail()}
                                    disabled={false}
                                >
                                    {this.props.companyList.map((company, index) => (
                                        <option key={index} value={company.companyCode}>{company.companyName}</option>
                                    ))}
                                </Input>
                            </Col>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="role"><span>Role</span></label>
                                <Input
                                    type='select'
                                    name='role'
                                    placeholder='Role'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={role}
                                    // valid = {this.validateEmail()}
                                    disabled={false}
                                >
                                    {this.props.roleList.map((role, index) => (
                                        <option key={role.id} value={role.name}>{role.description}</option>
                                    ))}
                                    {/* <option value={'SUPERADMIN'}>System Admin</option>
                                    <option value={'ADMIN'}>Company Admin</option>
                                    <option value={'USER'}>Company User</option> */}
                                </Input>
                            </Col>
                        </Row>
                    </Col>
                    <br />
                    {/* <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Unit No</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='unitNum'
                                    placeholder='Unit Number'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.unitNum}
                                />
                            </Col>
                            <Col md={2} style={labalStyle} >
                                <span>Street No</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='streetNum'
                                    placeholder='Street Number'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.streetNum}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Street Name</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='streetName'
                                    placeholder='Street Number'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.streetName}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>City</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='city'
                                    placeholder='City'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.city}
                                />
                            </Col>
                            <Col md={2} style={labalStyle} >
                                <span>Postal Code</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='postalCode'
                                    placeholder='Postal Code'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.postalCode}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Contry</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    type='select'
                                    name='contry'
                                    placeholder='Contry'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.contry}
                                >
                                    <option value={'CA'}>Canada</option>
                                    <option value={'USA'}>United States</option>}
                                </Input>
                            </Col>
                            <Col md={2} style={labalStyle} >
                                <span>Province</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='postalCode'
                                    placeholder='Postal Code'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.postalCode}
                                />
                            </Col>
                        </Row> */}
                    <hr style={{ marginTop: '0' }} />
                    <Col md={12}>
                        <Row>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="password"><span> Password <span style={{ color: "red" }}>*</span></span></label>
                                {/* <span>User Name</span> */}
                                <AutofillManager>
                                    <Input
                                        name='password'
                                        type='password'
                                        invalid={isSubmit && !(passwordConfirm && password === passwordConfirm)}
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={password}
                                    />
                                </AutofillManager>
                            </Col>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="passwordConfirm"><span>Password Confirmation</span></label>
                                {/* <span>User Name</span> */}
                                <Input
                                    // valid = {this.state.passConfirmed}
                                    // valid = {this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                                    // invalid = {this.state.passConfirmed}
                                    invalid={passwordConfirm && password !== passwordConfirm}
                                    name='passwordConfirm'
                                    type='password'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={passwordConfirm}
                                    disabled={!password || password == ''}
                                // disabled = {true}
                                />
                            </Col>
                        </Row>
                    </Col>

                    {/* <Row>
                            <UserAccessForm/>
                        </Row> */}
                    <br />
                    {/* <hr/> */}
                </ModalBody>
                <ModalFooter style={{ padding: '0', borderTop: '0' }}>
                    <Col md={12}>
                        <Row>
                            <Col md={12} className={'text-right'}>

                                {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                                    <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} /*disabled={!this.validateForm()}*/ >
                                        Submit
                                    </Button>}
                                {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                                    <Button outline color='custom' type="button" onClick={this.onSubmit} /*disabled={!this.validateForm()}*/ >
                                        Submit
                                    </Button>}
                                
                                <Button outline color='secondary' type="reset" onClick={this.onClose}>
                                    Close
                                    
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </ModalFooter>
                {/* </div> */}
            </Modal>
        )
    }
}

// export default UserAddModal;

const mapStateToProps = (state) => {
    const props = {
        ...state.user,
        theme: state.theme
    }
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ addUser, getCompanyList, getRoles }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAddModal);