/* eslint-disable */
import axios from 'axios';
import { toast } from "react-toastify";
import {
    ACTION_MGMT_MSG_TOGGLE_OFF,
    ACTION_MGMT_LOAD_ASSET_SUCCEED,
    ACTION_MGMT_LOAD_ASSET_FAILED,
    ACTION_MGMT_LOAD_ASSET_DEVICE_SUCCEED,
    ACTION_MGMT_LOAD_ASSET_DEVICE_FAILED,
    ACTION_MGMT_LOAD_ACTION_SUCCEED,
    ACTION_MGMT_LOAD_ACTION_FAILED,
    ACTION_GET_ACTIONS_SUCCEED,
    ACTION_GET_ACTIONS_FAILED,
    ACTION_SET_TELECOM_SUCCEED,
    ACTION_SET_TELECOM_FAILED,
    ACTION_GET_ACTION_RULE_SUCCEED,
    ACTION_GET_ACTION_RULE_FAILED,
    ACTION_CLEAR_ACTION_RULE,
    ACTION_GET_ACTION_BY_ID_SUCCEED,
    ACTION_GET_ACTIONS_BY_ID_FAILED,
    ACTION_GET_TELECOM_ACTION_BY_ID_SUCCEED,
    ACTION_GET_TELECOM_ACTION_BY_ID_FAILED,
    ACTION_CLEAR_ACTIONS_BY_ID,
    ACTION_UPSERT_ACTIONS_SUCCEED,
    ACTION_UPSERT_ACTIONS_FAILED,
    ACTION_UPSERT_ACTION_RULE_SUCCEED,
    ACTION_UPSERT_ACTION_RULE_FAILED,
    ACTION_GET_ACTION_ALERT_SUCCEED,
    ACTION_GET_ACTION_ALERT_FAILED,
    ACTION_CLEAR_ACTION_ALERT,
    ACTION_UPSERT_ACTION_ALERT_SUCCEED,
    ACTION_UPSERT_ACTION_ALERT_FAILED,
    ACTION_SET_ACTION_ID_SUCCEED,
    ACTION_DELETE_ACTIONS_SUCCEED,
    ACTION_DELETE_ACTIONS_FAILED,
    ACTION_GET_ACTION_LOG_SUCCEED,
    ACTION_GET_ACTION_LOG_FAILED,
    ACTION_GET_TEMPLATE_SUCCEED,
    ACTION_GET_TEMPLATE_FAILED,
    STEP_FORWARD,
    STEP_BACKWARD,
    STET_RESET,
    USER_GET_FAILED,
    ACTION_GET_CONTACT_LIST_SUCCEED,
    ACTION_GET_CONTACT_LIST_FAILED,
    ACTION_POST_CONTACT_LIST_FAILED,
    ACTION_POST_CONTACT_LIST_SUCCEED,
    ACTION_GET_NOTIFICTION_TEMPLATE_LIST_SUCCEED,
    ACTION_GET_NOTIFICTION_TEMPLATE_LIST_FAILED,
    ACTION_GET_DASHBOARDSETTING_LIST_SUCCEED,
    ACTION_GET_DASHBOARDSETTING_LIST_FAILED,
} from '../../constants/actionTypes';

import {
    WEB_SERVICE_ROOT,
    WEB_SERVICE_ROOT_DashBoardSetting,
    WEB_SERVICE_ATTRIBUTES,
    WEB_SERVICE_DEVICETYPE,
    WEB_SERVICE_ASSET,
    WEB_SERVICE_ACTION,
    WEB_SERVICE_ACTION_TELECOM,
    WEB_SERVICE_DEVICE,
    WEB_SERVICE_GEOFENCE,
    WEB_SERVICE_RULE,
    WEB_SERVICE_ACTION_RULE,
    WEB_SERVICE_ACTION_ALERT,
    WEB_SERVICE_ACTION_LOG,
    WEB_SERVICE_ACTION_TEMPLATE,
    WEB_SERVICE_ACTION_CONTACT,
    WEB_SERVICE_ACTION_POST_CONTACT,
    WEB_SERVIDE_ACTION_NOTIFICATION_TEMPLATE,
    WEB_SERVIDE_ACTION_DASHBOARDSETTING_TEMPLATE,
    WEB_SERVICE_ACTION_NOTIFICATION_TEMPLATE_APPNAME
} from '../../constants/appConstants'

import { toggleAlertOff } from './alertAction';
// import {axiosGet} from '../../factories/utils';
import { axiosGet, axiosPost, axiosDelete, getCurrentUser, base64Encode, getJwttoken } from '../../factories/utils';
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

// ----------------------------------------------
export function closeAlertOff() {
    return (toggleAlertOff(ACTION_MGMT_MSG_TOGGLE_OFF));
}

export function stepForward() {
    return (
        (dispatch) => {
            dispatch({
                type: STEP_FORWARD,
            });
        }
    );
}

export function stepBackward() {
    return (
        (dispatch) => {
            dispatch({
                type: STEP_BACKWARD,
            });
        }
    );
}

export function stepReset() {
    return (
        (dispatch) => {
            dispatch({
                type: STET_RESET,
            });
        }
    );
}
// ----------------------------------------------
export function setActionId(actionid) {
    return (
        (dispatch) => {
            dispatch({
                type: ACTION_SET_ACTION_ID_SUCCEED,
                payload: actionid,
            });
        }
    );
}
// ----------------------------------------------

function loadAssetOptionsSuccess(response) {


    let action;
    if (response.status == 200) {

        action = {
            type: ACTION_MGMT_LOAD_ASSET_SUCCEED,
            payload: response.data
        };
    } else {

        action = {
            type: ACTION_MGMT_LOAD_ASSET_FAILED,
            error: response.statusText
        };
    }

    return action;
}

function loadAssetOptionsFailed(error) {
    const action = {
        type: ACTION_MGMT_LOAD_ASSET_FAILED,
        error: error.message
    };

    return action;
}

export function loadAssetOptions() {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }
    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };
    // const urlStr = 'http://192.168.2.7:8080/sigfox/Devicetypes';
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ASSET}`;


    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {


            dispatch(
                loadAssetOptionsSuccess(response)
            )
        }
        ).catch((error) => {

            dispatch(
                loadAssetOptionsFailed(error)
            )
        }
        )
    }
    );
}

// ----------------------------------------------
function loadAssetDeviceSuccess(response) {


    let action;
    if (response.status == 200) {

        action = {
            type: ACTION_MGMT_LOAD_ASSET_DEVICE_SUCCEED,
            payload: response.data
        };
    } else {

        action = {
            type: ACTION_MGMT_LOAD_ASSET_DEVICE_FAILED,
            error: response.statusText
        };
    }

    return action;
}

function loadAssetDeviceFailed(error) {
    const action = {
        type: ACTION_MGMT_LOAD_ASSET_DEVICE_FAILED,
        error: error.message
    };

    return action;
}

export function loadAssetDevice(criteria) {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    let queryString = '';
    if (criteria && criteria != null) {
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
        queryString = '?' + queryString;
    }
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_DEVICE}${queryString}`;


    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            dispatch(
                loadAssetDeviceSuccess(response)
            )
        }
        ).catch((error) => {
            dispatch(
                loadAssetDeviceFailed(error)
            )
        }
        )
    }
    );
}


// ----------------------------------------------
function loadActionSuccess(response) {
    let action;
    if (response.status == 200) {
        action = {
            type: ACTION_MGMT_LOAD_ACTION_SUCCEED,
            payload: response.data
        };
    } else {
        action = {
            type: ACTION_MGMT_LOAD_ACTION_FAILED,
            error: response.statusText
        };
    }
    return action;
}

function loadActionFailed(error) {
    const action = {
        type: ACTION_MGMT_LOAD_ACTION_FAILED,
        error: error.message
    };

    return action;
}

export function loadAction(criteria) {

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    let queryString = '';
    if (criteria && criteria != null) {
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
        queryString = '?' + queryString;
    }
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION}${queryString}`;


    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            dispatch(
                loadActionSuccess(response)
            )
        }
        ).catch((error) => {
            dispatch(
                loadActionFailed(error)
            )
        }
        )
    }
    );
}
export function setTelecomActions(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_TELECOM}`,
        criteria: obj,
        logText: 'Set Telecom Actions Url ',
        sccessType: ACTION_SET_TELECOM_SUCCEED,
        failedType: ACTION_SET_TELECOM_FAILED,
        errorMessage: 'Failed to set telecom action.'
    };

    return (axiosPost(paramObj));
}
// ---------------------------------------------------------------------------------
export function getActions() {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION}`,
        // criteria: null,
        logText: 'Get Actions Url ',
        sccessType: ACTION_GET_ACTIONS_SUCCEED,
        failedType: ACTION_GET_ACTIONS_FAILED,
        errorMessage: 'Failed to get action.'
    };

    return (axiosGet(paramObj));
}
export function getTelecomActions() {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_TELECOM}`,
        // criteria: null,
        logText: 'Get Actions Url ',
        sccessType: ACTION_GET_ACTIONS_SUCCEED,
        failedType: ACTION_GET_ACTIONS_FAILED,
        errorMessage: 'Failed to get action.'
    };

    return (axiosGet(paramObj));
}
export function getTelecomActionsById(actionid) {
    const creteria = { actionid: actionid };
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_TELECOM}`,
        criteria: creteria,
        logText: 'Get Actions Url ',
        sccessType: ACTION_GET_TELECOM_ACTION_BY_ID_SUCCEED,
        failedType: ACTION_GET_TELECOM_ACTION_BY_ID_FAILED,
        errorMessage: 'Failed to get action.'
    };
    return (axiosGet(paramObj));
}
export function getActionById(actionid) {
    const creteria = { actionid: actionid };
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION}`,
        criteria: creteria,
        logText: 'Get Actions Url ',
        sccessType: ACTION_GET_ACTION_BY_ID_SUCCEED,
        failedType: ACTION_GET_ACTIONS_BY_ID_FAILED,
        errorMessage: 'Failed to get action.'
    };

    return (axiosGet(paramObj));
}

export function clearActionById() {
    return (
        dispatch => {
            dispatch({ type: ACTION_CLEAR_ACTIONS_BY_ID })
        }
    );
}

export function upsertAction(actionObj) {

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    let LocalRoot = 'http://192.168.2.103:8080/'; //WEB_SERVICE_ROOT
    let message = '';
    if (!actionObj) {
        return ((dispatch) => {
            message = 'Input Action is empty ';
            dispatch({
                type: ACTION_UPSERT_ACTIONS_FAILED,
                message: message,
            });
        });
    }

    // const url = `${LocalRoot}${WEB_SERVICE_ACTION}`;
    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        data: actionObj,
    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data && response.data.actionid) {
                    message = 'Upsert the action ' + actionObj.actionnaem + ' successfully.';
                    dispatch({
                        type: ACTION_UPSERT_ACTIONS_SUCCEED,
                        payload: response.data,
                    });
                    toast.success(message);
                } else {
                    message = 'Failed to Update the action ' + actionObj.actionnaem;
                    dispatch({
                        type: ACTION_UPSERT_ACTIONS_FAILED,
                        message: message,
                    });
                    console.error(message);
                    toast.error(message);
                }

            }).catch((error) => {
                message = 'Errors occurred when Updating the action ' + actionObj.actionnaem;
                dispatch({
                    type: ACTION_UPSERT_ACTIONS_FAILED,
                    message: error.message,
                });
                console.error('Upsert action error(s) : ' + error.message);
                toast.error(message);
            });
        }
    );
}

export function upsertActionAjax(actionObj) {

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        //   return dispatch => {
        //     dispatch({
        //       type: USER_GET_FAILED,
        //       error: "Failed to get current user, Please re-login",
        //       message: "Failed to get current user, Please re-login",
        //     });
        //   }
        toast.error("Failed to get current user, Please re-login");
        return;
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    let LocalRoot = 'http://192.168.2.103:8080/'; //WEB_SERVICE_ROOT
    let message = '';
    if (!actionObj) {
        // return ((dispatch)=>{
        //     message = 'Input Action is empty ';
        //     dispatch({
        //         type: ACTION_UPSERT_ACTIONS_FAILED,
        //         message: message, 
        //     });
        // });
        toast.error("Input Action is empty");
        return;
    }

    // const url = `${LocalRoot}${WEB_SERVICE_ACTION}`;
    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        data: actionObj,
    });

    return (
        (dispatch) => (
            request.then((response) => {
                let reValue = null;
                if (response.status == 200 && response.data && response.data.actionid) {
                    message = 'Upsert the action ' + actionObj.actionname + ' successfully.';
                    toast.success(message);

                    reValue = response.data.actionid;
                } else {
                    message = 'Faild to Upsert the action ' + actionObj.actionnaem;
                    console.error(message);
                    toast.error(message);
                    reValue = null;
                }

                return reValue;

            }).catch((error) => {
                message = 'Errors occurred when upserting the action ' + actionObj.actionnaem;
                console.error('Upsert action error(s) : ' + error.message);
                toast.error(message);
                // return null;
            })
        )
    );
}

export function getActionRule(creteria) {
    // const urlStr = 'http://192.168.2.103:8080/';
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_RULE}`,
        // url: `${urlStr}${WEB_SERVICE_ACTION_RULE}`,
        criteria: creteria,
        logText: 'Get Action Rule Url ',
        sccessType: ACTION_GET_ACTION_RULE_SUCCEED,
        failedType: ACTION_GET_ACTION_RULE_FAILED,
        errorMessage: 'Failed to get action rule.'
    };

    return (axiosGet(paramObj));
}

export function clearActionRule() {
    return (dispatch => {
        dispatch({
            type: ACTION_CLEAR_ACTION_RULE,
        });
    });
}

export function upsertActionRule(ruleObj, actionid) {
    // let LocalRoot = 'http://192.168.2.103:8080/'; //WEB_SERVICE_ROOT
    let message = '';
    if (!ruleObj) {
        return ((dispatch) => {
            message = 'Input Action Rule is empty ';
            dispatch({
                type: ACTION_UPSERT_ACTION_RULE_FAILED,
                message: message,
            });
        });
    }

    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_RULE}`;
    const request = axios({
        url: url,
        method: 'post',
        data: ruleObj,
        headers: {
            'Content-Type': 'application/json',
            'actionid': actionid,
        },

    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data && response.data.length >= 0) {
                    message = 'Upsert the action rule  successfully.';
                    dispatch({
                        type: ACTION_UPSERT_ACTION_RULE_SUCCEED,
                        payload: response.data,
                    });
                    toast.success(message);
                } else {
                    message = 'Faild to Upsert the action rule';
                    dispatch({
                        type: ACTION_UPSERT_ACTION_RULE_FAILED,
                        message: message,
                    });
                    console.error(message);
                    toast.error(message);
                }

            }).catch((error) => {
                message = 'Errors occurred when upserting the action rule. ';
                dispatch({
                    type: ACTION_UPSERT_ACTION_RULE_FAILED,
                    message: error.message,
                });
                console.error('Upsert action error(s) : ' + error.message);
                toast.error(message);
            });
        }
    );
}

export function getActionAlert(creteria) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_ALERT}`,
        criteria: creteria,
        logText: 'Get Action Alert Url ',
        sccessType: ACTION_GET_ACTION_ALERT_SUCCEED,
        failedType: ACTION_GET_ACTION_ALERT_FAILED,
        errorMessage: 'Failed to get action alert.'
    };

    return (axiosGet(paramObj));
}

export function clearActionAlert() {
    return (dispatch => {
        dispatch({
            type: ACTION_CLEAR_ACTION_ALERT,
        });
    })
}

export function upsertActionAlert(alertObj) {
    let LocalRoot = 'http://192.168.2.103:8080/'; //WEB_SERVICE_ROOT
    let message = '';
    if (!alertObj) {
        return ((dispatch) => {
            message = 'Input Action Alert is empty ';
            dispatch({
                type: ACTION_UPSERT_ACTION_ALERT_FAILED,
                message: message,
            });
        });
    }

    // const url = `${LocalRoot}${WEB_SERVICE_ACTION_ALERT}`;
    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_ALERT}`;
    const request = axios({
        url: url,
        method: 'post',
        data: alertObj
    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data && response.data.length > 0) {
                    message = 'Upsert the action alert successfully.';
                    dispatch({
                        type: ACTION_UPSERT_ACTION_ALERT_SUCCEED,
                        payload: response.data,
                    });
                    toast.success(message);
                } else {
                    message = 'Faild to Upsert the action alert';
                    dispatch({
                        type: ACTION_UPSERT_ACTION_ALERT_FAILED,
                        message: message,
                    });
                    console.error(message);
                    toast.error(message);
                }

            }).catch((error) => {
                message = 'Errors occurred when upserting the action alert.';
                dispatch({
                    type: ACTION_UPSERT_ACTION_ALERT_FAILED,
                    message: error.message,
                });
                console.error('Upsert action error(s) : ' + error.message);
                toast.error(message);
            });
        }
    );
}

export function deleteActionAlert(actionid) {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    let message = '';
    if (!actionid || actionid.length === 0) {
        return ((dispatch) => {
            message = 'Input Action Id is empty ';
            dispatch({
                type: ACTION_DELETE_ACTIONS_FAILED,
                message: message,
            });
        });
    }

    // const url = `${LocalRoot}${WEB_SERVICE_ACTION_ALERT}`;
    const queryStr = `?actionid=${actionid}`;
    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION}${queryStr}`;
    const request = axios({
        url: url,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        // data: alertObj
    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data === true) {
                    message = 'Delete the action successfully.';
                    dispatch({
                        type: ACTION_DELETE_ACTIONS_SUCCEED,
                        payload: response.data,
                    });
                    dispatch(getActions());
                    toast.success(message);
                } else {
                    message = 'Faild to Delete the action.';
                    dispatch({
                        type: ACTION_DELETE_ACTIONS_FAILED,
                        message: message,
                    });
                    console.error(message);
                    toast.error(message);
                }

            }).catch((error) => {
                message = 'Errors occurred when deleting the action alert.';
                dispatch({
                    type: ACTION_DELETE_ACTIONS_FAILED,
                    message: error.message,
                });
                console.error('Delete action error(s) : ' + error.message);
                toast.error(message);
            });
        }
    );
}

export function getActionLog() {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_LOG}`,
        // url: `${urlStr}${WEB_SERVICE_ACTION_RULE}`,
        criteria: null,
        logText: 'Get Action Log Url ',
        sccessType: ACTION_GET_ACTION_LOG_SUCCEED,
        failedType: ACTION_GET_ACTION_LOG_FAILED,
        errorMessage: 'Failed to get action log.'
    };

    return (axiosGet(paramObj));
}

export function getRuleTemplate(assetclassid) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_TEMPLATE}`,
        criteria: { assetclassid: assetclassid },
        logText: 'Get Rule Template Url ',
        sccessType: ACTION_GET_TEMPLATE_SUCCEED,
        failedType: ACTION_GET_TEMPLATE_FAILED,
        errorMessage: 'Failed to get rule template.'
    };

    return (axiosGet(paramObj));
}

export function getContact() {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_CONTACT}`,
        criteria: null,
        logText: 'Get Contact Url ',
        sccessType: ACTION_GET_CONTACT_LIST_SUCCEED,
        failedType: ACTION_GET_CONTACT_LIST_FAILED,
        errorMessage: 'Failed to get Contact List url.'
    };

    return (axiosGet(paramObj));
}

export function upsertContact(alertObj) {
    let LocalRoot = 'http://192.168.2.103:8080/'; //WEB_SERVICE_ROOT
    let message = '';
    if (!alertObj) {
        return ((dispatch) => {
            message = 'Input Contact is empty ';
            dispatch({
                type: ACTION_POST_CONTACT_LIST_FAILED,
                message: message,
            });
        });
    }

    // const url = `${LocalRoot}${WEB_SERVICE_ACTION_ALERT}`;
    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_POST_CONTACT}`;
    const request = axios({
        url: url,
        method: 'post',
        data: alertObj
    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data && response.data.length > 0) {
                    message = 'Upsert the contact list succeeded.';
                    dispatch({
                        type: ACTION_POST_CONTACT_LIST_SUCCEED,
                        payload: response.data,
                    });
                    toast.success(message);
                    dispatch(getContact());
                } else {
                    message = 'Faild to Upsert the contact list';
                    dispatch({
                        type: ACTION_POST_CONTACT_LIST_FAILED,
                        message: message,
                    });
                    console.error(message);
                    toast.error(message);
                }

            }).catch((error) => {
                message = 'Errors occurred when upserting the contact list.';
                dispatch({
                    type: ACTION_POST_CONTACT_LIST_FAILED,
                    message: error.message,
                });
                console.error('Upsert action error(s) : ' + error.message);
                toast.error(message);
            });
        }
    );
}

export function deleteContact(id) {
    let message = '';
    const queryStr = `?contactid=${id}`;
    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_CONTACT}${queryStr}`;
    const request = axios({
        url: url,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
            // 'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        // data: alertObj
    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data === true) {
                    message = 'Delete the contact successfully.';
                    // dispatch({
                    //     type: ACTION_DELETE_ACTIONS_SUCCEED,
                    //     payload: response.data, 
                    // });
                    dispatch(getContact());
                    toast.success(message);
                } else {
                    message = 'Faild to Delete the contact.';
                    // dispatch({
                    //     type: ACTION_DELETE_ACTIONS_FAILED,
                    //     message: message, 
                    // });
                    console.error(message);
                    toast.error(message);
                }

            }).catch((error) => {
                if (error.response && error.response.status === 403) {
                    console.error("delet contact 403", error.response);
                    toast.error(error.response.data);
                } else {
                    message = 'Errors occurred when deleting the contact.';
                    // dispatch({
                    //     type: ACTION_DELETE_ACTIONS_FAILED,
                    //     message: error.message, 
                    // });
                    console.error('Delete action error(s) : ' + error.message);
                    toast.error(message);
                }

            });
        }
    );
}

export function getNotificationTemplate() {
    // 
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVIDE_ACTION_NOTIFICATION_TEMPLATE}`,
        criteria: null,
        logText: 'Get Notification Template Url ',
        sccessType: ACTION_GET_NOTIFICTION_TEMPLATE_LIST_SUCCEED,
        failedType: ACTION_GET_NOTIFICTION_TEMPLATE_LIST_FAILED,
        errorMessage: 'Failed to get Notification Template List url.',
    };

    return (axiosGet(paramObj));
}

export function getNotificationTemplateByAppname(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_NOTIFICATION_TEMPLATE_APPNAME}`,
        criteria: obj,
        logText: 'Get Notification Template (by Appname) Url.',
        sccessType: ACTION_GET_NOTIFICTION_TEMPLATE_LIST_SUCCEED,
        failedType: ACTION_GET_NOTIFICTION_TEMPLATE_LIST_FAILED,
        errorMessage: 'Failed to get Notification Template (bt Appname) List Url.'
    }

    return (axiosGet(paramObj));
}

export function getDashBoardSettingList() {
    //
    // console.log('getDashBoardSettingList is hit')
    let paramObj = {
        url: `${WEB_SERVICE_ROOT_DashBoardSetting}${WEB_SERVIDE_ACTION_DASHBOARDSETTING_TEMPLATE}`,
        criteria: null,
        logText: 'Get Notification Template Url ',
        sccessType: ACTION_GET_DASHBOARDSETTING_LIST_SUCCEED,
        failedType: ACTION_GET_DASHBOARDSETTING_LIST_FAILED,
        errorMessage: 'Failed to get Notification Template List url.',
    };

    return (axiosGet(paramObj));
}



export function upsertNotificationTemplate(templateObj) {
    // const startType = paramObj.startType;

    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVIDE_ACTION_NOTIFICATION_TEMPLATE}`,
        //dataObject: templateObj,
        criteria: templateObj,
        logText: 'Get Notification Template Url ',
        errorMessage: 'Failed to update Notification Template List url.',
        successMessage: 'Successfully update the template.',
        successCallback: () => { return (getNotificationTemplate()) },
        // errorCallback: (msg) => {toast.error(msg)}
        responseKeyGetter: (response) => (response.data),
        successContent: true,
    };

    return (axiosPost(paramObj));
}

export function upsertNotificationTemplateByAppname(templateObj) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_NOTIFICATION_TEMPLATE_APPNAME}`,
        criteria: templateObj,
        logText: 'Get Notification Template (by Appname) Url',
        errorMessage: 'Failed to update Notification Template List url.',
        successMessage: 'Successfully update the template.',
        successCallback: () => {
            if(appname != null){
                return getNotificationTemplateByAppname(obj)
            }else {
                    return (getNotificationTemplate())
                }
            },
        responseKeyGetter: (response) => (response.data),
        successContent: true,
    };

    return (axiosPost(paramObj));
}

export function deleteNotificationTemplate(templateId) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVIDE_ACTION_NOTIFICATION_TEMPLATE}`,
        dataObject: null,
        criteria: { templateid: templateId },
        logText: 'Get Notification Template Url ',
        errorMessage: 'Failed to delete Notification Template List url.',
        successMessage: 'Successfully deleted the template.',
        responseKeyGetter: (res) => (res.data),
        successContent: true,
        callback: () => { return (getNotificationTemplate()) },
        // errorCallback: (msg) => {toast.error(msg)}
    };

    return (axiosDelete(paramObj));
}

export function deleteNotificationTemplateByAppname(templateId) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ACTION_NOTIFICATION_TEMPLATE_APPNAME}`,
        dataObject: null,
        criteria: { templateId: templateId },
        logText: 'Get Notification Template (by Appname) Url.',
        errorMessage: 'Failed to delete Notification Template List Url.',
        successMessage: 'Successfully deleted the template.',
        responseKeyGetter: (res) => (res.data),
        successContent: true,
        callback: () => {return (getNotificationTemplateByAppname())},
    };

    return (axiosDelete(paramObj));
}
