/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames'
import Spinner from 'react-bootstrap/Spinner';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    getClients,
    getConsumers,
    getCompanyList,
    getActivatedSimDashboard,
    getAllResellers,
    getPlanUsage,
    getPageContent,
    getServicePlanItem, getTotalUsage, getExportActivatedSimDashboardData, getOneCompanyByCode
} from '../../../redux/actions/userAction';
import {
    getTelecomActions,
    setTelecomActions,
    getTelecomActionsById
} from '../../../redux/actions/jobAction';

import {
    getActivityReport,
    getFullSIMList,
    getManualBillingStatus,
    startManualBilling,
    runManualBilling
} from '../../../redux/actions/inventoryActions';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import Select from 'react-select';

import ActiveUsageCard from './Dashboard/ActiveUsage/ActiveUsageCard';
import InventoryBoard from './Inventory/InventoryBoard';

import BillingFileBoard from '../../BillingApp/components/BillingFiles/BillingFileBoard';
import IntegrationFileBoard from '../../BillingApp/components/IntegrationFiles/IntegrationFileBoard';

import SchemaBoard from '../../Schema/components/SchemaBoard'; //'./components/SchemaBoard';
import ServicePlanManagementBoard from './ServicePlan/ServicePlanManagementBoard';
import DashboardPanel from './Dashboard/DashboardPanel';
import NotificationPanel from './Notification/NotificationPanel';
import { timeThursdays } from 'd3';
import { CSVLink } from "react-csv";

import TelecomBoardStyles from './TelecomBoard.module.css';


class TelecomBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            isIntegrationFileBoardMounted: false,
            resellerCode: null,
            resellerName: null,
            filterStartTime: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() + (60 * 60 * 1000),
            filterEndTime: new Date().getTime(),
            type: "daily",
            loading: false,
            simListLoading: false,
            loadUsageDateType: null,
            filterClientName: "",
            filterStatus: 1,
            filterDeviceName: "",
            filterIdentifierValue: "",
            filterDataUsage: 2,

            page: 1,
            pageSize: 10,
            usageCsvData: [],
            usageCsvHeaders: [
                { label: "date", key: "name" },
                { label: "usage", key: "amt" },
                { label: "client", key: "client" }
            ],
            customStyles: {},
            isPendingRunManualBilling: false
        };

        this.userObj = getCurrentUser();

        this.state.customStyles = {
            menu: (provided, state) => ({
                ...provided,
                width: "200px",
                cursor: "pointer"
            }),
            option: (provided, state) => ({
                ...provided,
                color: "black",
                width: "200px",
                cursor: "pointer",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                width: "200px",
                height: 35,
                minHeight: 35,
                cursor: "pointer",
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }

        this.manualBillinStatusInterval = null
        this.runManualBilling = this.runManualBilling.bind(this);
    }

    async componentDidMount() {

        await this.props.getPageContent({ "content": "Billing App" });

        const { getManualBillingStatus, pageContentList } = this.props

        if ((this.userObj && hasRole(this.userObj, ['ADMIN', 'USER']))
            && pageContentList.includes("Run Manual Billing")) {

            await getManualBillingStatus()

        }

        this.setState({
            loading: true
        }, async () => {

            this.props.getServicePlanItem({ "companyCode": this.userObj.companyCode, "resellerCode": this.userObj.resellerCode })
            this.props.getPageContent({ "content": "Telecom App" });
            this.props.getPageContent({ "content": "Company" });
            this.props.getCompanyList();
            this.props.getFullSIMList({
                "resellerCode": this.userObj.resellerCode,
                "fullInventory": true,
                "customerId": (this.userObj.customerId == 0) ? null : this.userObj.customerId //change once customerId is included in checkuser

            });

            this.props.getActivatedSimDashboard({
                "type": this.state.type,
                "fromDate": this.state.filterStartTime,
                "toDate": this.state.filterEndTime,
                "companyCode": this.userObj.companyCode,
                "resellerCode": this.userObj.resellerCode,
                "customerId": (this.userObj.customerId == 0) ? null : this.userObj.customerId, //change once customerId is included in checkuser
                "page": this.state.page,
                "pageSize": this.state.pageSize,
                "status": this.state.filterStatus,
                "isDesc": this.state.filterDataUsage == 2 ? true : false
            })

            this.props.getTotalUsage(this.userObj.companyCode, this.userObj.resellerCode, this.getTotalUsageFromDate(), this.formatDate(new Date()))
            this.props.getPlanUsage({
                "type": this.state.type,
                "startTime": this.state.filterStartTime,
                "endTime": this.state.filterEndTime,
                "customerId": (this.userObj.customerId != 0) ? this.userObj.customerId : null,
                "resellerCode": this.userObj.resellerCode,
                "companyCode": this.userObj.companyCode
            }).then(() => {
                let csvData = this.props.dashboardPlanUsageData
                for (let i = 0; i < csvData.length; i++) {
                    csvData[i].client = this.state.resellerName;
                }
                this.setState({ usageCsvData: csvData })
            })
            let companyObjArr = await this.props.getOneCompanyByCode(this.userObj.companyCode);
            if (companyObjArr.data.length > 0 && companyObjArr.data[0] != null) {
                await this.props.getActivityReport({ companyId: companyObjArr.data[0].id, resellerCode: this.userObj.resellerCode })
            }
            this.props.getAllResellers({ resellerCode: this.userObj.resellerCode });
            await this.props.getTelecomActions();
            this.setState({
                loading: false
            })
            let selects = document.getElementsByClassName("select-filter");
            for (let i = 0; i < selects.length; i++) {
                let options = selects[i].getElementsByTagName("option");
                for (let j = 0; j < options.length; j++) {
                    let option = options[j]
                    option.onclick = function () {
                        console.log();
                    }
                }
            }
        })


    }

    componentWillUnmount() {
        if (this.manualBillinStatusInterval) {
            clearInterval(this.manualBillinStatusInterval);
            this.manualBillinStatusInterval = null
        }
    }

    add0(m) { return m < 10 ? '0' + m : m }
    formatDate(timeStamp) {
        var time = new Date(timeStamp);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y + '-' + this.add0(m) + '-' + this.add0(d);
    }


    add0(m) { return m < 10 ? '0' + m : m }


    formatDate(timeStamp) {
        var time = new Date(timeStamp);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return y + '-' + this.add0(m) + '-' + this.add0(d);
    }

    handleClientChange = (value) => {
        console.log(value)
        if (value != null) {
            this.setState({
                type: this.state.type,
                resellerCode: value.resellerCode,
                resellerName: value.resellerName,

                loading: true,
                filterDataUsage: 2,
                filterClientName: "",
                filterStatus: 1,
                filterDeviceName: "",
                filterIdentifierValue: "",
                page: 1
            }, async () => {
                this.props.getPlanUsage({
                    "type": this.state.type,
                    "resellerCode": this.state.resellerCode,
                    "companyCode": this.userObj.companyCode,
                    "customerId": null,
                    "startTime": this.state.filterStartTime,
                    "endTime": this.state.filterEndTime
                }).then(() => {
                    let csvData = this.props.dashboardPlanUsageData
                    for (let i = 0; i < csvData.length; i++) {
                        csvData[i].client = this.state.resellerName;
                    }
                    this.setState({
                        usageCsvData: csvData
                    })
                })
                this.props.getTotalUsage(this.userObj.companyCode, this.state.resellerCode, this.getTotalUsageFromDate(), this.formatDate(new Date()))
                this.props.getFullSIMList({
                    "resellerCode": this.state.resellerCode,
                    "fullInventory": true,
                });
                let companyObjArr = await this.props.getOneCompanyByCode(this.userObj.companyCode);
                if (companyObjArr.data.length > 0 && companyObjArr.data[0] != null) {
                    await this.props.getActivityReport({ companyId: companyObjArr.data[0].id, resellerCode: this.state.resellerCode })
                }

                await this.props.getActivatedSimDashboard({
                    "type": this.state.type,
                    "companyCode": this.userObj.companyCode,
                    "resellerCode": this.state.resellerCode,
                    "page": this.state.page,
                    "pageSize": this.state.pageSize,
                    "status": this.state.filterStatus,
                    "isDesc": this.state.filterDataUsage == 2 ? true : false,
                    "fromDate": this.state.filterStartTime,
                    "toDate": this.state.filterEndTime,

                })
                this.setState({
                    loading: false
                })
            })
        } else {
            this.setState({
                resellerCode: null,
                resellerName: null,
                type: this.state.type,
                loading: true,
                page: 1,
                filterDataUsage: 2,
                filterClientName: "",
                filterStatus: 1,
                filterDeviceName: "",
                filterIdentifierValue: ""
            }, async () => {
                this.props.getFullSIMList({
                    "resellerCode": null,
                    "fullInventory": true,
                });
                await this.props.getActivatedSimDashboard({
                    "type": this.state.type,
                    "companyCode": this.userObj.companyCode,
                    "resellerCode": null,
                    "page": this.state.page,
                    "pageSize": this.state.pageSize,
                    "isDesc": this.state.filterDataUsage == 2 ? true : false,
                    "fromDate": this.state.filterStartTime,
                    "toDate": this.state.filterEndTime,
                    "status": this.state.filterStatus
                })
                let companyObjArr = await this.props.getOneCompanyByCode(this.userObj.companyCode);
                if (companyObjArr.data.length > 0 && companyObjArr.data[0] != null) {
                    await this.props.getActivityReport({ companyId: companyObjArr.data[0].id, resellerCode: this.state.resellerCode })
                }

                this.props.getTotalUsage(this.userObj.companyCode, null, this.getTotalUsageFromDate(), this.formatDate(new Date()))
                await this.props.getPlanUsage({
                    "type": this.state.type,
                    "resellerCode": null,
                    "companyCode": this.userObj.companyCode,
                    "customerId": null,
                    "startTime": this.state.filterStartTime,
                    "endTime": this.state.filterEndTime,

                }).then(() => {
                    let csvData = this.props.dashboardPlanUsageData
                    for (let i = 0; i < csvData.length; i++) {
                        csvData[i].client = this.state.resellerName;
                    }
                    this.setState({
                        usageCsvData: csvData,
                    })
                })
                this.setState({
                    loading: false
                })
            });

        }
    }

    exportSimListData = () => {
        return new Promise(async resolve => {

            let reqObj = {
                "companyCode": this.userObj.companyCode,
                "resellerCode": (this.userObj && hasRole(this.userObj, ['ADMIN', 'USER', 'SUPERADMIN'])) ? this.state.resellerCode : this.userObj.resellerCode,
                "fromDate": this.state.filterStartTime,
                "toDate": this.state.filterEndTime,
                "type": this.state.loadUsageDateType ? this.state.loadUsageDateType : "daily",
                "page": 0,
                // "status": this.state.filterStatus,
                "pageSize": 999999
            }
            if (this.state.filterClientName && this.state.filterClientName != '') {
                reqObj['resellerName'] = this.state.filterClientName
            }
            // if (this.state.filterStatus != null && this.state.filterStatus != '') {
            //     reqObj['status'] = this.state.filterStatus
            // }
            if (this.state.filterDeviceName && this.state.filterDeviceName != '') {
                reqObj['deviceName'] = this.state.filterDeviceName
            }
            if (this.state.filterIdentifierValue && this.state.filterIdentifierValue != '') {
                reqObj['identifierValue'] = this.state.filterIdentifierValue
            }
            if (this.state.filterDataUsage && this.state.filterDataUsage != 0) {
                reqObj['isDesc'] = this.state.filterDataUsage == 2 ? true : false;
            }
            let page = 0;
            let pageSize = 50000;
            //get sum
            let sum = 20000000000;
            //calculate page
            page = Math.ceil(sum / pageSize);
            reqObj['pageSize'] = pageSize;
            // let activatedSims={
            //     "totalPageNumber":null,
            //     "totalElements":0,
            //     "activatedSims":[]
            // }
            let activatedSims = [];
            //get data 0 1 2 <3
            for (let i = 0; i < page; i++) {
                //
                reqObj['page'] = i;
                //
                let data_sum = -1;

                // splice data
                await this.props.getExportActivatedSimDashboardData(reqObj).then(res => {

                    if (res.data != null) {

                        if (res.data.activatedSims)
                            // res.data.activatedSims.forEach(e=>activatedSims.push(e));
                            if (res.data.activatedSims.length > 0)//[[],[],[]]
                                activatedSims.push(res.data.activatedSims);
                        if (res.data.sum)
                            data_sum = res.data.sum;
                        console.log("all_data_sum:" + data_sum)
                        console.log("--------------i=", i + 1)
                        console.log("reponse data:", activatedSims[0]);

                    } else {
                        // activatedSims.push([]);
                    }
                })
                if (data_sum <= 0) {
                    break;
                }
            }
            //judge the size of block which decide how to sort activatedSims
            let block_size = 0;
            if (activatedSims.length > 0) {
                let identify_value = activatedSims[0][0]['identifierValue']
                //
                for (let i = 0; i < activatedSims[0].length; i++) {
                    if (activatedSims[0][i]['identifierValue'] === identify_value) {
                        block_size += 1;
                    } else {
                        break;
                    }
                }
            }
            console.log("block_size " + block_size);
            //sort
            let result = []
            // let identity_value_list=[]
            while (activatedSims.length > 0) {
                //     let i=0;
                //     let sum_size=activatedSims.length
                //     while(i<sum_size){
                //         let tmp_identity_value=activatedSims[i][0]['identifierValue']
                //         console.log("to find "+tmp_identity_value);
                //         if(identity_value_list.indexOf(tmp_identity_value)>=0)
                //         {
                //             let oo=identity_value_list.indexOf(tmp_identity_value)
                //             console.log("find in "+oo+" "+identity_value_list[oo])
                //             activatedSims[i].splice(0,block_size)
                //
                //             if(activatedSims[i].length==0)
                //             {
                //                 activatedSims.splice(i,1)
                //             }
                //
                //
                //         }else
                //         {
                //             identity_value_list.push(tmp_identity_value)
                //             i++
                //         }
                //         sum_size=activatedSims.length
                //     }


                let max_index = 0

                let data_sum = this.calculateSum(activatedSims[0].slice(0, block_size))

                for (let i = 0; i < activatedSims.length; i++) {
                    let tmp_sum = this.calculateSum(activatedSims[i].slice(0, block_size))
                    if (tmp_sum > data_sum) {
                        data_sum = tmp_sum
                        max_index = i

                    }
                }
                let tmp_list = activatedSims[max_index].slice(0, block_size)
                let find = 0
                for (let re = 0; re < result.length; re += block_size) {
                    if (tmp_list[0]['identifierValue'] === result[re]['identifierValue']) {

                        console.log("--------------------------------")
                        find = 1
                        break
                    }
                }
                if (find == 0) {
                    result = result.concat(tmp_list)
                }

                console.log(result);
                activatedSims[max_index].splice(0, block_size)

                if (activatedSims[max_index].length == 0) {
                    activatedSims.splice(max_index, 1)
                }

            }


            // console.log("3")
            // console.log(activatedSims);
            // let res=[]
            // for (let i = 0; i < activatedSims.length; i++) {
            //     res.concat(activatedSims[i])
            // }
            console.log("download data")
            resolve(result);


        })

    }
    calculateSum(list) {
        let sum = 0
        for (let i = 0; i < list.length; i++) {
            sum += list[i]['periodDataUsage']
        }
        console.log(sum);
        return sum
    }
    getTotalUsageFromDate() {
        let now = new Date();
        let nowYear = now.getFullYear();
        let nowMonth = now.getMonth();
        let nowDate = 1;
        let monthStartDate = new Date(nowYear, nowMonth, nowDate);
        return this.formatDate(monthStartDate.getTime());
        /*
         let monthStartDate;
         if(this.state.type=="monthly"){
             nowDate=1;
             /!*let monthAgo=nowMonth-3;
             if(monthAgo>=0){
                 nowMonth=monthAgo;
             }else{
                 nowMonth=11+monthAgo;
             }*!/
         }
         monthStartDate= new Date(nowYear, nowMonth, nowDate);
         if(this.state.type=="weekly"){
             monthStartDate=this.getFirstDayOfWeek(new Date());
         }
         return this.formatDate(this.state.filterStartTime!=null?this.state.filterStartTime:monthStartDate.getTime());*/
    }
    getFirstDayOfWeek(date) {
        var day = date.getDay() || 7;
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - day);
    };
    LoadUsageDate = (type, filterObj, isReset) => {
        type = type == null ? this.state.loadUsageDateType == null ? this.state.type : this.state.loadUsageDateType : type;
        let startTime = filterObj['startTime'] !== undefined ? (!filterObj['isClearFilterTime'] ? filterObj['startTime'] : new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() + (60 * 60 * 1000)) : this.state.filterStartTime;
        let endTime = filterObj['endTime'] !== undefined ? (!filterObj['isClearFilterTime'] ? filterObj['endTime'] : new Date().getTime()) : this.state.filterEndTime;
        if (startTime != null && endTime == null) {
            endTime = new Date().getTime();
        }
        let simListPage = filterObj['simListPage'] !== undefined ? filterObj['simListPage'] : this.state.page;
        let simListPageSize = filterObj['simListPageSize'] !== undefined ? filterObj['simListPageSize'] : this.state.pageSize;
        let filterClientName = filterObj['filterClientName'] !== undefined ? filterObj['filterClientName'] : this.state.filterClientName;
        let filterStatus = filterObj['filterStatus'] !== undefined ? parseInt(filterObj['filterStatus']) : this.state.filterStatus;
        let filterDeviceName = filterObj['filterDeviceName'] !== undefined ? filterObj['filterDeviceName'] : this.state.filterDeviceName;
        let filterIdentifierValue = filterObj['filterIdentifierValue'] !== undefined ? filterObj['filterIdentifierValue'] : this.state.filterIdentifierValue;
        let filterDataUsage = filterObj['filterDataUsage'] !== undefined ? filterObj['filterDataUsage'] : this.state.filterDataUsage;
        let filterPlanName = filterObj['filterPlanName'] !== undefined ? filterObj['filterPlanName'] : this.state.filterPlanName;
        let filterPlanCode = filterObj['filterPlanCode'] !== undefined ? filterObj['filterPlanCode'] : this.state.filterPlanCode;
        let simListLoading = filterObj['simListLoading'] !== undefined ? filterObj['simListLoading'] : this.state.simListLoading;
        if (isReset) {
            simListPage = 1;
            filterClientName = "";
            filterStatus = 1;
            filterDataUsage = 2;
            filterDeviceName = "";
            filterIdentifierValue = "";
            filterPlanName = "";
            filterPlanCode = "";
        }
        this.setState({
            type: type,
            loadUsageDateType: type,
            filterStartTime: startTime,
            filterEndTime: endTime,
            page: simListPage,
            pageSize: simListPageSize,
            filterClientName: filterClientName,
            filterStatus: filterStatus,
            filterDeviceName: filterDeviceName,
            filterIdentifierValue: filterIdentifierValue,
            filterDataUsage: filterDataUsage,
            filterPlanName: filterPlanName,
            filterPlanCode: filterPlanCode,
            simListLoading: simListLoading,
            loading: !simListLoading
        }, async () => {

            if (!simListLoading) {
                this.props.getPlanUsage({
                    "type": type,
                    "customerId": (this.userObj.customerId != 0) ? this.userObj.customerId : null,
                    "resellerCode": (this.userObj && hasRole(this.userObj, ['ADMIN', 'USER', 'SUPERADMIN'])) ? this.state.resellerCode : this.userObj.resellerCode,
                    "companyCode": this.userObj.companyCode,
                    "startTime": this.state.filterStartTime,
                    "endTime": this.state.filterEndTime
                }).then(() => {
                    let csvData = this.props.dashboardPlanUsageData
                    for (let i = 0; i < csvData.length; i++) {
                        csvData[i].client = this.state.resellerName;
                    }
                    this.setState({ usageCsvData: csvData })
                })
                this.props.getTotalUsage(this.userObj.companyCode, this.state.resellerCode, this.getTotalUsageFromDate(), this.formatDate(new Date()))
            }

            let reqObj = {
                "companyCode": this.userObj.companyCode,
                "resellerCode": (this.userObj && hasRole(this.userObj, ['ADMIN', 'USER', 'SUPERADMIN'])) ? this.state.resellerCode : this.userObj.resellerCode,
                // "page": this.state.page,
                //"pageSize": this.state.pageSize,
                "fromDate": this.state.filterStartTime,
                "toDate": this.state.filterEndTime,
                "type": type,
                "page": this.state.page,
                "pageSize": this.state.pageSize,
                /*"resellerName":this.state.filterClientName,
                "Status":this.state.filterStatus,
                "deviceName":this.state.filterDeviceName,
                "identifierValue":this.state.filterIdentifierValue,*/
            }
            if (this.state.filterClientName && this.state.filterClientName != '') {
                reqObj['resellerName'] = this.state.filterClientName
            }
            if (this.state.filterStatus != null && this.state.filterStatus >= 0) {
                reqObj['status'] = this.state.filterStatus
            }
            if (this.state.filterDeviceName && this.state.filterDeviceName != '') {
                reqObj['deviceName'] = this.state.filterDeviceName
            }
            if (this.state.filterPlanName && this.state.filterPlanName != '') {
                reqObj['planName'] = this.state.filterPlanName
            }
            if (this.state.filterPlanCode && this.state.filterPlanCode != '') {
                reqObj['planCode'] = this.state.filterPlanCode
            }
            if (this.state.filterIdentifierValue && this.state.filterIdentifierValue != '') {
                reqObj['identifierValue'] = this.state.filterIdentifierValue
            }
            if (this.state.filterDataUsage && this.state.filterDataUsage != 0) {
                reqObj['isDesc'] = this.state.filterDataUsage == 2 ? true : false;
            }
            //await this.props.getActivatedSimDashboard(reqObj)

            if (type == "monthly" && startTime == null && endTime == null) {
                reqObj['type'] = "daily"
                reqObj['fromDate'] = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() + (60 * 60 * 1000)
                reqObj['toDate'] = new Date().getTime()

            } await this.props.getActivatedSimDashboard(reqObj)

            this.setState({
                simListLoading: false,
                loading: false
            })

        })
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            let { activeTab, isIntegrationFileBoardMounted } = this.state
            switch (tab) {
                case '1':
                case '2':
                case '4':
                case '5':
                    activeTab = tab;
                    isIntegrationFileBoardMounted = false;
                    break;
                case '3':
                    activeTab = tab;
                    isIntegrationFileBoardMounted = true;
                    break;
                default:
                    console.log(`Could not use tab ${tab}.`);
            }
            this.setState({ activeTab, isIntegrationFileBoardMounted })
        }
    }
    onPageChange = (page, sizePerPage) => {
        this.setState({
            page: page,
            pageSize: sizePerPage,
        }, () => {
            this.props.getActivatedSimDashboard({
                "companyCode": this.userObj.companyCode,
                "resellerCode": null,
                "type": this.state.type,
                "page": this.state.simListPage,
                "pageSize": this.state.simListPageSize,
                "fromDate": this.state.filterStartTime,
                "toDate": this.state.filterEndTime,
                "isDesc": this.state.filterDataUsage == 2 ? true : false
            })
        })
    }
    renderSpinner = () => {
        return (
            <div>
                <Spinner
                    className="table-spinner"
                    as="span"
                    animation="border"
                    // size="sm"
                    // role="status"
                    // aria-hidden="true"
                    style={{ display: 'inline-block', margin: 'auto', left: '50%', top: "1px", position: "absolute", zIndex: "99999" }}
                />
            </div>

        );
    }

    runManualBilling = async () => {

        const { getManualBillingStatus,
            startManualBilling, runManualBilling } = this.props

        this.setState({ isPendingRunManualBilling: true })

        await getManualBillingStatus()

        const { manualBillingStatus } = this.props

        console.log('manualBillingStatus', manualBillingStatus)
        if (!manualBillingStatus) {
            let companyObjArr = await this.props.getOneCompanyByCode(this.userObj.companyCode);
            if (companyObjArr.data.length > 0 && companyObjArr.data[0] != null) {
                await startManualBilling()
                await runManualBilling(companyObjArr.data[0].id)
            }
        }

        this.setState({ isPendingRunManualBilling: false })
    }

    render() {
        const { pageContentList, manualBillingStatus } = this.props;
        return (
            <>
                {
                    (this.userObj && hasRole(this.userObj, ['ADMIN', 'USER']))
                    && pageContentList.includes("Run Manual Billing")
                    && (
                        <Col md={12}>
                            <Button
                                type="button"
                                disabled={manualBillingStatus}
                                // className={`btn btn-secondary`}
                                onClick={this.runManualBilling}
                                variant="primary"
                                color="success"
                            >
                                {
                                    !manualBillingStatus &&
                                    <span style={{ color: "white" }}>
                                        Run Manual Billing
                                    </span>
                                }
                                {
                                    manualBillingStatus &&
                                    <>
                                        <span
                                            className={`spinner-border spinner-border-sm ${TelecomBoardStyles['pos-manual-billing-spinner']}`}
                                            role="status"
                                            aria-hidden="true"
                                        >
                                        </span>
                                        <span
                                            className={`${TelecomBoardStyles['pos-manual-billing-status-text']}`}
                                        >
                                            Manual Billing in progress
                                        </span>
                                    </>
                                }
                            </Button>
                        </Col>
                    )
                }
                <Col md={12}>
                    <NavTabs
                        activeTab={this.state.activeTab}
                        toggleTab={this.toggleTab}
                        userObj={this.userObj}
                        pageContentList={pageContentList} />
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {
                                (this.userObj && hasRole(this.userObj, ['ADMIN', 'USER'])) ?
                                    <>
                                        <br />
                                        <Row>
                                            <Col md={2}>
                                                <div className={"box"}>
                                                    {this.state.loading ? this.renderSpinner() : null}
                                                    <Select
                                                        isClearable
                                                        isSearchable
                                                        // options = {this.state.assets} //assetList
                                                        options={this.props.activeReseller}
                                                        getOptionLabel={({ resellerName }) => resellerName}
                                                        getOptionValue={({ resellerCode }) => resellerCode}
                                                        placeholder={
                                                            this.userObj.companyCode && this.userObj.companyCode !== "TEISA" ? 'Select a Client ...' : 'Select a Reseller ...'
                                                        }
                                                        onChange={this.handleClientChange}
                                                        styles={this.state.customStyles}
                                                    />
                                                </div>

                                            </Col>

                                            <Col style={{ marginLeft: "80px" }}>
                                                {(this.state.resellerName != null) ?
                                                    <h3>{this.state.resellerName}'s Dashboard</h3> : null}
                                            </Col>
                                        </Row>


                                    </>
                                    : null
                            }
                            <DashboardPanel
                                filterIdentifierValueSort={this.state.filterIdentifierValue}
                                filterDeviceNameSort={this.state.filterDeviceName}
                                filterClientNameSort={this.state.filterClientName}
                                filterStatusSort={this.state.filterStatus}
                                filterDataUsageSort={this.state.filterDataUsage}
                                loading={this.state.loading}
                                currentUserObj={this.userObj}
                                dashboardActivatedSimList={this.props.dashboardActivatedSimList}
                                getClients={this.props.getClients}
                                LoadUsageDate={this.LoadUsageDate}
                                exportSimListData={this.exportSimListData}
                                type={this.state.type}
                                filterStartTime={this.state.filterStartTime}
                                filterEndTime={this.state.filterEndTime}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                clientList={this.props.clientList}
                                consumerList={this.props.consumerList}
                                getConsumers={this.props.getConsumers}
                                activeSIMList={this.props.activeSIMList}
                                inactiveSIMList={this.props.inactiveSIMList}
                                suspendedSIMList={this.props.suspendedSIMList}
                                onPageChange={this.props.onPageChange}
                                activityReportList={this.props.activityReportList}
                                totalUsage={this.props.totalUsage}
                            />
                        </TabPane>
                        {(this.userObj && hasRole(this.userObj, ['SUPERADMIN', 'ADMIN', 'USER'])) ? <TabPane tabId="2">
                            <InventoryBoard
                                currentUserObj={this.userObj}
                                activeTab={this.state.activeTab}
                                inactiveSIMList={this.props.inactiveSIMList}
                                SIMTypeList={this.props.SIMTypeList}
                                typeList={this.props.typeList}
                                getFullSIMList={this.props.getFullSIMList}
                                pageContentList={this.props.pageContentList}
                                itemType={this.props.itemType}
                            />
                        </TabPane> : null}
                        {(this.userObj && hasRole(this.userObj, ['SUPERADMIN', 'ADMIN'])) ? <TabPane tabId="3">
                            {this.state.isIntegrationFileBoardMounted &&
                                <IntegrationFileBoard
                                    currentUserObj={this.userObj}
                                    activeTab={this.state.activeTab}
                                />}
                        </TabPane> : null}
                        {(this.userObj && hasRole(this.userObj, ['SUPERADMIN', 'ADMIN'])) ? <TabPane tabId="4">
                            <BillingFileBoard
                                currentUserObj={this.userObj}
                                activeTab={this.state.activeTab}
                            />
                        </TabPane> : null}
                        {(this.userObj && hasRole(this.userObj, ['SUPERADMIN', 'ADMIN', 'USER', "CLIENTADMIN", "CLIENTUSER"])) ? <TabPane tabId="5">
                            <NotificationPanel
                                userObj={this.userObj}
                                getTelecomActions={this.props.getTelecomActions}
                                getTelecomActionsById={this.props.getTelecomActionsById}
                                setTelecomActions={this.props.setTelecomActions}
                                actionList={this.props.actionList}
                                activeSIMList={this.props.activeSIMList}
                                resellerList={this.props.resellerList}
                                ServicePlanList={this.props.ServicePlanList}

                            />
                        </TabPane> : null}
                        {/*(this.userObj && hasRole(this.userObj, ['SUPERADMIN', 'ADMIN']))?<TabPane tabId="3">
                  <ServicePlanManagementBoard
                     currentUserObj={this.userObj}
                     activeTab={this.state.activeTab}
                  />
      </TabPane>:null*/}
                    </TabContent>
                </Col>
            </>
        );
    }
}

function NavTabs({ activeTab, toggleTab, userObj, pageContentList }) {

    let displayText;
    if (pageContentList.includes("Products and Services")/* TEISA */) {
        displayText = 'Products and Services';
    } else {
        displayText = 'Dashboard';
    }

    let inventoryTabName;
    if (pageContentList.includes("Telecom Inventory Tab Contract Registration")) {
        inventoryTabName = 'Contract Registration';
    } else {
        inventoryTabName = 'Inventory';
    }

    return (
        <Nav tabs style={{ border: 'none', cursor: "pointer" }}>
            <NavItem>
                <NavLink style={{}}
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                        toggleTab('1');
                    }}>
                    <h4><b>{displayText}</b></h4>
                </NavLink>
            </NavItem>
            {(pageContentList.includes("TelecomBoard Inventory")  /* TEISA */
                && hasRole(userObj, ['SUPERADMIN', 'ADMIN', 'USER'])) ? <NavItem>
                <NavLink style={{}}
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                        toggleTab('2');
                    }}>
                    <h4><b>{inventoryTabName}</b></h4>
                </NavLink>
            </NavItem> : null}
            {(pageContentList.includes("Integration Files") /* TEISA */
                && hasRole(userObj, ['SUPERADMIN', 'ADMIN'])) ? <NavItem>
                <NavLink style={{}}
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                        toggleTab('3');
                    }}>
                    <h4><b>Integration Files</b></h4>
                </NavLink>
            </NavItem> : null}
            {(pageContentList.includes("Usage and Billing Files") /* TEISA */
                && hasRole(userObj, ['SUPERADMIN', 'ADMIN'])) ? <NavItem>
                <NavLink style={{}}
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                        toggleTab('4');
                    }}>
                    <h4><b>Usage and Billing Files</b></h4>
                </NavLink>
            </NavItem> : null}
            {(pageContentList.includes("Telecom Notification") /* TEISA */
                && hasRole(userObj, ['SUPERADMIN', 'ADMIN'])) ?
                <NavItem>
                    <NavLink style={{}}
                        className={classnames({ active: activeTab === '5' })}
                        onClick={() => { toggleTab('5'); }}>
                        <h4><b>Notification</b></h4>
                    </NavLink>
                </NavItem> : null}
            {/*(userObj && hasRole(userObj, ['SUPERADMIN', 'ADMIN']))?<NavItem>
            <NavLink style={{
               border: 'none'
            }}
               className={classnames({ active: activeTab === '3' })}
               onClick={() => { toggleTab('3'); }}>
               <h4><b>Service Plan</b></h4>
            </NavLink>
         </NavItem>:null*/}

        </Nav>
    )
}

function RenderTestingButtons({ props, state }) {
    return (
        <>
            <Button variant="primary" color="custom" onClick={
                () => {
                    console.log(props)
                }
            }>Show Props</Button>
            <Button variant="primary" color="custom" onClick={
                () => {
                    console.log(state)
                }
            }>Show State</Button>
        </>
    )
}

// export default AssetBoard;

function mapStateToProps(state) {
    let {
        SIMTypeList,
        typeList,
        itemType,
        activeSIMList,
        inactiveSIMList,
        suspendedSIMList,
        manualBillingStatus,
        activityReportList
    } = state.inventory;

    const { resellerList,
        consumerList,
        companyList,
        dashboardActivatedSimList,
        pageContentList,
        dashboardPlanUsageData,
        ServicePlanList } = state.user

    let {
        actionList
    } = state.job

    const activeReseller = resellerList.filter(reseller => reseller.status === 1).sort((a1, a2) => {
        const b1 = a1.resellerName.toLowerCase(); const b2 = a2.resellerName.toLowerCase(); if (b1 == b2) return 0;
        if (b1 > b2) return 1;
        return -1;
    })

    const props = {
        companyList, consumerList, dashboardActivatedSimList, ...state.asset,
        SIMTypeList,
        itemType,
        typeList,
        activeSIMList,
        inactiveSIMList,
        suspendedSIMList,
        pageContentList,
        resellerList,
        activeReseller,
        dashboardPlanUsageData,
        ServicePlanList,
        actionList,
        manualBillingStatus,
        activityReportList,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCompanyList,
        getActivityReport,
        getClients,
        getConsumers,
        getActivatedSimDashboard,
        getTotalUsage,
        getFullSIMList,
        getAllResellers,
        getPlanUsage,
        getPageContent,
        getServicePlanItem,
        getTelecomActions,
        setTelecomActions,
        getTelecomActionsById,
        getManualBillingStatus,
        startManualBilling,
        runManualBilling,
        getOneCompanyByCode,
        getExportActivatedSimDashboardData
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TelecomBoard);


