/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Col, Row, Button, Container } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { removeUser, getUserDevicetypeList, upsertUserDevicetypeList } from '../../../redux/actions/userAction';
import { groupBy } from '../../../factories/utils';

class UserDeviceTypeAccessForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devicetypeMap: [],
            restUserTypes: [],
        }
    }

    componentDidMount() {
        // alert('user access form mount ');
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // alert('user access form ');
        if (nextProps.deviceTypes === prevState.rawDeviceTypes && nextProps.userDeviceTypeList === prevState.rawUserDeviceTypeList) {
            return null;
        }

        let devicetypeMap = [];
        let restUserTypeIds = [];

        if (nextProps.deviceTypes) {


            // nextProps.deviceTypes && nextProps.deviceTypes.forEach((devicetype, index) => {
            //     // let mapItem = {
            //     //     devicetypeid : devicetype.id,
            //     //     devicetypename: devicetype.name,
            //     //     access: 2,
            //     // };
            //     let mapItem = {
            //         devicetypeid : devicetype.devicetypeid,
            //         devicetypename: devicetype.devicetypename,
            //         companycode: devicetype.companycode,
            //         companyname: devicetype.companyname,
            //         access: 2,
            //     };
            //     devicetypeMap.push(mapItem);
            // });
            if (nextProps.deviceTypes) {
                let deviceTypeGroup = groupBy(nextProps.deviceTypes, devicetype => devicetype.devicetypeid);
                deviceTypeGroup.forEach((val, key, map) => {
                    let deviceType = val[0];
                    let companyCode = '';
                    let companyName = '';
                    let delimiter = '';
                    val.forEach((item, index) => {
                        companyName += delimiter + item.companyname
                        companyCode += delimiter + item.companycode
                        delimiter = ', ';
                    });

                    let mapItem = {
                        devicetypeid: deviceType.devicetypeid,
                        devicetypename: deviceType.devicetypename,
                        companycode: companyCode,
                        companyname: companyName,
                        access: 2,
                    };
                    devicetypeMap.push(mapItem);
                });
            }
            if (nextProps.userDeviceTypeList) {
                // console.log('devicetypeMap :', devicetypeMap)

                if (devicetypeMap.length > 0) {
                    devicetypeMap.map((devicetype, index) => {
                        let found = nextProps.userDeviceTypeList.find((element) => {
                            return (element.devicetypeid === devicetype.devicetypeid);
                        });
                        if (found) {
                            devicetype.access = 1;
                        }
                    });
                } else {
                    // nextProps.userDeviceTypeList.forEach((item, index)=>{
                    //     let mapItem = {
                    //         devicetypeid : item.devicetypeid,
                    //         devicetypename: item.devicetypename,
                    //         companycode: '',
                    //         companyname: '',
                    //         access: 1,
                    //     };
                    //     devicetypeMap.push(mapItem);
                    // });
                }

                // --------------------------------------------------------------
                if (nextProps.companyDeviceTypes && nextProps.deviceTypes) {
                    let restDeviceTypes = nextProps.companyDeviceTypes.filter((cItem, cIndex) => (nextProps.deviceTypes.findIndex((d, i) => (cItem.devicetypeid == d.devicetypeid)) === -1));
                    nextProps.userDeviceTypeList.forEach((item, index) => {
                        if (restDeviceTypes.findIndex((rItem, ri) => (item.devicetypeid === rItem.devicetypeid)) !== -1) {
                            restUserTypeIds.push(item.devicetypeid);
                        }
                    });
                }
                // --------------------------------------------------------------

            }

        }
        return ({
            rawDeviceTypes: nextProps.deviceTypes,
            rawUserDeviceTypeList: nextProps.userDeviceTypeList,
            devicetypeMap: devicetypeMap,
            restUserTypeIds: restUserTypeIds,
        });

    }

    // componentWillReceiveProps(nextProps) {
    //      
    //      
    // // componentWillReceiveProps(nextProps, nextContext) {
    //     alert('user access form ');
    // }

    onSelectChange = (e, row) => {
        e.preventDefault();
        row.access = Number(e.target.value);
        this.setState((preState) => {
            return ({
                devicetypeMap: preState.devicetypeMap,
            });
        }
        );
    }

    onGroupSelectChange = (e, row) => {

    }

    cellAuthSelection(cell, row, enumObject, index) {
        return (
            <select
                name={`${index},user_role`}
                // value={this.state.data[index]['user_role']}
                onChange={(e) => this.onSelectChange(e, row)}
                value={cell}
            >
                {/*<option value='RO'><Trans i18nKey='read-access'>Read</Trans></option>*/}
                {/*<option value='RW'><Trans i18nKey='rw-access'>Read and Write</Trans></option>*/}
                {/* <option value=''>None Selected</option>
                <option value='RO'>Read</option>
                <option value='RW'>Read and Write</option> */}
                {/* <option value={0}>None Selected</option> */}
                <option value={1}>Allow</option>
                <option value={2}>Deny</option>
            </select>
        );
    }

    cellGroupSelection = (cell, row, enumObject, index) => {

        return (
            // <select
            //     name={`${index},user_group`}
            //     value={this.state.groupdata[index]['user_group']}
            //     onChange={this.onSelectChange}
            // >
            //     {/*<option value='NO'><Trans i18nKey='headquarter'>HQ</Trans></option>*/}
            //     {/*<option value=''><Trans i18nKey='none-selected'>None Selected</Trans></option>*/}
            //     <option value=''>None Selected</option>
            //     <option value='NO'>HQ</option>
            // </select>

            <input className='react-bs-select-all'
                type='checkbox'
                // name={`${index},user_group`}
                name={`${row.id},user_group`}
                id={'checkbox' + this.props.rowIndex}
                // checked={this.state.groupdata[index]['user_group']}
                checked={cell}
                onChange={this.onGroupSelectChange} />

        );
    }

    onSubmit = () => {
        if (sessionStorage.getItem('currentUserObj')) {
            try {
                // let userObj = JSON.parse(sessionStorage.getItem('currentUserObj'));
                let devicetypeids = [];
                this.state.devicetypeMap.map((item, index) => {
                    if (item.access === 1) {
                        devicetypeids.push(item.devicetypeid);
                    }
                });
                let mapObj = {
                    userId: this.props.userId,
                    devicetypeid: devicetypeids.concat(this.state.restUserTypeIds),
                };
                this.props.upsertUserDevicetypeList(mapObj);
            } catch (e) {
                console.error(e);
            }

        }

        // this.props.upsertUserAssetclassList(mapObj);
        // this.props.getUserAssetclassList("test123");
        // this.props.removeUser("test52347");
        this.props.toggle();
    }

    onClose = () => {
        // alert('inside');
        this.props.toggle();
    }

    render() {
        let data = [];
        // data = this.props.modules.groupPayload;
        const options = {
            // page: 1,  // which page you want to show as default
            // sizePerPageList: [ {
            //   text: '5', value: 5
            // }, {
            //   text: '10', value: 10
            // }, {
            //   text: '15', value: 15
            // } ], // you can change the dropdown list for size per page
            // sizePerPage: 5,  // which size per page you want to locate as default
            // pageStartIndex: 1, // where to start counting the pages
            // paginationSize: 5,  // the pagination bar size.
            // prePage: '<', // Previous page button text
            // nextPage: '>', // Next page button text
            // firstPage: '<<', // First page button text
            // lastPage: '>>', // Last page button text
        };
        return (
            <div>
                <br />
                <BootstrapTable data={this.state.devicetypeMap} options={options} height={400}>
                    <TableHeaderColumn dataField='devicetypename'>
                        {/* <Trans i18nKey="system">System</Trans> */}
                        Device Type Name
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='devicetypeid' isKey={true}>
                        <Trans i18nKey="id">Device Type ID</Trans>
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='companyname'>
                        Company Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        // dataField='user_role'
                        dataField='access'
                        dataFormat={this.cellAuthSelection.bind(this)}
                    >
                        <Trans i18nKey="action">Access</Trans>
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                                dataField='user_group'
                                dataFormat={this.cellGroupSelection.bind(this)}
                            >
                                <Trans i18nKey="action">Group</Trans>
                            </TableHeaderColumn> */}
                </BootstrapTable>
                <br />
                <hr />
                <div class="d-flex justify-content-end">
                    <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.state.devicetypeMap || this.state.devicetypeMap.length === 0} >
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onClose}>
                        Close
                    </Button>
                </div>
            </div>
        );
    }
}

// export default UserAccessForm;

const mapStateToProps = (state) => {
    return state.user;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUser, getUserDevicetypeList, upsertUserDevicetypeList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDeviceTypeAccessForm);
