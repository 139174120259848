/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label } from 'recharts';
import { renderSpinner } from './PeopleCountBoard';
import moment from 'moment';
import Select from 'react-select';
import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { groupByArray } from '../../../../factories/utils';
import { Camera } from '@material-ui/icons';
import { getCurrentUser, hasRole } from '../../../../factories/auth';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from 'react-bootstrap-table-next';
import VideoMpegPlayer from '../VideoMpegPlayer';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ReactPlayer from '../ReactPlayer';
import { Client } from '@stomp/stompjs';
// import './SmartBuilding.css'




export default function IndividualCamChart({localData}) {
    // const [LocalData, setLocalData] = useState(localData)
    const User = getCurrentUser();
    const [CameraChannel, setCameraChannel] = useState(null);
    const [NVRChannel, setNVRChannel] = useState(null)
    const [FilterDate, setFilterDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
    const [SelectStyle, setSelectStyle] = useState({
        menu: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor:"pointer",
            zIndex: 9999
        }),
        option: (provided, state) => ({
            ...provided,
            color: "black",
            width: "100%",
            cursor:"pointer",
            '&:active': { backgroundColor: user.detail.appButtonColorHover },
            backgroundColor: state.isFocused ? user.detail.appButtonColorHover : "white",
        }),
        control: (base, selectState) => ({
            ...base,
            // height: 35,
            // minHeight: 35
            width:"100%",
            height: 35,
            minHeight: 35,
            cursor:"pointer",
            borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor,
            // This line disable the blue border
            boxShadow: selectState.isFocused ? 0 : 0,
            '&:hover': { borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor }
        }),
        indicatorsContainer: (base, selectState) => ({
            ...base,
            padding: "0",
        }),
    })
    const [SelectedCam, setSelectedCam] = useState(null)
    const [CameraOptions, setCameraOptions] = useState(null)
    const [CamData, setCamData] = useState(null)
    const [FilteredCamData, setFilteredCamData] = useState(null)
    const user = getCurrentUser();
    useEffect(() => {
        let groupedDataByCam
        if(localData &&  localData.length > 0){
            let reduceCamDataArr = [...localData]
            //extract camera name for select
            reduceCamDataArr.map(data=>{
                data.cameraName = data.camera.name;
                return data
            })
            // console.log('REDUCED CAM', reduceCamDataArr)
            groupedDataByCam = groupByArray(reduceCamDataArr, 'cameraName')
            console.log('GROUPED BY CAM, ', groupedDataByCam)
            let tempOptions = [] //labels for select cameras
            let tempData = [] //data grouped by cameras
            for(const[key,value] of Object.entries(groupedDataByCam)){
                let dataOptions = {label: key, data: key}
                let data = {camera: key, data: value}
                tempOptions.push(dataOptions)
                tempData.push(data)
            }
            setCameraOptions(tempOptions)

            setCamData(tempData)        
        }
    }, [localData])
    // useEffect(() => {
    //     if(CameraOptions){
    //         let temp = CameraOptions.filter(data=>data.label == SelectedCam)[0];
    //         console.log('FILTERED DATA', temp)
    //         // setCamData(temp.map(data=>({
    //         //     ...data,
    //         //     inPremises: data.entranceCount - data.exitCount  < 0 ?  0 : data.entranceCount - data.exitCount
    //         // })));
    //     }
    // }, [SelectedCam])

    useEffect(() => {
        if(CamData && FilterDate && SelectedCam){
            // console.log('CAM DATA', CamData, SelectedCam)
            let filteredByCam = CamData.filter(data=>data.camera == SelectedCam)
            // console.log('FILTERED BY CAM, ', filteredByCam)
            if(filteredByCam && filteredByCam.length > 0){

                let filteredByTime = filteredByCam[0].data.filter(data=>data.time.includes(FilterDate))
                filteredByTime = groupByArray(filteredByTime, "time");
                // console.log("GROUPED BY TIME", filteredByTime)
                let temp = [];
                for(const[key,value] of Object.entries(filteredByTime)){
                    temp.push(value[value.length-1])
                }
                temp.map(el=>{
                    el.inPremises = el.entranceCount - el.exitCount < 0 ? 0 : el.entranceCount - el.exitCount
                })
                if(temp.length > 0 ){
                    setCameraChannel(temp[0].cameraChannel)
                    setNVRChannel(temp[0].nvr)
                } 
                
                // console.log('REDUCED, ', temp)
                setFilteredCamData(temp)
            }
        }
    }, [FilterDate, CamData, SelectedCam])


    const handleCameraChange = (value)=>{
        if(value){
            console.log(value);

            setSelectedCam(value.label) //camera name e.g IPC1
            //Calculate stay count and apply filter date
            // let tempData = [...value.data]
            // console.log('NVR CHANNEL', tempData[0].cameraChannel, tempData[0].nvr)
            // setCameraChannel(tempData[0].cameraChannel)
            // setNVRChannel(tempData[0].nvr)
            // tempData.map(el=>{
            //     el.inPremises = el.entranceCount - el.exitCount  < 0 ?  0 : el.entranceCount - el.exitCount
            // })
            // console.log('TEMP DATA', tempData)
            
            // setCamData(tempData)
        }
    }
    const handleDateChange = (date =>{
        date = moment(date).format('MM-DD-YYYY')
        setFilterDate(date)
    })

    const renderTable = (data) =>{
        let currentData
        if(data){
            // console.log('Table data', data)
            currentData = data[data.length - 1]
        }
        const columns=[
            {dataField: 'entranceCount', text: 'Entrance Count'},
            {dataField: 'exitCount', text: 'Exit Count'},
            {dataField: 'inPremises', text: 'No. of People at Premise', style:{fontWeight:'bold', fontSize:'1.25em', color: "#ff3300"}},
            {dataField: 'timestamp', text: 'Last Updated Date'},
        ]
        if(currentData){
            console.log('Table data', currentData)
            return (
                <table className="stat-table">
                <tr>
                  <th>Entrance Count</th>
                  <td>{currentData.entranceCount}</td>
                </tr>
                <tr>
                  <th>Exit Count</th>
                  <td>{currentData.exitCount}</td>
                </tr>
                <tr>
                  <th>NO. of People at Premises</th>
                  <td style={{fontWeight: "bold", color: "#33cc33"}}>{currentData.inPremises}</td>
                </tr>
                <tr>
                  <th>Last Updated date</th>
                  <td>{moment(currentData.timestamp).utc().subtract(4, 'hours').format('MM-DD-YYYY HH:mm:ss')}</td>
                </tr>
              </table>
                // <BootstrapTable striped={true} data={currentData} keyField='id' columns={columns} filter={filterFactory()}/>
            )
        }
    }
    return (
        <>
        <Row>
            <Col md={6}>
                <p className="footage-header">Real Time Footage</p>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <Select
                    // isClearable
                    isSearchable
                    // options = {this.state.assets} //assetList
                    options={CameraOptions}
                    // defaultValue={DefaultValue}
                    placeholder={'Select Camera ...'}
                    onChange={handleCameraChange}
                    styles={SelectStyle}
                />
            </Col>
            <Col md={6}>
                {User.role[0] == 'SUPERADMIN' && <Button color="warning">Reset</Button>}
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                {/* <VideoMpegPlayer ch={NVRChannel} chid={CameraChannel}/> */}
                <ReactPlayer
                    ch={NVRChannel}
                    chid={CameraChannel}
                    height="400px"
                    width="100%"/>
            </Col>
            <Col md={6}>
                {FilteredCamData ? renderTable(FilteredCamData): ''}
            </Col>
        </Row>

        <Row className="cam-chart">
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker 
                    autoOk
                    inputVariant="outlined"
                    label="Choose Date"
                    onChange={date=>handleDateChange(date)}    
                    variant="inline"
                    format="MM/DD/YYYY"
                    InputAdornmentProps={{ position: "start" }}
                    value={FilterDate}
                    maxDate={new Date()}
                />
            </MuiPickersUtilsProvider>
            <ResponsiveContainer height={250} width="98%" maxWidth={300}>
                <LineChart
                    data={FilteredCamData? FilteredCamData: null}
                    width={250} height={230}
                    >
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <XAxis dataKey="timeLabel" />
                        <Label value="Pages of my website" offset={0} position="insideBottom" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="entranceCount" legendType="circle" stroke="#0066ff" />
                        <Line type="monotone" dataKey="exitCount" legendType="circle" stroke="#ff3300" />
                        <Line type="monotone" dataKey="inPremises" stroke="#33cc33" legendType="circle" strokeWidth={3} />
                </LineChart>
            </ResponsiveContainer>
        </Row>
        </>
    )
}