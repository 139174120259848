/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Card, CardBody, Col, Row, Button, Container, Collapse } from 'reactstrap';
import { Trans } from "react-i18next";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
// import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import '../../../scss/component/react-bs-table.scss'

import PlusIcon from 'mdi-react/PlusIcon';
import { EditTextButton, DeleteTextButton } from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
// import {getCurrentUser, hasRole} from '../../../factories/auth';
import {
    getNotificationTemplate,
    upsertNotificationTemplate,
    deleteNotificationTemplate,
    getContact,
    upsertContact,
    deleteContact,
} from '../../../redux/actions/jobAction';
import NotificationTemplateModal from '../components/TemplateModal';
import { toast } from "react-toastify";
import moment from 'moment';



class NotificationTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            templateObj: null,
        };
    }

    componentDidMount() {
        this.props.getNotificationTemplate();
        this.props.getContact();
    }

    // -------------------------------------------------------------------------------------
    handleRowEditClick = (row) => {
        // this.props.editContact(row.id);


        if (this.props.notificationTemplateList) {
            console.log('this.props.notificationTemplateList is true')
            let selectedTemplate = this.props.notificationTemplateList.find((item, index) => (item.id == row.id));
            //  
            if (selectedTemplate) {
                console.log('selectedTemplate is true')
                this.setState({ templateObj: selectedTemplate }, () => { this.toggle(); });
            }
        }
    }

    handleRowDeleteClick = (row) => {
        // this.props.deleteContact(row.id);
        this.props.deleteNotificationTemplate(row.id);
    }

    tableActionColFormater = (cell, row) => {
        return (
            <div>
                <Fragment>
                    <EditTextButton label={''} onClick={(e) => { this.handleRowEditClick(row); }} />{' '}
                    {/* <ConfirmDeleteDialog 
                        label={""} 
                        onConfirm={(e)=>{this.handleRowDeleteClick(row);}}
                        msg={`Do you want to delete the select Contact ${row.name} ?`}
                    />                     */}
                </Fragment>

            </div>
        )
    }

    tableDeleteColFormater = (cell, row) => {
        return (
            <div>
                <Fragment>
                    <ConfirmDeleteDialog
                        label={""}
                        onConfirm={(e) => { this.handleRowDeleteClick(row); }}
                        msg={`Do you want to delete the select Contact ${row.name} ?`}
                    />
                </Fragment>

            </div>
        )
    }


    handleAddNewButtonClick = (onClick) => {
        let templateObj = {
            "id": 0,
            "contactid": '',
            "destination": '',           //String     
            "title": '',//String
            "content": '',//String

            "weekday": '', //String not use
            "starttime": '00:00:00',//Time not use
            "endtime": '00:00:00',//Time not use

            "owner": '',//not changeable
            "companycode": '',//not changeable
            "createdtime": '' //not changeable
        }
        this.setState({ templateObj }, () => { this.toggle(); })
    }

    addNewUserButton = (onClick) => {
        const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        return (

            <InsertButton
                //   btnText='Add Company'
                btnContextual='btn-success'
                btnGlyphicon='glyphicon-edit'
                //   color='success'
                onClick={() => this.handleAddNewButtonClick(onClick)}
            //   disabled = {!check}
            >
                {/* <PlusCircleOutlineIcon size={100}/> */}
                {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
                <PlusIcon /> Add New Template
            </InsertButton>
        );
    }

    renderNotificationList = () => {
        // const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        const options = {
            insertBtn: this.addNewUserButton,
        };
        return (

            <Card>
                <CardBody>
                    <BootstrapTable
                        data={this.props.notificationTemplateList}
                        tableHeaderClass='header_white'
                        // tableHeaderClass = 'react-bs-table'
                        // tableBodyClass ='header_white'
                        pagination={false}
                        options={options}


                        search
                        insertRow={true}
                        bordered={false}
                        maxHeight={500}
                    >
                        <TableHeaderColumn dataField='id' isKey={true} hidden >ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='name' dataSort >Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='companyname' dataSort >Network</TableHeaderColumn>
                        <TableHeaderColumn dataField='companycode' dataSort hidden>Company Code</TableHeaderColumn>
                        <TableHeaderColumn dataField='owner' dataSort >Created By</TableHeaderColumn>
                        <TableHeaderColumn dataField='createdtime' dataSort  >Created Time</TableHeaderColumn>
                        <TableHeaderColumn dataAlign="center" width="10%" dataFormat={this.tableActionColFormater} >Edit</TableHeaderColumn>
                        <TableHeaderColumn dataAlign="center" width="10%" dataFormat={this.tableDeleteColFormater} > Delete </TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        )
    }

    toggle = () => {

        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }))
    }

    handleContactChange = (val) => {
        if (val) {
            this.setState(prevState => {
                if (prevState.templateObj) {
                    // let contactList = val.map((item, index) => (item.id)).join(',');
                    let contactList = val.map((item, index) => (item.id));
                    prevState.templateObj.contactid = JSON.stringify(contactList);
                    return ({
                        templateObj: prevState.templateObj,
                    });
                } else {
                    return null;
                }

            });
        }
    }

    handleTimeInputChange = (e) => {
        // let val = e.target.value;
        // let key = e.target.name;
        // this.setState(prevState => {
        //     if (prevState.templateObj){
        //         prevState.templateObj[key] = val
        //         return ({
        //             templateObj: prevState.templateObj,
        //         });
        //     }
        //     else{
        //         return null;
        //     }
        // });
    }

    handlePeriodsSelect = (val) => {
        this.setState(prevState => {
            if (prevState.templateObj) {
                prevState.templateObj.weekday = val
                return ({
                    templateObj: prevState.templateObj,
                });
            }
            else {
                return null;
            }
        });
    }

    handleInputChange = (e) => {
        let val = e.target.value;
        let key = e.target.name;
        this.setState(prevState => {
            if (prevState.templateObj) {
                prevState.templateObj[key] = val
                return ({
                    templateObj: prevState.templateObj,
                });
            }
            else {
                return null;
            }
        });
    }

    validateTimeRange = (starttime, endtime) => {
        let reVal = false;
        try {
            if (starttime && endtime) {
                let startMoment = new moment(starttime, 'HH:mm:ss');
                let endMoment = new moment(endtime, 'HH:mm:ss');
                reVal = endMoment.isAfter(startMoment) || endMoment.isSame(startMoment);
                //  
                //  
                //  
                //  
                //  
            }
        }
        catch (e) {
            reVal = false;
        }

        return reVal;
    }

    onSubmit = () => {

        if (this.state.templateObj) {
            this.setState(prevState => {
                let timeRegex = /^\d{2}[:]\d{2}$/;
                if (!prevState.templateObj.starttime || prevState.templateObj.starttime.length === 0) {
                    prevState.templateObj.starttime = '00:00:00';
                } else if (timeRegex.test(prevState.templateObj.starttime)) {
                    prevState.templateObj.starttime += ':00';
                }

                if (!prevState.templateObj.endtime || prevState.templateObj.endtime.length === 0) {
                    prevState.templateObj.endtime = '00:00:00';
                } else if (timeRegex.test(prevState.templateObj.endtime)) {
                    prevState.templateObj.endtime += ':00';
                }


                return ({
                    templateObj: prevState.templateObj,
                })
            }, () => {
                if (this.state.templateObj && this.validateTimeRange(this.state.templateObj.starttime, this.state.templateObj.endtime)) {
                    this.props.upsertNotificationTemplate(this.state.templateObj);
                    this.toggle();
                } else {
                    toast.error('Time Range is not valid.');
                }

            });

        }
    }

    render() {
        return (
            <Col md={12}>
                <Collapse isOpen={!this.state.showModal}>
                    {/* <Card>
                    <CardBody> */}
                    {this.renderNotificationList()}
                    {/* </CardBody>
                </Card> */}
                </Collapse>
                <Collapse isOpen={this.state.showModal}>
                    {this.state.showModal &&
                        <NotificationTemplateModal
                            showModal={this.state.showModal}
                            toggle={this.toggle}
                            contactList={this.props.contactList}
                            upsertContact={this.props.upsertContact}
                            deleteContact={this.props.deleteContact}
                            templateObj={this.state.templateObj}
                            handleContactChange={this.handleContactChange}
                            // handleTimeInputChange = {this.handleTimeInputChange}
                            handlePeriodsSelect={this.handlePeriodsSelect}
                            handleInputChange={this.handleInputChange}
                            onSubmit={this.onSubmit}
                        />
                    }
                </Collapse>
            </Col>
        )
    }
}

const mapStateToProps = state => {
    return state.job;
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getNotificationTemplate,
        upsertNotificationTemplate,
        deleteNotificationTemplate,
        getContact,
        upsertContact,
        deleteContact,
    }, dispatch);
};

// const enhance = compose(
//     connect(mapStateToProps, mapDispatchToProps),
//     withStyles(styles),
//     // withRouter
// );


export default connect(mapStateToProps, mapDispatchToProps)(NotificationTemplateList);
