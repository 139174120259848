/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';
import {
    // loadDevice, 
    loadAssetClass,   
} from '../../../redux/actions/mapActions';
import {
    getAssetList,
} from '../../../redux/actions/dashboardActions';
import {
    getAttributes,
} from '../../../redux/actions/attributeMgmtAction';

class AttrParamEditModal extends Component {
    constructor(props){
        super(props);
        this.state={
            tital: 'Dashboard Element',
            modalList: this.props.modalList,
            devices: [],
            currentAssetClassId: null,
            currentAssetId: null,
            currentDeviceId: null,
            // selectedDeviceTypeId: null,
            // modal: true,
        }
    }

    componentDidMount(){
        // this.props.loadDevice();
        this.props.loadAssetClass();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.layout && nextProps.layout !== this.state.layout){ //&& nextProps.layout !== this.state.layout
            if (nextProps.layout.parameter){
                let parameter = JSON.parse(nextProps.layout.parameter);
                if (parameter.assetid){
                    if (nextProps.assetClasses) {
                        let isBreak = false;
                        for (let i = 0; i < nextProps.assetClasses.length; i++) {
                            let assetclass = nextProps.assetClasses[i];
                            if (assetclass.assets) {
                                for (let j = 0; j < assetclass.assets.length; j++) {
                                    let asset = assetclass.assets[j];
                                    if (asset.assetid == parameter.assetid) {
                                        this.setState({
                                            currentAssetClassId: assetclass.assetclassid,                       
                                        }, ()=>{
                                            this.props.getAssetList({assetclassid: this.state.currentAssetClassId});
                                        });
                                        isBreak = true;
                                        break;
                                    }
                                }
                                if (isBreak) {
                                    break;
                                }
                            }
                        }
                    }
                    this.setState({
                        currentAssetId: parameter.assetid,                       
                    });
                }
                if(parameter.deviceid){
                    this.setState({ 
                        currentDeviceId: parameter.deviceid,
                    }, ()=>{
                         
                        this.setAttrList();
                    });
                }
                if (parameter.attrid){
                    this.setState({
                        currentAttrId: parameter.attrid,
                    });
                }
                if(parameter.attrname){
                    this.setState({ 
                        currentAttrName: parameter.attrname,
                    });
                }
            }
            
            this.setState({
                currentName: nextProps.layout.name,
                layout: nextProps.layout,
                currentParameter: nextProps.layout.parameter,
            });
        }

        this.setDeviceList(nextProps.assets);
       
    }

    handleInputChange =(newVal) => {
        this.setState((prevState)=>{
            if (prevState.layout){
                prevState.layout.name = newVal;
                return ({
                    currentName: newVal,
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        });
    }

    renderTitle =()=> {
        return(
            
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                         type='text'
                         defaultValue={this.props.layout ? this.props.layout.name : ''} 
                         exitOnEnterKeyPress={false}
                         onSave={newVal => { this.handleInputChange(newVal)}}
                    />
                </Col>
            </Row>           
        )
    }

    onAssetClassChange = (e) => {
         
        let assetClassId = e.target.value;
        this.setState({
            currentAssetClassId: assetClassId,
            currentAssetId: null,
            currentDeviceId: null,
            devices: [],
        });
        this.props.getAssetList({assetclassid: assetClassId});
    }

    renderAssetClass = () => {
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="assetclass" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="assetclass" name="assetclass" width="100%" value={this.state.currentAssetClassId} onChange={this.onAssetClassChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={0}>NONE</option> 
                        {this.props.assetClasses && this.props.assetClasses.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }

    onAssetChange = (e) => {
        let assetId = e.target.value;
        this.setState({
            currentAssetId: assetId,
            currentDeviceId: null,
        }, () => {
            this.setDeviceList(this.props.assets);
        });
    }

    renderAsset = () => {
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="asset" name="asset" width="100%" value={this.state.currentAssetId} onChange={this.onAssetChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={0}>NONE</option> 
                        {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }

    onDeviceChange = (e) =>{
        let deviceId = e.target.value;
        this.setState({
            currentDeviceId: deviceId,
        },() =>{
            this.setAttrList();
        });
    }

    setDeviceList = (assets) => {
        // let devices = this.state.devices ? this.state.devices : [];
        if (assets && this.state.currentAssetId) {
            let assetObj = assets.find((asset)=>(asset.assetid == this.state.currentAssetId));
            if (assetObj) {
                let devices = assetObj.devices;
                this.setState({
                    devices: devices,
                }, () =>{
                     
                    this.setAttrList();
                });
            }
        }
       
    }

    renderDevice = () => {
        //  
        // let devices = [];
        // if (this.props.assets && this.state.currentAssetId) {
        //     let assetObj = this.props.assets.find((asset)=>(asset.assetid == this.state.currentAssetId));
        //     if (assetObj) {
        //         devices = assetObj.devices;
        //     }
        // }
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="device" style={{display: 'inline', width: "100%"}} ><h4><b>Device</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="device" name="device" width="100%" value={this.state.currentDeviceId} onChange={this.onDeviceChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={null}>NONE</option> 
                        {/* {devices.map((device, i) => (<option key={device.deviceid} value={device.deviceid}>{device.devicename}</option>))} */}
                        {this.state.devices.map((device, i) => (<option key={device.deviceid} value={device.deviceid}>{device.devicename}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }

    setAttrList = () => {
         
        if (this.state.devices && this.state.currentDeviceId && this.state.currentDeviceId != this.state.preiousDeviceId) {
            let deviceObj = this.state.devices.find((device)=>(device.deviceid == this.state.currentDeviceId));
            if (deviceObj) {
                this.setState({preiousDeviceId: this.state.currentDeviceId});
                this.props.getAttributes(deviceObj.devicetype);
            }
        }
    }

    onAttrChange = (e) => {
         
        let index = e.target.selectedIndex
        this.setState({
            currentAttrId: e.target.value,
            currentAttrName: e.target.options[index].text
        });
    }

    renderAttribute = () => {
         
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="attribute" style={{display: 'inline', width: "100%"}} ><h4><b>Attribute</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="attribute" name="attribute" width="100%" value={this.state.currentAttrId} onChange={this.onAttrChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={null}>NONE</option> 
                        {/* {devices.map((device, i) => (<option key={device.deviceid} value={device.deviceid}>{device.devicename}</option>))} */}
                        {this.props.attributes && this.props.attributes.map((attr, i) => (<option key={attr.attributeid} value={attr.attributeid}>{attr.attributename}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }

    onCancleClick = (e) => {
        this.props.toggle();
    }
    onOkClick = (e) => {
        this.setState((prevState)=>{
            if (prevState.layout){
                let parameter = {
                    assetid: prevState.currentAssetId,
                    deviceid: prevState.currentDeviceId,
                    attrid:prevState.currentAttrId,
                    attrname:prevState.currentAttrName,
                }
                prevState.layout.parameter = JSON.stringify(parameter);
                return ({
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        }, ()=>{
            this.props.onEditSubmit(this.state.layout);
        });
        // this.props.onEditSubmit(this.state.layout);
        this.props.toggle();
    }

    render(){
        return(
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                size = {'lg'}
            >
                <ModalHeader 
                    toggle={this.props.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    {this.renderTitle()}
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {/* {this.props.layout && this.renderParameterInput(this.props.layout.type)} */}
                            {this.renderAssetClass()}
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAsset()}
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderDevice()}
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAttribute()}
                        </Col>
                    </Row>
                    {/* <br/> */}
                
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

// export default AttrParamEditModal;

function mapStateToProps(state) {
    // const types = state.mapboard.types;
    const assets = state.dashboard.assetList;
    const assetClasses = state.mapboard.assetClass;
    const attributes = state.attributemgmt.attributes;
    const props = {
        // types,  
        assetClasses,
        assets,
        attributes,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            // loadDevice, 
            loadAssetClass,
            getAssetList,
            getAttributes,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AttrParamEditModal);