/* eslint-disable */
import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
// import { useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
// import { browserHistory } from 'react-router';
import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
// import { compose } from 'redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { config as i18nextConfig } from '../../translations';
import log from 'loglevel';
import { getCurrentUser } from '../../factories/utils'

i18next.init(i18nextConfig);
toast.configure();
log.setLevel("debug");
// log.setLevel("warn");

axios.interceptors.response.use((response) => {
  //
  return response;
}, (error) => {
  if (sessionStorage.getItem("jToken") != null) {
    if (error.response && (error.response.status === 417 || error.response.status === 401)) {
      var com_name=localStorage.getItem("com_name");
      if(com_name != null && com_name != 'null'){
        toast.error('The token has expired or is not valid, please log in again');
        window.location.href = '/'+ com_name + '/#/log_out';
      }else {
        toast.error('The token has expired or is not valid, please log in again');
        window.location.href = '/#/log_out';
      }

      //window.location.href =  "/#/log_out";
      // window.location.href = process.env.REACT_APP_URL_ROOT + "#/log_out";
      // window.history.push('/log_out');
      // let history = createBrowserHistory();
      // history.push("/#/log_out");
      // --------------------------
      //let history = useHistory();
      // let history = createBrowserHistory({basename: process.env.REACT_APP_URL_ROOT});
      //
      //
      //
      // history.push(`${process.env.REACT_APP_URL_ROOT}#/log_out`);
      // browserHistory.push('/log_out');
      // var element = document.createElement("div");
      //
      // ReactDOM.render(
      //   <Redirect to="/log_out" push={true} />, element
      // );
      // --------------------------
    }
  }
  return Promise.reject(error);
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
    });
  }

  render() {
    const { loaded, loading } = this.state;
    const storeState = store.getState();


    return (
      <Provider store={store}>
        <HashRouter basename='/'>
          <I18nextProvider i18n={i18next}>
            <ScrollToTop>
              <div>
                {!loaded &&
                  <div className={`load${loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                      </svg>
                    </div>
                    {/* <ToastContainer
                    position="top-center"
                    hideProgressBar={true}
                  /> */}
                  </div>
                }
                <Router user={storeState.user} />
              </div>
            </ScrollToTop>
          </I18nextProvider>
        </HashRouter>
      </Provider>
    );
  }
}

export default hot(module)(App);

