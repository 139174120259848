/* eslint-disable */
import React, { Component, PureComponent, Fragment } from 'react';
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Col, Container, Row } from 'reactstrap';
import { NavTabs } from '../../Map/components/MainBoard';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import VideoPlayer from './VideoPlayer';
import VideoMpegPlayer from './VideoMpegPlayer';
import VideoModalIcon from './VideoModalIcon';
import { RelativeScaleIcon } from 'mdi-react';
import { VIDEO_ROOT, WEB_SUB_CHANNEL, WEB_SUB_EVENT } from './../../../constants/appConstants';
import CameraMapDisplay from './camera map/CameraMapDisplay';
import CameraListDisplay from './cameraList/CameraListDisplay';
import { loadAssets, loadClasses, postAssetAttribute } from '../../../redux/actions/assetActions';
import { loadAssetsSurveillance, loadLicensePlateRecords } from '../../../redux/actions/surveilenceActions';
import { loadDevice, loadDeviceInfo, getAssetClassAttributeAjax } from '../../../redux/actions/mapActions';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { bindActionCreators } from 'redux';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Menu, MenuItem, Typography, Button, styled} from '@material-ui/core';
import NestedMenuItem from "material-ui-nested-menu-item";
import office_img from '../../../../src/shared/img/Office_Layout.jpg';
import LicensePlateRecordsDisplay from './licensePlateRecords/LicensePlateRecordsDisplay';
import FaceRecordsDisplay from './faceRecognitionRecords/FaceRecordsDisplay';
import FaceDetectionDisplay from './faceDetection/FaceDetectionDisplay';
import PeopleCountDisplay from './peopleCountRecords/PeopleCountDisplay';
import { loadPeopleCountRecords } from '../../../redux/actions/surveilenceActions';
import  PeopleCountBoard from './peopleCountRecords/PeopleCountBoard';
import classnames from 'classnames';
import NvrDisplay from "./nvr/NvrDisplay";
import MainBoard from '../../Opg/components/MainBoard';
import { withRouter } from 'react-router-dom';
// import SensorMonitoring from '../components/sensorMonitoring/SensorMonitoring'
import _ from 'lodash';
import  memoize  from 'memoize-one';




// const WS_ROOT = 'wss://wssdemo.itsp-inc.com';
const CustomizedBtn = styled(Button)({
    backgroundColor: '#e6e6e6',
    color: 'var(--headerFontColor)',
    fontSize: '20px',
    border: 'none',
    padding: '0 15px',
    margin: '0 0 15px 10px',
    boxShadow: '2px 3px 2px 1px #cccaca',
    "&:hover": {
        backgroundColor: 'var(--appButtonColor)',
        opacity: 40
    }
    
    
})
class SurveillanceMain extends PureComponent {
    constructor(props) {
        super(props);
        this.eventSource = new EventSource(WEB_SUB_CHANNEL);
        this.state = {
            activeTab: '1',
            // pubSubData: null,
            menuPosition: null,
            pathItems: null,
            selectedLayoutId: null,
            layoutImg: null,
            showPeopleCount: false,
            selectedLayout: null,
            showFacialRecognition: true,
            extraTabs: [],
            activeFloorTab: '1',
        }
        this.userObj = getCurrentUser();
        this.handleTreeItemClick = this.handleTreeItemClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
    }

    



    
    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    handleFloorTabClick = (tab)=>{
        if(this.state.activeFloorTab !== tab){
            this.setState({activeFloorTab: tab})
        }
    }


    // togglePeopleCount =  () => {
    //     this.setState({showPeopleCount: !this.state.showPeopleCount})
    // }

    componentDidMount() {
        // this.eventSource.addEventListener(WEB_SUB_EVENT, (e)=> {
        //     console.log('camera pubsub ', e.data)
        //     this.pubSubUpdate(e.data);
        // });
        this.props.loadAssets();
        this.props.loadAssetsSurveillance();
        this.props.loadClasses();
        this.props.loadDevice();
        this.props.loadDeviceInfo();
        // this.props.loadPeopleCountRecords();


    }

    static getDerivedStateFromProps(props, state){

    }

    componentDidUpdate(prevProps, prevState) {
        // Object.entries(this.props).forEach(([key,val])=>{
        //     if (!_.isEqual(val, prevProps[key])){
        //         console.log(`Prop '${key}' changed....`)
        //     }
        // })
        // Object.entries(this.state).forEach(([key,val])=>{
        //     if (!_.isEqual(val, prevState[key])){
        //         console.log(`State '${key}' changed...`)
        //     }
        // })
        if((!_.isEqual(prevProps.allAssets, this.props.allAssets) && this.props.allAssets) || !_.isEqual(prevProps.selectedPlace, this.props.selectedPlace)){
            let layoutAssetList = this.props.allAssets.filter(asset=>asset.assetclassname.includes("Layout"))
            // console.log("LAYOUT LIST...", layoutAssetList)
            let initialAsset = this.props.selectedPlace ? layoutAssetList.filter(asset=>asset.assetname == this.props.selectedPlace.assetname)[0] : layoutAssetList[0]
            let defaultUserLayoutId = initialAsset ? initialAsset.assetid : null;
            let filterDefaultLayouts = layoutAssetList.filter(asset => asset.assetid == defaultUserLayoutId);
            let defaultLayout = filterDefaultLayouts.length > 0 ? filterDefaultLayouts[0] : null
            let floorPlanImg
            if (defaultLayout && defaultLayout.assetattributes.length > 0 && defaultLayout.assetattributes.filter(attribute=>attribute.attmetaname == "Image").length > 0){
                floorPlanImg = defaultLayout.assetattributes.filter(attribute=>attribute.attmetaname == "Image")[0].value
            }
            this.setState({
                ...this.state,
                selectedLayoutId: defaultUserLayoutId,
                layoutList: layoutAssetList,
                treeAssetList: recursiveAssetList([...this.props.allAssets]),
                selectedLayout: defaultLayout,
                layoutImg: floorPlanImg ? floorPlanImg : office_img
            })
        }

        if(!_.isEqual(prevProps.selectedFloor, this.props.selectedFloor) && this.props.selectedFloor){
            let activeFloor = this.props.selectedFloor + 1
            this.setState({
                ...this.state,
                activeFloorTab: activeFloor + ''
            })
        }



        //Set a default layout to display on the map
        // if (prevState.selectedLayoutId == null && (this.props.allAssets && !_.isEqual(this.props.allAssets, prevProps.allAssets))) {
        //     // console.log('LAYOUT LIST, ', this.props.layoutList)
        //     //initial asset rendered will be either the clicked layout asset on dashboard map, or set to be first layout asset found
        //     // console.log("SELECTED PLACE, ", prevProps.selectedPlace, this.props.layoutList)
        //     let layoutAssetList = this.props.allAssets.filter(asset=> asset.assetname.includes("Layout"))
        //     let initialAsset = prevProps.selectedPlace ? layoutAssetList.filter(asset => asset.assetname == prevProps.selectedPlace.assetname)[0] : layoutAssetList[0];
        //     let defaultUserLayoutId = initialAsset ? initialAsset.assetid : null;
        //     let selectedLayout = this.props.allAssets.filter(asset => asset.assetid == defaultUserLayoutId);
        //     let activeFloor = this.props.selectedFloor + 1;
        //     //console.log("INITIAL ASSET, ", initialAsset, this.props.selectedFloor, activeFloor)
        //     //console.log('SELECTED LAYOUT')
        //     console.table(selectedLayout)
        //     let selectedImg = selectedLayout && selectedLayout[0].assetattributes.length > 0 ? selectedLayout[0].assetattributes.filter(attribute => attribute.attmetaname == "Image") : null
        //     this.setState({
        //         selectedLayoutId: defaultUserLayoutId,
        //         layoutImg: selectedImg ? selectedImg[0].value : office_img,
        //         showPeopleCount: selectedLayout[0].assetid == 272 || selectedLayout[0].assetname.includes('Port Hope Plaza') || selectedLayout[0].assetname.includes('ITSP Office') ,
        //         showFacialRecognition: selectedLayout[0].assetname.includes('ITSP Office'),
        //         activeTab: '1',
        //         activeFloorTab: activeFloor ? activeFloor + '' : '1',
        //         selectedLayout: selectedLayout[0]

        //         // selectedLayout: selectedLayout[0] /* Comment this line to set default value as: CHOOSE LOCATION instead of updating directly when user first time clicks Smart Building sidebar link. */

        //     })

        // }



    }
    // shouldComponentUpdate(prevProps, prevState) {

    // }

    componentWillUnmount() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    // pubSubUpdate = (data) => {
    //     this.setState({
    //       pubSubData: JSON.parse(data),
    //     });
    // }

    handleBackClick(){
        // console.log(this.props.history)
        let path = '/pages/dashboard';
        this.props.history.goBack()
    }

    handleRightClick = (event) => {
        if (this.state.menuPosition) {
            return;
        }
        event.preventDefault();
        this.setState({
            menuPosition: {
                top: event.pageY,
                left: event.pageX
            }
        });
        // setMenuPosition({
        //   top: event.pageY,
        //   left: event.pageX
        // });
    };


    handleTreeItemClick = (value, path, state) => {
        //console.log('TREE CLICKED,' , state)
        //console.table({ value: value, path: path });
        if (state.layoutList) {
            //console.log('TREE ASSETS', state.layoutList)
            let filterLayouts = state.layoutList.filter(asset => asset.assetid == value);
            // console.table(selectedLayout[0]);
            let selectedImg = filterLayouts && filterLayouts[0].assetattributes.length > 0 ? filterLayouts[0].assetattributes.filter(attribute => attribute.attmetaname == "Image") : null
            this.setState({
                ...state,
                pathItems: path,
                selectedLayoutId: filterLayouts[0].assetid,
                layoutImg: selectedImg ? selectedImg[0].value : office_img,
                selectedLayout: filterLayouts[0],
                menuPosition: null,
                // activeTab: '1',
                activeFloor: '1',
            })
        } 
    }


    renderMenubar = (treeAssetList) => {

        return (
            <div>
                <Row>
                    <Col md={6}>
                        <CustomizedBtn
                            // aria-controls="customized-menu"
                            // aria-haspopup="true"
                            // variant="contained"
                            // color="success"
                            onClick={this.handleRightClick}
                        >
                            {this.state.selectedLayout ? this.state.selectedLayout.assetname : 'Choose Location'}
                        </CustomizedBtn>
                        <Menu
                            open={!!this.state.menuPosition}
                            onClose={() => this.setState({ menuPosition: null })}
                            anchorReference="anchorPosition"
                            anchorPosition={this.state.menuPosition}
                        >
                            <TreeMenu
                                dataList={treeAssetList}
                                menuPosition={this.state.menuPosition}
                                handleItemClick={(name, value, path) => this.handleTreeItemClick(value, path, this.state)}
                            ></TreeMenu>
                        </Menu>
                    </Col>
                    <Col md={10}>
                        {/* <Breadcrumb>
                            {this.state.pathItems && this.state.pathItems.map((item, index) => (
                                <BreadcrumbItem key={index}>{item.label}</BreadcrumbItem>
                            ))}
                        </Breadcrumb> */}
                    </Col>
                </Row>
            </div>
        )
    }

    render() {
        var tooltipid = ReactDOM.findDOMNode(this.refs.tooltipref);
        let tabArr = ['Floor Map','Camera List','License Plate Recognition', 'People Count']
        if (this.state.showFacialRecognition) {
            tabArr.push('Face Recognition')
            // tabArr.push('Face Detection')
        }
        if(this.props.pageContentList && this.props.pageContentList.length > 0){ //temporary solution - do not follow
            // console.log('PAGE CONTENT', this.props.pageContentList)
                tabArr = tabArr.filter(tab => this.props.pageContentList.includes(tab))
        }
        if(this.userObj.userName == 'ONrouteLegal' || this.userObj.companyCode == 'ONRT'){
            tabArr = tabArr.filter(tab => !tab.includes('Camera List'))
        }
        //console.log("MAIN RERENDER...")

        //console.log('MAIN RENDER', tabArr)
        //console.log('MAIN RENDER', extraTabs)
        // let tabArray = this.userObj.userName == 'ONrouteLegal' || this.userObj.companyCode == 'ONRT' ? ['Map', ...extraTabs] : ['Map', 'Camera List',...extraTabs];

        //OPG multi floor 
        let floorsLayout = []
        let floorTabs = ['floor 1']
        if(this.state.selectedLayout && this.props.layoutList){
            // console.log('RENDER LAYOUT, ', this.state.selectedLayout)
            let floorAttributes = this.state.selectedLayout.assetattributes.filter(at=>at.attmetaname.includes("floor"))
            if(floorAttributes && floorAttributes.length > 0){
                //console.log("FLOOR ATTRIBUTES", floorAttributes)
                floorAttributes.forEach((floor, index)=>{
                    let assetid = floor.value
                    let asset = this.state.layoutList.find(layout=>layout.assetid == assetid) 
                    if(asset) {
                        floorsLayout.push(asset)
                        //console.log('FLOOR ADDED', floorsLayout)
                    }
                    floorTabs.push('floor ' + (index + 2))
                })
            }
        }
        return (
            <Col md={12}>
                {this.renderMenubar(this.state.treeAssetList)}
                <NavTabs styles={{marginLeft: '1%'}} tabArrs={tabArr} activeTab={this.state.activeTab} toggleTab={this.toggleTab} handleBackClick={this.handleBackClick}/>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={tabArr.indexOf('Floor Map') + 1 + ''}>
                        {/* subtabs for multifloor if rendered asset has multifloor attribute  */}
                        {floorTabs.length > 1 &&
                            <NavTabs tabArrs={floorTabs} activeTab={this.state.activeFloorTab} toggleTab={this.handleFloorTabClick}/>
                        }

                        {
                            floorTabs.length == 1 &&
                            <CameraMapDisplay
                                cameraList={this.props.cameraList}
                                showAssetList={this.props.showAssetList}
                                cameraClass={this.props.cameraClass}
                                tooltipid={tooltipid}
                                // pubSubData={this.state.pubSubData}
                                postAssetAttribute={this.props.postAssetAttribute}
                                loadAssets={this.props.loadAssets}
                                layoutImg={this.state.layoutImg}
                                selectedLayoutId={this.state.selectedLayoutId}
                                selectedLayout={this.state.selectedLayout}
                            />
                        }
                        {/* multiple floors layout */}
                        {floorsLayout && floorsLayout.length > 0 && floorTabs.length > 1 &&
                            <TabContent activeTab={this.state.activeFloorTab}>
                                <TabPane tabId="1">
                                    <CameraMapDisplay
                                        cameraList={this.props.cameraList}
                                        // showAssetList={this.props.showAssetList}
                                        tooltipid={tooltipid}
                                        // pubSubData={this.state.pubSubData}
                                        postAssetAttribute={this.props.postAssetAttribute}
                                        loadAssets={this.props.loadAssets}
                                        layoutImg={this.state.layoutImg}
                                        selectedLayoutId={this.state.selectedLayoutId}
                                        selectedLayout={this.state.selectedLayout}
                                    />
                                </TabPane>
                                {floorsLayout.map((layout, index)=>{
                                        return(<TabPane tabId={`${index + 2}`}>
                                            <CameraMapDisplay
                                                cameraList={this.props.cameraList}
                                                // showAssetList={this.props.showAssetList}
                                                tooltipid={tooltipid}
                                                // pubSubData={this.state.pubSubData}
                                                postAssetAttribute={this.props.postAssetAttribute}
                                                loadAssets={this.props.loadAssets}
                                                layoutImg={layout.assetattributes.find(attribute => attribute.attmetaname == "Image").value}
                                                selectedLayoutId={layout.assetid}
                                                selectedLayout={layout}
                                            />
                                        </TabPane>)
                                })}
                        </TabContent> }

                    </TabPane>
                    <TabPane tabId={tabArr.indexOf('Camera List') + 1 + ''}>
                        <CameraListDisplay/>
                    </TabPane>
                    <TabPane tabId={tabArr.indexOf('License Plate Recognition') + 1 + ''}>
                        <LicensePlateRecordsDisplay />
                    </TabPane>
                    <TabPane tabId={tabArr.indexOf('Face Recognition') + 1 + ''}>
                        <FaceRecordsDisplay />
                    </TabPane>
                    {/* <TabPane tabId={tabArr.indexOf('Face Detection') + 1 + ''}>
                        <FaceDetectionDisplay />
                    </TabPane> */}
                    <TabPane tabId={tabArr.indexOf('People Count') + 1 + ''}>
                            <PeopleCountBoard
                             location = {this.state.selectedLayout}/>
                    </TabPane>
                    {/* <TabPane tabId={tabArr.indexOf('Sensor Monitoring') + 1 + ''}>
                            <SensorMonitoring/>
                    </TabPane> */}
                    {/* <TabPane tabId='4'>
                        <NvrDisplay
                        />
                    </TabPane> */}
                </TabContent>
            </Col>
        );
    }
}

const assetShowList = (assetList, assetClassList) => {
    if (!assetList || !assetClassList) {
        return [];
    }
    // let displayList = assetList.filter(asset => {
    //     let match = false;
    //     asset.assetattributes && asset.assetattributes.forEach(attr => {
    //         if(attr.attmetaname == 'display' && attr.value == 'true'){
    //             match = true;
    //         }
    //     });
    //     return match;
    // });
    let showlist = assetList.map(asset => {
        let assetclass = {};
        if (assetClassList) {
            assetclass = assetClassList.find(ac => ac.id == asset.assetclassid);
            // console.log('loadAssetsSurveillance===================================================',assetclass)
        }
        return { asset, assetclass }
    });
    // console.log('loadAssetsSurveillance==========================================================',showlist)
    return showlist;
}

const recursiveAssetList = (assetList) => {

    let treeList = []
    if (!assetList) {
        return treeList;
    }
    let rootList = assetList.filter(asset => asset.assetclassname.includes('Enterprise'));
    rootList && rootList.forEach((asset, index) => {
        let rootItem = {
            label: asset.assetname,
            value: asset.assetid,
            path: [{ label: asset.assetname }]
        }
        childNode(assetList, rootItem);
        treeList.push(rootItem);
    });
    return treeList;
}

const childNode = (sourceList, parentNode) => {

    let childrenList = sourceList.filter(asset => asset.parentasset == parentNode.value && !asset.assetname.includes('Floor'));
    let items = [];
    childrenList && childrenList.forEach((child, index) => {
        let isEnd = (child.assetclassname.includes('Layout'));
        let childItem = {
            label: child.assetname,
            value: child.assetid,
            last: isEnd,
            path: [...parentNode.path, { label: child.assetname }]
        }
        if (!isEnd) {
            childNode(sourceList, childItem);
        }
        items.push(childItem);
    });
    if (items.length > 0) {
        parentNode.items = items;
    }
}

const TreeMenu = (props) => {
    return (
        <div>
            {props.dataList && props.dataList.map((item, index) => {
                if (!item.last && item.items && item.items.length > 0) {
                    return(
                            <NestedMenuItem
                                key={index}
                                label={item.label}
                                parentMenuOpen={!!props.menuPosition}
                                className="tree-item"
                            >
                                <TreeMenu
                                    dataList={item.items}
                                    menuPosition = {props.menuPosition}
                                    handleItemClick = {props.handleItemClick}
                                ></TreeMenu>
                            </NestedMenuItem>
                    );
                } else {
                    return (
                        <MenuItem
                            key={index}
                            className="tree-item"
                            onClick = {()=>{if(item.last && props.handleItemClick){props.handleItemClick(item.label, item.value, item.path)}}}
                        >{item.label}</MenuItem>
                    );
                }
            })}
        </div>
    )
}


const mapStateToProps = (state) => {
    const props = {
        // layoutList: state.asset.assetList.filter(asset => asset.assetclassname.includes('Layout')),
        // cameraList: state.asset.assetList.filter(asset=> asset.assetclassname=='Camera_Base'),
        // showAssetList: state.surveillance.assets.map(assetobj => {
        //     let asset = state.asset.assetList.find(a => a.assetid == assetobj.asset.assetid);
        //     return { asset, assetclass: assetobj.assetclass }
        // }),
        // showLoadAssetList: assetShowList(state.asset.assetList, state.asset.assetClassList),
        // treeAssetList: recursiveAssetList(state.asset.assetList),
        // cameraClass: state.asset.assetClassList.find(c => c.name.includes('Camera_Base')),
        // sensorClass: state.asset.assetClassList.filter(c=>c.name.includes('Airbox_Sensor')),
        // deviceTypes: state.mapboard.types,
        allAssets: state.asset.assetList,
        peopleCountRecords: state.surveillance.peopleCountRecords,
        pageContentList: state.user.pageContentList
    }
    return props;
}

const mapDispatchToProps = (dispatch) =>{
    return bindActionCreators({loadAssets, loadAssetsSurveillance, loadClasses, postAssetAttribute, loadDevice, loadDeviceInfo, loadPeopleCountRecords}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SurveillanceMain));