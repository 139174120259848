/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Client } from '@stomp/stompjs';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table, Col, Container, Row, Card, CardBody, CardTitle, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse, Dropdown, DropdownMenu, DropdownToggle, Badge } from 'reactstrap';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import Icon from '@mdi/react'
import { mdiRefresh } from '@mdi/js';
import axios from 'axios';
import { name } from 'file-loader';
import { LabelTextInput } from '../cameraList/CameraCreateModal';
import { sendSMS } from '../../../../factories/utils';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { AI_API_ROOT_HTTPCONTROL, WEB_SERVICE_GET_PASSLINE_COUNT } from '../../../../constants/appConstants';
import { postAssetAttribute, loadAssets } from '../../../../redux/actions/assetActions';
import { bindActionCreators } from 'redux';
import  jQuery from 'jquery'




function NvrDisplay() {

    setInterval(()=>{
        try{
            let nvriFrame=document.getElementById("nvriFrame")
            if(nvriFrame==null || nvriFrame==undefined){
                return;
            }
            let idoc=nvriFrame.contentWindow.document
            if(idoc==null ||idoc==undefined){
                return;
            }
            let page = nvriFrame.contentWindow.location.href.split("#")[1];
            switch (page){
                case "login":
                    idoc.getElementById("txtUserName").value="admin"
                    idoc.getElementById("txtPassword").value="123456*"
                    idoc.getElementById("btnLogin").click()
                    break;
                case "live":
                    let links1 = idoc.getElementsByTagName("link");
                    for (let i = 0; i < links1.length; i++) {
                        let link = links1[i];
                        if(link.href.search("recWasm.css")>-1){
                            link.remove()
                        }
                    }
                    break
                case "record":
                    let links = idoc.getElementsByTagName("link");
                    for (let i = 0; i < links.length; i++) {
                        let link = links[i];
                        if(link.href.search("liveWasm.css")>-1){
                            link.remove()
                        }
                    }
                    break;
            }


        }catch (e) {

        }
    },300)


    return (
        <Collapse isOpen={true}>
            <Card>
                <CardBody>
                    <iframe id={"nvriFrame"} src="http://localhost/nvrIndex/" name="iframe_a" style={{width:"100%",height:"1080px"}}></iframe>
                </CardBody>
            </Card>
        </Collapse>
    )
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ postAssetAttribute, loadAssets }, dispatch)
}

export default connect(null, mapDispatchToProps)(NvrDisplay)
