/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BootstrapTableNext from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { Comparator, textFilter } from "react-bootstrap-table2-filter";
import { EditIcon, TrashCanOutlineIcon } from 'mdi-react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Button } from 'reactstrap';
import { SwichConfirmation } from '../../../Asset/components/AssetList';
import { getCompanyList } from '../../../../redux/actions/userAction';
import { loadAssets, postAssetAttribute } from '../../../../redux/actions/assetActions';
import { Switch, MenuItem, InputLabel, Select, FormControl, FormHelperText } from '@material-ui/core';




const { SearchBar } = Search;

const ClearButton = props => {
    const handleClick = () => {
        props.onSearch("");
        props.clearAllFilter();
    };
    return (
        <Button
            variant="secondary"
            onClick={handleClick}
            style={{
                fontSize: "16px",
                padding: "5px",
                margin: "10px",
                height: "40px"
            }}
        >
            Clear
        </Button>
    );
};

const ListTable = ({ list, handleDeleteClick, handleEditClick, companiesList, getCompanyList, loadAssets, postAssetAttribute }) => {
    const [CameraList, setCameraList] = useState(null);
    const [ModalVisible, setModalVisible] = useState(false);
    const [CompanyList, setCompanyList] = useState(null);
    const [CompanyFilter, setCompanyFilter] = useState('none');
    const [StatusFilter, setStatusFilter] = useState('none');

    //load list of companies on receiving companiesList from the store
    useEffect(() => {
        getCompanyList()
    }, []);
    useEffect(() => {
        console.log('company', companiesList);
        setCompanyList(companiesList)
    }, [companiesList]);


    //apply filters to rendered list
    useEffect(() => {
        let filteredList = [...list]
        let cameraCompanies = reduceList(filteredList);
        if (CompanyFilter !== 'none') {
            console.log('================FILTERED LIST====================================', filteredList)
            console.log('===================CAMERA COMPANY===============', cameraCompanies)
            cameraCompanies = cameraCompanies.filter(cam => cam.companycode == CompanyFilter)
        }
        if (StatusFilter !== 'none') {
            cameraCompanies = cameraCompanies.filter(cam => cam.status == StatusFilter)
        }
        setCameraList(cameraCompanies)
    }, [CompanyFilter, StatusFilter, list]);

    //this function reduces the camera list to have only meta attributes
    const reduceList = (list) => {
        return list.map(c => {
            let attributes = {};
            c.assetattributes.map(attr => {
                attributes[attr.attmetaname] = attr.value;
            })
            attributes['cameraid'] = c.assetid;
            attributes['cameraname'] = c.assetname;
            attributes['companycode'] = c.companycode;
            return attributes
        })
    }

    if (!list || list.length == 0) {
        return (
            //    <>Loading...</>
            <>No data display ...</>
        )
    }


    // const onCamActivate = (row, activate) => {
    //     let status = 'inactive';
    //     if(activate){
    //         status = 'active';
    //     }
    //     if (row && row.deviceid) {
    //         this.props.deviceActiveAjax(row.deviceid, status)
    //         .then(res => {
    //             if(res.status === 200){
    //                 this.refreshDeviceInfo();
    //                 toast.success(`Successfully activate the device ${row.deviceid}`);
    //             }else{
    //                 toast.error(`Error occurred during activating the device ${row.deviceid}`);
    //             }
    //         })
    //         .catch(err => {
    //             console.error(err.message);
    //             toast.error(`Error occurred during during activating the device ${row.deviceid}`);
    //         })
    //     }

    // }

    const updateAssetAttribute = (data) => {
        console.log(data);
        postAssetAttribute(data).then(
            res => { loadAssets(); }
        );
    }

    const onCamActivate = (e, row, value) => {
        let statusAttr = list[0] && list[0].assetattributes ? list[0].assetattributes.find(attr => attr.attmetaname == 'status') : null;
        if (statusAttr) {
            console.log(statusAttr)
            let param = {
                assetid: row.cameraid,
                attmetaid: statusAttr.attmetaid,
                value: value
            };
            updateAssetAttribute(param);
        }

    }

    const handleCompFilterChange = (e) => {
        setCompanyFilter(e.target.value);
    }

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    }


    //Camelized column name
    const nameColumn = (text) => {
        let splitted = text.split(' ');
        let joined = '';
        splitted.forEach(el => {
            joined += el.substring(0, 1).toUpperCase() + el.substring(1) + ' ';
        });
        return joined
    }

    const options = {
        page: 1,
        sizePerPageList: [
            { text: '5', value: 5 },
            { text: '10', value: 10 },
            { text: 'All', value: list.length }
        ],
        sizePerPage: 5, pageStartIndex: 1, paginationSize: 5,
        prePage: '<', nextPage: '>', firstPage: '<<', lastPage: '>>',
    };

    const editFormatter = (cell, row) => (
        <div className='actions'>
            {/* <a onClick={() => fillerFunction(row)}><FileDocumentBoxMultipleIcon /></a>
            <a onClick={() => fillerFunction(row)}><PlusCircleIcon /></a> */}
            <a onClick={() => handleEditClick(row.cameraid)} style={{ cursor: 'pointer' }} ><EditIcon color='#0066cc' /></a>
            <a onClick={() => handleDeleteClick(row.cameraid)} style={{ cursor: 'pointer' }} ><TrashCanOutlineIcon color='#830300' /></a>
            {/* <EditTextButton onClick={() => handleEditClick(row.assetid)}/>{' '} */}
            {/* <DeleteTextButton onClick={() => handleDeleteClick(row.assetid)}/> */}
        </div>
    )

    // const handleStatusChanged = () => {
    //     console.log('Switch clicked');
    //     setModalVisible(true);
    // }

    // const onConfirmClick = () =>{
    //     setModalVisible(false)
    // }

    // const onCancelClick = () => {
    //     setModalVisible(false)
    // }

    // const toggle = () => {
    //     setModalVisible(!ModalVisible)
    // }

    const statusFormatter = (value, row) => {
        // console.log(row);
        // console.log('===================ROW============', row, value)
        // console.log(value)
        let checked = (row.status == 'true');
        let msg = 'ACTIVATED';
        if (checked) {
            msg = 'IN-ACTIVATED'
        }
        return (
            <>
                {/* <Switch
                checked={value == 'true'}
                onChange={()=>console.log(value)}
                /> */}
                <SwichConfirmation
                    key={checked}
                    checked={checked}
                    onConfirm={(e, value) => { onCamActivate(e, row, value); }}
                    // onConfirm={(e, value)=>{console.log(value);}}
                    label={""}
                    msg={`The camera ${row.name} will be ${msg} !`}
                />
            </>
        )
    }

    const expandRow = {
        // onlyOneExpanding: true,
        renderer: row => {
            if (row.devices && row.devices.length > 0) {
                let col = [
                    { dataField: 'deviceid', text: 'Device Id' },
                    { dataField: 'devicename', text: 'Device Name' },
                    { dataField: 'devicetypename', text: 'Device Type' },
                ]
                return <BootstrapTableNext key={'expand_' + row} keyField='deviceid' data={row.devices} columns={col} />
            }
            return (<p>There are no devices here</p>)
        }
    };

    let ignoreKeys = ['assetid', 'assetclassid', 'geofence', 'devices', 'iconid', 'iconscale', 'iconsvg', 'companycode', 'position'];
    let columns = list[0].assetattributes.map(attr => {
        let name = attr.attmetaname;
        let ret
        if (name.includes('status')) { //status column
            ret = {
                //dataField: key, text: key.substring(0, 1).toUpperCase() + key.substring(1),
                dataField: name,
                text: nameColumn(name),
                formatter: statusFormatter,
                sort: true,
                // filter: textFilter()
            }
        } else if (name == 'camera number') {
            ret = {
                dataField: name,
                text: nameColumn(name),
                sort: true,
                filter: textFilter({ placeholder: 'Filter', style: { fontSize: '1em' }, comparator: Comparator.EQ })
            }
        } else {
            ret = {
                dataField: name,
                text: nameColumn(name),
                sort: true,
            }
        }
        // if (key === 'devices')
        //    ret.formatter = (cell) => (cell ? cell.length : 0) + ' Devices'
        // if (key === 'assetattributes')
        //    ret.formatter = (cell) => (cell ? cell.length : 0) + ' Attributes'
        if (ignoreKeys.includes(name))
            ret.hidden = true
        ret.headerClasses = 'header_white';
        return ret
    })

    columns.push({
        dataField: 'editAssetClass', text: '',
        formatter: editFormatter, headerStyle: () => ({ width: '75px' }),
        headerClasses: 'header_white',
    })
    return (
        CameraList ? <ToolkitProvider
            keyField='name'
            data={CameraList}
            columns={columns}
            search={{ searchFormatted: true }}
        >
            {props => (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
                        <FormControl>
                            <Select autoWidth={true} labelId="company-filter" value={CompanyFilter} onChange={handleCompFilterChange}>
                                <MenuItem value="none">All</MenuItem>
                                {
                                    companiesList.map(c => {
                                        return (<MenuItem value={c.companyCode}>{c.companyName}</MenuItem>)
                                    })
                                }
                            </Select>
                            <FormHelperText><b>Filter Company</b></FormHelperText>
                        </FormControl>
                        <FormControl>
                            <Select autoWidth={true} labelId="status-filter" value={StatusFilter} onChange={handleStatusFilterChange}>
                                <MenuItem value="none">All</MenuItem>
                                <MenuItem value='true'>ON</MenuItem>
                                <MenuItem value='false'>OFF</MenuItem>
                            </Select>
                            <FormHelperText><b>Filter Status</b></FormHelperText>
                        </FormControl>
                        <SearchBar {...props.searchProps} style={{ width: '200px' }} />
                    </div>
                    <BootstrapTableNext
                        {...props.baseProps}
                        filter={filterFactory()}
                    // expandRow={expandRow}
                    />
                </div>
            )}
        </ToolkitProvider> : null
    )
};

//getting companies list from store
const mapToStateProps = (state) => ({
    companiesList: state.user.companyList
})

export default connect(mapToStateProps, { getCompanyList, loadAssets, postAssetAttribute })(ListTable);