/* eslint-disable */
import React, { Component } from 'react';
// import {Modal, ModalHeader , ModalBody, ModalFooter, Button} from 'reactstrap';
import {Card, CardHeader , CardBody, CardFooter, Button} from 'reactstrap';
import NotificationTemplateForm from '../components/TemplateForm';
import { textAlign } from '@material-ui/system';

class NotificationTemplateModal extends Component {
    constructor(props){
        console.log('modal is trigger')
        super(props);
        this.state = {};
    }

    onSubmit = () => {
        this.props.onSubmit();
    }

    onClose = () => {
        this.props.toggle();
    }

    render(){
        let modalTitle = '';
        if (this.props.templateObj){
            modalTitle = this.props.templateObj.id == 0 ? 'Add New Template' : 'Edit Template';
        }
        return(
            // <Modal
            //     // style={modalStyle()}
            //     isOpen={this.props.showModal} 
            //     toggle={this.props.toggle}
            //     renderBackdrop={this.renderBackdrop}
            //     size = 'lg'
            // >
            //     <ModalHeader toggle={this.onClose}>{ modalTitle }</ModalHeader >
            //     <ModalBody>      
            //         <NotificationTemplateForm
            //             contactList = {this.props.contactList}
            //             templateObj = {this.props.templateObj}
                        
            //         />                                       
            //     </ModalBody>
            //     <ModalFooter>
            //     <Button 
            //         outline color='custom' 
            //         type="button" 
            //         onClick={this.onSubmit} 
            //         // disabled = {!this.validateForm()} 
            //     >
            //         Submit
            //     </Button>
            //     <Button 
            //         outline 
            //         color='secondary' 
            //         type="reset" 
            //         onClick={this.onClose}
            //     >
            //         Close
            //     </Button>
            //     </ModalFooter>
            // </Modal>
            <Card
            // style={modalStyle()}
            // isOpen={this.props.showModal} 
            // toggle={this.props.toggle}
            // renderBackdrop={this.renderBackdrop}
            // size = 'lg'
            >
            <CardHeader toggle={this.onClose}>{ modalTitle }</CardHeader >
            <CardBody>      
                <NotificationTemplateForm
                    contactList = {this.props.contactList}
                    templateObj = {this.props.templateObj}
                    upsertContact={this.props.upsertContact}
                    deleteContact = {this.props.deleteContact}
                    handleContactChange = {this.props.handleContactChange}
                    // handleTimeInputChange = {this.props.handleTimeInputChange}
                    handlePeriodsSelect = {this.props.handlePeriodsSelect}
                    handleInputChange = {this.props.handleInputChange}
                />          
                <hr/>
                <div style={{textAlign:'right'}}>
                    <Button 
                        outline color='custom' 
                        type="button" 
                        onClick={this.onSubmit} 
                        // disabled = {!this.validateForm()} 
                    >
                        Submit
                    </Button>
                    <Button 
                        outline 
                        color='secondary' 
                        type="reset" 
                        onClick={this.onClose}
                    >
                        Close
                    </Button>
                </div>                             
            </CardBody>
            {/* <CardFooter style={{textAlign:'right'}}>
                <Button 
                    outline color='custom' 
                    type="button" 
                    onClick={this.onSubmit} 
                    // disabled = {!this.validateForm()} 
                >
                    Submit
                </Button>
                <Button 
                    outline 
                    color='secondary' 
                    type="reset" 
                    onClick={this.onClose}
                >
                    Close
                </Button>
            </CardFooter> */}
        </Card>
        );
    }
}

export default NotificationTemplateModal
