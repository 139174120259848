/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import { Card, CardBody, Col } from 'reactstrap';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import { EditIcon } from 'mdi-react';
import DashboardMap from './DashboardMap';
import AssetClassEditModal from './AssetClassEditModal';
import {loadAssetMapData} from '../../../redux/actions/dashboardActions';
import {getCurrentUser, hasRole} from    '../../../factories/auth'

class AssetMapWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {attr:{}},
            infowindowLat: null,
            infowindowLng: null,
            modal: false,
        }
        this.userObj = getCurrentUser();
    }

    componentDidMount(){
        const assetclassid = this.props.assetclassId;
        const dashname = this.props.dashName;
        this.props.loadAssetMapData(assetclassid, dashname);
    }

    componentWillReceiveProps(nextProps){
        
         
        if (nextProps.assetclassId && nextProps.dashName) {
            if(JSON.stringify(nextProps.assetclassId) !== JSON.stringify(this.props.assetclassId) || JSON.stringify(nextProps.dashName) !== JSON.stringify(this.props.dashName)){
                // console.log('CHANGED ASSET CLASS ID PROPS');
                this.setState({
                    assetclassId: nextProps.assetclassId,
                    dashName: nextProps.dashName,
                });
                 
                this.props.loadAssetMapData(nextProps.assetclassId, nextProps.dashName);
            }
        }
    }

    // static getDerivedStateFromProps(props, state) {}


    renderMap = () => {
      // let data = this.props.currentDeviceList.filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
      let dashname = this.props.dashName;
      let data = this.props.assetmapdata[dashname]; //filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
      return(
        <DashboardMap 
          mapData = {data}
          layout = {this.props.layout}
        />
      )
    }

    toggle = () => {
        // alert('yes');
        this.setState((prevState) => ({
            modal : !prevState.modal
        }))
    }
    renderModal = () => {
        return(
            <AssetClassEditModal
                modal = {this.state.modal}
                toggle = {this.toggle}
                layout = {this.props.layout}
                onEditSubmit = {this.props.onEditSubmit}
            />
        )
    }

    render() {
        const check1 = this.userObj && hasRole(this.userObj, ['SUPERADMIN', 'ADMIN']);
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    <Row>
                    <Col>
                    <h5 className="bold-text">
                        {this.props.dashName}  
                    </h5>     
                    </Col>  
                    <Col>    
                        {this.props.layout && 0 == this.props.layout.locked && check1 &&
                        <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>    
                        }
                    {/* <EditIcon style={{float: 'right', display:'inline'}}/>     */}
                    </Col>
                    </Row>       
                </CardHeader>
                <CardBody style={{padding:15}} >  
                    {/* <div className="card__title">
                        <h5 className="bold-text">{this.props.dashName} </h5>                      
                    </div> */}
                    {this.renderMap()}
                    
                </CardBody>  
          </Card>
          {this.renderModal()}
          </Fragment>
        );
    }

}

// export default AssetMapWidget;

function mapStateToProps(state) { 
  const props = {...state.dashboard};
  return props;
};
  
function mapDispatchToProps(dispatch) {
  return bindActionCreators({loadAssetMapData}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetMapWidget);