/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PlusIcon from 'mdi-react/PlusIcon';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import uuid from "uuid/v4";
import { Card, CardBody, Col, Row, Button, Collapse, Tooltip, UncontrolledTooltip, Modal } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { EditTextButton, DeleteTextButton } from '../../../shared/components/Button/IconButton';
import { CheckIcon, TickIcon, ExclamationIcon } from 'mdi-react';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { cloneDeep } from 'lodash'
import {
    getGeneralLedgerCodesByCompanyCode,
    getGeneralLedgerCodesReasonsByCompanyCode,
    updateCustomerInvoiceMiscellaneousDetailsJournal,
    cancelInvoiceMiscellaneousDetail,
    getAdjustmentsByInvoiceByCompanyCode,
    updateCustomerInvoiceWithAdjustments,
    getCustomerInvoiceApprovalDetails,
    confirmCustomerInvoiceApproval
} from '../../../redux/actions/userAction';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import ClientInvoiceModal from './ClientInvoiceModal';
import ClientMiscellaneousInvoiceItemEditModal from './ClientMiscellaneousInvoiceItemEditModal'
import ClientGenerateInvoiceModal from './InvoicingSteps/ClientGenerateInvoiceModal';
import { toast } from 'react-toastify';

class ClientInvoiceBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAccount: null,
            startDateMoment: moment("2020-10-01"),
            endDateMoment: moment("2020-10-30"),
            invoicehtml: '<div>No Invoice data</div>',
            showDetails: false,
            invoiceId: null,
            modal: false,
            adjustAmount: null,
            paidAmount: 0,
            invAmt: 0,
            comment: "",
            status: 12,
            userObj: getCurrentUser(),
            showInvoice: true,
            showUsageReport: false,
            showInvoiceAdjustmentNav: false,
            invoiceMiscellaneousItemListWithIndexOperation: [],
            miscellaneousInvoiceItemSelected: {},
            isMiscellaneousInvoiceItemEditMode: true,
            showMiscellaneousInvoiceItemEditModal: false,
            showInvoiceGenerationModal: false,
            adjustmentPending: false,
            approvalPending: false,
            recalculationPending: false,
            approvalConfirmationPending: false,
            // markAsPaidIsReady: false,
            paymentStatus: '',
            previewInvoice: null,
            cellLoadingId: null,
        }

        // Miscellaneous adjustments
        this.renderInvoiceNavigation = this.renderInvoiceNavigation.bind(this)
        this.showInvoiceAdjustments = this.showInvoiceAdjustments.bind(this)
        this.showInvoice = this.showInvoice.bind(this)
        this.addNewMiscellaneousInvoiceItemPopupLaunch = this.addNewMiscellaneousInvoiceItemPopupLaunch.bind(this);
        this.renderMiscellaneousInvoiceAdjustmentDetails = this.renderMiscellaneousInvoiceAdjustmentDetails.bind(this);
        this.onOpenClientMiscellaneousInvoiceItemEditModal = this.onOpenClientMiscellaneousInvoiceItemEditModal.bind(this)
        this.onCloseClientMiscellaneousInvoiceItemEditModal = this.onCloseClientMiscellaneousInvoiceItemEditModal.bind(this)
        this.onUpdateRemoveClientMiscellaneousInvoiceItem = this.onUpdateRemoveClientMiscellaneousInvoiceItem.bind(this)
        this.cellMiscellaneousInvoiceItemEditButton = this.cellMiscellaneousInvoiceItemEditButton.bind(this)
        this.cellMiscellaneousInvoiceItemDeleteButton = this.cellMiscellaneousInvoiceItemDeleteButton.bind(this)
        this.ModalMiscellaneousInvoiceItemDeleteButton = this.ModalMiscellaneousInvoiceItemDeleteButton.bind(this)
        this.addNewMiscellaneousInvoiceItemButton = this.addNewMiscellaneousInvoiceItemButton.bind(this)
        this.createInvoiceAdjustmentConfirmationPayload = this.createInvoiceAdjustmentConfirmationPayload.bind(this)
        this.invoiceAdjustmentConfirmationButton = this.invoiceAdjustmentConfirmationButton.bind(this);
    }
    async componentDidMount() {
        const { getGeneralLedgerCodesByCompanyCode,
            getGeneralLedgerCodesReasonsByCompanyCode } = this.props
        const { userObj } = this.state
        const { companyCode } = userObj

        await getGeneralLedgerCodesByCompanyCode(companyCode)
        await getGeneralLedgerCodesReasonsByCompanyCode(companyCode)
    }

    componentDidUpdate(prevProps) {
        let { paymentInvoicePendingDetails } = this.props

        if (paymentInvoicePendingDetails
            !== prevProps.paymentInvoicePendingDetails) {

            let invoiceId = this.state.invoiceId

            if (invoiceId) {
                let miscellaneousCopy = cloneDeep(paymentInvoicePendingDetails[invoiceId].miscellaneous)
                let adjustmentPending = paymentInvoicePendingDetails[invoiceId].adjustmentStatus
                let approvalPending = paymentInvoicePendingDetails[invoiceId].approvalStatus

                this.setState({
                    invoiceMiscellaneousItemListWithIndexOperation: miscellaneousCopy,
                    adjustmentPending: adjustmentPending === 0 ? true : false,
                    approvalPending: approvalPending === 0 ? true : false,
                    paymentStatus: paymentInvoicePendingDetails[invoiceId].statusDesc,
                    previewInvoice: 'show'
                })
            }

        }

    }

    toggle = () => {
        const { handleRefreshInvoiceList } = this.props
        handleRefreshInvoiceList()
            .then(() => {
                this.setState(prevState => ({
                    showDetails: !prevState.showDetails,
                    showInvoiceAdjustmentNav: false,
                }));
            });
    }
    renderSpinner = () => {
        return (
            <div>
                <Spinner
                    className="table-spinner"
                    as="span"
                    animation="border"
                    // size="sm"
                    // role="status"
                    // aria-hidden="true"
                    style={{ left: '38%', position: "absolute", zIndex: "99999", zoom: "0.7" }}
                />
            </div>

        );
    }
    invoiceIdFormater = (cell, row) => {
        return (
            <a style={{ position: "relative", display: "block" }} href={'#'}
                onClick={async (e) => {
                    e.preventDefault();

                    await this.setState({
                        invoiceId: cell,
                        comment: row.comment,
                        adjustAmount: row.amountAdjust,
                        paymentMethod: row.paymentMethod,
                        paidAmount: row.amountPaid,
                        status: row.statId,
                        invAmt: row.invAmt,
                        cellLoadingId: cell,
                        showUsageReport:false
                    });

                    await this.handleInvoiceClick(cell);
                    if (row.usageDtl != "Order") {
                        await this.handleUsageReport(cell);
                        this.setState({ showUsageReport: true })
                    }
                    this.setState({
                        cellLoadingId: null
                    })
                }
                }
            >   {cell == this.state.cellLoadingId ? this.renderSpinner() : null}
                {cell}
            </a>
        );
    }
    handleInvoiceClick = async (cell) => {

        window.scrollTo(0, 0)

        const { getAdjustmentsByInvoiceByCompanyCode,
            getCustomerInvoiceDetails,
            getCustomerInvoiceApprovalDetails } = this.props
        const { userObj } = this.state
        const { companyCode } = userObj

        /* get details here */
        if ((this.state.userObj && hasRole(this.state.userObj, ['ADMIN', 'USER']))) {
            this.setState({ invoiceId: cell })
            await getAdjustmentsByInvoiceByCompanyCode({
                invoiceId: cell,
                companyCode
            })
        }
        await getCustomerInvoiceApprovalDetails(cell)

        let { paymentInvoicePendingDetails } = this.props

        let adjustmentPending
            = paymentInvoicePendingDetails[cell].adjustmentStatus
                === 0 ? true : false

        let approvalPending
            = paymentInvoicePendingDetails[cell].approvalStatus
                === 0 ? true : false


        if ((userObj && hasRole(userObj, ['ADMIN', 'USER']))) {
            await getCustomerInvoiceDetails(cell);
        }

        if ((!adjustmentPending && !approvalPending)
            && (userObj && hasRole(userObj, ['CLIENTADMIN', 'CLIENTUSER']))) {
            await getCustomerInvoiceDetails(cell);
        }

        // await getCustomerInvoiceDetails(cell);
        this.toggle()
        /*await getCustomerInvoiceApprovalDetails(cell)*/
    }
    handleUsageReport = async (cell) => {
        let obj = {
            "objId": cell,
            "resellerCode": this.props.selectedRow.resellerCode
        }
        await this.props.getUsageReport(obj)

    }

    handleInvoiceAdjustment = async (id, value, isAdmin) => {

        this.setState({
            recalculationPending: true
        }, async () => {

            if (isAdmin === false) {
                var param = {
                    "companyId": this.props.selectedRow.companyId,
                    "resellerId": this.props.selectedRow.id,
                    "invoiceId": id,
                    "payAmount": value
                }
            }
            if (isAdmin === true) {
                var param = {
                    "companyId": this.props.selectedRow.companyId,
                    "resellerId": this.props.selectedRow.id,
                    "invoiceId": id,
                    "payMethod": value
                }
            }
            await this.props.payInvoice(param).then(res => {
                if (res.data != null) {
                    if (res.data.statusCode === '200') {
                        toast.success(res.data.statusDetail);
                        if (res.data.invoice != null) {
                            var inv = res.data.invoice;
                            this.setState({
                                invoiceId: inv.invId,
                                adjustAmount: inv.amountAdjust,
                                paidAmount: inv.amountPaid,
                                paymentMethod: inv.paymentMethod,
                                status: inv.statId,
                                invAmt: inv.invAmt
                            });
                        }
                        return;
                    }
                }
                toast.error("Payment Failed")
                return;
            });

            await this.props.getCustomerInvoiceApprovalDetails(id)
            await this.props.getCustomerInvoiceDetails(id);
            await this.props.handleRefreshInvoiceList();
            this.onClose();

            this.setState({
                recalculationPending: false
            })
        })
    }

    handleInvoiceBack = () => {
        this.toggle();
    }

    moneyFormatter = (cell, row) => {
        let price = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
        }
        return (
            <span>{`USD $${price}`}</span>
        )
    }
    adjustMoneyFormatter = (cell, row) => {
        if (cell != null) {
            let price = parseFloat(cell).toFixed(2);
            return (
                <span>{`USD $${price}`}</span>
            )
        }
        return "N/A";
    }
    adjustFormatter = (cell, row) => {
        if (cell != null) {
            return <CheckIcon style={{ color: "green" }} />
        }


    }
    adjustmentStatusFormatter = (cell, row) => {
        return ((
            <div>
                <span id={`verified-invoice-${row.invId}`}>
                    {(cell === 1) && <CheckIcon style={{ color: "green" }} />}
                    {(cell === 0) && <ExclamationIcon style={{ color: "red" }} />}
                </span>
                <UncontrolledTooltip
                    placement="right"
                    target={`verified-invoice-${row.invId}`}
                    trigger="hover"
                >
                    {(cell === 1) && `Invoice #${row.invId} has no pending adjustments`}
                    {(cell === 0) && `Invoice #${row.invId} has pending adjustments`}
                </UncontrolledTooltip>
            </div>)
        )
    }
    approvalStatusFormatter = (cell, row) => {
        return ((
            <div>
                <span id={`approval-invoice-${row.invId}`}>
                    {(cell === 1) && <CheckIcon style={{ color: "green" }} />}
                    {(cell === 0) && <ExclamationIcon style={{ color: "red" }} />}
                </span>
                <UncontrolledTooltip
                    placement="right"
                    target={`approval-invoice-${row.invId}`}
                    trigger="hover"
                >
                    {(cell === 1) && `Invoice #${row.invId} is approved`}
                    {(cell === 0) && `Invoice #${row.invId} is pending approval`}
                </UncontrolledTooltip>
            </div>)
        )
    }
    timeFormatter = (cell, row) => {
        let UTCTime = new Date(cell).toLocaleDateString('en-US', { timeZone: 'UTC' })
        return (
            `${UTCTime}`
        );
    }
    dateFormatter = (cell, row) => {
        if (cell != null) {
            var date = new Date(cell)
            var str = date.toLocaleString('en-US', {year: 'numeric', timeZone: 'UTC'}) + "-" 
                    + date.toLocaleString('en-US', {month: '2-digit', timeZone: 'UTC'}) + "-" 
                    + date.toLocaleString('en-US', {day: '2-digit', timeZone: 'UTC'});
            return (`${str}`);
        }
    }
    statusFromatter = (cell, row) => {
        if (cell == 0) {
            return `Inactive`
        }
        else if (cell == 1) {
            return `Active`
        }
        else if (cell == 2) {
            return `Suspended`
        }
        else if (cell == 3) {
            return `Canceled`
        }
        else {
            return `Unknown Status`
        }
    }
    usageFormatter = (usageNum) => {
        let formatedNum = 0;
        if (usageNum != null) {
            formatedNum = (usageNum / 1024).toFixed(2);
            return formatedNum;
        }
        return 0;
    }
    pdfDownload = (e) => {
        e.preventDefault()
        var printWindow = window.open('', '', 'height=400,width=800');
        printWindow.document.write(`<html><head><title>Invoice ${(this.props.selectedRow != null) ? this.props.selectedRow.resellerName : ""}</title>`);
        printWindow.document.write('</head><body >');
        printWindow.document.write(this.props.paymentInvoiceDetails.invDetail);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    }
    createCustomExportCSVButton = (onClick) => {
        return (
            <ExportCSVButton
                btnText='Download CSV'
                onClick={() => this.handleExportCSVButtonClick(onClick)} />
        );
    }
    handleExportCSVButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        onClick();
    }

    /* ------------------------------- Accepting Invoice Redjustment - START -------------------- */

    createInvoiceAdjustmentConfirmationPayload = () => {

        window.scrollTo(0, 0)

        const { invoiceId } = this.state

        const { updateCustomerInvoiceWithAdjustments,
            getCustomerInvoiceDetails,
            getCustomerInvoiceApprovalDetails,
            handleRefreshInvoiceList } = this.props

        this.setState({
            invoicehtml: '',
            recalculationPending: true
        }, async () => {

            // do update here
            await updateCustomerInvoiceWithAdjustments(invoiceId)

            // get invoice status
            await getCustomerInvoiceApprovalDetails(invoiceId)

            // refresh and get the customer's invoice details
            await getCustomerInvoiceDetails(invoiceId)

            await handleRefreshInvoiceList()

            this.setState({
                showInvoice: true,
                showInvoiceAdjustmentNav: false,
                recalculationPending: false
            })
        })

    }

    invoiceAdjustmentConfirmationButton = (align) => {

        const { recalculationPending, approvalPending,
            invoiceMiscellaneousItemListWithIndexOperation } = this.state

        return (
            <div style={{ textAlign: align, marginTop: align === 'left' ? '0px' : '20px' }}>
                <InsertButton
                    btnContextual='btn-success'
                    btnGlyphicon='glyphicon-edit'
                    disabled={recalculationPending
                        || !approvalPending
                        || invoiceMiscellaneousItemListWithIndexOperation.length === 0
                    }
                    onClick={(e) => {
                        e.preventDefault();
                        this.createInvoiceAdjustmentConfirmationPayload();
                    }}
                >
                    {recalculationPending === true ? 'Recalculating' : 'Recalculate'}
                </InsertButton>
            </div>
        );
    }

    /* ------------------------------- Accepting Invoice Redjustment - End ---------------------- */

    /* ------------------------------- Invoice Approval - START ---------------------- */

    createInvoiceApprovalConfirmationPayload = () => {

        const { invoiceId } = this.state

        const { confirmCustomerInvoiceApproval,
            getCustomerInvoiceDetails,
            getCustomerInvoiceApprovalDetails,
            handleRefreshInvoiceList } = this.props

        this.setState({
            approvalConfirmationPending: true
        }, async () => {

            // do update here
            await confirmCustomerInvoiceApproval(invoiceId)

            // get invoice status
            await getCustomerInvoiceApprovalDetails(invoiceId)

            // refresh and get the customer's invoice details
            await getCustomerInvoiceDetails(invoiceId)

            await handleRefreshInvoiceList()

            this.setState({
                approvalConfirmationPending: false,
                // markAsPaidIsReady: true
            })
        })

    }

    /* ------------------------------- Invoice Approval - END ---------------------- */

    /* ------------------------------- miscellaneous - START ------------------------------------ */

    renderInvoiceNavigation = () => {
        const { recalculationPending,
            adjustmentPending, approvalPending,
            paymentStatus, approvalConfirmationPending,
            previewInvoice, userObj } = this.state

        return (
            <div style={{ marginBottom: '20px' }}>
                {/* <h3>Options</h3> */}
                <div style={{ textAlign: 'left', marginTop: '20px' }}>
                    <Button
                        type="button"
                        color='custom'
                        onClick={this.handleInvoiceBack}
                        disabled={recalculationPending || approvalConfirmationPending}
                    >
                        Back
                    </Button>
                    {
                        (this.state.userObj && hasRole(this.state.userObj, ['ADMIN'])) &&
                        (
                            <>
                                {/* <Button onClick={this.addNewMiscellaneousInvoiceItemPopupLaunch}>Adjust Invoice</Button> */}
                                <Button
                                    type="button"
                                    color='custom'
                                    onClick={this.showInvoiceAdjustments}
                                    disabled={recalculationPending || approvalConfirmationPending}
                                >
                                    Show Adjustments
                                </Button>
                            </>
                        )
                    }

                    {
                        (userObj && hasRole(userObj, ['ADMIN', 'USER']))
                        && (
                            <Button
                                type="button"
                                color='custom'
                                onClick={this.pdfDownload}
                                disabled={recalculationPending || approvalConfirmationPending}
                            >
                                Print
                            </Button>
                        )
                    }

                    {
                        (userObj && hasRole(userObj, ['CLIENTADMIN', 'CLIENTUSER', 'USER']))
                        // && showInvoice
                        && !adjustmentPending
                        && !approvalPending
                        && previewInvoice !== null
                        && (
                            <Button
                                onClick={this.pdfDownload}
                                disabled={recalculationPending || approvalConfirmationPending}
                            >
                                Print
                            </Button>
                        )
                    }


                    {/* <Button onClick={this.showInvoice}>Show Invoice</Button> */}

                    {
                        (this.state.userObj && this.state.paymentMethod === "CC" && hasRole(this.state.userObj, ['CLIENTADMIN'])) &&
                        (
                            <>
                                {/* <Button onClick={this.addNewMiscellaneousInvoiceItemPopupLaunch}>Adjust Invoice</Button> */}
                                <Button
                                    onClick={this.onOpen}
                                    disabled={recalculationPending || approvalConfirmationPending || this.state.status == 6}
                                >
                                    Pay
                                </Button>
                            </>
                        )
                    }
                    {
                        (this.state.userObj
                            && hasRole(this.state.userObj, ['ADMIN']))
                        && !adjustmentPending
                        && approvalPending
                        && !recalculationPending
                        && paymentStatus !== "paid"
                        &&
                        (
                            <>
                                <InsertButton
                                    btnContextual='btn-success'
                                    btnGlyphicon='glyphicon-edit'
                                    disabled={approvalConfirmationPending}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.createInvoiceApprovalConfirmationPayload();
                                    }}
                                >
                                    {approvalConfirmationPending === true ? 'Approving' : 'Approve'}
                                </InsertButton>
                            </>
                        )
                    }
                    {
                        (this.state.userObj
                            && hasRole(this.state.userObj, ['ADMIN']))
                        && !adjustmentPending
                        && !approvalPending
                        && !recalculationPending
                        && (
                            <>
                                {/* <Button onClick={this.addNewMiscellaneousInvoiceItemPopupLaunch}>Adjust Invoice</Button> */}
                                <Button
                                    type="button"
                                    color='custom'
                                    onClick={this.onOpen}
                                    disabled={recalculationPending || this.state.status === 6}
                                >
                                    Mark As Paid
                                </Button>
                            </>
                        )
                    }
                </div>
            </div>
        )
    }

    showInvoice = () => {
        this.setState({ showInvoice: true })
    }

    showInvoiceAdjustments = async () => {
        const { getAdjustmentsByInvoiceByCompanyCode } = this.props
        const { companyCode } = this.state.userObj

        await getAdjustmentsByInvoiceByCompanyCode({
            invoiceId: this.state.invoiceId,
            companyCode
        })
        this.setState({ showInvoiceAdjustmentNav: true, showInvoice: false })
    }

    // Version 2
    addNewMiscellaneousInvoiceItemPopupLaunch = () => {

        const { invoiceId, userObj } = this.state
        const { companyCode } = userObj

        /* create a sample row item */
        const rowItemTemplate = {
            operationsIndex: uuid(),
            operationsEdit: true,
            operationsDelete: true,
            id: 0,
            invoiceId,
            codeId: -1,
            reasonId: -1,
            divisionId: -1,
            amount: 0,
            companyCode
        }

        this.setState({
            isMiscellaneousInvoiceItemEditMode: false
        }, () => {
            this.onOpenClientMiscellaneousInvoiceItemEditModal(rowItemTemplate)
        });
    }

    onOpenClientMiscellaneousInvoiceItemEditModal = (miscellaneousInvoiceItemSelected) => {
        this.setState({
            showMiscellaneousInvoiceItemEditModal: true,
            miscellaneousInvoiceItemSelected
        })
    }

    onCloseClientMiscellaneousInvoiceItemEditModal = (miscellaneousInvoiceItemSelected) => {
        this.setState({
            showMiscellaneousInvoiceItemEditModal: false,
            miscellaneousInvoiceItemSelected: null
        })
    }

    onUpdateRemoveClientMiscellaneousInvoiceItem = async (miscellaneousInvoiceItemSelected, isEditMode) => {

        const { updateCustomerInvoiceMiscellaneousDetailsJournal,
            getCustomerInvoiceApprovalDetails,
            handleRefreshInvoiceList } = this.props
        const { invoiceId, userObj, recalculationPending } = this.state
        const { companyCode, userName } = userObj

        const invoiceMiscellaneousItemPayload = {
            invoiceId,
            companyCode: companyCode,
            userName: userName,
            miscellaneous: miscellaneousInvoiceItemSelected
        }

        this.setState({ recalculationPending: true }, async () => {

            await updateCustomerInvoiceMiscellaneousDetailsJournal(invoiceMiscellaneousItemPayload)
            await getCustomerInvoiceApprovalDetails(invoiceId)
            await handleRefreshInvoiceList()

            this.setState({
                showInvoiceAdjustmentNav: true,
                showInvoice: false,
                recalculationPending: false
            })
        })
    }

    cellMiscellaneousInvoiceItemEditButton = (cell, row, enumObject, index) => {

        const { paymentStatus } = this.state

        return (
            <div>
                <EditTextButton
                    disabled={paymentStatus === "paid"}
                    label={''}
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            isMiscellaneousInvoiceItemEditMode: true
                        }, () => {
                            this.onOpenClientMiscellaneousInvoiceItemEditModal(cloneDeep(row))
                        });

                    }} />
            </div>
        )

    }

    ModalMiscellaneousInvoiceItemDeleteButton = (cell, row, enumObject, index) => {
        const { cancelInvoiceMiscellaneousDetail,
            getCustomerInvoiceApprovalDetails,
            getCustomerInvoiceDetails,
            handleRefreshInvoiceList } = this.props
        const { invoiceId, userObj, paymentStatus,
            isMiscellaneousInvoiceItemEditMode } = this.state
        const { companyCode, userName } = userObj
        if (row != null) {
            return (
                <div>
                    <ConfirmDeleteDialog
                        label={""}
                        onConfirm={async (e) => {
                            e.preventDefault();
                            const invoiceMiscellaneousItemPayload = {
                                invoiceId,
                                companyCode: companyCode,
                                userName: userName,
                                isMiscellaneousInvoiceItemEditMode,
                                miscellaneousInvoiceItemSelected: row,
                            }

                            await cancelInvoiceMiscellaneousDetail(invoiceMiscellaneousItemPayload)
                            await getCustomerInvoiceApprovalDetails(invoiceId)
                            await getCustomerInvoiceDetails(invoiceId)
                            await handleRefreshInvoiceList()
                        }}
                        msg={(row.pendingStatus === 1) ? 'This adjustment has been calculated, Are you sure you want to delete the adjustment ?' : 'This adjustment has not been calculated, Are you sure you want to delete the adjustment ?'}
                    />
                </div>
            )
        }
        else {
            return (
                <>N/A</>
            )
        }
    }

    cellMiscellaneousInvoiceItemDeleteButton = (cell, row, enumObject, index) => {

        const { cancelInvoiceMiscellaneousDetail,
            getCustomerInvoiceApprovalDetails,
            getCustomerInvoiceDetails,
            handleRefreshInvoiceList } = this.props
        const { invoiceId, userObj, paymentStatus,
            isMiscellaneousInvoiceItemEditMode } = this.state
        const { companyCode, userName } = userObj
        return (
            <div>
                <DeleteTextButton
                    disabled={paymentStatus === "paid"}
                    label={''}
                    onClick={async (e) => {
                        e.preventDefault();

                        const invoiceMiscellaneousItemPayload = {
                            invoiceId,
                            companyCode: companyCode,
                            userName: userName,
                            isMiscellaneousInvoiceItemEditMode,
                            miscellaneousInvoiceItemSelected: row,
                        }

                        await cancelInvoiceMiscellaneousDetail(invoiceMiscellaneousItemPayload)
                        await getCustomerInvoiceApprovalDetails(invoiceId)
                        await getCustomerInvoiceDetails(invoiceId)
                        await handleRefreshInvoiceList()
                    }} />
            </div>
        )
    }

    addNewMiscellaneousInvoiceItemButton = (invoiceItem) => {

        const { invoiceId, userObj, recalculationPending } = this.state
        const { companyCode } = userObj

        /* create a sample row item */
        const rowItemTemplate = {
            operationsIndex: uuid(),
            operationsEdit: true,
            operationsDelete: true,
            id: 0,
            invoiceId,
            codeId: -1,
            reasonId: -1,
            divisionId: -1,
            amount: 0.0,
            companyCode
        }

        return (
            <InsertButton
                btnContextual='btn-success'
                btnGlyphicon='glyphicon-edit'
                disabled={recalculationPending}
                onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                        isMiscellaneousInvoiceItemEditMode: false
                    }, () => {
                        this.onOpenClientMiscellaneousInvoiceItemEditModal(rowItemTemplate)
                    });
                }}
            >
                Add an Adjustment
            </InsertButton>
        );
    }

    handleInvoiceBackToUsage = () => {
        this.setState({
            showInvoiceAdjustmentNav: false,
            showInvoice: true
        })
    }



    renderMiscellaneousInvoiceAdjustmentDetails = () => {

        const { invoiceId, recalculationPending,
            invoiceMiscellaneousItemListWithIndexOperation,
            paymentStatus, approvalPending, adjustmentPending } = this.state

        const options = {
            paginationPosition: 'bottom'
        };

        return (<>
            <div style={{ marginBottom: '20px' }}>
                <h3>Adjustment Items</h3>
                <div style={{ textAlign: 'left', marginTop: '20px' }}>
                    <Button onClick={this.handleInvoiceBackToUsage} disabled={recalculationPending}>Back</Button>
                    {paymentStatus !== ''
                        && paymentStatus !== "paid"
                        // && approvalPending
                        && this.addNewMiscellaneousInvoiceItemButton(invoiceId)}
                </div>
            </div>
            <BootstrapTable
                data={invoiceMiscellaneousItemListWithIndexOperation}
                options={options}
                tableHeaderClass='header_white'
                bordered={false}
                height='100%'
                pagination
                exportCSV={false}
            >
                <TableHeaderColumn dataField='id' isKey={true} hidden={true}>
                    Index
                </TableHeaderColumn>
                <TableHeaderColumn dataField='createdAtFormatted' dataSort
                    dataAlign="center" caretRender={sortCaret}
                    hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                >
                    Date
                </TableHeaderColumn>
                <TableHeaderColumn dataField='generalLedgerCodeStr' dataSort
                    dataAlign="center" caretRender={sortCaret}
                    hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                >
                    General Ledger
                </TableHeaderColumn>
                <TableHeaderColumn dataField='department' dataSort
                    dataAlign="center" caretRender={sortCaret}
                    hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                >
                    Department
                </TableHeaderColumn>
                <TableHeaderColumn dataField='reason' dataSort
                    dataAlign="center" caretRender={sortCaret}
                    hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                >
                    Reason
                </TableHeaderColumn>
                <TableHeaderColumn dataField='amountFormatted' dataSort
                    dataAlign="center" caretRender={sortCaret}
                    hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                >
                    Amount
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField='operationsEdit'
                    dataAlign="center"
                    dataFormat={this.cellMiscellaneousInvoiceItemEditButton}
                    width="10%"
                    editable={false}
                >
                    Edit
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField='operationsDelete'
                    dataAlign="center"
                    dataFormat={this.ModalMiscellaneousInvoiceItemDeleteButton}
                    width="10%"
                    editable={false}
                >
                    Delete
                </TableHeaderColumn>
            </BootstrapTable>
            <br />
            <hr style={{ borderTop: '3px dotted rgb(140, 139, 139)' }} />
        </>)
    }


    /* ------------------------------- miscellaneous - END -------------------------------------- */

    renderInvoiceDetails = () => {
        let htmlStr = this.state.invoicehtml;
        if (this.props.paymentInvoiceDetails && this.props.paymentInvoiceDetails.invDetail) {
            htmlStr = this.props.paymentInvoiceDetails.invDetail
        }
        const options = {
            exportCSVBtn: this.createCustomExportCSVButton,
        };

        const { miscellaneousInvoiceItemSelected,
            isMiscellaneousInvoiceItemEditMode,
            showMiscellaneousInvoiceItemEditModal,
            showInvoiceAdjustmentNav, showInvoice,
            adjustmentPending, invoiceId, userObj,
            recalculationPending, paymentStatus,
            approvalPending, previewInvoice } = this.state

        const { generalLedgerCodes,
            generalLedgerCodeUsageReasons,
            BUDivisionList, paymentInvoicePendingDetails } = this.props

        return (
            <Col md={12}>
                <Card>
                    <CardBody style={{ margin: "auto" }}>
                        <ClientInvoiceModal
                            modal={this.state.modal}
                            invoiceId={this.state.invoiceId}
                            adjustAmount={this.state.adjustAmount}
                            paidAmount={this.state.paidAmount}
                            comment={this.state.comment}
                            status={this.state.status}
                            invAmt={this.state.invAmt}
                            paymentMethod={this.state.paymentMethod}
                            isAdmin={this.props.isAdmin}
                            handleInvoiceAdjustment={this.handleInvoiceAdjustment}
                            onClose={this.onClose}
                        />
                        <ClientMiscellaneousInvoiceItemEditModal
                            showModal={showMiscellaneousInvoiceItemEditModal}
                            miscellaneousInvoiceItemSelected={miscellaneousInvoiceItemSelected}
                            onClose={this.onCloseClientMiscellaneousInvoiceItemEditModal}
                            onSave={this.onUpdateRemoveClientMiscellaneousInvoiceItem}
                            isEditMode={isMiscellaneousInvoiceItemEditMode}
                            generalLedgerCodes={generalLedgerCodes}
                            generalLedgerCodeUsageReasons={generalLedgerCodeUsageReasons}
                            BUDivisionList={BUDivisionList}
                        />

                        {/* Render Navigation */}
                        {!showInvoiceAdjustmentNav && this.renderInvoiceNavigation()}

                        {/* Render a table for miscellaneous adjustments */}
                        {showInvoiceAdjustmentNav && this.renderMiscellaneousInvoiceAdjustmentDetails()}

                        {/* Show a button for accepting adjustments */}
                        {(userObj && hasRole(userObj, ['ADMIN']))
                            && showInvoiceAdjustmentNav
                            //&& paymentStatus !== "paid"
                            && this.invoiceAdjustmentConfirmationButton('right')}

                        {(userObj && hasRole(userObj, ['ADMIN', 'USER']))
                            && showInvoice
                            && !adjustmentPending
                            && (
                                <>
                                    <div id="pdf-view">
                                        <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
                                    </div>
                                </>
                            )
                        }

                        {(userObj && hasRole(userObj, ['ADMIN', 'USER']))
                            && showInvoice
                            && adjustmentPending
                            // && paymentStatus !== "paid"
                            && (
                                <>
                                    <Col md={12}>
                                        <Row>
                                            <div class="col-md-auto pl-0">
                                                <h4 className={`text-danger `} style={{ 'marginTop': '10px' }}>
                                                    There are pending adjustments!
                                                </h4>
                                            </div>
                                            <div class="col-md-auto text-left">
                                                {this.invoiceAdjustmentConfirmationButton('left')}
                                            </div>
                                        </Row>
                                    </Col>
                                    {
                                        !recalculationPending &&
                                        (<div id="pdf-view">
                                            <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
                                        </div>)
                                    }
                                    {
                                        recalculationPending &&
                                        (<div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                                            <Spinner
                                                className="spinner"
                                                as="span"
                                                animation="border"
                                                variant="success"
                                                style={{
                                                    display: 'inline-block',
                                                    margin: 'auto'
                                                }}
                                            />
                                        </div>)
                                    }
                                </>
                            )}

                        {(userObj && hasRole(userObj, ['CLIENTADMIN', 'CLIENTUSER']))
                            && showInvoice
                            && !adjustmentPending
                            && !approvalPending
                            && previewInvoice !== null
                            && (
                                <>
                                    <div id="pdf-view">
                                        <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
                                    </div>
                                </>
                            )
                        }

                        {(userObj && hasRole(userObj, ['CLIENTADMIN']))
                            && (adjustmentPending || approvalPending)
                            && (
                                <>
                                    <Col md={12}>
                                        <Row>
                                            <div class="col-md-auto pl-0">
                                                <h4 className={`text-danger `} style={{ 'marginTop': '10px' }}>
                                                    This invoice is under review!
                                                </h4>
                                                <br />
                                            </div>
                                        </Row>
                                    </Col>
                                </>
                            )
                        }

                        {(this.state.showUsageReport) ?
                            <>
                                <h3>Usage Report</h3>
                                <br />
                                <BootstrapTable
                                    data={this.props.usageReportList}
                                    options={options}
                                    tableHeaderClass='header_white'

                                    bordered={false}
                                    height='100%'
                                    pagination
                                    exportCSV
                                    csvFileName={`Usage_Report_${this.props.selectedRow.resellerName}.csv`}
                                >
                                    <TableHeaderColumn dataField='historyId' isKey={true} dataSort hidden={true} dataAlign="center" caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} >Invoice</TableHeaderColumn>
                                    <TableHeaderColumn dataField='resellerName' dataSort dataAlign="center" caretRender={sortCaret} hidden={true} export={true} csvHeader='Client Name' filter={{ type: 'TextFilter', placeholder: ' ' }} >Client Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField='type' dataSort dataAlign="center" caretRender={sortCaret} hidden={true} export={true} csvHeader='Identifier Type' filter={{ type: 'TextFilter', placeholder: ' ' }} >Type</TableHeaderColumn>
                                    <TableHeaderColumn dataField='value' dataSort dataAlign="center" caretRender={sortCaret} hidden={true} export={true} csvHeader='Identifier Value' filter={{ type: 'TextFilter', placeholder: ' ' }} >Value</TableHeaderColumn>
                                    <TableHeaderColumn dataField='deviceName' dataSort dataAlign="center" caretRender={sortCaret} csvHeader='Device Name' filter={{ type: 'TextFilter', placeholder: ' ' }} >Device Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField='status' dataSort dataFormat={this.statusFromatter.bind(this)} hidden={true} export={true} dataAlign="center" csvFormat={this.statusFromatter.bind(this)} caretRender={sortCaret} csvHeader='Status' filter={{ type: 'TextFilter', placeholder: ' ' }} >Status</TableHeaderColumn>
                                    <TableHeaderColumn dataField='planName' dataSort dataAlign="center" caretRender={sortCaret} csvHeader='Plan Name' filter={{ type: 'TextFilter', placeholder: ' ' }} >Plan Name</TableHeaderColumn>
                                    <TableHeaderColumn dataField='planCode' dataSort dataAlign="center" caretRender={sortCaret} hidden={true} export={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false} csvHeader='Plan Code' filter={{ type: 'TextFilter', placeholder: ' ' }} >Plan Code</TableHeaderColumn>
                                    <TableHeaderColumn dataField='usage' dataSort dataFormat={this.usageFormatter.bind(this)} csvHeader='Usage (MB)' csvFormat={this.usageFormatter.bind(this)} dataAlign="center" caretRender={sortCaret} filter={{ type: 'NumberFilter', placeholder: ' ' }} >Usage (MB)</TableHeaderColumn>
                                    <TableHeaderColumn dataField='activationDate' dataSort dataFormat={this.dateFormatter.bind(this)} csvHeader='Activation Date' hidden={true} export={true} csvFormat={this.dateFormatter.bind(this)} dataAlign="center" caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} >Activation Date</TableHeaderColumn>
                                    <TableHeaderColumn dataField='startDate' dataSort dataFormat={this.dateFormatter.bind(this)} csvHeader='Period Start' csvFormat={this.dateFormatter.bind(this)} dataAlign="center" caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} >Start Date</TableHeaderColumn>
                                    <TableHeaderColumn dataField='endDate' dataSort dataFormat={this.dateFormatter.bind(this)} csvHeader='Period End' csvFormat={this.dateFormatter.bind(this)} dataAlign="center" caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} >End Date</TableHeaderColumn>
                                </BootstrapTable>
                            </>
                            :
                            null}

                    </CardBody>
                </Card>
            </Col>

        );
    }
    onOpen = () => {
        this.setState({
            modal: true
        })
    }
    onClose = () => {
        this.setState({
            modal: false,
            invoiceId: null
        })
    }

    csvAdjustmentFormatter(cell, row) {
        if (cell == 1) {
            return `Adjusted`
        }
        else if (cell == 0) {
            return `Pending`
        }
    }

    csvApprovalFormatter(cell, row) {
        if (cell == 1) {
            return 'Approved'
        }
        else if (cell == 0) {
            return 'Pending'
        }
    }

    renderInvoiceList() {

        const { userObj } = this.state

        const paidType = {
            paid: 'paid',
            unpaid: 'unpaid'
        };
        const adjustType = {
            Yes: 'Yes'
        }
        const adjustmentType = {
            0: 'Pending',
            1: 'Adjusted'
        }
        const approvalType = {
            0: 'Pending',
            1: 'Approved'
        }
        const options = {
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        };
        let invList = this.props.paymentInvoice.invList




        if (userObj && hasRole(userObj, ['CLIENTADMIN', "CLIENTUSER"])) {
            invList = invList
                .filter(invItem => invItem.adjustmentStatus === 1
                    && invItem.approvalStatus === 1)
        }

        return (
            <Card>
                <CardBody className="mainCardBody">
                    {this.renderInvoiceGeneration()}

                    {
                        (userObj && hasRole(userObj, ['ADMIN', 'USER']))
                        && (
                            <BootstrapTable
                                data={invList}
                                tableHeaderClass='header_white'
                                exportCSV
                                csvFileName='Client_Invoice.csv'

                                bordered={false}
                                height='100%'
                                pagination={true}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField='createDt'
                                    dataFormat={this.timeFormatter.bind(this)}
                                    dataAlign="center"
                                    dataSort
                                    width='5%'
                                    caretRender={sortCaret}
                                    filterFormatted
                                    filter={{ type: 'TextFilter', placeholder: ' ' }}
                                    csvHeader='Date'>
                                    Date
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='invId'
                                    isKey={true}
                                    dataSort
                                    dataFormat={this.invoiceIdFormater}
                                    dataAlign="center"
                                    width='6%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'TextFilter', placeholder: ' ' }}
                                    csvHeader='Invoice'>
                                    Invoice
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='invTtl'
                                    dataFormat={this.moneyFormatter}
                                    dataAlign="center"
                                    dataSort
                                    width='8%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'NumberFilter', delay: 1000, numberComparators: ['=', '>', '<'], placeholder: ' ' }}
                                    csvHeader='Amount'>
                                    Amount
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='amountAdjust'
                                    dataFormat={this.adjustMoneyFormatter}
                                    dataAlign="center"
                                    dataSort
                                    width='8%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'NumberFilter', delay: 1000, numberComparators: ['=', '>', '<'], placeholder: ' ' }}
                                    csvHeader='Adjustment'>
                                    Adjustment
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='adjustmentStatus'
                                    dataFormat={this.adjustmentStatusFormatter.bind(this)}
                                    dataAlign="center"
                                    width='10%'
                                    filter={{ type: 'SelectFilter', options: adjustmentType }}
                                    csvFormat={this.csvAdjustmentFormatter}
                                    csvHeader='Adjustment Status'>
                                    Adjustment Status
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='approvalStatus'
                                    dataFormat={this.approvalStatusFormatter.bind(this)}
                                    dataAlign="center"
                                    width='10%'
                                    filter={{ type: 'SelectFilter', options: approvalType }}
                                    csvFormat={this.csvApprovalFormatter}
                                    csvHeader='Approval Status'>
                                    Approval Status
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='amountPaid'
                                    dataFormat={this.moneyFormatter}
                                    dataAlign="center"
                                    dataSort
                                    width='8%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'NumberFilter', delay: 1000, numberComparators: ['=', '>', '<'], placeholder: ' ' }}
                                    csvHeader='Paid'>
                                    Paid
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='statusDesc'
                                    dataAlign="center"
                                    dataSort width='5%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'SelectFilter', options: paidType, condition: 'eq' }}
                                    csvHeader='Status'>
                                    Status
                                </TableHeaderColumn>

                                {/*
                                    <TableHeaderColumn 
                                        dataField='amountAdjust' 
                                        dataFormat={this.adjustFormatter.bind(this)} 
                                        dataAlign="center" 
                                        width='5%' 
                                        filterFormatted 
                                        filter={{ type: 'SelectFilter', options: adjustType }}>
                                            Adjusted
                                    </TableHeaderColumn>
                                    */}

                                {/*<TableHeaderColumn 
                                        dataField='comment' 
                                        dataAlign="center" 
                                        width='10%' 
                                        filter={{ type: 'TextFilter', placeholder: ' ' }} >
                                            Comment
                                    </TableHeaderColumn>*/}
                            </BootstrapTable>
                        )
                    }

                    {
                        (userObj && hasRole(userObj, ['CLIENTADMIN', 'CLIENTUSER']))
                        && (
                            <BootstrapTable
                                data={invList}
                                tableHeaderClass='header_white'
                                exportCSV
                                csvFileName='Client_Invoice.csv'

                                bordered={false}
                                height='100%'
                                pagination={true}
                                options={options}
                            >
                                <TableHeaderColumn
                                    dataField='createDt'
                                    dataFormat={this.timeFormatter.bind(this)}
                                    dataAlign="center"
                                    dataSort
                                    width='5%'
                                    caretRender={sortCaret}
                                    filterFormatted
                                    filter={{ type: 'TextFilter', placeholder: ' ' }}
                                    csvHeader='Date'>
                                    Date
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='invId'
                                    isKey={true}
                                    dataSort
                                    dataFormat={this.invoiceIdFormater}
                                    dataAlign="center"
                                    width='6%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'TextFilter', placeholder: ' ' }}
                                    csvHeader='Invoice'>
                                    Invoice
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='invTtl'
                                    dataFormat={this.moneyFormatter}
                                    dataAlign="center"
                                    dataSort
                                    width='8%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'NumberFilter', delay: 1000, numberComparators: ['=', '>', '<'], placeholder: ' ' }}
                                    csvHeader='Amount'>
                                    Amount
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='amountAdjust'
                                    dataFormat={this.adjustMoneyFormatter}
                                    dataAlign="center"
                                    dataSort
                                    width='8%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'NumberFilter', delay: 1000, numberComparators: ['=', '>', '<'], placeholder: ' ' }}
                                    csvHeader='Adjustment'>
                                    Adjustment
                                </TableHeaderColumn>

                                {/* Adjustment status and approval status not shown to the client */}
                                {/* Later this was switched back */}

                                <TableHeaderColumn
                                    dataField='adjustmentStatus'
                                    dataFormat={this.adjustmentStatusFormatter.bind(this)}
                                    dataAlign="center"
                                    width='10%'
                                    filter={{ type: 'SelectFilter', options: adjustmentType }}
                                    csvFormat={this.csvAdjustmentFormatter}
                                    csvHeader='Adjustment Status'>
                                    Adjustment Status
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='approvalStatus'
                                    dataFormat={this.approvalStatusFormatter.bind(this)}
                                    dataAlign="center"
                                    width='10%'
                                    filter={{ type: 'SelectFilter', options: approvalType }}
                                    csvFormat={this.csvApprovalFormatter}
                                    csvHeader='Approval Status'>
                                    Approval Status
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='amountPaid'
                                    dataFormat={this.moneyFormatter}
                                    dataAlign="center"
                                    dataSort
                                    width='8%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'NumberFilter', delay: 1000, numberComparators: ['=', '>', '<'], placeholder: ' ' }}
                                    csvHeader='Paid'>
                                    Paid
                                </TableHeaderColumn>

                                <TableHeaderColumn
                                    dataField='statusDesc'
                                    dataAlign="center"
                                    dataSort width='5%'
                                    caretRender={sortCaret}
                                    filter={{ type: 'SelectFilter', options: paidType, condition: 'eq' }}
                                    csvHeader='Status'>
                                    Status
                                </TableHeaderColumn>

                                {/*
                                    <TableHeaderColumn 
                                        dataField='amountAdjust' 
                                        dataFormat={this.adjustFormatter.bind(this)} 
                                        dataAlign="center" 
                                        width='5%' 
                                        filterFormatted 
                                        filter={{ type: 'SelectFilter', options: adjustType }}>
                                            Adjusted
                                    </TableHeaderColumn>
                                    */}

                                {/*<TableHeaderColumn 
                                        dataField='comment' 
                                        dataAlign="center" 
                                        width='10%' 
                                        filter={{ type: 'TextFilter', placeholder: ' ' }} >
                                            Comment
                                    </TableHeaderColumn>*/}
                            </BootstrapTable>
                        )
                    }

                </CardBody>
            </Card>
        )
    }

    renderInvoiceGeneration() {
        return (
            <div>
                <ClientGenerateInvoiceModal
                    userObj={this.state.userObj}
                    showInvoiceGenerationModal={this.state.showInvoiceGenerationModal}
                    addressList={this.props.addressList}
                    SIMTypeList={this.props.SIMTypeList}
                    paymentAccountList={this.props.paymentAccountList}
                    selectedRow={this.props.selectedRow}
                    generatePurchaseOrder={this.props.generatePurchaseOrder}
                    handleRefreshInvoiceList={this.props.handleRefreshInvoiceList}
                    onClose={this.onCloseGenerateInvoiceModal}
                />
                {(this.props.pageContentList.includes("Client Invoice Generate")) ?

                    <Button color='custom' type="button" onClick={this.onOpenGenerateInvoiceModal}>
                        <PlusIcon /> Generate Invoice
                    </Button>
                    :
                    null
                }
            </div>
        )

    }

    onOpenGenerateInvoiceModal = () => {
        this.setState({
            showInvoiceGenerationModal: true
        })
    }

    onCloseGenerateInvoiceModal = () => {
        this.setState({
            showInvoiceGenerationModal: false
        })
    }

    render() {
        return (
            <div>
                <Collapse
                    isOpen={!this.state.showDetails}
                    onEntering={() => { }}
                    onExited={() => { }}
                >
                    {this.renderInvoiceList()}
                </Collapse>
                <Collapse
                    isOpen={this.state.showDetails}
                    onEntering={() => { }}
                    onExited={() => { }}
                >

                    {this.renderInvoiceDetails()}
                </Collapse>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    let { paymentInvoicePendingDetails,
        generalLedgerCodes,
        generalLedgerCodeUsageReasons,
        BUDivisionList } = state.user;
    let props = {
        paymentInvoicePendingDetails,
        generalLedgerCodes,
        generalLedgerCodeUsageReasons,
        BUDivisionList
    }

    return props
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        /* Getting Approval details */
        getCustomerInvoiceApprovalDetails,

        /* Instead start by getting all codes */
        getGeneralLedgerCodesByCompanyCode,

        /* Get all code reasons */
        getGeneralLedgerCodesReasonsByCompanyCode,

        /* Then get usages by invoice number */
        getAdjustmentsByInvoiceByCompanyCode,

        /* Add a miscellaneous item */
        updateCustomerInvoiceMiscellaneousDetailsJournal,

        /* Cancel a miscellaneous item */
        cancelInvoiceMiscellaneousDetail,

        /* Saving adjustments to DB backend */
        updateCustomerInvoiceWithAdjustments,

        /* Aprove the invoice */
        confirmCustomerInvoiceApproval
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientInvoiceBoard);