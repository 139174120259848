/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody, Col, Row, Button, Container } from 'reactstrap';
import { Trans } from "react-i18next";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { bindActionCreators } from 'redux';
import { fetchDetail } from "../../../redux/actions/AccountMgmt/fetchDetailAction";
import { deleteDetail } from "../../../redux/actions/AccountMgmt/deleteDetailAction";
import { removeUser } from '../../../redux/actions/userAction';
import PropTypes from "prop-types";
import DetailModal from './userDetailModal';
import UserEditModal from './userEditModal';
import PlusIcon from 'mdi-react/PlusIcon';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { EditTextButton, DeleteTextButton, ManageTextButton } from '../../../shared/components/Button/IconButton';

class SearchResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            show: false,
            fullName: '',
            userName: '',
            userId: null,
            selectedUser: null,
            userObj: getCurrentUser()
        };
    }

    // userObj;

    componentDidMount() {
        // let userObj = getCurrentUser();
        // if (userObj === null || userObj.userName.length === 0){
        //     this.props.history.push('/log_in');
        // }
        // this.userObj = userObj;
        //  
        //  
    }

    fetchUserDetail = (row) => {
        if (this.props.searchResult) {
            let userObj = this.props.searchResult.find((item, index) => (row.userName === item.userName));
            this.setState({
                selectedUser: userObj,
            });
        }
    }

    cellActionButton = (cell, row, enumObject, index) => {
        const check1 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN','ADMIN', 'CLIENTADMIN']);
        const check2 = this.props.userObj && this.props.userObj.userName === row.userName;
        const view = (check1 || check2);
        //  
        // 
        if (view) {
            return (
                <div style={{ lineHeight: '50px' }}>
                    <ManageTextButton label={''} onClick={() => {
                        this.setState({
                            userEmail: row.email,
                            show: true,
                            // fullName: row.full_name,
                            userName: row.userName,
                            userId: row.userId
                        });
                        // this.props.fetchDetail({userName: row.user_name});
                        this.fetchUserDetail(row);
                        // setTimeout(()=> {
                        //     this.setState({show: false})
                        // }, 1);

                    }} />{' '}
                </div>
            );
        };
    }

    cellDeleteButton = (cell, row, enumObject, index) => {
        const check1 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'USER', 'CLIENTADMIN', 'CLIENTUSER']);
        // const check2 = this.props.userObj && this.props.userObj.userName === row.userName;
        const check2 = this.props.userObj && this.props.userObj.userName === row.userName;
        const disabled = (check1 && check2);
        //  
        //  
        if (!disabled) {
            return (
                <div style={{ lineHeight: '50px' }}>
                    <ConfirmDeleteDialog
                        label={""}
                        msg={'Do you want to delete the select User ? '}
                        onConfirm={() => {
                            this.setState({
                                show: false
                            });
                            // this.props.deleteDetail({userName: row.user_name});
                            this.props.removeUser(row.userName, true);
                        }}
                        disabled={disabled}
                    />
                </div>
            );
        }
    };

    modalToggle = () => {
        // alert('outsit toggle');
        this.setState((preState) => ({
            show: !preState.show,
        }));
    }

    handleAddNewButtonClick = (onClick) => {
        this.props.addNewToggle();
    }

    handleAddMultiButtonClick = (onClick) => {
        this.props.addMultiToggle();
    }

    addNewUserButton = (onClick) => {
        const check1 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"]);
        return (
            <Row>
                <div style={{ position: "relative", margin: "10px" }}>
                    <InsertButton
                        btnText='Add User'
                        btnContextual='btn-success'
                        btnGlyphicon='glyphicon-edit'
                        onClick={() => this.handleAddNewButtonClick(onClick)}
                        disabled={!check1}
                        className='btn-add'

                    >
                        {/* <PlusCircleOutlineIcon size={100}/> */}
                        {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
                        <PlusIcon /> Add User
                    </InsertButton>
                </div>
                {
                    (this.props.pageContentList.includes("Add Multiple")) ?
                        <div style={{ position: "relative", margin: "10px" }}>
                            <InsertButton
                                btnText='Add Multiple'
                                btnContextual='btn-success'
                                // className='my-custom-class'
                                //   style={{backgroundColor:'#ffffff'}}
                                btnGlyphicon='glyphicon-edit'
                                onClick={() => this.handleAddMultiButtonClick(onClick)}
                                disabled={!check1}
                                className='btn-add'
                            >
                                {/* <PlusCircleOutlineIcon size={100}/> */}
                                {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
                                <PlusIcon /> Add Multiple
                            </InsertButton>
                        </div>
                        :
                        null

                }


            </Row>
            // <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
        );
    }

    companyFormater = (cell, row) => {
        let companyStr = '';
        if (cell) {
            companyStr = cell.companyName;
            if (row.role && row.role.includes('SUPERADMIN')) {
                companyStr = '';
            };
        }

        return (
            <span>{companyStr}</span>
        );
    }

    phoneFormatter = (cell, row) => {
        return (cell ? `(${cell ? cell.slice(0, 3) : ''}) ${cell ? cell.slice(3, 6) : ''}-${cell ? cell.slice(6, 10) : ""} ${(row.phoneExt) ? `Ext.${row.phoneExt}` : ""}` : "")
    }

    roleFormater = (cell) => {
        let roleStr = '';
        let dilimeter = '';
        if (cell && cell.length > 0) {
            cell.forEach((item, index) => {
                roleStr += dilimeter + item;
                dilimeter = ', ';
            });
        }

        return (
            <span>{roleStr}</span>
        );
    }

    render() {
        const { searchResult } = this.props;
        const options = {
            insertBtn: this.addNewUserButton,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        }
        let data = [];
        if (hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'USER', "CLIENTADMIN"])) {
            data = searchResult;
        } else {
            if (searchResult && searchResult.length > 0) {
                data = searchResult.filter((item, index) => (item.userName === this.props.userObj.userName));
            }
        }
        const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"]);

        const { pageContentList } = this.props

        let textDisplay = [];
        if (pageContentList.includes("Reseller")) {
            textDisplay = ['Reseller'];
        } else {
            textDisplay = ['Client'];
        }

        return (


            <Card>
                <CardBody>
                    <BootstrapTable
                        data={data}
                        pagination
                        tableHeaderClass='header_white'
                        options={options}
                        bordered={false}
                        insertRow={check}
                        exportCSV
                        csvFileName='User_List.csv'>

                        {/* <TableHeaderColumn dataField='userId' dataAlign="center" >
                            <Trans i18nKey="user_id">User ID</Trans>
                        </TableHeaderColumn> */}
                        <TableHeaderColumn dataField='userName' dataAlign="center" dataSort caretRender={sortCaret} isKey filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='User Name'>
                            <Trans i18nKey="userName">User Name</Trans>
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='email' dataAlign="center" dataSort caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Email'>
                            <Trans i18nKey="email">Email</Trans>
                        </TableHeaderColumn>
                        {/* <TableHeaderColumn dataField='userName' dataAlign="center" >
                            <Trans i18nKey="fullName">Full Name</Trans>
                        </TableHeaderColumn> */}
                        <TableHeaderColumn dataField='phoneNum' dataAlign="center" dataSort caretRender={sortCaret} dataFormat={this.phoneFormatter} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Phone Number'>
                            <Trans i18nKey="phone_number">Phone Number</Trans>
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='company' dataAlign="center" dataSort caretRender={sortCaret} dataFormat={this.companyFormater} csvFormat={this.companyFormater} hidden={this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN']) ? false : true} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Company'>
                            Company
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='resellerName' dataAlign="center" dataSort caretRender={sortCaret} hidden={this.state.userObj && hasRole(this.state.userObj, ['ADMIN', 'CLIENTADMIN', 'CLIENTUSER', 'USER']) ? false : true} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader={textDisplay[0]}>
                            {textDisplay[0]}
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='role' dataAlign="center" dataSort caretRender={sortCaret} dataFormat={this.roleFormater} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Role'>
                            Role
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='createTime' dataSort caretRender={sortCaret} dataAlign="center" hidden={this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN']) ? false : true} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Created Time'>
                            <Trans i18nKey="last_login">Created Time</Trans>
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='action'
                            dataAlign="center"
                            dataFormat={this.cellActionButton.bind(this)}
                            width="10%"
                            export={false}
                        >
                            {/* <Trans i18nKey="action">action</Trans> */}
                            Manage
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='delete'
                            dataAlign="center"
                            dataFormat={this.cellDeleteButton.bind(this)}
                            width="10%"
                            export={false}
                            hidden = {(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'CLIENTADMIN']))? false: true}
                        >
                            Delete
                        </TableHeaderColumn>
                    </BootstrapTable>
                    {/* <DetailModal userEmail={this.state.userEmail} show={this.state.show} fullName={this.state.fullName} userName={this.state.userName}/> */}
                    <UserEditModal
                        pageContentList={this.props.pageContentList}
                        userEmail={this.state.userEmail}
                        show={this.state.show}
                        fullName={this.state.fullName}
                        userName={this.state.userName}
                        userId={this.state.userName}
                        userObj={this.state.selectedUser}
                        toggle={this.modalToggle}
                        currentUserObj={this.props.userObj}
                    />
                </CardBody>
            </Card>


        );
    }
}

SearchResult.propTypes = {
    searchResult: PropTypes.array.isRequired
};

// const mapStateToProps = (state) => {
//     // const {isLoading, result, fetched} = state.users;
//     // const {isModifying, type, users, message, status} = state.userModifyStatus;
//     // // return {props: state.users, message: state.userModifyStatus};
//     // const props = {isLoading, isModifying, result, type, fetched, users, message, status};
//     return state;
// };

const mapStateToProps = (state) => {
    let {
        pageContentList
    } = state.user;

    let props = {
        pageContentList
    };
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchDetail,
        deleteDetail,
        removeUser,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResult));
// export default SearchResult;
