/* eslint-disable */
import React, { Component, Fragment, useState } from 'react';
import {
    Col, Container, Row, Card, CardBody, Label,
    Input, Button, Modal, ModalHeader, ModalBody,
    ModalFooter, Form, FormGroup, UncontrolledDropdown,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import DeviceTypeTableStyles from './DeviceTypeTable.module.css';
import './DeviceTypeTableStyles.css';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import {
    compose,
    bindActionCreators
} from "redux";
import { connect } from "react-redux";
import { EditTextButton, DeleteButton } from '../../../../shared/components/Button/IconButton';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';
import { getCurrentUser } from '../../../../factories/auth';
import {
    getAllConfig,
    updateConfig,
} from '../../../../redux/actions/weatherAction';
import { getPageContent } from '../../../../redux/actions/userAction';
import ReactFileReader from 'react-file-reader';
import { addMultiCustomersInventoryRecords } from '../../../../redux/actions/inventoryActions';
// import { WEB_SERVICE_TELELCOM_BACKEND_ROOT, WEB_SERVICE_LOAD_CUSTOMER_INVENTORY } from '../../../../constants/appConstants';
// import axios from 'axios';
import { toast } from "react-toastify";
import JSZip from 'jszip';
import { parse, Papa, RECORD_SEP, UNIT_SEP } from 'papaparse'
import { takeRight, camelCase } from 'lodash'
import InventoryItemModal from './InventoryItemModal'
import { sortCaret } from '../../../../shared/components/Table/tableElements';

const editableFiles = ['CHA_RATING_DETAILS.csv'];

const options = {
    paginationPosition: 'bottom'
};

const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelizeKeys(obj[key]),
            }),
            {},
        );
    }
    return obj;
};

const papaparseConfig = {
    delimiter: "",	// auto-detect
    newline: "",	// auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: "",
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    downloadRequestBody: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    chunkSize: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';', RECORD_SEP, UNIT_SEP]
}

// const DropdownMenuFn = (props) => {

//     return (
//         <DropdownMenu>
//             {
//                 props.items.length > 0 &&
//                 props.items.map((fileDetail, index) => {
//                     (<DropdownItem>{index}</DropdownItem>)
//                 })
//             }
//         </DropdownMenu>
//     )
// }

const ButtonCleared = (props) => {
    const [isShown, setIsShown] = useState(true);

    return (
        <button
            type="button"
            className={`btn btn-success ${isShown === false ? "button-moused-out" : ""}`}
            data-toggle="button"
            onClick={() => {
                setIsShown(false)
                setTimeout(() => {
                    setIsShown(true)
                })
            }}>
            Add CSV Bundle File
        </button>
    );
};

class DeviceTypeTable extends Component {

    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showItemModal: false,
            simId: 0,
            simReseller: 0,
            simPrice: 0,
            simItemDescription: "",
            simItemName: "",
            carrier: -1,
            FolioPrefixString: "",
            Series: "",
            U_DOCD: "",
            U_ARET: "",
            U_CDOC: "",
            U_EST: "",
            U_FVTD: "",
            U_PDE: "",
            U_TIM: "",
            U_SFTE: "",
            U_UNIDAD: "",
            ControlAccount: "",
            U_moneda_origen: "",
            Printed: "",
            WhsCode: "",
            TaxCode: "",
            U_STIM: "",
            U_FITE: "",
            userObj: getCurrentUser(),
            feedback: '',
            isFilePreviewDropdownOpen: false,
            fileDetails: [],
            fileSelected: null,
            isFilePreviewDropdownVisible: false,
            isFilePreviewTableVisible: false,
            isFileUploadVisible: false,
            fileUploadFormdata: null,
            showInvItemModal: false,
            invItemEditData: null,
            invItemEditIndex: null
        };
        this.handleFilePreviewSelect = this.handleFilePreviewSelect.bind(this);
        this.handleLoadFileClick = this.handleLoadFileClick.bind(this);
        // this.addMultiCustomersInventory = this.addMultiCustomersInventory.bind(this);
        this.handleFilePreviewDropdownToggle = this.handleFilePreviewDropdownToggle.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.handleRecordUpload = this.handleRecordUpload.bind(this);
        this.staticDataRef = React.createRef();
        this.addCSVBundleRef = React.createRef();
        this.uploadFileRef = React.createRef();

        // this.withOperationsButton = this.withOperationsButton.bind(this);
        this.inventoryDelete = this.inventoryDelete.bind(this);
        this.inventoryEdit = this.inventoryEdit.bind(this);
        this.noAction = this.noAction.bind(this);
        this.renderInventoryItemModal = this.renderInventoryItemModal.bind(this);
        this.onEditSave = this.onEditSave.bind(this);
        this.onEditClose = this.onEditClose.bind(this);
        this.onEditOpen = this.onEditOpen.bind(this);
        this.editButton = this.editButton.bind(this);
    }
    componentDidMount() {
        this.props.getPageContent({ "content": "Telecom App" });
        this.props.getAllConfig();
    }
    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 368) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }

    cellItemEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.onEditItemOpen(row) }} />
            </div>
        );
    };



    onItemOpen = () => {
        this.staticDataRef.current.blur();
        this.props.getAllConfig();
        this.setState({
            showItemModal: true,
            simId: 0,
            simReseller: 0,
            simItemDescription: "",
            simPrice: "",
            simItemName: "",
            carrier: -1
        })
        for (let i = 0; i < this.props.allConfig.length; i++) {

            let itemName = this.props.allConfig[i].itemname;
            let itemValue = this.props.allConfig[i].itemvalue;
            this.setState({
                [itemName]: itemValue
            });

        }
    }

    onEditItemOpen = (row) => {

        this.setState({
            showItemModal: true,
            simId: row.itemId,
            companyCode: this.props.currentUserObj.companyCode,
            simItemDescription: row.itemDescription,
            simPrice: row.unitPrice,
            simItemName: row.itemName,
            carrier: row.carrier.carrierId
        })

    }
    onItemClose = () => {
        this.staticDataRef.current.blur();
        this.setState({
            showItemModal: false
        })
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    validateForm = (Management) => {
        if (Management == "item") {
            if (this.state.FolioPrefixString != "") {

                return true;

            }
        }
        return false;

    }

    onItemSubmit = () => {
        var data = {};
        for (let i = 0; i < this.props.allConfig.length; i++) {
            var element = this.props.allConfig[i];
            var itemName = this.props.allConfig[i].itemname;
            data = {
                appname: element.appname,
                companycode: element.companycode,
                displayname: element.displayname,
                id: element.id,
                itemname: element.itemname,
                itemvalue: this.state[itemName],
                modulename: element.modulename
            }
            console.log(data)
            updateConfig(data);
        }
        this.props.getAllConfig();
        for (let i = 0; i < this.props.allConfig.length; i++) {
            let itemName = this.props.allConfig[i].itemname;
            let itemValue = this.props.allConfig[i].itemvalue;

            this.setState({
                [itemName]: itemValue
            });

        }
        this.onItemClose();
    }

    // addMultiCustomersInventory(formdata) {
    //     const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_LOAD_CUSTOMER_INVENTORY}`;
    //     const request = axios({
    //         url: url,
    //         method: 'post',
    //         headers: {
    //             'Content-Type': 'application/json',
    //         },
    //         data: formdata,
    //     })

    //     request.then((response) => {

    //         if (response.status === 200) {
    //             console.log(response)

    //             if (response.data === "success") {
    //                 toast.success(response.data);
    //             }
    //             else {
    //                 toast.error(response.data);
    //             }

    //         } else {
    //             toast.error(response.data);
    //         }
    //     })
    // }

    handleFilePreviewSelect = (fileName) => {
        // filter files by fileName selected
        let selectedFile = this.state.fileDetails.filter(file => file.fileName === fileName);
        console.log('selectedFile', selectedFile);

        // get headers
        // console.log()

        this.setState({ fileSelected: selectedFile, isFilePreviewTableVisible: true });

        // setTimeout(() => {
        //     this.setState({ isFilePreviewTableVisible: true });
        // }, 1000)
    }

    handleFilePreviewDropdownToggle = () => {
        let { isFilePreviewDropdownOpen } = this.state;
        isFilePreviewDropdownOpen = !isFilePreviewDropdownOpen;
        this.setState({ isFilePreviewDropdownOpen });
    }

    handleLoadFileClick = async (files) => {
        this.addCSVBundleRef.current.blur();
        if (!sessionStorage.getItem('companyCode') || !sessionStorage.getItem('currentUser')) {
            return;
        }

        let { fileDetails, isFilePreviewDropdownVisible,
            isFilePreviewTableVisible, isFileUploadVisible,
            fileUploadFormdata, feedback } = this.state;

        fileDetails = [];
        // Do not set to avoid flashing
        // isFilePreviewDropdownVisible = false;
        isFilePreviewTableVisible = false;
        // Do not set to avoid flashing
        // isFileUploadVisible = false;
        fileUploadFormdata = null;

        this.setState({
            fileDetails, isFilePreviewTableVisible,
            fileUploadFormdata
        });

        var formdata = new FormData();
        formdata.append("file", files[0]);
        formdata.append("companyCode", this.state.userObj.companyCode);

        var csvBundle = new JSZip();
        if (files[0] !== null) {
            csvBundle.loadAsync(files[0]).then(async (inventory) => {
                // console.log('inventory', JSON.stringify(inventory))

                // ideas:
                // Loop through filenames and show a toast of a file is missing

                // get filenames for dropdown
                // populate state fileDetails
                // let { fileDetails, isFilePreviewDropdownVisible,
                //     isFileUploadVisible, fileUploadFormdata } = this.state;

                console.log('BEFORE READ')

                // get object keys on files attribute
                let objectKeysFromFilesAttribute = Object.keys(inventory.files)
                for (let inv = 0; inv < objectKeysFromFilesAttribute.length; inv++) {
                    const invElement = objectKeysFromFilesAttribute[inv];
                    console.log(invElement);
                    let splitElement = invElement.split('/');
                    console.log('splitElement', splitElement);
                    let folderWithFile = (takeRight(splitElement, splitElement.length - 1)).join('/');
                    console.log('folderWithFile', folderWithFile);
                    if (inv >= 1 && invElement !== null && invElement.includes('.csv')) {
                        let folderWithFileContent = await inventory.file(invElement).async("text");
                        console.log('folderWithFileContent', folderWithFileContent);
                        let fileDetail = {
                            fileName: folderWithFile,
                            fileContent: folderWithFileContent,
                            tableId: (folderWithFile.split('/')[folderWithFile.split('/').length - 1]).replace(".csv", "")
                        }
                        fileDetails.push(fileDetail);
                    }
                }

                console.log('AFTER READ')

                // this.setState({ fileDetails });

                // This is good, now do it with papaparse
                // for (let f = 0; f < fileDetails.length; f++) {
                //     const detail = fileDetails[f];
                //     let headers = detail.fileContent.split('\n')[0].split(';');
                //     headers[headers.length - 1] = headers[headers.length - 1].replace("\r", "");
                //     headers.unshift('id');
                //     console.log('headers', headers);

                //     let data = {}
                //     for (let h = 0; h < headers.length; h++) {
                //         const heading = headers[h];
                //         data[heading] = ''
                //     }
                //     console.log('data', data)
                // }

                console.log('BEFORE HEADERS')

                // get headers and remove any carriage return
                for (let f = 0; f < fileDetails.length; f++) {
                    let detail = fileDetails[f];
                    let headers = detail.fileContent.split('\n')[0].split(';');
                    for (let h = 0; h < headers.length; h++) {
                        headers[h] = headers[h].replace("\r", "");
                    }
                    headers.unshift('Index');
                    // headers.push('Operations');
                    fileDetails[f].headers = headers
                    // fileDetails[f].headers.unshift('Index');
                }

                console.log('AFTER HEADERS')

                console.log('fileDetails.length', fileDetails.length)

                if (fileDetails.length === 0) {
                    toast.error(`Bundle was empty or had invalid file types`);
                    return;
                }

                for (let f = 0; f < fileDetails.length; f++) {
                    let detail = fileDetails[f];
                    let detailParse = parse(detail.fileContent, papaparseConfig);
                    console.log('detailParse', detailParse);
                }

                for (let f = 0; f < fileDetails.length; f++) {
                    let detail = fileDetails[f];
                    let detailParse = parse(detail.fileContent, papaparseConfig);
                    console.log('detailParse', detailParse);

                    if (detailParse.errors && detailParse.errors.length > 0) {
                        console.log(`Parse error`)
                        toast.error(`${detail.fileName} was not a valid CSV file`);
                        return;
                    }

                    for (let p = 0; p < detailParse.data.length; p++) {
                        const parseObj = detailParse.data[p];
                        parseObj.Index = p;
                        // parseObj.Delete = p;
                        // parseObj.Edit = p;

                        console.log('fileName in parse', detail)
                        console.log('File match', detail.tableId)
                        console.log('edit list', editableFiles)
                        console.log('fileName in edit list', editableFiles.filter((file) => file.includes(detail.tableId)))

                        // console.log('fileName in parse', detail)
                        let editableFilesResult = editableFiles.filter((file) => file.includes(detail.tableId))
                        if (editableFilesResult.length > 0) {
                            parseObj.Delete = p;
                            parseObj.Edit = p;
                        }
                    }

                    detail.parse = detailParse.data;
                    console.log(detail);

                    fileDetails[f] = detail;
                }
                fileDetails = fileDetails.reverse();
                isFilePreviewDropdownVisible = true;
                isFileUploadVisible = true;
                fileUploadFormdata = formdata;
                feedback = 'csv bundle added'
                this.setState({
                    fileDetails, isFilePreviewDropdownVisible,
                    isFileUploadVisible, fileUploadFormdata, feedback, /*testRipple:false*/
                });

            }).catch((error) => {
                toast.error(`Bundle was not a zip file`);
            })
        }
    }

    handleFileUpload = () => {
        this.uploadFileRef.current.blur();
        const { fileUploadFormdata } = this.state;
        console.log('fileUploadFormdata', fileUploadFormdata);
        this.props.addMultiCustomersInventoryRecords(fileUploadFormdata);
    }

    handleRecordUpload = () => {
        this.uploadFileRef.current.blur();
        let { fileDetails } = this.state;
        console.log('fileDetails', fileDetails)
        let chaRatingDetails = fileDetails.filter(file => file.fileName.includes('DIS_RATING_DETAILS'))

        let records = []

        for (let r = 0; r < chaRatingDetails[0].parse.length; r++) {
            const record = chaRatingDetails[0].parse[r];
            let convertedRecord = camelizeKeys(record);
            if (convertedRecord.feeSegment == "Customer") {
                records.push(convertedRecord)
            }
        }

        console.log('records', records)
        this.props.addMultiCustomersInventoryRecords(records)
    }

    renderInventoryItemModal() {
        let { invItemEditData, showInvItemModal, invItemEditIndex } = this.state;

        return (showInvItemModal
            && <InventoryItemModal
                data={invItemEditData}
                dataIndex={invItemEditIndex}
                show={showInvItemModal}
                onSave={this.onEditSave}
                onClose={this.onEditClose} />)
    }

    inventoryDelete() {

        const { fileSelected } = this.state;

        let isEditable = editableFiles
            .filter((file) => file.includes(fileSelected[0].tableId)).length > 0

        return (
            <TableHeaderColumn
                width="120px" row='0'
                rowSpan='2' dataField='Delete'
                hidden={isEditable ? false : true}
                dataFormat={isEditable ? this.deleteButton : this.noAction}
            >
                Delete
            </TableHeaderColumn>
        )

    }

    inventoryEdit() {

        const { fileSelected } = this.state;

        let isEditable = editableFiles
            .filter((file) => file.includes(fileSelected[0].tableId)).length > 0

        return (
            <TableHeaderColumn
                width="120px" row='0'
                rowSpan='2' dataField='Edit'
                hidden={isEditable ? false : true}
                dataFormat={isEditable ? this.editButton : this.noAction}
            >
                Edit
            </TableHeaderColumn>
        )
    }

    onEditSave = (invItem) => {
        console.log('invItem', invItem)
        const { invItemEditIndex, fileSelected } = this.state
        fileSelected[0].parse[invItemEditIndex] = invItem
        this.setState({ invItemEditIndex, fileSelected })
    }

    onEditClose = () => {
        this.setState({ showInvItemModal: false })
    }

    onEditOpen = (index, row) => {
        console.log('index', index, 'row', row)

        let { invItemEditData, showInvItemModal, invItemEditIndex, fileSelected } = this.state;
        invItemEditData = fileSelected[0].parse[index];
        invItemEditIndex = index;

        console.log('invItemEditData', invItemEditData)

        showInvItemModal = true;

        this.setState({ invItemEditData, showInvItemModal, invItemEditIndex })
    }

    onDeleteRow = (index, row) => {
        console.log('index', index, 'row', row)
        let { fileSelected } = this.state;
        fileSelected[0].parse.splice(index, 1);
        this.setState({ fileSelected })
    }

    // withOperationsButton = (cell, row, enumObject, index) => {

    //     const { fileSelected } = this.state;

    //     if (editableFiles
    //         .filter((file) => file.includes(fileSelected[0].tableId)).length > 0) {

    //         return (
    //             <div className={'item-operation-container'}>
    //                 {cell}<EditSharpIcon
    //                     className={'item-edit-tag'}
    //                     label={''}
    //                     onClick={(e) => { this.onEditOpen(index, row) }} />
    //                 <DeleteSharpIcon
    //                     className={'item-delete-tag'}
    //                     label={''}
    //                     onClick={(e) => { this.onDeleteRow(index, row) }} />
    //             </div>
    //         );

    //     } else {

    //         return (
    //             <div>{cell}</div>
    //         );

    //     }

    // };

    noAction = (cell, row, enumObject, index) => {
        return;
    }

    editButton = (cell, row, enumObject, index) => {
        console.log('cell', cell)
        console.log('row', row)
        console.log('enumObject', enumObject)
        console.log('index', index)
        return (
            <div className={'text-center'}>
                <EditTextButton label={''} onClick={(e) => { this.onEditOpen(index, row) }} />
            </div>
        );
    };

    deleteButton = (cell, row, enumObject, index) => {
        console.log('cell', cell)
        console.log('row', row)
        console.log('enumObject', enumObject)
        console.log('index', index)
        return (
            <div className={'text-center'}>
                <DeleteButton label={''} onClick={(e) => { this.onDeleteRow(index, row) }} />
            </div>
        );
    };

    renderItemModal() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showItemModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onItemClose}>{(this.state.simId != 0) ? `Customize` : `Edit Static Data`}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Invoice prefix String <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='FolioPrefixString'
                                    id='FolioPrefixString'
                                    placeholder={this.state.FolioPrefixString}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.FolioPrefixString}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Series <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='Series'
                                    id='Series'
                                    placeholder={this.state.Series}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.Series}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Declarable Document <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_DOCD'
                                    id='U_DOCD'
                                    placeholder={this.state.U_DOCD}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_DOCD}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Withholding applies <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_ARET'
                                    id='U_ARET'
                                    placeholder={this.state.U_ARET}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_ARET}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>U_CDOC <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_CDOC'
                                    id='U_CDOC'
                                    placeholder={this.state.U_CDOC}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_CDOC}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Establishment <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_EST'
                                    id='U_EST'
                                    placeholder={this.state.U_EST}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_EST}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Stamp Expiration Date <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_FVTD'
                                    id='U_FVTD'
                                    placeholder={this.state.U_FVTD}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_FVTD}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Expedition Point string <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_PDE'
                                    id='U_PDE'
                                    placeholder={this.state.U_PDE}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_PDE}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>U_TIM <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_TIM'
                                    id='U_TIM'
                                    placeholder={this.state.U_TIM}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_TIM}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>U_SFTE	 <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_SFTE'
                                    id='U_SFTE'
                                    placeholder={this.state.U_SFTE}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_SFTE}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>U_UNIDAD <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_UNIDAD'
                                    id='U_UNIDAD'
                                    placeholder={this.state.U_UNIDAD}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_UNIDAD}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Control Account <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='ControlAccount'
                                    id='ControlAccount'
                                    placeholder={this.state.ControlAccount}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.ControlAccount}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>U_moneda_origen	 <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_moneda_origen'
                                    id='U_moneda_origen'
                                    placeholder={this.state.U_moneda_origen}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_moneda_origen}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Printed	 <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='Printed'
                                    id='Printed'
                                    placeholder={this.state.Printed}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.Printed}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Warehouse Code<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='WhsCode'
                                    id='WhsCode'
                                    placeholder={this.state.WhsCode}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.WhsCode}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Tax Code	 <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='TaxCode'
                                    id='TaxCode'
                                    placeholder={this.state.TaxCode}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.TaxCode}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>U_STIM <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_STIM'
                                    id='U_STIM'
                                    placeholder={this.state.U_STIM}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_STIM}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>U_FITE <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='U_FITE'
                                    id='U_FITE'
                                    placeholder={this.state.U_FITE}
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.U_FITE}
                                />
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onItemSubmit} disabled={!this.validateForm("item")}>
                            Submit
                        </Button>}
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onItemSubmit} disabled={!this.validateForm("item")}>
                            Submit
                        </Button>}
                    <Button outline color='secondary' type="reset" onClick={this.onItemClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const { isFilePreviewDropdownVisible, fileDetails, isFileUploadVisible,
            fileSelected, isFilePreviewTableVisible, userObj } = this.state

        const { pageContentList, manualBillingStatus } = this.props

        /* const options = {
            sizePerPageList:[{
                text:10,value:10
              },{
                text:50,value:50
              },{
                text:100,value:100
              },{
                text:500,value:500
              }]
        }; */

        return (
            <div className={DeviceTypeTableStyles['max-content-nav']}>
                {this.renderItemModal()}
                {this.renderInventoryItemModal()}
                <div className={DeviceTypeTableStyles['label-for-header']}>Options</div>
                <div className={"clearfix"}>
                    {/* <InsertButton
                        className={"float-left"}
                        btnText='Add Device Types'
                        btnContextual='btn-success'
                        btnGlyphicon='glyphicon-edit'
                        onClick={this.onItemOpen}
                    >
                        StaticData
                    </InsertButton> */}
                    {<button type="button"
                        //id="billingApp"
                        className={`btn btn-secondary float-left ${DeviceTypeTableStyles['integration-button']}`}
                        ref={this.staticDataRef}
                        onClick={this.onItemOpen}
                        disabled={manualBillingStatus}
                    /* disable this while manual billing in progress */
                    >
                        Static Data
                    </button>}
                    <div className={"float-left"}>
                        <ReactFileReader handleFiles={this.handleLoadFileClick} fileTypes={'.zip'}>
                            {/* <InsertButton
                                btnText='Add Device Types'
                                btnContextual='btn-success'
                                btnGlyphicon='glyphicon-edit'
                                ref={this.addCSVBundleRef}
                            >
                                Add CSV Bundle File
                            </InsertButton> */}
                            {<button type="button"
                                //id="billingApp"
                                className={`btn btn-secondary ${DeviceTypeTableStyles['integration-button']}`}
                                ref={this.addCSVBundleRef}
                            /*  Allow this button to be enabled during manual billing
                                so that the user can keep editing
                            */
                            >
                                Add CSV Bundle File
                            </button>}
                            {/* <ButtonCleared></ButtonCleared> */}
                        </ReactFileReader>
                    </div>
                    {isFilePreviewDropdownVisible && (<div className={"float-left"}>
                        <UncontrolledDropdown id={"preview"}>
                            <DropdownToggle
                                //id="billingApp"
                                color="secondary" caret>
                                Preview Files from Bundle
                            </DropdownToggle>
                            <DropdownMenu>
                                {fileDetails.map((fileDetail, index) => (
                                    <DropdownItem
                                        key={index}
                                        onClick={() => {
                                            this.handleFilePreviewSelect(fileDetail.fileName)
                                        }}
                                    /*  Allow this dropdown item to be enabled during manual billing
                                        so that the user can keep editing
                                    */
                                    >
                                        {fileDetail.fileName}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>)}
                    {isFileUploadVisible && (<div className={"float-left"}>
                        {/* <InsertButton
                            className={"float-left"}
                            btnText='Upload Files'
                            btnContextual='btn-success'
                            btnGlyphicon='glyphicon-edit'
                            onClick={this.handleFileUpload}
                        >
                            Upload Files
                        </InsertButton> */}
                        {<button type="button"
                            //id="billingApp"
                            className={`btn btn-secondary float-left ${DeviceTypeTableStyles['integration-button']}`}
                            ref={this.uploadFileRef}
                            onClick={this.handleRecordUpload}
                            disabled={manualBillingStatus}
                        >
                            {/* {'Upload Files'} */}
                            {'Upload Records'}
                        </button>}
                    </div>)}
                </div>
                <div id={(fileSelected !== null && fileSelected[0].tableId)
                    ? fileSelected[0].tableId : 'inventory'}
                    className={'col-xl-12' + ' ' + DeviceTypeTableStyles['clean-edge']}>
                    {
                        isFilePreviewTableVisible && fileSelected && `File selected: ${fileSelected[0].fileName}`
                    }
                    {/* {
                    this.state.fileSelected === null &&
                    (<BootstrapTable
                        ref={"csvFilePreviewTable"}
                        data={this.state.fileSelected ? this.state.fileSelected[0].parse : [{ deviceType: 0, customerName: 'test' }]}
                        options={options}
                        tableHeaderClass='header_white'
                        striped
                        hover
                        containerStyle={{ fontSize: "1vw" }}
                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        pagination
                        exportCSV={this.state.userObj.companyCode != 'TEISA'}
                    >
                        <TableHeaderColumn isKey ref='nameType' dataField='deviceType' dataAlign='center' hidden={false} export={false} csvHeader='Device Type'>deviceType</TableHeaderColumn>
                        <TableHeaderColumn ref='nameType' dataField='customerName' dataAlign='center' hidden={false} export={false} csvHeader='Device Type'>customerName</TableHeaderColumn>
                    </BootstrapTable>)
                } */}


                    {
                        isFilePreviewTableVisible && fileSelected && fileSelected[0].parse &&
                        (<BootstrapTable
                            ref={"csvFilePreviewTable"}
                            data={fileSelected[0].parse}
                            options={options}
                            tableHeaderClass='header_white'
                            striped
                            hover
                            containerStyle={{ fontSize: "1vw" }}
                            bordered={false}
                            height='100%'
                            bodyStyle={{ overflow: 'overlay' }}
                            pagination
                            exportCSV={pageContentList.includes("Integration Files DeviceTypeTable *NO*") ? false : true}
                        >
                            {/* {
                            fileSelected[0].headers.map((header, index) => {
                                return header !== null && <TableHeaderColumn
                                            row='0'
                                            key={index}
                                            ref={header + 'sheet'}
                                            dataField={header}
                                            dataAlign='center'
                                            hidden={header === 'Index'}
                                            export={false}
                                            csvHeader={header}
                                            width='150'
                                            dataFormat={this.withOperationsButton}>
                                                {String.fromCharCode(64 + index)}
                                        </TableHeaderColumn>
                            })
                        } */}
                            {
                                fileSelected[0].headers.map((header, index) => {
                                    return header !== null && <TableHeaderColumn
                                        row='1'
                                        key={index}
                                        isKey={header === 'Index'}
                                        ref={header + 'Type'}
                                        dataField={header}
                                        dataAlign='center'
                                        hidden={header === 'Index'}
                                        export={false}
                                        csvHeader={header}
                                        width='150'
                                        dataSort
                                        caretRender={sortCaret}
                                        filter={{ type: 'TextFilter', placeholder: header }}
                                    // dataFormat={this.withOperationsButton}
                                    >
                                        {header}
                                    </TableHeaderColumn>
                                })
                            }
                            {/* {this.inventoryDelete()}
                            {this.inventoryEdit()} */}
                        </BootstrapTable>)
                    }
                    <div style={{ height: '147px' }}></div>

                    {/*
                    this.state.isFilePreviewTableVisible && this.state.fileSelected && this.state.fileSelected[0].parse &&
                    <Spreadsheet data={this.state.fileSelected[0].parse} onChange={() => {
                        console.log('Changed data')
                    }} />
                */}




                    {/* <BootstrapTable
                        ref={"csvFilePreviewTable"}
                        data={this.state.fileSelected ? this.state.fileSelected[0].parse : [{ deviceType: 0, customerName: 'test' }]}
                        options={options}
                        tableHeaderClass='header_white'
                        striped
                        hover
                        containerStyle={{ fontSize: "1vw" }}
                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        pagination
                        exportCSV={this.state.userObj.companyCode != 'TEISA'}
                    >
                        <TableHeaderColumn isKey ref='nameType' dataField='deviceType' dataAlign='center' hidden={false} export={false} csvHeader='Device Type'>deviceType</TableHeaderColumn>
                        <TableHeaderColumn ref='nameType' dataField='customerName' dataAlign='center' hidden={false} export={false} csvHeader='Device Type'>customerName</TableHeaderColumn>
                </BootstrapTable> */}
                </div>
                <div class="d-none">
                    <BootstrapTable
                        data={this.props.SIMTypeList}
                        tableHeaderClass='header_white'
                        striped
                        hover
                        hidden={true}
                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        containerStyle={{ fontSize: "1vw" }}
                    /* options={options}

                    pagination={true} */
                    >
                        <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort >SIM Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Description' }} dataSort>SIM Descriptin</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' dataFormat={this.priceFormatter.bind(this)} dataAlign='center' dataSort filter={{ type: 'NumberFilter', delay: 1000 }}>Price</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='Operations'
                            dataAlign="center"
                            dataFormat={this.cellItemEditButton.bind(this)}
                        >
                            Operations
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div >
        )
    }
}
function mapStateToProps(state) {
    let {
        manualBillingStatus
    } = state.inventory;

    const { pageContentList } = state.user

    let { allConfig } = state.weather;

    return {
        allConfig,
        pageContentList,
        manualBillingStatus
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllConfig,
        updateConfig,
        addMultiCustomersInventoryRecords,
        getPageContent
    }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypeTable);