/* eslint-disable */
import React, {useEffect, useState, useRef} from 'react'
import { connect} from 'react-redux'
import classnames from 'classnames'
import { loadPeopleCountRecords } from '../../../../redux/actions/surveilenceActions'
import { postAssetAttribute, loadAssets } from '../../../../redux/actions/assetActions';
import { Row, Col, Label, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse } from 'reactstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import PeopleCountInfo from './PeopleCountInfo';
import PeopleCountGraph from './PeopleCountGraph';
import IndividualCamChart from './IndividualCamChart';
import './SmartBuilding.css'
import { EditIcon} from 'mdi-react';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { LabelTextInput } from '../cameraList/CameraCreateModal';
import { Client } from '@stomp/stompjs';
import { WEB_SUB_SOCKET } from '../../../../constants/appConstants';
import { getJwttoken } from '../../../../factories/utils';
import { sendSMS } from '../../../../factories/utils';



export const  renderSpinner = () =>{
    return <Spinner
        className="spinner"
        as="span"
        animation="border"
        variant="success"
        // size="sm"
        // role="status"
        // aria-hidden="true"
        style={{display: 'inline-block', margin: 'auto',position:"absolute" }}
    />;
}
const PeopleCountBoard = ({location, peopleCountRecords, loadPeopleCount,  postAssetAttribute, loadAssets}) => {
    const [LastUpdateTime, setLastUpdateTime] = useState(null)
    const [CurrentLocationData, setCurrentLocationData] = useState(null)
    const [hourlyData, sethourlyData] = useState(null);
    const [LimitModal, setLimitModal] = useState(false)
    const [PeopleLimit, _setPeopleLimit] = useState(null)
    const [PeopleLimitInput, setPeopleLimitInput] = useState(null)
    const [alertReceivers, setalertReceivers] = useState([])
    const [stompClient, setstompClient] = useState(null)
    const [locationName, setlocationName] = useState('')
    const [stayCount, _setstayCount] = useState(null)
    const [alreadySend, setalreadySend] = useState(false)
    const PeopleLimitRef = useRef(PeopleLimit);
    const stayCountRef = useRef(stayCount);
    const setPeopleLimit = (data)=>{
        PeopleLimitRef.current = data;
        _setPeopleLimit(data);
    }
    const setstayCount = (data)=>{
        stayCountRef.current = data;
        _setstayCount(data);
    }



    useEffect(()=>{
        // console.log('LOAD PEOPLE COUNT RECORDS.....')
        if(location){
            // console.log("PC location,",location.assetname)
            let capacityLimit = location.assetattributes.find(el=>el.attmetaname == 'capacity')
            if(capacityLimit) {
                // console.log('CAPACITY LIMIT: ',capacityLimit)
                setPeopleLimit(parseInt(capacityLimit.value))
                setPeopleLimitInput(parseInt(capacityLimit.value))
            } 
            setlocationName(location.assetname)
        }
    },[location])

    useEffect(() => {
        loadPeopleCount();
        connectSocket();
    }, [])

    useEffect(() => {
        // console.log('PEOPLE LIMIT: ', PeopleLimit)
        // console.log('STAY COUNT: ', stayCount)
        if ((PeopleLimit !== null) && (stayCount !== null) && (stayCount >= PeopleLimit)) {
            // console.log('SHOULD SEND SMS', PeopleLimit, stayCount)
            // sendSMS(['6475511693'], `Warning: A capacity limit of ${PeopleLimit} has been reached at the ${plazaLocation}`)
            // sendSMS(["416-371-6616", "416-402-9449", "416-402-4558", "647293-4022"], `“You have reached the store capacity of 3 – action is required`)
            // sendSMS(alertReceivers, `You have reached the store capacity of ${PeopleLimit}– action is required`)
            // setPeopleLimit(null)
        }
        setalreadySend(true);
    }, [PeopleLimit, stayCount])
    
    useEffect(() => {
        if(stompClient){
            // console.log('PPCount SOCKET CONNECTED', stompClient);
            stompClient.onConnect = connect_callback;
            stompClient.onDisconnect = disconnect_callback;
            stompClient.activate();
        }
    }, [stompClient])   

    useEffect(() => {
        // console.log('PEOPLE COUNT RECORDS ===', peopleCountRecords)
        // if(location) console.log(location.assetname);
        if(peopleCountRecords && peopleCountRecords.length > 0){
            let local = peopleCountRecords.filter(i=> i.location.includes(locationName))
            // console.log("local data",local)
            // debugger;
            setCurrentLocationData(local);
            //temporary solution
            // console.log('LOCAL RECORDS', local)
            if(local.length > 0){
                let lastRow = local[local.length - 1]
                setLastUpdateTime(moment(lastRow.timestamp).utc().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss'))
            }
        }
    }, [peopleCountRecords, locationName]);

    const connectSocket =()=> { 
        if (stompClient !== null && stompClient!==undefined) {
            stompClient.deactivate();
            //stompClient = null;
        }
        
        let query='?q='+getJwttoken();
        ///////////////////////////////////////////////////
        setstompClient(new Client({
            // brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query,
            brokerURL: `${WEB_SUB_SOCKET}`+query,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        }));
        
        
        //////////////////////////////////////////////////
        //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
        //stompClient = Stomp.over(socket_URL); 
        //stompClient.reconnectDelay=5000; 
        //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;
    
    }

    const connect_callback =()=> {
        //console.log('connected to websoket');
        // called back after the stompClient is connected and authenticated to the STOMP server
        // stompClient.subscribe('/topic/alarmLinePassing', callbackDeviceMessage);
        stompClient.subscribe('/topic/Pcountmessage', callbackDeviceMessage);
    }
    const disconnect_callback =()=> {
        stompClient.deactivate();
        // called back after the stompClient is connected and authenticated to the STOMP server
        //console.log('client disconnected')
        
    }
    const callbackDeviceMessage = (message) => {
        // console.log('PPC DETECTED--------------------------', message)
        // //console.log(message.body);
        // console.log('SHOULD SEND SMS', PeopleLimitRef.current, stayCountRef.current)
        if ((PeopleLimitRef.current !== null) && (stayCountRef.current !== null) && (stayCountRef.current == 2)) {
            // console.log('SHOULD SEND SMS')
            // sendSMS(['6475511693'], `Warning: A capacity limit of ${PeopleLimit} has been reached at the ${plazaLocation}`)
            // sendSMS(["416-371-6616", "416-402-9449", "416-402-4558", "647293-4022"], `You have reached the store capacity of 3 – action is required`)
            sendSMS(alertReceivers, `You are currently at building capacity – action required`)
            // setPeopleLimit(null)
        }
        loadPeopleCount();
    }


    const toggleCountLimit = () =>
    {
        setLimitModal(!LimitModal)
    }

    const handleAddAlertReceiver = (phone) => {
        // console.log("=======ITEM ADDED", phone);
        let tempArr = alertReceivers;
        tempArr.push(phone);
        setalertReceivers(tempArr)
    }

    const handleDeleteReceiver = (phone) => {
        // console.log("ITEM DELETED",phone);
        let tempAlertReceivers = alertReceivers;
        if(tempAlertReceivers){
            const index = tempAlertReceivers.indexOf(phone);
            if(index > -1){
                tempAlertReceivers.splice(index, 1)
            }
            setalertReceivers(tempAlertReceivers)
        }
    }

    const handlePeopleLimitChange = e => {
        setPeopleLimitInput(e.target.value);
    }

    const handleStayCountChange = value => {
        setstayCount(value);
    }

    const handleLimitSubmit = () =>{
        setPeopleLimit(PeopleLimitInput);
        if(location){
            let attribute = location.assetattributes.find(el=> el.attmetaname == 'capacity')
            if(attribute){
                let param = {
                    assetid: attribute.assetid,
                    attmetaid: attribute.attmetaid,
                    value: PeopleLimitInput
                }

                // console.log('PARAM', param)

                updateCapacity(param)
                
            }
        }
        setLimitModal(false);
    }


    const updateCapacity = (data) => {
        //console.log('UPDATE CAPACITY')
    
        postAssetAttribute(data).then(
            res => { loadAssets() }
        );
    }
    
    if (CurrentLocationData && CurrentLocationData.length){
        return (
            <>
                <Card>
                    <CardBody>
                        
                        <Row>
                            <Col md={6}>
                                <span style={{marginTop: "50px"}}>
                                    <span style={{fontWeight:"bold", fontSize: "1.5em", display: "inline"}}>
                                    Last Updated Date: 
                                    </span>  
                                    <p style={{fontSize: "1.2rem", display: "inline"}}>{LastUpdateTime ?' ' + LastUpdateTime: renderSpinner()}</p>
                                </span>
                            </Col>
                        </Row>
                        <Row className="box-container-ai">
                        <PeopleCountInfo localData={CurrentLocationData} handleStayCountChange={handleStayCountChange}/>
                        </Row>
                        <Row className="box-container-graph">
                            <Col md={4}>
                            <span className="capacity">
                                    Capacity Limit: 
                                    <span className="limit" style={{margin: '5px', padding: '10px'}}>{PeopleLimit}</span>
                                    <a type="button" className="limit-edit" onClick={toggleCountLimit}><EditIcon/></a>
                            </span>
                        </Col>
                            <PeopleCountGraph 
                            localData={CurrentLocationData}
                            limit={PeopleLimit}
                            />
                        </Row>
                        <IndividualCamChart localData={CurrentLocationData}/>
                    </CardBody>
                </Card>
    
                <Modal toggle={toggleCountLimit} isOpen={LimitModal}>
                    <ModalHeader toggle={toggleCountLimit} tag="h3">Set Building Capacity Limit</ModalHeader>
                    <ModalBody>
                        <LabelTextInput tag="h5" forIdName="peopleLimit" label="Number of People Allowed" value={PeopleLimitInput} type="text" onChange={handlePeopleLimitChange}/>
                        <FormGroup row>
                    <Col md={3}>
                        <Label tag="h5" htmlFor={"alertReceiver"}>Alert Receiver(s)</Label>
                    </Col>
                    <Col md={9}>
                        <MultipleValueTextInput
                            name="receivers-input"
                            values = {alertReceivers}
                            onItemAdded={(item,allItems)=>handleAddAlertReceiver(item)}
                            onItemDeleted={(item,allItems)=>handleDeleteReceiver(item)}
                        />
                    </Col>
                </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleLimitSubmit}>Set Limit</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }else{
        return (
            <>
                <Card>
                    <CardBody>
                        <Row>
                            <span className="noData"> Currently there is no people counting data in this location</span>
                        </Row>
                    </CardBody>
                </Card>
            </>
        )
    }
    
}


const mapStateToProps = (state) => ({
     peopleCountRecords: state.surveillance.peopleCountRecords
    
})

const mapDispatchToProps = (dispatch) => {
    return {
        loadPeopleCount: ()=> dispatch(loadPeopleCountRecords()),
        postAssetAttribute: (data)=>dispatch(postAssetAttribute(data)),
        loadAssets: ()=>dispatch(loadAssets()),
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleCountBoard)

