/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { ChromePicker} from 'react-color';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
// import { ENGINE_METHOD_DIGESTS } from 'constants';
import {getAssetClassAttribute, clearAssetClassAttribute} from '../../../redux/actions/mapActions';
import {generateSelectOptions} from '../../../factories/utils';
import classNames from 'classnames';

class AssetSelectModal extends Component{
    constructor(props){
        super(props);
         
        this.state = {
            isNew: true,
            btnDisable: false,
            attribute: {},
            selectedAssetClassOption: null,
            // allAssetOptions: [
            //     {value: 1, label: 'Asset 1'},
            //     {value: 2, label: 'Asset 2'},
            //     {value: 3, label: 'Asset 3'}
            // ],
            allAssetOptions: [],
            selectedAssetOption: null,
            AssetDeviceOptions: [
                {value: 1, label: 'Device 1'},
                {value: 2, label: 'Device 2'},
                {value: 3, label: 'Device 3'}
            ],
            selectedDeviceOption: null,
            // AssetAttributeOption: [
            //     {value: -1, label: 'Position'},
            //     {value: 1, label: 'Speed'},
            //     {value: 2, label: 'Temperaturn'},
            //     {value: 3, label: 'Valve'}
            // ],
            AssetAttributeOption: [],
            selectedAttributeOption: null,
            useDevice: false,
            isPostback : false,
            tital: 'Choose Asset Attribute',
        }
        this.default = {
            selectedAssetClassOption: null,
            selectedAssetOption: null,
            selectedAttributeOption: null,
            allAssetOptions: [],
            AssetAttributeOption: [],
        }
        this.initial = false;
        this.props.clearAssetClassAttribute();
    }

    componentDidMount(){
         
         
         
        // if (this.props.assetClass) {
        //     assetClassOptions = generateSelectOptions(nextProps.assetClass, 'assetclassname', 'assetclassid');
        //     this.setState({
        //         assetClassOptions:assetClassOptions,
        //     });
        // }
    }
    // static getDerivedStateFromProps(props, state) {
    //     alert('yes get');
    //     return({
    //         // currentDeviceTypeId : props.currentDeviceTypeId,
    //         // currentDeviceTypeName : props.currentDeviceTypeName,
    //         // currentDeviceId : props.currentDeviceId,
    //         // currentDeviceName : props.currentDeviceName,
    //     });
    // } 

    // componentWillReceiveProps(nextProps){

    //      
    //      

    //     let assetClassOptions = [];
    //     let allAssetOptions = [];
    //     let assetAttributeOption = null;
    //     let selectedAssetClassOption = null;
    //     let selectedAssetOption = null;
    //     let selectedAttributeOption = null;
    //     let useDevice = false;

    //     // if (nextProps.ruleItem && nextProps.ruleItem !== this.state.ruleItem){
    //     //     nextProps.getAssetClassAttribute({assetclassid:nextProps.ruleItem.assetclassid});
    //     // }

    //     if (nextProps.ruleItem ){
    //         selectedAssetClassOption = {
    //             value: nextProps.ruleItem.assetclassid,
    //             label: nextProps.ruleItem.assetclassname,
    //         };
    //         selectedAssetOption = {
    //             value: nextProps.ruleItem.assetid,
    //             label: nextProps.ruleItem.assetname,
    //         };
    //         selectedAttributeOption = {
    //             value: nextProps.ruleItem.attributeid,
    //             label: nextProps.ruleItem.attributename,
    //         };
    //         useDevice = nextProps.ruleItem.assetid === -1 ? false: true;

    //         //  
    //         //  
    //         //  
    //         //  

    //         this.setState((prevState) => ({
    //             // selectedAssetClassOption: selectedAssetClassOption,
    //             ruleItem: nextProps.ruleItem
    //         }));
    //         // nextProps.getAssetClassAttribute({assetclassid:nextProps.ruleItem.assetclassid});
    //     }
    //     if (nextProps.assetClass ){            //&& nextProps.assetClass !== this.state.assetClass
    //         assetClassOptions = generateSelectOptions(nextProps.assetClass, 'assetclassname', 'assetclassid');
    //     }

    //     if(assetClassOptions && assetClassOptions.length > 0) {
    //         if (selectedAssetClassOption === null) {
    //             selectedAssetClassOption = assetClassOptions[0];
    //         }
    //         //  
    //         //  
    //         if (!this.state.isPostback){
    //              
    //             nextProps.getAssetClassAttribute({assetclassid:selectedAssetClassOption.value});
    //             this.setState({
    //                 isPostback: true,
    //             });
    //         }else{
    //             selectedAssetClassOption = this.state.selectedAssetClassOption;
    //         }
            
    //         //  
    //         //  
    //         let selectedClass = nextProps.assetClass.find((item, index) => (item.assetclassid === selectedAssetClassOption.value));
    //         if (selectedClass && selectedClass.assets){
    //             //  
    //             allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
    //             //  
    //         }
            
    //     }

    //     if (nextProps.assetClassAttr && nextProps.assetClassAttr.length > 0 && nextProps.assetClassAttr[0].atrributes && nextProps.assetClassAttr !== this.state.assetClassAttr) {
    //          
    //         assetAttributeOption = generateSelectOptions(nextProps.assetClassAttr[0].atrributes, 'displayname', 'attributeid');
    //         assetAttributeOption.push( {value: -1, label: 'Position'});
    //         this.setState({
    //             // AssetAttributeOption: assetAttributeOption,
    //             assetClassAttr: nextProps.assetClassAttr,
    //         });
    //     }else{
    //         assetAttributeOption = this.state.assetAttributeOption;
    //     }

    //     if (this.state.isPostback) {
    //         this.setState((prevState) => (
    //             {
    //                 assetClassOptions:assetClassOptions,
    //                 allAssetOptions: allAssetOptions,
    //                 AssetAttributeOption: assetAttributeOption ? assetAttributeOption : prevState.assetAttributeOption,
    //                 // selectedAssetClassOption: selectedAssetClassOption,
    //                 // selectedAssetOption: selectedAssetOption, 
    //                 // selectedAttributeOption: selectedAttributeOption,
    //                 // useDevice:useDevice,
    //                 // isPostback: true,
    //             }
    //         ));
    
    //     }else{
    //         this.setState((prevState) => (
    //             {
    //                 assetClassOptions:assetClassOptions,
    //                 allAssetOptions: allAssetOptions,
    //                 AssetAttributeOption:  assetAttributeOption ? assetAttributeOption : prevState.assetAttributeOption,
    //                 selectedAssetClassOption: selectedAssetClassOption,
    //                 selectedAssetOption: selectedAssetOption, 
    //                 selectedAttributeOption: selectedAttributeOption,
    //                 useDevice:useDevice,
    //                 // isPostback: true,
    //             }
    //         ));
    
    //     }

    //     // this.setState((prevState) => (
    //     //     {
    //     //         assetClassOptions:assetClassOptions,
    //     //         allAssetOptions: allAssetOptions,
    //     //         AssetAttributeOption: assetAttributeOption,
    //     //         selectedAssetClassOption: selectedAssetClassOption,
    //     //         // selectedAssetOption: selectedAssetOption, 
    //     //         // selectedAttributeOption: selectedAttributeOption,
    //     //         // useDevice:useDevice,
    //     //     }
    //     // ));

    //     // let selectedClass = nextProps.assetClass.find((item, index) => (item.assetclassid === selectedAssetClassOption.value));
    //     // if (selectedClass && selectedClass.assets){
    //     //     allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
    //     // }
    // }
    // --------------------------------------------------------------------------------
    componentWillReceiveProps(nextProps){
         
         

        let assetClassOptions = [];
        let allAssetOptions = [];
        let assetAttributeOption = null;
        let selectedAssetClassOption = null;
        let selectedAssetOption = null;
        let selectedAttributeOption = null;
        let useDevice = false;

        if (nextProps.assetClass ){            //&& nextProps.assetClass !== this.state.assetClass
            assetClassOptions = generateSelectOptions(nextProps.assetClass, 'assetclassname', 'assetclassid');
            // assetClassOptions.unshift({value: null, label: null});
            this.setState({
                assetClassOptions: assetClassOptions,
            });
        }

        if (nextProps.assetClass && nextProps.ruleItem && nextProps.ruleItem != this.state.ruleItem){
             
            selectedAssetClassOption = {
                value: nextProps.ruleItem.assetclassid,
                label: nextProps.ruleItem.assetclassname,
            };
            selectedAssetOption = {
                value: nextProps.ruleItem.assetid,
                label: nextProps.ruleItem.assetname,
            };
            selectedAttributeOption = {
                value: nextProps.ruleItem.attributeid,
                label: nextProps.ruleItem.attributename,
            };
            useDevice = nextProps.ruleItem.assetid === -1 ? false: true;

            //  
            //  
            //  
            //  
            this.default.selectedAssetClassOption = selectedAssetClassOption;
            this.default.selectedAssetOption = selectedAssetOption;
            this.default.selectedAttributeOption = selectedAttributeOption;
            
            this.setState((prevState) => ({
                selectedAssetClassOption: selectedAssetClassOption,
                selectedAssetOption: selectedAssetOption, 
                selectedAttributeOption: selectedAttributeOption,
                useDevice:useDevice,
                ruleItem: nextProps.ruleItem,
                ruleId: nextProps.ruleId,
            }));
            // -------------------------------------------------------------------------------------
            nextProps.getAssetClassAttribute({assetclassid:nextProps.ruleItem.assetclassid});
            this.initial = true;
            let selectedClass = nextProps.assetClass.find((item, index) => (item.assetclassid === nextProps.ruleItem.assetclassid));
            if (selectedClass && selectedClass.assets){
                //  
                allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
                //  
                this.default.allAssetOptions = allAssetOptions;
                this.setState((prevState) => ({
                    allAssetOptions: allAssetOptions,
                }))
            }
        }else if (!nextProps.ruleItem && !this.state.isPostback) { //nextProps.ruleId != this.state.ruleId
             
             
             
            //  if (! this.state.selectedAssetClassOption) {
                 
                // selectedAssetClassOption = assetClassOptions[0];
                selectedAssetClassOption = null;
                this.setState((prevState) => ({
                    selectedAssetClassOption: selectedAssetClassOption,
                    ruleId: nextProps.ruleId,
                    isPostback: true,
                }))
            // }

        }
        // if(assetClassOptions && assetClassOptions.length > 0) {
        //     // if (selectedAssetClassOption === null) {
        //     //      
        //     //     selectedAssetClassOption = assetClassOptions[0];
        //     //     this.setState((prevState) => ({
        //     //         selectedAssetClassOption: selectedAssetClassOption,
        //     //     }))
        //     // }
        // }
        // // -------------------------------------------------
        // if (selectedAssetClassOption && this.state.selectedAssetClassOption && selectedAssetClassOption.value != this.state.selectedAssetClassOption.value){
        //      
        //      
        //      
        //     nextProps.getAssetClassAttribute({assetclassid:selectedAssetClassOption.value});
        //     let selectedClass = nextProps.assetClass.find((item, index) => (item.assetclassid === selectedAssetClassOption.value));
        //     if (selectedClass && selectedClass.assets){
        //         //  
        //         allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
        //         //  
        //         this.setState((prevState) => ({
        //             allAssetOptions: allAssetOptions,
        //         }))
        //     }
        // }

        if (nextProps.assetClassAttr && nextProps.assetClassAttr.length > 0 && nextProps.assetClassAttr[0].atrributes && nextProps.assetClassAttr !== this.state.assetClassAttr) {
             
             
            assetAttributeOption = generateSelectOptions(nextProps.assetClassAttr[0].atrributes, 'displayname', 'attributeid');
            assetAttributeOption.push( {value: -1, label: 'Position'});
            if (this.initial) {
                this.default.AssetAttributeOption = assetAttributeOption;
                this.initial = false;
            }
            
            this.setState({
                AssetAttributeOption: assetAttributeOption,
                assetClassAttr: nextProps.assetClassAttr,
            });
        }
        // else{
        //     assetAttributeOption = this.state.assetAttributeOption;
        // }

    }
    // --------------------------------------------------------------------------------
    // componentWillReceiveProps(nextProps){
    //     // alert('yes');
    //     // if (nextProps.isNew){
    //     //     this.setState({
    //     //         isNew: nextProps.isNew,
    //     //         nameDisable: nextProps.isNew,
    //     //         typeDisable: nextProps.isNew,
    //     //         tital: 'New Attribute',
    //     //         attributename: '',
    //     //         displayname: '',
    //     //         attribute: nextProps.attribute
    //     //     });
    //     // }else{
    //     //     this.setState({
    //     //         isNew: nextProps.isNew,
    //     //         nameDisable: nextProps.attribute.useradded,
    //     //         typeDisable: nextProps.attribute.useradded,
    //     //         tital: nextProps.attribute.attributename,
    //     //         attributename: nextProps.attribute.attributename,
    //     //         displayname: nextProps.attribute.displayname,
    //     //         datatype:  nextProps.attribute.attributetype,
    //     //         attribute: nextProps.attribute
    //     //     });
    //     // }
    //      
    //      
    //      
    //      
    //     let selectedAssetClassOption = null;
    //     let assetClassOptions = [];
    //     let allAssetOptions = [];
    //     let AssetAttributeOption = []
    //     if (nextProps.assetClass && nextProps.assetClass !== this.state.assetClass){            
    //         assetClassOptions = generateSelectOptions(nextProps.assetClass, 'assetclassname', 'assetclassid');
            
    //         if (assetClassOptions && assetClassOptions.length > 0){
    //             selectedAssetClassOption = assetClassOptions[0];
    //             let selectedClass = nextProps.assetClass.find((item, index) => (item.assetclassid === selectedAssetClassOption.value));
    //             if (selectedClass && selectedClass.assets){
    //                 allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
    //             }
    //         }
    //         this.setState({
    //             assetClassOptions:assetClassOptions,
    //             selectedAssetClassOption: selectedAssetClassOption,
    //             allAssetOptions: allAssetOptions,
    //             assetClass: nextProps.assetClass,
    //         });
    //     }        
    //     if (nextProps.assetClassAttr && nextProps.assetClassAttr.length > 0 && nextProps.assetClassAttr[0].atrributes && nextProps.assetClassAttr !== this.state.assetClassAttr) {
    //          
    //         AssetAttributeOption = generateSelectOptions(nextProps.assetClassAttr[0].atrributes, 'displayname', 'attributeid');
    //         AssetAttributeOption.push( {value: -1, label: 'Position'});
    //         this.setState({
    //             AssetAttributeOption: AssetAttributeOption,
    //             assetClassAttr: nextProps.assetClassAttr,
    //         });
    //     }
       
    //     if (nextProps.ruleId >= 0) {
    //         // const assetid = nexProps.ruleItem.assetid;
    //         // const assetname = nexProps.ruleItem.assetname;
    //         // {value: 2, label: "Asset 2"}
    //     //    alert('yes');
    //         const selectedAssetOption = {
    //             value: nextProps.ruleItem.assetid,
    //             label: nextProps.ruleItem.assetname,
    //         };
    //         // const deviceid = nexProps.ruleItem.deviceid;
    //         // const devicename = deviceid === -1 ? "" : nexProps.ruleItem.devicename;
    //         let selectedDeviceOption;
    //         if (nextProps.ruleItem.deviceid == -1 ) {
    //             selectedDeviceOption = null;
    //         }else{
    //             selectedDeviceOption = {
    //                 value: nextProps.ruleItem.deviceid,
    //                 label: nextProps.ruleItem.devicename,
    //             };
    //         }
    //         // const attributeid = nexProps.ruleItem.attributeid;
    //         // const attributename = nexProps.ruleItem.attributename;
    //         const selectedAttributeOption = {
    //             value: nextProps.ruleItem.attributeid,
    //             label: nextProps.ruleItem.attributename,
    //         };
    //         const useDevice = nextProps.ruleItem.deviceid === -1 ? false: true;

            

    //         this.setState({
    //             selectedAssetOption: selectedAssetOption, 
    //             selectedDeviceOption: selectedDeviceOption,
    //             selectedAttributeOption: selectedAttributeOption,
    //             useDevice:useDevice,
    //         });

    //     }
    // }

    // ---------------------------------------------------------------------------
    clearParams = () => {
        this.setState(prevState => {
             
            let newState = {...prevState, ...this.default}
            return newState;
        });
    }
    handleAssetChange = (val) => {
         
        // this.props.loadAssetDevice({assetid: val.value});
        // this.props.loadAssetAttribute({assetid: val.value});
        this.setState({
            selectedAssetOption: val,
            selectedDeviceOption: null,
            selectedAttributeOption: null,
        });
    }

    handleAssetClassChange = (val) => {
        //  
        let allAssetOptions = [];
        let selectedClass = this.props.assetClass.find((item, index) => (item.assetclassid === val.value));
        this.props.getAssetClassAttribute({assetclassid:val.value});

        if (selectedClass && selectedClass.assets){
            allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
        }
        this.setState({
            selectedAssetClassOption: val,
            allAssetOptions: allAssetOptions,
            selectedAssetOption: null,
            selectedAttributeOption: null,
            isPostback: true,
        });
    }

    handleDeviceChange = (val) => {
        //  
        this.setState({
            selectedDeviceOption: val,
            btnDisable: true,
        });
    }

    handleAttributeChange  = (val) => {
        //  
        this.setState({
            selectedAttributeOption: val,
            btnDisable: true,
        });
    }

    handleDeviceClick = (e) => {
        const value = e.target.checked;
       
        this.setState(preState => {
            const option = value ? preState.selectedDeviceOption : null;
            return ({
                useDevice: value,
                selectedDeviceOption: option,
                // btnDisable: true,
            })
        });
    }

    // handleDeviceDropdownChange = (e) => {
    //     alert('test');
    //     let selectedValue = e.target.value;
    //     let index = e.target.selectedIndex;
    //     let name = e.target.options[index].text;
    //     // alert(name);
    //     this.setState(
    //         {
    //             currentDeviceId: selectedValue,
    //             currentDeviceName: name                
    //         }
    //     )
    // }
    
    onAddClick = (e) => {
        // let attriObj = this.state.attribute;
        // attriObj.displayname = this.state.displayname;
        if (this.state.isNew){
            // attriObj.attributename = this.state.attributename;
            // attriObj.attributetype = this.state.datatype;
        }
        // this.props.onAddClick(this.state.attributename, this.state.displayname, this.state.datatype);
        this.props.onAddClick(this.props.groupId, this.props.ruleId, this.state.selectedAssetOption, this.state.selectedDeviceOption, this.state.selectedAttributeOption, this.state.selectedAssetClassOption);
        // this.setState({
        //     name: '',
        //     btnDisable: false,
        // });
        this.setState({
            // selectedAssetClassOption: null,
            // selectedAssetOption: null,
            // selectedAttributeOption: null,
            isPostback: false,
        });
        this.props.toggle();
        this.clearParams();
    }

    onCancleClick = (e) =>{
        this.setState({
            name: '',
            btnDisable: false,
            isPostback: false,
            // selectedAssetClassOption: null,
            // selectedAssetOption: null,
            // selectedAttributeOption: null
        });
        this.props.toggle();
        this.clearParams();
    }

    render(){
        // const modalClass = classNames({
        //     'modal-dialog--colored': true,
        //     'modal-dialog--header': true,
        //   });
        // className={`modal-dialog--primary  ${modalClass}`}
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle}
            >
                <ModalHeader 
                    toggle={this.props.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    <Row>
                    <Col md={12}>
                        <Label   style={{ display: 'inline'}}>Asset Class</Label>
                        <Select            
                            // value= {this.state.selectedAssetOption}           
                            // options={this.state.allAssetOptions}
                            // onChange={this.handleAssetChange}

                            value= {this.state.selectedAssetClassOption}           
                            options={this.state.assetClassOptions}
                            onChange={this.handleAssetClassChange}
                        />
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                    <Col md={12}>
                        <Label  style={{ display: 'inline'}} >
                            <Input type="checkbox" checked={this.state.useDevice} onClick={this.handleDeviceClick}/> Assets
                        </Label>
                        { this.state.useDevice &&
                            // <Select
                            //     value= {this.state.selectedDeviceOption}  
                            //     options={this.state.AssetDeviceOptions}
                            //     onChange={this.handleDeviceChange}
                            // />

                            <Select            
                                value= {this.state.selectedAssetOption}           
                                options={this.state.allAssetOptions}
                                onChange={this.handleAssetChange}
                            />
                        }
                    </Col>
                    </Row>
                    <br/>
                    <Row>
                    <Col md={12}>
                        <Label  style={{ display: 'inline'}} >Attributes</Label>
                        <Select
                            value= {this.state.selectedAttributeOption}  
                            options={this.state.AssetAttributeOption}
                            onChange={this.handleAttributeChange}
                        />
                    </Col>
                    </Row>
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="devicename" >Device</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="select" id="devicename" name="devicename" width="90%" value={this.props.currentDeviceId} onChange={this.handleDeviceDropdownChange} >             
                                    <option value = {0}>All</option>                
                                        {this.props.currentDeviceList.map((item,i) => {
                                            return(
                                                <option key={i} value={item.deviceid}>{item.devicename}</option>
                                            )
                                        })}                                  
                                </Input>
                            </Col>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button outline color="primary" onClick={this.onAddClick} disabled={!this.state.btnDisable}>Add</Button>{' '}
                    <Button outline color="secondary" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }
}

// export default AssetSelectModal;

function mapStateToProps(state) {
    
     
    // const allfences = state.fence.fenceData;
    // const assetClass = state.mapboard.assetClass;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const props = {assetClassAttr};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getAssetClassAttribute, clearAssetClassAttribute}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetSelectModal);

