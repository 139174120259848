/* eslint-disable */
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { userLogout } from '../../redux/actions/userAction';

class Logout extends Component {
    componentDidMount() {
        sessionStorage.clear();
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('companyCode');
        sessionStorage.removeItem('department');
        sessionStorage.removeItem('businessUnit');
        sessionStorage.removeItem('network');
        sessionStorage.removeItem('currentUserObj');
        sessionStorage.removeItem('jToken');
        // sessionStorage.setItem('locale', 'en_CA');
        // sessionStorage.setItem('loggedIn', 'false');
        // sessionStorage.removeItem('username');
        // sessionStorage.removeItem('userrole');
        // sessionStorage.removeItem('loggedIn');
        // sessionStorage.removeItem('locationCode');
        this.props.userLogout();
        document.getElementsByTagName("html").item(0).style = "";
    }

    render() {
        return (
            <Redirect to='/log_in' />
        );
    }
}

// export default Logout;
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ userLogout }, dispatch);
};

export default connect(null, mapDispatchToProps)(Logout);
