/* eslint-disable */
import { stat } from 'fs-extra';
import {surveilActionTypes} from '../../constants/actionTypes'
import SurveillancePage from '../../containers/Surveillance';

const initialState = {
    assets: [], //asset list to show in surveillance map
    licenseplates: [],
    faceRecords: [],
    faceDetectionRecords: [],
    peopleCountRecords: [],
}

export default function(state = initialState, action) {
    const {type, payload} = action;
    switch(type) {
        case surveilActionTypes.ADD_ASSET:
            let assetList = state.assets
            let assetToAdd = payload;
            if(assetList.includes(assetToAdd)){ //if item already in list
                return state
            }else{
                //store the list of assets in the local storage for now, later will have to post to backend
                let list = JSON.stringify([...assetList, assetToAdd])
                sessionStorage.setItem("tempList", list)
                return {
                    ...state,
                    assets: [...assetList, assetToAdd]
                }
            }
        
        case surveilActionTypes.LOAD_ASSETS:
            return {
                ...state,
                assets: payload
            }

        case surveilActionTypes.CLEAR_ASSETS:
            sessionStorage.setItem("tempList", "[]")
            return {
                ...state,
                assets: []
            }
        
        case surveilActionTypes.LOAD_LPR_SUCCEED:
            // console.log('LOAD LPR SUCCEED')
            return {
                ...state,
                licenseplates: payload
            }

        case surveilActionTypes.LOAD_FACE_RECOGNITION_SUCCEED:
            // console.log('LOAD FR SUCCEED', payload)
            return {
                ...state,
                faceRecords: payload
            }
        case surveilActionTypes.LOAD_FACE_DETECTION_SUCCEED:
            // console.log('LOAD FR SUCCEED', payload)
            return {
                ...state,
                faceDetectionRecords: payload
            }
        case surveilActionTypes.LOAD_PEOPLE_COUNT_SUCCEED:
            // console.log('LOAD PC SUCCEED', payload)
            return {
                ...state,
                peopleCountRecords: payload
            }

        default:
            return state;
    }
}
