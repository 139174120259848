/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody, Form, CustomInput } from 'reactstrap';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableLargeIcon from 'mdi-react/TableLargeIcon';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';
import NumericIcon from 'mdi-react/NumericIcon';
import ImageIcon from 'mdi-react/ImageIcon';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { withStyles } from "@material-ui/core/styles";
import DashboardWidgetEditModal from './DashboardWidgetEditModal';
import DashboardAddmodal from './DashboardAddmodal';

class DasBoardEditModal extends Component{
    constructor(props){
        super(props);
        this.state={
            tital: 'Dashboard Element',
            modalList: this.props.modalList,
            isLocked: false,
        }
    }

    componentWillReceiveProps(nextProps){
        console.log('dash modal will get props')
        if (nextProps.modalList && nextProps.modalList != this.state.rawModalList){
            let islocked = 0;
            if (nextProps.modalList && nextProps.modalList[0]){
                islocked = (nextProps.modalList[0].locked === 1);
            }
            this.setState({
                modalList: JSON.parse(JSON.stringify(nextProps.modalList)),
                rawModalList: nextProps.modalList,
                isLocked: islocked,
            });
        }
    }

    handleCheckChange = (e, index) => {
        // alert(index);
        // let value = e.target.checked;
        let value = e.target.checked ? 1 : 0
        this.setState((preState)=>{
            // preState.modalList[index].hidden = value;
            preState.modalList[index].display = value;
             
            return({
                modalList: preState.modalList,
            });
        });
    }

    onOkayClick = () => {
         
         
        this.props.onDashBoardUpdateClick(this.state.modalList);
        this.props.toggle();
    }

    onCancleClick = () => {
         
        this.props.toggle();
    }

    onIconClick = (e, layout, index) => {
         
        this.setState({
            currentLayout: layout,
            currentLayoutIndex : index
        });
        this.props.editModalToggle();
    }

    onEditOkayClick = (index, currentParameter, currentName) => {
        // layout.name = currentName;
        // layout.parameter = currentParameter;
         
         
        //  
        this.setState((preState)=>{
            // preState.modalList[index].hidden = value;
           preState.modalList[index].name = currentName;
           preState.modalList[index].parameter = currentParameter;
            
            
            return({
                modalList: preState.modalList, //JSON.parse(JSON.stringify(preState.modalList)),
            });
        });

        // this.setState((preState)=>{
        //     // preState.modalList[index].hidden = value;
        //     preState.modalList[index].name = currentName;
        //      
        //      
        //     return({
        //         modalList: preState.modalList,
        //     });
        // });
    }

    renderIcon = (layout, index) => {
        // console.log('LAYOUT', layout)
        let icon = null;
        switch (layout.type) {
            case 'map':
            case 'AssetMap':
                return <GoogleMapsIcon className={layout.display ? 'dashboard-checked': 'unchecked'} color={'#ff0000'} size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'chart':
            case 'DeviceChart':
            case 'DviceUsage':
            case 'MsgCountChart':
            case 'AttributeChart':
                return <ChartBarIcon className={layout.display ? 'dashboard-checked': 'unchecked'} color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'table':
            case 'AlertTable':
            case 'ActionTable':
            case 'DeviceTable':
            case 'AssetTable':
                return <TableLargeIcon className={layout.display ? 'dashboard-checked': 'unchecked'} color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}} />;
                // break;
            case 'AttributeValue':
                return <NumericIcon className={layout.display ? 'dashboard-checked': 'unchecked'} color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
            case 'AssetElement':
                return <ImageIcon className={layout.display ? 'dashboard-checked': 'unchecked'} color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
            default: 
                return null;
        }

        // return {icon};
    }

    handleChange = name => event => {
        // this.setState({ ...this.state, [name]: event.target.checked });
        let isLocked = event.target.checked;
        this.setState((prevState) => {
            prevState.modalList.forEach((element, index)=>{
                if (isLocked){
                    element.locked = 1;
                }
                else{
                    element.locked = 0;
                }
            });
            return(
                { ...this.state, 
                    modalList: prevState.modalList,
                    [name]: isLocked, 
                }
            )
        });
    };

    render(){
        let currentLayout = null;
        if (this.state.modalList && this.state.modalList.length>0) {
            const {layoutname, templateid, templatename, selected} = this.state.modalList[0];
            currentLayout = {layoutname, templateid, templatename, selected};
        }
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle} size='lg'>
                <ModalHeader toggle={this.props.toggle}>{this.state.tital}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={9}/>
                        <Col md={3}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <GreenSwitch
                                        checked={this.state.isLocked}
                                        onChange={this.handleChange('isLocked')}
                                        value="Lock Screen"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                }
                                label='Lock Screen'
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        {/* <Col md={12}>
                            <Row style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                                <Col md={3} style={{ textAlign: 'left' }}>
                                    <Label htmlFor='react-select-2-input'>{this.props.assetName}</Label>
                                </Col>
                                <Col md={9}>
                                    <IconElement width={32} height={32} path={this.state.iconPath}/>
                                </Col>
                            </Row>
                        </Col> */}
                        {this.state.modalList && this.state.modalList.map((item, index)=>{
                            let checked = item.display == 1 ? true : false
                            return(
                                <Col md={4} key={index}>
                                <Card>
                                    {/* <CardTitle>{item.title}</CardTitle> */}
                                    <CardBody>
                                    <Row>
                                    {/* {item.title} */}
                                    {item.name}
                                    </Row>
                                        {this.renderIcon(item, index)}
                                        <FormGroup row>    
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    // checked={item.hidden}
                                                    checked = {checked}
                                                    onChange={(e) =>this.handleCheckChange(e, index)}
                                                    // value={item.hidden}
                                                    color="custom"
                                                    disabled = {this.state.isLocked}
                                                />
                                            }
                                            label="Shown"
                                        />
                                        </FormGroup>
                                </CardBody>
                                </Card>
                                </Col>
                            );
                        })}
                        <Col md={4}>
                        <Card>
                        <CardBody>
                            <Row>
                            Add New Element
                            </Row>
                            {/* <PlusCircleOutlineIcon color='#cdcdcd' size={50}/> */}
                            <DashboardAddmodal parentToggle={this.props.toggle} currentLayout={currentLayout}/>
                        </CardBody>
                        </Card>
                        </Col>
                    </Row>
                    {/* <hr/>    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkayClick} >Okay</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                    <DashboardWidgetEditModal 
                        nestedVisible={this.props.nestedVisible} 
                        toggle={this.props.editModalToggle} 
                        closeAll={this.props.closeAll}
                        layout = {this.state.currentLayout}
                        index = {this.state.currentLayoutIndex}
                        onEditOkayClick = {this.onEditOkayClick}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

export const GreenSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(18px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: theme.palette.grey[200],
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "var(--appTableHeader)",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        //   border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: "var(--appTableHeader)",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});


export default DasBoardEditModal;