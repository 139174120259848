/* eslint-disable */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import {bindActionCreators, compose} from 'redux';
import {connect} from "react-redux";
import {Redirect, withRouter} from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import {Alert, Col, Row} from "reactstrap";
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { forgotPassword } from '../../../redux/actions/userAction';
import { FORGOT_PASSWORD_ROOT } from '../../../constants/appConstants';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class ForgotPasswordForm extends PureComponent {

    constructor() {
        super();
        this.state = {
          showPassword: false,
          showErrors: false,
          isLoading: false,
          // forgetSuccess: '',
          username: '',
          password: '',
          errors: {},
          forgetPasswordMessage: ''
        };
      }
    
    componentWillUnmount() {
      this.setState({
          showPassword: false,
          showErrors: false,
          isLoading: false,
          username: '',
          password: '',
          errors: {},      
          loggedIn: 'false',
          forgetPasswordMessage: ''
      });

    }
    
      showPassword = (e) => {
        e.preventDefault();
        this.setState({
          showPassword: !this.state.showPassword,
          showErrors: false,
        });
      };
    
      onInputChange = (event) => {
        event.preventDefault();
        const{name, value} = event.target;
        switch (name) {
            case 'username':
                this.setState({
                    username: value,
                    showErrors: false
                });
                sessionStorage.setItem('username', event.target.value);
                break;
            case 'password':
                this.setState({
                    password: value,
                    showErrors: false
                });
                break;
            default:
                return this.state;
        }
      };
    
      handleTouch = (e) =>{
        e.preventDefault();
        this.setState({showErrors: false})
      }

      handleSubmit = (e) => {
        e.preventDefault();
        this.setState(
          {isLoading: true, showErrors: true}
      );
        this.props.forgotPassword(this.state.username);
      }
    
      renderError = () => {
        if (! this.props.isAuthenticated && this.props.error === 1) {
            return (
                <Alert className='form__form-group-field alert-danger text-center'>
                    <span className='text-center'>{this.props.message}</span>
                </Alert>
            )
        } else {
            return null;
        }
    };

    validateForm = () => {
      return this.state.username;
    }

    setPasswordMessage = (message) => {
        this.setState({forgetPasswordMessage: message})
    }
    
      render() {
        if ( sessionStorage.getItem('isAuthenticated') && 'true' == sessionStorage.getItem('isAuthenticated')){
          return <Redirect push to="/pages" />; 
        }

        this.setPasswordMessage(this.props.forgetPasswordMessage);
    
        return (
            <div className="account">
            <div className="account__wrapper">
                <div className="account__card">
                    <div className="account__head">
                      <h3 className="account__title">Forgot
                        <span className="account__logo"> Password
                          <span className="account__logo-accent">  Form</span>
                        </span>
                      </h3>
                    </div>

                    <form className="form" onSubmit={this.handleSubmit}>
                      <div className="form__form-group">
                        <span className="form__form-group-label">Username</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <AccountOutlineIcon style={{marginLeft: '8px', marginBottom: '10px'}} />
                          </div>
                          <input
                              name="username"
                              type="text"
                              value={this.state.username}
                              placeholder="Name"
                              onChange={this.onInputChange}
                              onClick={this.handleTouch}
                          />
                        </div>
                      </div>
                      <div className='form__form-group-field'>
                          {this.state.showErrors === true && this.renderError()}
                      </div>
                      <div className='form__form-group-field'>
                          <br/>
                      </div>
                      {/* <button className="btn btn-primary account__btn account__btn--small" type="submit" disabled = {!this.validateForm()}>Submit</button> */}
                      <ConfirmDeleteDialog 
                              color = "custom"
                              disabled = {!this.validateForm()}
                              outline={false}
                              label={"Submit"} 
                              noIcon={true}
                              className={'confirm-dialog'}
                              login={false}
                              style={{
                                padding:'10px 25px',
                                marginRight:'0px',
                                width:'100%'
                                // backgroundColor: '#678aff',
                                // borderColor:'#678aff'
                              }} 

                              onConfirm={(e)=>{this.handleSubmit(e)}}
                              msg={`Do you want to reset password? `}
                              />
                      <a className="btn btn-primary account__btn account__btn--small" href={FORGOT_PASSWORD_ROOT}>Back</a>
                    </form>

                    <h5>{this.state.forgetPasswordMessage}</h5>
                </div>
            </div>
            </div>
        );
      }
}


const mapStateToProps = (state) => {
    return state.user;
  };
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({forgotPassword}, dispatch);
  };
  
  const enhance = compose(
    connect(mapStateToProps,mapDispatchToProps),
    withRouter
  );
  
  export default enhance(ForgotPasswordForm);

          //   <div style={{margin: '100px'}}>
                  //   <h2>{this.props.forgetPasswordMessage}</h2>
        //   </div>