/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AttriburesList from '../components/AttributesList';
import AlertMessage from '../../AlertComponent/components/AlertMessage';
import {itemFinder, dropdownOnChangeHandler} from '../../../factories/utils';
import {loadDeviceTypes, loadAssets, getAttributes, upsertAttributes, deleteAttributes, closeAlertOff} from '../../../redux/actions/attributeMgmtAction';
import AttributeEditModal from '../components/AttributeEditModal';

class AttributeMgmtBoard extends Component{
    constructor(props){
        super(props);
        this.state={
            modalVisible: false,
            isNew: true,
            currentDeviceTypeId : '5cf828390499f50b995ae851',
            currentDeviceTypeName : '',
            currentAssetId : 6,
            currentAssetName : '',
            selectedAttributeId : 0,
            selectedAttribute: null,           
        }
    }
    // ----------------------------------------------------------------------------------------------
    componentDidMount(){
         
        this.props.loadDeviceTypes();  
        // this.props.loadAssets({devicetypeid: this.state.currentDeviceTypeId});
        // this.props.getAttributes(this.state.currentAssetId);
        this.props.getAttributes(this.state.currentDeviceTypeId);
         
         
    }
    //-----------------------------------------------------------------------------------------------
    componentWillReceiveProps(nextProps){
        if (nextProps.devicetypes && nextProps.devicetypes !== this.props.devicetypes){
            if (nextProps.devicetypes.length > 0){
                let currentDeviceTypeId = nextProps.devicetypes[0].devicetypeid;
                this.props.getAttributes(currentDeviceTypeId);
                this.setState({ currentDeviceTypeId : currentDeviceTypeId});
            }
        }
    }
    //-----------------------------------------------------------------------------------------------
     toggle = () => {
        //  alert('toggle');
        this.setState(prevState => ({
            modalVisible: !prevState.modalVisible
        }));
    }
    onAddClick = (attriObj) =>{
        // alert("Add");
         
        this.props.upsertAttributes(attriObj);
        // alert(name);
        // this.setState({
        //     currentrule:  {
        //         ruleid: 0,
        //         rulename:name,
        //         devicetypeid:this.state.currentDeviceTypeId,
        //         devicetypename:this.state.currentDeviceTypeName,
        //         deviceid:this.state.currentDeviceId,
        //         devicename:this.state.currentDeviceName,
        //         createtime: new Date().toISOString(),
        //         rule:[],
        //     },
        //     currentruleid: 0,
        //     showRuleEdieCard: true,
        // });
    }
    
    renderEditModal() {
        return(
            <AttributeEditModal 
                modalSetVisible={this.state.modalVisible}
                attribute={this.state.selectedAttribute}
                isNew={this.state.isNew}
                toggle={this.toggle} 
                handleDeviceTypeDropdownChange = {this.handleDeviceTypeDropdownChange}
                handleDeviceDropdownChange = {this.handleDeviceDropdownChange}
                onAddClick={this.onAddClick}
            />
        )
    }

    // ----------------------------------------------------------------------------------------------

    renderAlert(){
        return(                 
                 <AlertMessage
                    error = {this.props.error}
                    message =  {this.props.message}
                    messagevisible = {this.props.messagevisible}
                    messagetype = {this.props.messagetype} 
                    toggle = {this.props.closeAlertOff}
                 />
             ); 
     }

    // ----------------------------------------------------------------------------------------------
    manufactureOnChangeHandler = (e) => {
        let selectedValue = dropdownOnChangeHandler(e); //alert(selectedValue.id);
        this.props.getAttributes(selectedValue.id);
        this.props.loadAssets({devicetypeid: selectedValue.id});
        this.setState({
            currentDeviceTypeId : selectedValue.id,
            currentDeviceTypeName : selectedValue.name,
         });
        
    }

    assetOnChangeHandler = (e) => {
        let selectedValue = dropdownOnChangeHandler(e);
        this.setState({
            currentAssetId : selectedValue.id,
            currentAssetName : selectedValue.name,
        });
   }

   onSearchClick = (e) => {
    //    alert(this.state.currentAssetId);
        // this.props.getAttributes(this.state.currentAssetId);
        this.props.getAttributes(this.state.currentDeviceTypeId);
   }

    renderFilterBar = () => {
        return(
            <Col md={12}>
                <Card>
                <CardBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>

                            {/* <Col md={1} style={{textAlign: 'right' }}>
                                <Label for="deviceType"   style={{ display: 'inline'}}>Device Type</Label>
                            </Col> */}
                            <Col md={3}>
                                <Label for="deviceType"   style={{ display: 'inline'}}><h4><b>Device Type</b></h4></Label>
                                <Input type="select" id="deviceType" name="deviceType" width="60%" value = {this.state.currentDeviceTypeId} onChange={this.manufactureOnChangeHandler}>
                                    {this.props.devicetypes && this.props.devicetypes.map((type, i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))}
                                    {/* <option value={'0'}>ALL</option>
                                    <option value={'1'}>Manufacture 1</option> */}
                                </Input>
                            </Col>

                            <Col md={3}>
                                {/* <Label for="messageType"  style={{ display: 'inline'}} >Asset</Label>
                                <Input type="select" id="messageType" name="messageType" width="100%" value={this.state.currentAssetId} onChange={this.assetOnChangeHandler} >
                                    {this.props.assets && this.props.assets.map((asset, i )=>(<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))}
                                </Input> */}
                            </Col>
                            <Col md = {3}></Col>
                            {/* <Col md={2}></Col> */}
                            <Col md={3} style={{ textAlign: 'left',   justifyContent: 'center',alignItems: 'center' }}>

                                <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.onSearchClick}>
                                    Search
                                </Button>

                                {/* <Label for="theme" style={{ display: 'inline'}} >&nbsp;</Label>
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={() => {this.setModalVisible(true)}}>
                                    New Theme
                                </Button> */}
                            </Col>
                    </Row>
                </CardBody>
                </Card>
            </Col>
        );
    }

    // ----------------------------------------------------------------------------------------
    handleAddNew = () => {
        let newAttri =   {
            attributeid: 0,
            assetid: this.state.currentAssetId,
            assetname: this.state.currentAssetName,
            devicetype: this.state.currentDeviceTypeId,
            displayname: "",
            attributename: "",
            attributetype: "",
            editable: false,
            useradded: true
        }
        this.setState({
            selectedAttribute: newAttri,
            modalVisible: true,
            isNew: true,
        });
    }

    handleRowEditClick = (row) =>{
        let selectAttri = itemFinder(this.props.attributes, 'attributeid', row.attributeid);
        // alert(selectAttri.attributeid);
        this.setState({
            selectedAttribute: Object.assign({}, selectAttri),
            modalVisible: true,
            isNew: false,
        });
    }

    handleRowDeleteClick = (row) => {
        let attributeid = row.attributeid;
        // alert(attributeid);
        this.props.deleteAttributes(attributeid);
    }

    renderAttributeList= () => {
        return (
            <AttriburesList
                attributes = {this.props.attributes}
                devicetypes = {this.props.devicetypes}
                currentDeviceTypeId = {this.state.currentDeviceTypeId}
                manufactureOnChangeHandler = {this.manufactureOnChangeHandler}
                handleAddNew = {this.handleAddNew} 
                toggle = {this.toggle}
                handleRowEditClick = {this.handleRowEditClick}
                handleRowDeleteClick = {this.handleRowDeleteClick}
            />
        );
    }

    render(){
        return(
            <div style={{width: '100%'}}>
                {/* {this.renderFilterBar()} */}
                {this.renderAlert()}
                {this.renderAttributeList()}
                {this.renderEditModal()}
            </div>
        )
        
    }
}

// export default AttributeMgmtBoard;

function mapStateToProps(state) {
     
     
    
    const props = state.attributemgmt;
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDeviceTypes, loadAssets, getAttributes, upsertAttributes, deleteAttributes, closeAlertOff}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributeMgmtBoard);