/* eslint-disable */
import React, {useEffect, useState} from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';



const FaceDetectionList = ({list}) => {

    useEffect(() => {
        // console.log('Face Detection List', list);
    }, [list]);

    const cameraNameFormatter = (cell, row) => {
        return (
            <span>IPC</span>
        )
    }
    const cameraChannelFormatter = (cell, row) => {
        return (
            <span>1</span>
        )
    }
    const ageFormatter = (cell, row) => {
        if (cell == 1){
            return (
                <span>0-6</span>
            )
        }else if (cell == 2){
            return (
                <span>7-17</span>
            )
        }else if (cell == 3){
            return (
                <span>18-40</span>
            )
        }else if (cell == 4){
            return (
                <span>41-65</span>
            )
        }else{
            return (
                <span>{"> 66"}</span>
            )
        }
    }
    const sexFormatter = (cell, row) => {
        if(cell == 1){
            return (
                <span>Male</span>
            )
        }else if(cell == 2){
            return (
                <span>Female</span>
            )
        }else{
            return (
                <span>N/A</span>
            )
        }
    }
    const glassFormatter = (cell, row) => {
        if(cell == 0){
            return (
                <span>N/A</span>
            )
        }else if (cell == 1){
            return (
                <span>No Glasses</span>
            )
        }else if (cell == 2){
            return (
                <span>Ordinary Glasses</span>
            )
        }else{
            return (
                <span>Sunglasses</span>
            )
        }
    }
    const wearMaskFormatter = (cell, row) => {
        if(cell == 0){
            return(
                <span>N/A</span>
            )
        }else if (cell == 1){
            return(
                <span>No Mask</span>
            )
        }else{
            return(
                <span>Wear Mask</span>
            )
        }
    }

    const timeFormatter = (cell, row) => {
        return (
            <span>{moment(cell).utc().subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss')}</span>
        )
    }
    const renderTable = (data)=>{
        // console.log('FACE DATA', data)
        const columns=[
            {dataField: 'nvrIp', text: 'NVR IP'},
            // {dataField: 'nvrName', text: 'NVR Name', filter: textFilter({placeholder: 'Filter', style: {fontSize: '1em', display:'block'}})},
            {dataField: 'nvrName', text: 'NVR Name'},
            {dataField: 'cameraName', text: 'Camera Name', formatter :cameraNameFormatter},
            {dataField: 'cameraChannel', text: 'camera Channel',formatter: cameraChannelFormatter},
            {dataField: 'age', text: 'Age',formatter: ageFormatter},
            {dataField: 'sex', text: 'Sex',formatter: sexFormatter},
            {dataField: 'glasses', text: 'Glasses',formatter: glassFormatter},
            {dataField: 'wearMask', text: 'Wear Mask',formatter: wearMaskFormatter},
            {dataField: 'time', text: 'Time Recorded', formatter: timeFormatter},
            {dataField: 'stringFrameImg', text: 'Image', formatter: (value, row)=>(<img src={`data:image/png;base64,${value}`}/>)},
        ]
        if(data){
            // console.log('data existed')
            return (
                <BootstrapTable data={data} keyField='id' columns={columns} filter={filterFactory()}/>
            )
        }
    }

    return (
        renderTable(list)
    )

}

export default FaceDetectionList