/* eslint-disable */
import React, {useEffect, useState} from 'react'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { Popover, UncontrolledPopover, Button, PopoverBody, Card, CardTitle, CardBody, CardHeader, CardSubtitle, CardText, Row} from 'reactstrap';
const LicensePlateList = ({list, location}) => {
    const [plazaLocation, setplazaLocation] = useState('ITSP Office')
    useEffect(() => {
        console.log(list);
    }, [list]);
    useEffect(() => {
        if(location && location !== plazaLocation){
            setplazaLocation(location)
        }
    }, [location]);
    const licensePlateFormatter = (cell,row)=>{
        return (
            <>
                <Button outline id={`PopoverFocus${row.id}`} onClick={showData(row.id)}> {row.licensePlate} </Button>
            </>
        )
    }
    const expandRow = {
        renderer: row =>(
            <div>
                <Card>
                    <CardBody>
                        <CardTitle tag="h3">MTO Info:  <span style={{fontWeight: 'bold'}}>{row.licensePlate}</span></CardTitle>
                        <CardText>
                            <Row><h5>Province</h5>: Ontario</Row>
                            <Row><h5>Expiry date</h5>: XX/XX/XXXX</Row>
                            <Row><h5>Vehicle Type</h5>: XXX</Row>
                            <Row><h5>Maker</h5>: XXX</Row>
                            <Row><h5>Model</h5>: XXX</Row>
                            <Row><h5>Year</h5>: XXX</Row>
                        </CardText>
                    </CardBody>
                </Card>
            </div>
        )
        
    }
    const renderTable = (data)=>{
        console.log('PLATE DATA', data)
        const columns=[
            {dataField: 'cameraIp', text: 'Camera IP'},
            {dataField: 'cameraName', text: 'Camera Name', filter: textFilter({placeholder: 'Filter', style: {fontSize: '1em', display:'block'}})},
            {dataField: 'licensePlate', text: 'Plate Number', style:{fontWeight:'bold', fontSize:'1.25em'}, filter: textFilter({placeholder: 'Filter', style: {fontSize: '1em', display:'block'}})},
            {dataField: 'time', text: 'Time Recorded'},
        ]
        if(data){
            console.log('data existed')
            return (
                <BootstrapTable data={data} keyField='id' columns={columns} filter={filterFactory()} expandRow={expandRow}/>
            )
        }
    }
        
    return (
        <>
        <h3>{plazaLocation}</h3>
        {renderTable(list)}
        </>
    )
}

export default LicensePlateList
